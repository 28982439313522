import moment from "moment";
import { useEffect, useState } from "react";
import { getRegistrationStatuses } from "../../api/user";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import MainLayout from "../../components/layouts/MainLayout";
import ModalDetail from "../../components/registration/ModalDetail";
import ModalDownload from "../../components/registration/ModalDownload";
import { convertToRupiah } from "../../tools/numerictools";
import { BUILDING_STATUS } from "../../tools/userdatatools";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useRegisteredCostumer } from "../../utils/CostumerListHooks";
import { useCityRegionHooks } from "../../utils/RegionHooks";

function SalesRegisteredPage() {
  let { hasAccess } = useControlPermission();

  const [formFilter, setFormFilter] = useState({
    name: "",
    referral: "",
    n: "",
    registration_type: "",
    status: "",
    date_from: "",
    date_until: "",
    survey_status: "",
  });
  const [filter, setFilter] = useState({
    name: "",
    referral: "",
    n: "",
    registration_type: "",
    province: "",
    kota_kab: "",
    status: "",
    date_from: "",
    date_until: "",
    survey_status: "",
    page: 1,
    page_size: 10,
  });
  const regionFilter = useCityRegionHooks();
  const [selectedId, setSelectedId] = useState("");
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [registrationStatuses, setRegistrationStatuses] = useState([]);

  useEffect(() => {
    const getTypes = async () => {
      try {
        const { data } = await getRegistrationStatuses();
        setRegistrationStatuses(data.data);
      } catch (error) {}
    };

    getTypes();
  }, []);

  const customers = useRegisteredCostumer({ filter });

  useEffect(() => {
    if (customers.errorMsg) {
      setAlert({ show: true, message: customers.errorMsg, type: "danger" });
    }
  }, [customers.errorMsg]);

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(!showModalDetail);
  };

  const toggleModalDownload = () => {
    setShowModalDownload(!showModalDownload);
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const applyFilter = () => {
    let form_filter = {
      ...filter,
      ...formFilter,
      province: regionFilter.province,
      kota_kab: regionFilter.city,
      page: 1,
    };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    regionFilter.resetRegion();
    let formFilterReset = {
      name: "",
      referral: "",
      n: "",
      registration_type: "",
      status: "",
      date_from: "",
      date_until: "",
      survey_status: "",
    };
    let filterReset = {
      name: "",
      referral: "",
      n: "",
      registration_type: "",
      status: "",
      province: "",
      kota_kab: "",
      date_from: "",
      date_until: "",
      survey_status: "",
      page: 1,
      page_size: 10,
    };
    setFormFilter({ ...formFilterReset });
    setFilter({ ...filterReset });
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const printRow = (costumer, index) => {
    return (
      <tr key={index}>
        <td>
          <button
            className="btn btn-sm btn-block mb-1 btn-primary text-nowrap"
            onClick={() => toggleModalDetail(costumer.id)}
          >
            <i className="fas fa-eye"></i> Lihat Detail
          </button>
        </td>
        <td>{costumer.status.display_name}</td>
        <td>
          {costumer.billings[0] ? (
            <>
              <span className="badge badge-info">Sudah Disurvey</span>
              <br />
              {costumer.billings[0]?.status === "PAID" ? (
                costumer.status_id === 7 || costumer.status_id === 9 ? (
                  <span className="badge badge-primary">Sudah Dipasang</span>
                ) : (
                  <span className="badge badge-success">Siap Pasang</span>
                )
              ) : (
                <span className="badge badge-danger">Belum Bayar</span>
              )}
            </>
          ) : costumer.status_id <= 2 ? (
            <span className="badge badge-dark">Belum Cek Coverage</span>
          ) : (
            <span className="badge badge-warning">Belum Survey</span>
          )}
        </td>
        <td>{costumer.referral?.name || "-"}</td>
        <td className="text-nowrap">
          {moment.unix(costumer.createdAt).format("DD MMM YYYY - HH:mm")}
        </td>
        <td>{costumer.registration_number}</td>
        <td>{costumer.internet_package.name}</td>
        <td>
          {costumer.billings[0] ? `Rp. ${convertToRupiah(costumer.billings[0].final_amount)}` : "-"}
        </td>
        <td className="text-nowrap">
          {costumer.billings[0]
            ? costumer.billings[0].confirmed_date
              ? moment.unix(costumer.billings[0].confirmed_date).format("DD MMM YYYY - HH:mm")
              : "-"
            : "-"}
        </td>
        <td>{costumer.promo ? costumer.promo.code : "-"}</td>
        <td>{costumer.name}</td>
        <td className="text-wrap">
          {costumer.installation_address.address} RT {costumer.installation_address.rt}/RW{" "}
          {costumer.installation_address.rw}
        </td>
        <td>
          {costumer.installation_address.addresses.kelurahan} -{" "}
          {costumer.installation_address.addresses.postal_code}
        </td>
        <td>{BUILDING_STATUS[costumer.building_ownership_status]}</td>
        <td>{costumer.project?.name || "-"}</td>
      </tr>
    );
  };

  return (
    <MainLayout resource_key="sales">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Daftar Pendaftaran</h1>
            </div>
            <div className="col-auto">
              {hasAccess("export-sales-registration") && (
                <button className="btn btn-primary" onClick={() => toggleModalDownload()}>
                  <i className="fas fa-download"></i> Unduh Data Registrasi
                </button>
              )}
            </div>
          </div>
        </div>
        <Box
          title={`Daftar Pendaftaran (Total Data: ${customers.meta.count})`}
          loading={customers.loading}
        >
          <div className="mb-4">
            <div className="row">
              <div className="col-12 col-md-2">
                <label htmlFor="status">Status</label>
                <select
                  name="status"
                  id="status"
                  className="form-control"
                  value={formFilter.status}
                  onChange={(event) => changeFilter("status", event.target.value)}
                >
                  <option value="">SEMUA</option>
                  {registrationStatuses.map((type_status) => (
                    <option value={type_status.id} key={type_status.id}>
                      {type_status.display_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Status Survey</label>
                  <select
                    className="form-control"
                    value={formFilter.survey_status}
                    onChange={(event) => changeFilter("survey_status", event.target.value)}
                  >
                    <option value="">Semua</option>
                    <option value="no-survey">Belum Survey</option>
                    <option value="survey-unpaid">Sudah Survey - Belum Bayar</option>
                    <option value="survey-paid">Sudah Survey - Siap Pasang</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="referral">Nama Sales/Affiliate</label>
                  <input
                    type="text"
                    name="referral"
                    value={formFilter.referral}
                    onChange={(event) => changeFilter("referral", event.target.value)}
                    className="form-control"
                    id="referral"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="costumer_name">Nama Pelanggan</label>
                  <input
                    type="text"
                    name="costumer_name"
                    value={formFilter.name}
                    onChange={(event) => changeFilter("name", event.target.value)}
                    className="form-control"
                    id="costumer_name"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="customer_number">No. reg/Pelanggan</label>
                  <input
                    type="text"
                    name="customer_number"
                    value={formFilter.n}
                    onChange={(event) => changeFilter("n", event.target.value)}
                    className="form-control"
                    id="customer_number"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="date_from">Tanggal dari</label>
                  <input
                    type="date"
                    max={formFilter.date_until}
                    name="date_from"
                    value={formFilter.date_from}
                    onChange={(event) => changeFilter("date_from", event.target.value)}
                    className="form-control"
                    id="date_from"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="date_until">Tanggal ke</label>
                  <input
                    type="date"
                    min={formFilter.date_from}
                    name="date_to"
                    value={formFilter.date_until}
                    onChange={(event) => changeFilter("date_until", event.target.value)}
                    className="form-control"
                    id="date_until"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <label>Provinsi</label>
                <select
                  className="form-control"
                  value={regionFilter.province}
                  onChange={(event) => regionFilter.changeProvince(event.target.value)}
                >
                  <option value="">-- Semua Provinsi --</option>
                  {regionFilter.provinces.map((province, index) => (
                    <option value={province.province} key={index}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-2">
                <label>Kabupaten</label>
                <select
                  className="form-control"
                  value={regionFilter.city}
                  onChange={(event) => regionFilter.changeCity(event.target.value)}
                >
                  <option value="">-- Semua Kabupaten --</option>
                  {regionFilter.cities.map((city, index) => (
                    <option value={city.kota_kab} key={index}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <button
                    className="btn btn-primary"
                    onClick={() => applyFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => resetFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div style={{ overflowX: "scroll" }}>
            <table className="table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>Status</th>
                  <th>Status Survey</th>
                  <th>Nama Sales/Affiliator</th>
                  <th>Tanggal Pendaftaran</th>
                  <th>Nomor Registrasi</th>
                  <th>Pilihan Paket</th>
                  <th>Total Pembayaran</th>
                  <th>Konfirmasi Pembayaran</th>
                  <th>Promo</th>
                  <th>Nama Lengkap</th>
                  <th>Alamat Pemasangan</th>
                  <th>Kelurahan Pemasangan</th>
                  <th>Kepemilikan Lokasi</th>
                  <th>Grup/Project</th>
                </tr>
              </thead>
              <tbody>
                {customers.costumers.length === 0 && (
                  <tr>
                    <td colSpan={15} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {customers.costumers.map((costumer, index) => printRow(costumer, index))}
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            {customers.meta.count > customers.meta.page_size && (
              <Pagination
                totalpage={Math.ceil(customers.meta.count / customers.meta.page_size)}
                selectedpage={customers.meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(customers.meta.page + 1)}
                prev={() => gotoPage(customers.meta.page - 1)}
              />
            )}
          </div>
        </Box>

        <ModalDetail
          show={showModalDetail}
          id={selectedId}
          setShow={toggleModalDetail}
        ></ModalDetail>
        <ModalDownload
          show={showModalDownload}
          setShow={toggleModalDownload}
          statuses={registrationStatuses}
        ></ModalDownload>
      </div>
    </MainLayout>
  );
}

export default SalesRegisteredPage;
