import { apiAuth } from "./base";

export function getAllRoles(params) {
  let api = apiAuth();
  return api.get(`v1/permissions/roles`, { params });
}

export function getAllResources() {
  let api = apiAuth();
  return api.get(`v1/permissions/resources`);
}

export function getAllPermissions(params) {
  let api = apiAuth();
  return api.get(`v1/permissions`, { params });
}

export function getPermissionById(id) {
  let api = apiAuth();
  return api.get(`v1/permissions/${id}`);
}

export function getPermissionByRoleId(id) {
  let api = apiAuth();
  return api.get(`v1/permissions/roles/${id}`);
}

export function updatePermissionById(id, data) {
  let api = apiAuth();
  return api.put(`v1/permissions/${id}`, data);
}

export function addRoleOnPermission(id, role_id) {
  let api = apiAuth();
  return api.post(`v1/permissions/${id}/roles/${role_id}`);
}

export function deleteRoleOnPermission(id, role_id) {
  let api = apiAuth();
  return api.delete(`v1/permissions/${id}/roles/${role_id}`);
}

export function getPermissionsByAuth(params) {
  let api = apiAuth();
  return api.get(`v1/auths/permissions`, {params});
}