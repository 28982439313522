import { apiAuth, apiDownloadAuth } from "./base";

export function getAllTickets(params) {
  let api = apiAuth();
  return api.get(`v1/tickets`, { params });
}

export function createTicket(data) {
  let api = apiAuth();
  return api.post(`v1/tickets`, data);
}

export function getTicketById(id) {
  let api = apiAuth();
  return api.get(`v1/tickets/${id}`);
}

export function createTikcetDetail(id, data) {
  let api = apiAuth();
  return api.post(`v1/tickets/${id}/details`, data);
}

export function downloadTickets(params = {}) {
  let api =  apiDownloadAuth();
  return api.get("v1/tickets/export/xlsx", {params});
}