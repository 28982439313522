import { useState } from "react";
import Box from "../../Box";

export default function BoxPreviewExcel({ preview_inventories, upload_info }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  if (preview_inventories.length === 0) return <div />;
  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the data array to get the current page's data
  const currentData = preview_inventories.slice(startIndex, endIndex);
  return (
    <Box title="Preview Barang yang Diunggah">
      <p>
        <b>Tipe:</b> {upload_info?.type || "-"}
      </p>
      <p>
        <b>Total SKU:</b> {upload_info?.total_sku || 0}
      </p>
      <p>
        <b>Total Stok:</b> {upload_info?.total_stock || 0}
      </p>
      {upload_info?.type === "inventory" && <TableInventories currentData={currentData} />}

      {upload_info?.type === "modem" && <TableModem currentData={currentData} />}
      <div className="d-flex justify-content-end" style={{ gap: "10px" }}>
        <button
          className="btn btn-primary"
          onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fa fa-arrow-left"></i> Prev
        </button>
        <button
          className="btn btn-primary"
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          disabled={endIndex >= preview_inventories.length}
        >
          Next <i className="fa fa-arrow-right"></i>
        </button>
      </div>
    </Box>
  );
}

function TableInventories({ currentData }) {
  return (
    <table className="table table-hover table-striped table-bordered">
      <thead>
        <tr>
          <th>SKU</th>
          <th>Qty</th>
        </tr>
      </thead>
      <tbody>
        {currentData.length === 0 && (
          <tr>
            <td colSpan={2} className="text-center">
              Tidak ada data yang ditampilkan
            </td>
          </tr>
        )}
        {currentData.map((inventory, i) => (
          <tr key={i}>
            <td>{inventory.sku}</td>
            <td>{inventory.stock}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function TableModem({ currentData }) {
  return (
    <table className="table table-hover table-striped table-bordered">
      <thead>
        <tr>
          <th>SKU</th>
          <th>Tipe</th>
          <th>Brand</th>
          <th>Serial Number</th>
          <th>GPON</th>
        </tr>
      </thead>
      <tbody>
        {currentData.length === 0 && (
          <tr>
            <td colSpan={5} className="text-center">
              Tidak ada data yang ditampilkan
            </td>
          </tr>
        )}
        {currentData.map((inventory, i) => (
          <tr key={i}>
            <td>{inventory.sku}</td>
            <td>{inventory.type}</td>
            <td>{inventory.brand}</td>
            <td>{inventory.serial_number_modem}</td>
            <td>{inventory.serial_number_gpon}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
