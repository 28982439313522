import { useState, useContext } from "react";
import { getPermissionsByAuth } from "../api/roleaccess";
import { createContext } from "react";
import { useCallback } from "react";

const controlPermissionContext = createContext();
// Provider component that wraps your app and makes permission object ...
// ... available to any child component that calls usePermission().
export function ProvideControlPermission({ children }) {
  const [permission, setPermissions] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getPermission = useCallback(async (r = "") => {
    setLoading(true);
    setErrorMsg('');
    try {
      const { data } = await getPermissionsByAuth({ r });
      
      let permitTemp = {};
      data.data.forEach(menu => {
        permitTemp[menu.name] = true
      });

      setPermissions({ ...permitTemp })
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }, [])

  const hasAccess = (name) => {
    if (permission[name]) {
      return true;
    }

    return false;
  };

  const ctxValue = {
    permission,
    errorMsg,
    loading,
    getPermission,
    hasAccess
  }

  return <controlPermissionContext.Provider value={ctxValue}>{children}</controlPermissionContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useControlPermission = () => {
  return useContext(controlPermissionContext);
};