import { useEffect } from "react";
import { useState } from "react";
import {
  getCategories,
  getCategoryById,
  getInventoriesByWarehouse,
  getWarehouseById,
  getWarehouses,
  getInventoryById,
  getModemByInventory,
  getModemByInventoryId,
  getRequestStockout,
  getModemBySerial,
  getRequestStockById,
  getSetupModem,
  getUnsavedModem,
  getInventoryOutById,
  getInventoryInById,
  getInventoryHistoryDetailById,
  getModemByWarehouseId,
} from "../api/inventory";
import { getRegistrationSetupModem } from "../api/user";

export function useWarehousesHooks({ filter }) {
  const [warehouses, setWarehouses] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchWarehouses = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getWarehouses({ ...filter });
      setWarehouses(data.data);
      setMeta(data.pagination);
    } catch (error) {
      console.log(error);
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchWarehouses(filter);
  }, [filter]);

  return {
    warehouses,
    meta,
    loading,
    errorMsg,
    fetchWarehouses,
  };
}

export function useWarehouseHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getWarehouse(id);
    }
  }, [id]);

  const getWarehouse = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getWarehouseById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getWarehouse,
  };
}

export function useInventoriesByWarehouseHooks({ filter }) {
  const [inventories, setInventories] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const fetchInventories = async (filter = {}) => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      const { data } = await getInventoriesByWarehouse(filter.id, { ...filter });
      setInventories(data.data);
      setMeta(data.pagination);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchInventories(filter);
    }
  }, [filter]);

  return {
    inventories,
    meta,
    loading,
    errorMsg,
    fetchInventories,
    fetched,
  };
}

export function useInventoryHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getInventory(id);
    }
  }, [id]);

  const getInventory = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getInventoryById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getInventory,
  };
}

export function useModemByInventoryHooks({ id, filter = {} }) {
  const [inventories, setInventories] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchInventories = async (id, filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getModemByInventory(id, { ...filter });
      setInventories(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchInventories(id, filter);
    }
  }, [filter, id]);

  return {
    inventories,
    meta,
    loading,
    errorMsg,
    fetchInventories,
  };
}

export function useModemHooks({ id, inventoryId }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getModem(id);
    }
  }, [id]);

  const getModem = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getModemByInventoryId(inventoryId, id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getModem,
  };
}

export function useModemBySerialHooks() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  const getModem = async (serial, warehouse) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getModemBySerial({ serial_number_modem: serial, warehouse });
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  const reset = () => {
    setData({});
    setError("");
    setLoading(false);
    setSuccess(null);
    setFetched(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getModem,
    reset,
  };
}

export function useUnsavedModemListHooks({ filter }) {
  const [modems, setModems] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchUnsavedModem = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getUnsavedModem({ ...filter });
      setModems(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUnsavedModem(filter);
  }, []);

  return {
    modems,
    meta,
    loading,
    errorMsg,
    fetchUnsavedModem,
  };
}

export function useSetupModemListHooks({ filter }) {
  const [modems, setModems] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchSetupModem = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getSetupModem({ ...filter });
      setModems(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSetupModem(filter);
  }, []);

  return {
    modems,
    meta,
    loading,
    errorMsg,
    fetchSetupModem,
  };
}

export function useInventoryCategories({ filter }) {
  const [categories, setCategories] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, pageSize: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchInventoryCategories = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getCategories({ ...filter });
      setCategories(data.data);
      setMeta(data.pagination);
    } catch (error) {
      console.log(error);
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInventoryCategories(filter);
  }, []);

  return {
    categories,
    meta,
    loading,
    errorMsg,
    fetchInventoryCategories,
  };
}

export function useCategoryId({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getCategory(id);
    }
  }, [id]);

  const getCategory = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getCategoryById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getCategory,
  };
}

export function useRequestStockOutList({ filter }) {
  const [requests, setRequests] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, pageSize: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchInventories = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getRequestStockout({ ...filter });
      setRequests(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInventories(filter);
  }, [filter]);

  return {
    requests,
    meta,
    loading,
    errorMsg,
    fetchInventories,
  };
}

export function useRequestStockId({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getRequest(id);
    }
  }, [id]);

  const getRequest = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getRequestStockById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getRequest,
  };
}

export function useModemByRegistrationHooks() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  const getRegistration = async (registration_number) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getRegistrationSetupModem({ registration_number: registration_number });
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  const reset = () => {
    setData({});
    setError("");
    setLoading(false);
    setSuccess(null);
    setFetched(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getRegistration,
    reset,
  };
}

export function useInventoryInHooks({ filter }) {
  const [histories, setHistories] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchHistories = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getInventoryInById(filter.id, { ...filter });
      setHistories(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchHistories(filter);
    }
  }, [filter]);

  return {
    histories,
    meta,
    loading,
    errorMsg,
    fetchHistories,
  };
}

export function useInventoryOutHooks({ filter }) {
  const [histories, setHistories] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchHistories = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getInventoryOutById(filter.id, { ...filter });
      setHistories(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchHistories(filter);
    }
  }, [filter]);

  return {
    histories,
    meta,
    loading,
    errorMsg,
    fetchHistories,
  };
}

export function useInventoryDetailHistoryHooks({ filter }) {
  const [histories, setHistories] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchHistories = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getInventoryHistoryDetailById(
        filter.id,
        filter.type,
        filter.history_id,
        { page_size: filter.page_size, page: filter.page }
      );
      setHistories(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchHistories(filter);
    }
  }, [filter]);

  return {
    histories,
    meta,
    loading,
    errorMsg,
    fetchHistories,
  };
}

export function useModemsByWarehouse({ filter }) {
  const [modems, setModems] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchModems = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getModemByWarehouseId(filter.id, { ...filter });
      setModems(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchModems(filter);
    }
  }, [filter]);

  return {
    modems,
    meta,
    loading,
    errorMsg,
    fetchModems,
  };
}
