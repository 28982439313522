import { useState, useEffect } from "react";
import { setupModemByRegNumber } from "../../api/inventory";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import { RequiredAsterisk } from "../../components/FormHelper";
import MainLayout from "../../components/layouts/MainLayout";
import BoxNasList from "../../components/radius/BoxNasList";
import { useModemByRegistrationHooks, useModemBySerialHooks } from "../../utils/InventoryHooks";
import { useALlFramedPool } from "../../utils/radius/FramedPoolUtil";
import { useAuth } from "../../utils/AuthHooks";
import BoxUnsavedModem from "../../components/inventory/modem/BoxUnsavedModem";
import BoxSetupModem from "../../components/inventory/modem/BoxSetupModem";
import BoxDismantleModem from "../../components/inventory/modem/BoxDismantleModem";
import BoxRelocateModem from "../../components/inventory/modem/BoxRelocateModem";
import Toggle from "react-toggle";
import IconvestFormSetupModem from "../../components/inventory/IconvestFormSetupModem";

export default function SetupModemPage() {
  const auth = useAuth();
  let modem = useModemBySerialHooks();
  let registrationData = useModemByRegistrationHooks();
  let [loading, setLoading] = useState(false);
  let framedPools = useALlFramedPool({});

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [regisNumber, setRegisNumber] = useState("");
  let [inputSerial, setInputSerial] = useState("");
  let [override, setOverride] = useState(false);
  let [modemInfo, setModemInfo] = useState({ serial_number_gpon: "" });
  let [form, setForm] = useState({
    pppoe_username: "",
    pppoe_password: "",
    framed_pool: "",
    new_framed_pool: "",
    olt: "",
    inventory_modem_id: "",
    vlan: "",
    odp: "",
    odc: "",
    odp_port: "",
    ont_damping: "",
    odp_damping: "",
  });

  useEffect(() => {
    const loadDataForm = () => {
      setInputSerial(registrationData.data.inventory_modem_pair.modem?.serial_number_modem || "");

      if (registrationData.data.project?.partner?.code !== "ICONNET") {
        setForm({
          pppoe_username: registrationData.data.inventory_modem_pair.modem?.pppoe_username || "",
          pppoe_password: registrationData.data.inventory_modem_pair.modem?.pppoe_password || "",
          framed_pool: registrationData.data.inventory_modem_pair.modem?.framed_pool || "",
          olt: registrationData.data.inventory_modem_pair.modem?.olt || "",
          vlan: registrationData.data.inventory_modem_pair.modem?.vlan || "",
          odp: registrationData.data.inventory_modem_pair.modem?.odp || "",
          odc: registrationData.data.inventory_modem_pair.modem?.odc || "",
          odp_port: registrationData.data.inventory_modem_pair.modem?.odp_port || "",
          ont_damping: registrationData.data.inventory_modem_pair.modem?.ont_damping || "",
          odp_damping: registrationData.data.inventory_modem_pair.modem?.odp_damping || "",
          inventory_modem_id: registrationData.data.inventory_modem_pair.modem?.id,
        });
      }

      setModemInfo({ ...registrationData.data.inventory_modem_pair.modem });
      if (registrationData.data.inventory_modem_pair.modem.automation === 1) {
        setOverride(false);
      } else {
        setOverride(true);
      }
    };

    if (registrationData.fetched) {
      if (registrationData.data.inventory_modem_pair !== null) {
        loadDataForm();
      } else {
        setForm({
          pppoe_username: "",
          pppoe_password: "",
          framed_pool: "",
          olt: "",
          vlan: "",
          odp: "",
          odc: "",
          inventory_modem_id: "",
          odp_port: "",
          ont_damping: "",
          odp_damping: "",
        });
        setInputSerial("");
        setModemInfo({ serial_number_gpon: "", automation: 1 });
      }
      modem.reset();
    }
  }, [registrationData.fetched]);

  useEffect(() => {
    if (modem.fetched) {
      setForm({ ...form, inventory_modem_id: modem.data.id });
      setModemInfo({ ...modem.data });
      if (modem.data.automation === 1) {
        setOverride(false);
      } else {
        setOverride(true);
      }
    }
  }, [modem.fetched]);

  const checkSerial = (event) => {
    event.preventDefault();
    if (inputSerial) {
      modem.getModem(inputSerial);
    }
  };

  const loadRegistration = (event) => {
    event.preventDefault();
    if (regisNumber) {
      registrationData.getRegistration(regisNumber);
    }
  };

  const resetForm = () => {
    setRegisNumber("");
    registrationData.reset();
    modem.reset();
    setInputSerial("");
    setOverride(false);
    setModemInfo({ serial_number_gpon: "" });
    setForm({
      pppoe_username: "",
      pppoe_password: "",
      framed_pool: "",
      new_framed_pool: "",
      inventory_modem_id: "",
      olt: "",
      vlan: "",
      odp: "",
      odc: "",
      odp_port: "",
      ont_damping: "",
      odp_damping: "",
    });
  };

  const setupModem = async (form_data) => {
    setLoading(true);
    try {
      let { data } = await setupModemByRegNumber(registrationData.data.id, form_data);
      setAlert({ show: true, message: data.message, type: "success" });
      resetForm();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message: message, type: "danger" });
    }

    window.scrollTo(0, 0);
    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      pppoe_username: form.pppoe_username,
      pppoe_password: form.pppoe_password,
      framed_pool: form.framed_pool,
      olt: form.olt,
      vlan: form.vlan,
      odp: form.odp,
      odc: form.odc,
      inventory_modem_id: form.inventory_modem_id,
      override_automation: String(override),
      odp_port: form.odp_port,
      ont_damping: form.ont_damping.toString(),
      odp_damping: form.odp_damping.toString(),
    };

    if (formData.framed_pool === "add_new") {
      formData.framed_pool = "";
      formData.new_framed_pool = form.new_framed_pool;
    }

    setupModem(formData);
  };

  const submitDataIconvest = (iconnetForm) => {
    let formData = {
      ...iconnetForm,
    };

    setupModem(formData);
  };

  const disabledByRole = (roles = [], allowed = []) => {
    return roles.some((role) => allowed.includes(role.name));
  };

  const canEditForm = () => {
    if (modemInfo.automation === 1) {
      // jika modem bisa di-otomasi
      // maka return override value
      // jika override true, maka form dapat diedit
      return override;
    }

    return true;
  };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Setup Modem</h1>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4">
            <Box title="Form Setup Modem">
              <form onSubmit={(event) => loadRegistration(event)}>
                <div className="form-group">
                  <label>Nomor Registrasi:</label>
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        value={regisNumber}
                        onChange={(event) => setRegisNumber(event.target.value)}
                        className="form-control"
                        placeholder="Masukan nomor registrasi"
                      />
                      {registrationData.loading && (
                        <span className="text-dark">Mengecek registrasi...</span>
                      )}
                      {registrationData.fetched && (
                        <span className="text-success">Berhasil memuat data registrasi</span>
                      )}
                      {registrationData.error && (
                        <span className="text-danger">{registrationData.error}</span>
                      )}
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={registrationData.loading}
                      >
                        Input Nomor
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-12 pt-3 pb-2">
                  <h4>Informasi Pelanggan</h4>
                </div>
                <div className="col-12">
                  <AlertBox {...alert} setAlert={setAlert} />
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>Nomor Registrasi</td>
                        <td>{registrationData.data.registration_number || "-"}</td>
                      </tr>
                      <tr>
                        <td>Nama</td>
                        <td>{registrationData.data.name || "-"}</td>
                      </tr>
                      <tr>
                        <td>Paket</td>
                        <td>
                          {registrationData.data.internet_package?.name || "-"}{" "}
                          {registrationData.data.internet_package &&
                            `(${registrationData.data.internet_package.internet_package_varian.amount} ${registrationData.data.internet_package.internet_package_varian.unit})`}
                        </td>
                      </tr>
                      <tr>
                        <td>Status Pendaftaran</td>
                        <td>{registrationData.data.status?.display_name || "-"}</td>
                      </tr>
                      <tr>
                        <td>Status Billing</td>
                        <td>{registrationData.data.billing_status || "-"}</td>
                      </tr>
                      <tr>
                        <td>Catatan</td>
                        <td>{registrationData.data.note || "-"}</td>
                      </tr>
                      <tr>
                        <td>Alamat Pemasangan</td>
                        <td>
                          {registrationData.data.installation_address ? (
                            <>
                              {registrationData.data.installation_address?.address} RT{" "}
                              {registrationData.data.installation_address?.rt} RW{" "}
                              {registrationData.data.installation_address?.rw},
                              {registrationData.data.installation_address?.addresses.kelurahan},{" "}
                              {registrationData.data.installation_address?.addresses.kecamatan},{" "}
                              {registrationData.data.installation_address?.addresses.kota_kab},{" "}
                              {registrationData.data.installation_address?.addresses.province},{" "}
                              {registrationData.data.installation_address?.addresses.postal_code}
                            </>
                          ) : (
                            <>-</>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="pt-3 pb-2">
                    <h4>Informasi Perangkat</h4>
                  </div>
                  {registrationData.data.project?.partner?.code !== "ICONNET" ? (
                    <>
                      <form onSubmit={(event) => submitData(event)}>
                        <div className="form-group">
                          <label>Nomor Serial Modem:</label>
                          <div className="row">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Serial Modem"
                                value={inputSerial}
                                onChange={(event) => setInputSerial(event.target.value)}
                              />
                              {modem.loading && (
                                <span className="text-dark">Mengecek modem...</span>
                              )}
                              {modem.fetched && (
                                <span className="text-success">Modem tersedia</span>
                              )}
                              {modem.error && (
                                <span className="text-danger">
                                  Modem tidak tersedia ({modem.error})
                                </span>
                              )}
                            </div>
                            <div className="col-auto">
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled={modem.loading}
                                onClick={(event) => checkSerial(event)}
                              >
                                Cek Modem
                              </button>
                            </div>
                          </div>
                        </div>
                        {modemInfo.automation === 0 && (
                          <div className="alert alert-warning">
                            <b>
                              <i className="fa-solid fa-triangle-exclamation"></i> Perhatian
                            </b>{" "}
                            <br />
                            Modem ini tidak dapat disetup otomatis. Hubungi NOC untuk melakukan
                            setup manual.
                          </div>
                        )}

                        <div className="form-group">
                          <label>Nomor GPON</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            value={modemInfo.serial_number_gpon}
                          />
                        </div>
                        <div className="form-group row">
                          <div className="col-auto">
                            <label>Setup modem manual</label>
                          </div>
                          <div className="col-auto">
                            <Toggle
                              checked={override}
                              onChange={(event) => {
                                setOverride(event.target.checked);
                              }}
                              disabled={
                                disabledByRole(auth.user.roles, ["technician"]) ||
                                modemInfo.automation !== 1
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>PPPoE Username</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={
                              disabledByRole(auth.user.roles, ["technician"]) || !canEditForm()
                            }
                            value={form.pppoe_username}
                            onChange={(event) =>
                              setForm({ ...form, pppoe_username: event.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>PPPoE Password</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={
                              disabledByRole(auth.user.roles, ["technician"]) || !canEditForm()
                            }
                            value={form.pppoe_password}
                            onChange={(event) =>
                              setForm({ ...form, pppoe_password: event.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>OLT</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={
                              disabledByRole(auth.user.roles, ["technician"]) || !canEditForm()
                            }
                            value={form.olt}
                            onChange={(event) => setForm({ ...form, olt: event.target.value })}
                          />
                        </div>
                        <div className="form-group">
                          <label>Framed Pool</label>
                          <select
                            className="form-control"
                            value={form.framed_pool}
                            disabled={
                              disabledByRole(auth.user.roles, ["technician"]) || !canEditForm()
                            }
                            onChange={(event) =>
                              setForm({ ...form, framed_pool: event.target.value })
                            }
                          >
                            <option value=""></option>
                            {framedPools.data.map((framed_pool, index) => (
                              <option key={index} value={framed_pool.name}>
                                {framed_pool.name}
                              </option>
                            ))}
                            <option value="add_new">Tambah Framed Pool Baru</option>
                          </select>
                        </div>
                        {form.framed_pool === "add_new" && (
                          <div className="form-group">
                            <label>
                              Framed Pool Baru
                              <RequiredAsterisk />
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={form.new_framed_pool}
                              onChange={(event) =>
                                setForm({ ...form, new_framed_pool: event.target.value })
                              }
                            />
                          </div>
                        )}
                        <div className="form-group">
                          <label>VLAN</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={
                              disabledByRole(auth.user.roles, ["technician"]) || !canEditForm()
                            }
                            value={form.vlan}
                            onChange={(event) => setForm({ ...form, vlan: event.target.value })}
                          />
                        </div>

                        <div className="form-group">
                          <label>ODC</label>
                          <input
                            type="text"
                            className="form-control"
                            value={form.odc}
                            onChange={(event) => setForm({ ...form, odc: event.target.value })}
                          />
                        </div>
                        <div className="form-group">
                          <label>ODP</label>
                          <input
                            type="text"
                            className="form-control"
                            value={form.odp}
                            onChange={(event) => setForm({ ...form, odp: event.target.value })}
                          />
                        </div>
                        <div className="form-group">
                          <label>Port ODP</label>
                          <input
                            type="text"
                            className="form-control"
                            value={form.odp_port}
                            onChange={(event) => setForm({ ...form, odp_port: event.target.value })}
                          />
                        </div>
                        <div className="form-group">
                          <label>Redaman ODP</label>
                          <input
                            type="number"
                            className="form-control"
                            value={form.odp_damping}
                            onChange={(event) =>
                              setForm({ ...form, odp_damping: event.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>Redaman ONT</label>
                          <input
                            type="number"
                            className="form-control"
                            value={form.ont_damping}
                            onChange={(event) =>
                              setForm({ ...form, ont_damping: event.target.value })
                            }
                          />
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                          <button className="btn btn-primary" disabled={loading}>
                            {loading ? "Mensetup modem ..." : "Simpan Setting Modem"}
                          </button>
                        </div>
                      </form>
                    </>
                  ) : (
                    <IconvestFormSetupModem
                      registration={registrationData.data}
                      onSubmit={submitDataIconvest}
                      submitLoading={loading}
                    />
                  )}
                </div>
              </div>
            </Box>
          </div>

          <div className="col-lg-8">
            <BoxSetupModem />
            <BoxUnsavedModem />
            <BoxDismantleModem />
            <BoxRelocateModem />
            <BoxNasList />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
