import { useEffect, useState } from "react";
import { getRegistratioById, getRegistrationLogById } from "../api/user";

export function useUserRegitrationHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getCustomer(id);
    }
  }, [id]);

  const getCustomer = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getRegistratioById({ id });
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getCustomer,
  };
}

export function useRegistrationLogHooks({ filter }) {
  const [histories, setHistories] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchLog = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getRegistrationLogById(filter.id, { ...filter });
      setHistories(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchLog(filter);
    }
  }, [filter]);

  return {
    histories,
    meta,
    loading,
    errorMsg,
    fetchLog,
  };
}
