import moment from "moment";

const months = moment.months();
const years = [];

for (let start = 2021; start <= moment().year(); start++) {
  years.push(start);
}

export default function MonthYearPicker({ value, changeMonthYear }) {
  let monthVal = "";
  let yearVal = "";

  if (value !== "") {
    let valueStringSplit = value.split("-");
    monthVal = valueStringSplit[0];
    yearVal = valueStringSplit[1];
  }

  const onChangeMonth = (event) => {
    if (!value || !yearVal) {
      changeMonthYear(event.target.value + "-" + moment().year());
    } else {
      changeMonthYear(event.target.value + "-" + yearVal);
    }
  };

  const onChangeYear = (event) => {
    if (!value || !monthVal) {
      changeMonthYear(1 + "-" + event.target.value);
    } else {
      changeMonthYear(monthVal + "-" + event.target.value);
    }
  };

  return (
    <div className="row">
      <div className="col">
        <select
          className="form-control"
          value={monthVal}
          onChange={(event) => onChangeMonth(event)}
        >
          <option value="">-- Bulan --</option>
          {months.map((month, index) => (
            <option value={index + 1} key={index}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div className="col">
        <select className="form-control" value={yearVal} onChange={(event) => onChangeYear(event)}>
          <option value="">-- Tahun --</option>
          {years.map((year) => (
            <option value={year} key={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
