import { useEffect, useRef, useState } from "react";
import { getAllSplitterPartnerInfra, presetPartnerOdpSplitter } from "../../api/projects";

export function usePartnerSplitterListHooks({ filter }) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getOdps = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllSplitterPartnerInfra({ ...filter });
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getOdps(filter);
  }, [filter]);

  return {
    data,
    meta,
    loading,
    errorMsg,
    getOdps,
  };
}

export function useSearchPartnerSplitterListHooks({ filter }) {
  // flag for first render
  const isFirstRender = useRef(true);

  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getOdpSplitters = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await presetPartnerOdpSplitter({ ...filter });
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  const resetList = () => {
    setData([]);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getOdpSplitters(filter);
  }, [filter]);

  return {
    data,
    meta,
    loading,
    errorMsg,
    getOdpSplitters,
    resetList,
  };
}
