import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createWarehouse } from "../../../api/inventory";
import useRegionHooks from "../../../utils/RegionHooks";
import AlertBox from "../../AlertBox";
import { RequiredAsterisk } from "../../FormHelper";

export default function ModalCreateWarehouse({ show, onClose, onSuccess }) {
  let [form, setForm] = useState({name: '', description: ''});
  let [loading, setLoading] = useState(false);
  let region = useRegionHooks();
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  
  const handleClose = () => {
    setForm({name: '', description: ''});
    region.resetRegion();
    setAlert({show: false, message: '', type: ''})
    onClose(false);
  }

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
      address: region.address,
      address_id: region.addressId
    }
    sendCreateWarehouse(formData)
  }

  const sendCreateWarehouse = async (form) => {
    setLoading(true);
    try {
      let response = await createWarehouse(form)
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }

    setLoading(false);
  }

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={event => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Gudang Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert}/>
          <div className="form-group">
            <label htmlFor="">Nama Gudang <RequiredAsterisk /> </label>
            <input type="text" className="form-control" required value={form.name} onChange={event => setForm({ ...form, name: event.target.value})} />
          </div>
          <div className="form-group">
            <label htmlFor="">Deskripsi <RequiredAsterisk /></label>
            <textarea type="text" className="form-control" required value={form.description} onChange={event => setForm({ ...form, description: event.target.value})} />
          </div>
          <div className="form-group">
            <label htmlFor="">Alamat <RequiredAsterisk /></label>
            <textarea type="text" className="form-control" required value={region.address} onChange={event => region.setAddress(event.target.value)} />
          </div>
          <div className="form-group">
            <label htmlFor="projectname">Provinsi <RequiredAsterisk /></label>
            <select name="provinsi"  requiredid="provinsi" className="form-control" value={region.province} onChange={event => region.changeProvince(event.target.value)}>
              <option value="">-- Pilih Provinsi --</option>
              {region.provinces.map((province, index) => (
                <option key={index} value={province.province}>{province.province}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="projectname">Kota/Kabupaten <RequiredAsterisk /></label>
            <select name="kabupaten"  requiredid="kabupaten" className="form-control" value={region.city} onChange={event => region.changeCity(event.target.value)}>
              <option value="">-- Pilih Kabupaten/Kota --</option>
              {region.cities.map((city, index) => (
                <option key={index} value={city.kota_kab}>{city.kota_kab}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="projectname">Kecamatan <RequiredAsterisk /></label>
            <select name="kecamatan" required id="kecamatan" className="form-control" value={region.district} onChange={event => region.changeDistrict(event.target.value)}>
              <option value="">-- Pilih Kecamatan --</option>
              {region.districts.map((district, index) => (
                <option key={index} value={district.kecamatan}>{district.kecamatan}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="projectname">Kelurahan <RequiredAsterisk /></label>
            <select name="kelurahan" required id="kelurahan" className="form-control" value={region.addressId} onChange={event => region.setAddressId(event.target.value)}>
              <option value="">-- Pilih Kelurahan --</option>
              {region.addresslist.map((kelurahan, index) => (
                <option key={index} value={kelurahan.id}>{kelurahan.kelurahan}</option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>Close</button>
          <button type="submit" className="btn btn-primary"><i className="fas fa-plus"></i> Buat Gudang Baru</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}