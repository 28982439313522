import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useAllRequestPackageChange } from "../../utils/CustomerUtilHooks";
import { confirmBillingById } from "../../api/finance";
import { convertToRupiah } from "../../tools/numerictools";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function RequestChangePackagePage() {
  let { hasAccess } = useControlPermission();

  let [formFilter, setFormFilter] = useState({ n: "", q: "", status: "" });
  let [filter, setFilter] = useState({ n: "", q: "", status: "", page: 1, page_size: 10 });
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [selectedId, setSelectedId] = useState("");
  const [processing, setProcessing] = useState(false);

  const requestData = useAllRequestPackageChange();

  useEffect(() => {
    requestData.getRequest(filter);
  }, [filter]);

  const confrimBilling = async (id) => {
    setSelectedId(id);
    setProcessing(true);
    try {
      let { data } = await confirmBillingById(id);
      onSuccess(data.message);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        onFailure(data.message);
      } else {
        onFailure("Something Error Happened");
      }
    }
    setProcessing(false);
    setSelectedId("");
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
    setFilter({ ...filter });
  };

  const onFailure = (message) => {
    setAlert({ show: true, message: message, type: "danger" });
    setFilter({ ...filter });
  };
  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  function applyFilter() {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  }

  const resetFilter = () => {
    setFormFilter({ n: "", q: "", status: "" });
    setFilter({ n: "", q: "", status: "", page: 1, page_size: 10 });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="manage-customer">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Pengajuan Ganti Paket</h1>
          </div>
        </div>
        <Box
          title={`Pengajuan Ganti Paket (Total: ${requestData.meta.count})`}
          loading={requestData.loading}
        >
          <div className="mb-4">
            <div className="row">
              <div className="col-auto">
                <label htmlFor="n_label">Nomor</label>
                <input
                  type="text"
                  className="form-control"
                  value={formFilter.n}
                  onChange={(event) => changeFilter("n", event.target.value)}
                />
              </div>
              <div className="col-auto">
                <label htmlFor="q_label">Nama</label>
                <input
                  type="text"
                  className="form-control"
                  value={formFilter.q}
                  onChange={(event) => changeFilter("q", event.target.value)}
                />
              </div>
              <div className="col-auto">
                <label htmlFor="customer_number">Status</label>
                <select
                  value={formFilter.status}
                  onChange={(event) => changeFilter("status", event.target.value)}
                  className="form-control"
                >
                  <option value="">Default</option>
                  <option value="WAITING-PAYMENT">Waiting Payment</option>
                  <option value="SUCCESS">Success</option>
                  <option value="CANCELED">Canceled</option>
                </select>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-primary"
                  onClick={() => applyFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => resetFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>Status</th>
                  <th>No. Billing</th>
                  <th>No. Reg</th>
                  <th>Nama</th>
                  <th>Paket Lama</th>
                  <th>Paket Baru</th>
                  <th>Harga Paket Baru</th>
                </tr>
              </thead>
              <tbody>
                {requestData.customers.length === 0 && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {requestData.customers.map((customers, index) => (
                  <tr key={index}>
                    <td>
                      {customers.status === "WAITING-PAYMENT" ? (
                        !(processing && selectedId === customers.billing.id) ? (
                          hasAccess("confirm-change-package") ? (
                            <button
                              className="btn btn-sm btn-block btn-success mb-1 text-nowrap"
                              onClick={() => confrimBilling(customers.billing.id)}
                            >
                              <i className="fa fa-check-square"></i> Confirm
                            </button>
                          ) : (
                            "---"
                          )
                        ) : (
                          <button
                            className="btn btn-sm btn-block btn-success mb-1 text-nowrap"
                            disabled
                          >
                            Processing
                          </button>
                        )
                      ) : (
                        <button className="btn btn-sm btn-block btn-gray mb-1 text-nowrap" disabled>
                          <i className="fa fa-check-square"></i> Confirm
                        </button>
                      )}
                    </td>
                    <td>{customers.status}</td>
                    <td>{customers.billing_number}</td>
                    <td>{customers.customer.registration.registration_number}</td>
                    <td>{customers.customer.registration.name}</td>
                    <td>{customers.old_internet_package.name}</td>
                    <td>{customers.new_internet_package.name}</td>
                    <td>Rp {convertToRupiah(customers.billing.amount || 0)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {requestData.meta.count > requestData.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(requestData.meta.count / requestData.meta.page_size)}
              selectedpage={requestData.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(requestData.meta.page + 1)}
              prev={() => gotoPage(requestData.meta.page - 1)}
            />
          )}
        </Box>
      </div>
    </MainLayout>
  );
}
