/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../../utils/AuthHooks";

function Navbar() {
  const auth = useAuth();
  const history = useHistory();
  let location = useLocation();
  
  useEffect(() => {
    auth.getUnreadNotifications();
  }, [location]);

  // useEffect(() => {
  //   let intervalNotif = setInterval(() => {
  //     auth.getUnreadNotifications();
  //   }, 10000);

  //   return () => clearInterval(intervalNotif);
  // }, []);
  
  let [showNotif, setShowNotif] = useState(false);

  const collapseSidebar = () => {
    document.body.classList.toggle("sidebar-toggled")
    document.getElementById('accordionSidebar').classList.toggle('toggled')
  }

  const logout = () => {
    auth.logout();
    history.push('/login')
  }

  const toggleNotif = () => {
    if (!showNotif) {
      auth.getAllNotifications();
    } else {
      auth.readAllNotifications();
    }
    setShowNotif(!showNotif)
  }

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={() => collapseSidebar()}>
        <i className="fa fa-bars"></i>
      </button>
      <ul className="navbar-nav ml-auto">
        {/* <!-- Nav Item - Alerts --> */}
        <li className="nav-item dropdown no-arrow mx-1" onClick={() => toggleNotif()}>
          <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button">
            <i className="fas fa-bell fa-fw"></i>
            {/*
            <!-- Counter - Alerts --> */}
            {auth.notifCount > 0 
              && <span className="badge badge-danger badge-counter">{auth.notifCount}</span>
            }
          </a>
          {/*
          <!-- Dropdown - Alerts --> */}
          <div className={`dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in ${showNotif ? "show" : ""}`}>
            <h6 className="dropdown-header">
              Notifikasi
            </h6>
            <div style={{maxHeight: '500px', overflowY: 'auto'}}>
              {auth.notifications.map((notification, index) => (
                <a className="dropdown-item d-flex align-items-center" href="#" key={index}>
                  <div>
                    <div className="small text-gray-500">{moment.unix(notification.createdAt).format("DD MMMM YYYY - HH:mm")}</div>
                    <span className="font-weight-bold">{notification.title}</span> <br />
                    <span>{notification.message}</span>
                  </div>
                </a>
              ))}
            </div>
            <a className="dropdown-item text-center small text-gray-500" href="#">Lihat Semua Notif</a>
          </div>
        </li>

        <div className="topbar-divider d-none d-sm-block ml-auto"></div>
        {/* <!-- Nav Item - User Information --> */}
        <li className="nav-item dropdown no-arrow">
          <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{auth.user.name}</span>
              
            <img className="img-profile rounded-circle" src={auth.user.image_url ? auth.user.image_url : 'https://randomuser.me/api/portraits/lego/2.jpg'} alt={auth.user.name} />
          </a>
          {/* <!-- Dropdown - User Information --> */}
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <button className="dropdown-item" data-toggle="modal" data-target="#logoutModal" onClick={() => logout()}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </button>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar;