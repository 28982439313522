import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { updateAppBanner } from "../../api/setting";

export default function ModalUpdateBanner({ show, selected, onClose, onSuccess }) {
  const [file, setFile] = useState(null);
  const [form, setForm] = useState({ title: "", description: "", status: 1 });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setForm((prev) => ({
        ...prev,
        title: selected.title,
        description: selected.description,
        status: selected.status,
      }));
    }
  }, [show, selected]);

  const handleClose = () => {
    if (!loading) {
      setFile(null);
      setForm({ title: "", description: "", status: 1 });
      setAlert({ show: false, message: "", type: "" });
      onClose();
    }
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = new FormData();
    formData.append("title", form.title);
    formData.append("description", form.description);
    formData.append("status", form.status);

    if (file) {
      formData.append("file", file);
    }

    onUpdateBanner(formData);
  };

  const onUpdateBanner = async (formData) => {
    setLoading(true);
    try {
      let response = await updateAppBanner(selected.id, formData);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Data Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="">Gambar</label>
            <div className="custom-file" style={{ zIndex: 0 }}>
              <label className="custom-file-label" htmlFor="customFile">
                {file ? file.name : "Pilih file"}
              </label>
              <input
                type="file"
                className="custom-file-input"
                onChange={(event) => setFile(event.target.files[0])}
                accept="image/*"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="">
              Judul <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.title}
              maxLength={50}
              onChange={(event) => setForm({ ...form, title: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi <span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control"
              required
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Status Keaktifan <span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              required
              value={form.status}
              onChange={(event) => setForm({ ...form, status: event.target.value })}
            >
              <option value="1">Aktif</option>
              <option value="0">Tidak Aktif</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-plus"></i> Simpan Perubahan
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
