import { useState } from "react";
import { Modal } from "react-bootstrap";
import { requestRelocateByCustomerId } from "../../api/customer";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import useRegionHooks from "../../utils/RegionHooks";
import AlertBox from "../AlertBox";

export default function ModalRequestRelocate({ show, id, onSuccess, onClose }) {
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  const { data } = useGetCustomerHooks({id});

  const userRegion = useRegionHooks();
  // internet package loader
  let [ form, setForm ] = useState({description: ''});

  const resetForm = () => {
    setForm({description: ''});
    userRegion.resetRegion();
    setAlert({show: false, message: '', type: ''});
  }

  const submitData = (event) => {
    event.preventDefault();
    let formData  =  {
      description: form.description,
      relocate_address: {
        address: userRegion.address,
        rt: userRegion.rt,
        rw: userRegion.rw,
        address_id: userRegion.addressId
      }
    }  

    submitRelocate(formData);
  }

  const submitRelocate = async (form) => {
    setLoading(true);
    try {
      let response = await requestRelocateByCustomerId(id, form)
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }

    setLoading(false);
  }
  const handleClose = () => {
    resetForm();
    onClose();
  }

  return(
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Permintaan Relokasi - {data.registration?.registration_number} - {data.name}</Modal.Title>
      </Modal.Header>
      <AlertBox {...alert} setAlert={setAlert}/>
      <form onSubmit={event => submitData(event)}>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Nama</td>
                <td>{data.name || ""}</td>
              </tr>
              <tr>
                <td>Nomor Telp.</td>
                <td>{data.phone_number || ""}</td>
              </tr>
              <tr>
                <td>Alamat Pemasangan Sekarang </td>
                <td>
                  {
                    data.installation_address?.address + 
                    " RT " + (data.installation_address?.rt || "") + 
                    "/RW " + (data.installation_address?.rw || "")   + ", " 
                    + (data.installation_address?.addresses?.kelurahan || "") + ", " 
                    + (data.installation_address?.addresses?.kecamatan || "") + ", " 
                    + (data.installation_address?.addresses?.kota_kab || "") + ", " 
                    + (data.installation_address?.addresses?.province || "") + ", " 
                    + (data.installation_address?.addresses?.postal_code || "")
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <div className="form-group">
            <label>Catatan <span className="text-danger">*</span></label>
            <textarea className="form-control" required value={form.description} onChange={event => setForm({ ...form, description: event.target.value})}></textarea>
          </div>
          <div className= "form-group">
            <label htmlFor="address">Alamat Lengkap <span className="text-danger">*</span></label>
            <input type="text" name="address" id="address" className="form-control" value={userRegion.address} onChange={event => userRegion.setAddress(event.target.value)} required />
          </div>
          <div className= "form-group">
            <label htmlFor="addressRT">RT (3 Digit) <span className="text-danger">*</span></label>
            <input type="text" name="addressRT" id="addressRT" className="form-control" value={userRegion.rt} onChange={event => userRegion.setRt(event.target.value)} required />
          </div>
          <div className= "form-group">
            <label htmlFor="addressRW">RW (3 Digit) <span className="text-danger">*</span></label>
            <input type="text" name="addressRW" id="addressRW" className="form-control" value={userRegion.rw} onChange={event => userRegion.setRw(event.target.value)} required />
          </div>
          <div className= "form-group">
            <label htmlFor="user_province">Provinsi <span className="text-danger">*</span></label>
            <select className="input form-control" id="user_province" required value={userRegion.province} onChange={event => userRegion.changeProvince(event.target.value)}>
              <option value="">-- Pilih Provinsi --</option>
              {userRegion.provinces.map((province, index) => (
                <option key={index} value={province.province}>{province.province}</option>
              ))}
            </select>
          </div>
          <div className= "form-group">
            <label htmlFor="user_city">Kota/Kabupaten <span className="text-danger">*</span></label>
            <select className="input form-control" id="user_city" required value={userRegion.city} onChange={event => userRegion.changeCity(event.target.value)}>
              <option value="">-- Pilih Kota / Kab --</option>
              {userRegion.cities.map((city, index) => (
                <option key={index} value={city.kota_kab}>{city.kota_kab}</option>
              ))}
            </select>
          </div>
          <div className= "form-group">
            <label htmlFor="your_email">Kecamatan <span className="text-danger">*</span></label>
            <select className="input form-control" id="user_district" required value={userRegion.district} onChange={event => userRegion.changeDistrict(event.target.value)}>
              <option value="">-- Pilih Kecamatan --</option>
              {userRegion.districts.map((district, index) => (
                <option key={index} value={district.kecamatan}>{district.kecamatan}</option>
              ))}
            </select>
          </div>
          <div className= "form-group">
            <label htmlFor="user_kelurahan">Kelurahan <span className="text-danger">*</span></label>
            <select className="input form-control" id="user_kelurahan" required value={userRegion.addressId} onChange={event => userRegion.setAddressId(event.target.value)}>
              <option value="">-- Pilih Kelurahan --</option>
              {userRegion.addresslist.map((kelurahan, index) => (
                <option key={index} value={kelurahan.id}>{kelurahan.kelurahan} ({kelurahan.postal_code})</option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button type="button" onClick={() => handleClose()} className="btn btn-secondary mr-1">Batal</button>
            <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Menunggu ...' : 'Simpan'}</button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}