import MainLayout from "../components/layouts/MainLayout";
import BoxPackHome from "../components/internetpacks/BoxPackHome";
import BoxTotalTraffic from "../components/dashbooard/BoxTotalTraffic";
import BoxTopLocation from "../components/dashbooard/BoxTopLocation";
import RegistrationStat from "../components/dashbooard/RegistrationStats";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import BoxLocalExchange from "../components/dashbooard/BoxLocalExchange";
import SectionCustomerGraph from "../components/dashbooard/SectionCostumerGraph";

export default function HomePage() {
  let { hasAccess } = useControlPermission();

  return (
    <MainLayout resource_key="home">
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
        </div>
        <SectionCustomerGraph />
        <div className="row">
          {hasAccess("traffic-customer-monitor") && (
            <>
              <div className="col-12 col-lg-6">
                <BoxTotalTraffic />
              </div>
              <div className="col-12 col-lg-6">
                <BoxLocalExchange />
              </div>
            </>
          )}
          {hasAccess("registration-status-count") && (
            <div className="col-lg-12">
              <RegistrationStat />
            </div>
          )}
          {hasAccess("most-installation-location") && (
            <div className="col-lg-12">
              <BoxTopLocation />
            </div>
          )}
          {hasAccess("package") && (
            <div className="col-lg-12">
              <BoxPackHome />
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}
