import moment from "moment";
import { Modal } from "react-bootstrap";
import { useProjectHooks } from "../../utils/ProjectsUtilHooks";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../Loading";
import { convertToRupiah } from "../../tools/numerictools";

export default function ModalProjectDetail({ show, onClose, id }) {
  let project = useProjectHooks({ id });

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Data Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoadingInsideBoxWrapper>
          {project.loading && <LoadingInsideBox />}
          <div className="row">
            <div className="col-12 col-lg-6">
              <h5 className="mb-3">Detail</h5>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Nama Project</td>
                    <td>{project.data.name}</td>
                  </tr>
                  <tr>
                    <td>Lokasi</td>
                    <td>
                      {project.data.address}, RT{project.data.rt}/RW{project.data.rw},
                      {project.data.addresses &&
                        `${project.data.addresses.kelurahan}, ${project.data.addresses.kecamatan}, ${project.data.addresses.kota_kab}, ${project.data.addresses.province}, ${project.data.addresses.postal_code}`}
                    </td>
                  </tr>
                  <tr>
                    <td>slug</td>
                    <td>{project.data.slug}</td>
                  </tr>
                  <tr>
                    <td>PIC</td>
                    <td>{project.data.pic_name}</td>
                  </tr>
                  <tr>
                    <td>Mitra/Partner</td>
                    <td>{project.data.partner?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>Waktu dibuat</td>
                    <td>{moment.unix(project.data.createdAt).format("DD MMMM YYYY - HH:mm:ss")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-12 col-lg-6">
              <h5 className="mb-3">Setting Aktivasi</h5>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Framed Pool</td>
                    <td>{project.data?.framed_pool || "-"}</td>
                  </tr>
                  <tr>
                    <td>OLT</td>
                    <td>
                      {project.data?.olt
                        ? `${project.data?.olt?.name} (${project.data?.olt?.ip})`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>VLAN</td>
                    <td>{project.data?.vlan || "-"}</td>
                  </tr>
                  <tr>
                    <td>VLAN Profile</td>
                    <td>{project.data?.vlan_profile || "-"}</td>
                  </tr>
                  <tr>
                    <td>Aktivasi Internet oleh Pengguna</td>
                    <td>
                      {project.data.user_activation ? (
                        <span className="badge badge-success">Ya</span>
                      ) : (
                        <span className="badge badge-danger">Tidak</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <h5 className="mb-3">Setting Komisi</h5>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Komisi via PulsaPro</td>
                    <td>
                      {project.data.commission_disbursement ? (
                        <span className="badge badge-success">Ya</span>
                      ) : (
                        <span className="badge badge-danger">Tidak</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <TableCommissionsSetting commissions={project.data.commissions} />
            </div>
          </div>

          <h5 className="my-3">Inventory</h5>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Barang</th>
                <th>Tipe</th>
                <th>Jumlah</th>
                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              {project.data.project_inventories?.length === 0 && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
              {project.data.project_inventories?.map((inventory, yindex) => (
                <tr key={yindex}>
                  <td>{inventory.name}</td>
                  <td>{inventory.type}</td>
                  <td>{inventory.amount}</td>
                  <td>{inventory.unit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </LoadingInsideBoxWrapper>
      </Modal.Body>
    </Modal>
  );
}

function CommmissionTypeRole(val) {
  switch (val) {
    case "technician":
      return "Teknisi";
    case "sales":
      return "Sales";
    case "helper":
      return "Helper";
    default:
      return "";
  }
}

function TableCommissionsSetting({ commissions, commission_disbursement }) {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Komisi</th>
          <th>Instalasi</th>
          <th>Relokasi</th>
          <th>Dismantle</th>
        </tr>
      </thead>
      <tbody>
        {commissions?.map((commission, index) => (
          <tr key={index}>
            <td>Komisi {CommmissionTypeRole(commission.type)}</td>
            <td className="text-nowrap">Rp {convertToRupiah(commission.installation || 0)}</td>
            <td className="text-nowrap">Rp {convertToRupiah(commission.relocate || 0)}</td>
            <td className="text-nowrap">Rp {convertToRupiah(commission.dismantle || 0)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
