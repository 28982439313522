import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useModemsByWarehouse } from "../../../utils/InventoryHooks";
import { LoadingInsideBox } from "../../Loading";
import Pagination from "../../Pagination";

export default function ModalChooseOutgoingModem({
  show,
  onSelectInventory,
  warehouse_id,
  onClose,
}) {
  const handleClose = () => {
    onClose();
  };

  const selectModem = (selected_modem) => {
    let inventory = {
      ...selected_modem,
      ...selected_modem.inventory,
      qty: 1,
      modem_id: selected_modem.id,
    };
    onSelectInventory(inventory);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Daftar Modem untuk Dikeluarkan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableWarehouseModem
          warehouse_id={warehouse_id}
          onSelect={(inventory) => selectModem(inventory)}
        />
      </Modal.Body>
    </Modal>
  );
}

function TableWarehouseModem({ warehouse_id, onSelect }) {
  let [formFilter, setFormFilter] = useState({ n: "" });
  let [filter, setFilter] = useState({
    n: "",
    page: 1,
    page_size: 10,
    id: warehouse_id,
    available: true,
    warehouse_status: true,
  });

  let { modems, loading, meta, error } = useModemsByWarehouse({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const applyFilter = (event) => {
    event.preventDefault();
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ n: "" });
    setFilter({
      n: "",
      page: 1,
      page_size: 10,
      id: warehouse_id,
      available: true,
      warehouse_status: true,
    });
  };

  return (
    <div className="loading-data-div">
      <form onSubmit={(event) => applyFilter(event)}>
        <div className="row mb-4">
          <div className="col-12 col-sm-2">
            <label>SN Modem/GPON</label>
            <input
              type="text"
              className="form-control"
              value={formFilter.n}
              onChange={(event) => setFormFilter({ ...formFilter, n: event.target.value })}
            />
          </div>
          <div className="col-12 col-sm">
            <button className="btn btn-primary" style={{ marginTop: "32px" }} type="submit">
              Filter
            </button>
            <button
              className="btn btn-default"
              style={{ marginTop: "32px" }}
              onClick={() => resetFilter()}
              type="button"
            >
              Reset
            </button>
          </div>
        </div>
      </form>
      {error && <div className="alert alert-danger">{error}</div>}
      {loading && <LoadingInsideBox />}
      <table className="table table-bordered table-striped table-hover">
        <thead>
          <tr>
            <th></th>
            <th>SKU</th>
            <th>Brand</th>
            <th>Type</th>
            <th>S/N Modem</th>
            <th>S/N GPON</th>
            <th>Ketersediaan</th>
            <th>Status Gudang</th>
          </tr>
        </thead>
        <tbody>
          {modems.length === 0 && (
            <tr>
              <td colSpan={8} className="text-center">
                Tidak ada data
              </td>
            </tr>
          )}
          {modems.map((modem) => (
            <tr key={modem.id}>
              <td>
                <button className="btn btn-info text-nowrap btn-sm" onClick={() => onSelect(modem)}>
                  <i className="fa-solid fa-right-from-bracket"></i> Keluarkan
                </button>
              </td>
              <td>{modem.inventory?.sku || "-"}</td>
              <td>{modem.brand}</td>
              <td>{modem.type}</td>
              <td>{modem.serial_number_modem}</td>
              <td>{modem.serial_number_gpon}</td>
              <td>
                {modem.available ? (
                  <span className="badge badge-success">Tersedia</span>
                ) : (
                  <span className="badge badge-danger">Tidak Tersedia</span>
                )}
              </td>
              <td>
                {modem.warehouse_status ? (
                  <span className="badge badge-success">Stok Tersedia</span>
                ) : (
                  <span className="badge badge-danger">Stok Tidak Tersedia</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {meta.count > meta.page_size && (
        <Pagination
          totalpage={Math.ceil(meta.count / meta.page_size)}
          selectedpage={meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(meta.page + 1)}
          prev={() => gotoPage(meta.page - 1)}
        />
      )}
    </div>
  );
}
