import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateDueDateCustomerById } from "../../api/customer";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import AlertBox from "../AlertBox";

export default function ModalChangeDueDate({ show, id, onSuccess, onClose }) {
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  const { data } = useGetCustomerHooks({id});

  // internet package loader
  let [ form, setForm ] = useState({start_date: '', end_date: ''});

  const resetForm = () => {
    setForm({start_date: '', end_date: ''});
    setAlert({show: false, message: '', type: ''});
  }

  const submitData = (event) => {
    event.preventDefault();
    let formData  =  {
      start_date: form.start_date,
      end_date: form.end_date,
    }  

    submitChange(formData);
  }

  const submitChange = async (form) => {
    setLoading(true);
    try {
      let response = await updateDueDateCustomerById(id, form)
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }

    setLoading(false);
  }
  const handleClose = () => {
    resetForm();
    onClose();
  }

  return(
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Ubah Waktu Langganan - {data.registration?.registration_number} - {data.name}</Modal.Title>
      </Modal.Header>
      <AlertBox {...alert} setAlert={setAlert}/>
      <form onSubmit={event => submitData(event)}>
        <Modal.Body>
          <div className="form-group">
            <label>Tanggal Mulai</label>
            <input type="date" className="form-control" required value={form.start_date} onChange={event => setForm({ ...form, start_date: event.target.value })} max={form.end_date} />
          </div>
          <div className="form-group">
            <label>Tanggal Berakhir</label>
            <input type="date" className="form-control" required value={form.end_date} onChange={event => setForm({ ...form, end_date: event.target.value })} min={form.start_date} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button type="button" onClick={() => handleClose()} className="btn btn-secondary mr-1">Batal</button>
            <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Menunggu ...' : 'Simpan'}</button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}