import { useState, useEffect } from "react";
import { getInventoriesMaster } from "../../api/inventory";

export function useInventoryMasterHooks({ filter }) {
  let [data, setData] = useState([]);
  let [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  const getInventories = async (filter) => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getInventoriesMaster({ ...filter });
      setData(response.data.data);
      setMeta(response.data.pagination);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (filter) {
      getInventories(filter);
    }
  }, [filter]);

  return {
    data,
    meta,
    fetched,
    success,
    error,
    loading,
    getInventories,
  };
}
