import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getRegistratioById } from "../../api/user";
import { BUILDING_STATUS } from "../../tools/userdatatools";
import { convertToRupiah } from "../../tools/numerictools";

export default function ModalDetail(props) {
  const [data, setData] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await getRegistratioById({ id: props.id });
        setData(response.data.data);
      } catch (error) {
        if (error.response) {
          let { data } = error.response;
          setError(data.message);
        } else {
          setError("Terjadi kesalahan");
        }
      }
    };

    if (props.id && props.show) {
      setError("");
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const handleClose = () => {
    setData({});
    setError("");
    props.setShow("");
  };

  return (
    <Modal show={props.show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Detail Pendaftaran</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="row">
          <div className="col-12 col-lg-6">
            <h5>Data Paket</h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Pilihan Paket</td>
                  <td>{data.internet_package ? data.internet_package.name : ""}</td>
                </tr>
                <tr>
                  <td>Deposit</td>
                  <td>
                    {data.deposit_amount ? `Rp. ${convertToRupiah(data.deposit_amount)}` : `-`}
                  </td>
                </tr>
                <tr>
                  <td>Jenis Pelanggan</td>
                  <td>
                    {data.registration_type === "INSTALLED" ? "Pelanggan Lama" : "Pelanggan Baru"}
                  </td>
                </tr>
                <tr>
                  <td>Nomor Registrasi</td>
                  <td>{data.registration_number}</td>
                </tr>
                <tr>
                  <td>Nomor Pelanggan</td>
                  <td>{data.customer_number ? data.customer_number : "-"}</td>
                </tr>
              </tbody>
            </table>
            <h5>Data Pemasangan</h5>
            {data.installation_address && (
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td>{data.status ? data.status.display_name : "-"}</td>
                  </tr>
                  <tr>
                    <td>Catatan Teknisi</td>
                    <td>{data.registration_schedules[0]?.schedule.additional_note || "-"}</td>
                  </tr>
                  <tr>
                    <td>Catatan</td>
                    <td>{data.note || "-"}</td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>{data.installation_address.address}</td>
                  </tr>
                  <tr>
                    <td>RT</td>
                    <td>{data.installation_address.rt}</td>
                  </tr>
                  <tr>
                    <td>RW</td>
                    <td>{data.installation_address.rw}</td>
                  </tr>
                  <tr>
                    <td>Kelurahan</td>
                    <td>{data.installation_address.addresses.kelurahan}</td>
                  </tr>
                  <tr>
                    <td>Kecamatan</td>
                    <td>{data.installation_address.addresses.kecamatan}</td>
                  </tr>
                  <tr>
                    <td>Kota/Kabupaten</td>
                    <td>{data.installation_address.addresses.kota_kab}</td>
                  </tr>
                  <tr>
                    <td>Provinsi</td>
                    <td>{data.installation_address.addresses.province}</td>
                  </tr>
                  <tr>
                    <td>Kode Pos</td>
                    <td>{data.installation_address.addresses.postal_code}</td>
                  </tr>
                  <tr>
                    <td>Status Bangunan</td>
                    <td>{BUILDING_STATUS[data.building_ownership_status]}</td>
                  </tr>
                  <tr>
                    <td>Grup/Project</td>
                    <td>{data.project?.name || "-"}</td>
                  </tr>
                </tbody>
              </table>
            )}
            {data.bast_survey && (
              <>
                <h5>Hasil Survey</h5>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Disurvey oleh</td>
                      <td>{data.bast_survey.created_by || "-"}</td>
                    </tr>
                    <tr>
                      <td>Dapat Dipasang</td>
                      <td>{data.bast_survey.installable ? "Bisa" : "Tidak"}</td>
                    </tr>
                    {data.bast_survey.installable && (
                      <>
                        <tr>
                          <td>Butuh Item Tambahan</td>
                          <td>{data.bast_survey.additional_item ? "Ada" : "Tidak"}</td>
                        </tr>
                        <tr>
                          <td>Persetujuan Pemasangan</td>
                          <td>
                            {data.bast_survey.installation_agreement ? "Setuju" : "Tidak Setuju"}
                          </td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td>Catatan</td>
                      <td>{data.bast_survey.note || "-"}</td>
                    </tr>
                    {data.bast_survey.installation_agreement &&
                      data.bast_survey.installable &&
                      data.bast_survey.additional_item && (
                        <>
                          <tr>
                            <td>Tanda Tangan</td>
                            <td>
                              <img
                                src={data.bast_survey.attachments[0].attachment_url}
                                alt="Tanda tangan survey"
                                style={{
                                  maxWidth: "100%",
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Nama Terang</td>
                            <td>{data.bast_survey.attachments[0].description}</td>
                          </tr>
                        </>
                      )}
                  </tbody>
                </table>
                {data.bast_survey.additional_items.length !== 0 && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th colSpan={3}>Tambahan</th>
                      </tr>
                      <tr>
                        <th>Item</th>
                        <th>Jumlah</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.bast_survey.additional_items.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>
                            {item.qty} {item.unit}
                          </td>
                          <td>Rp. {convertToRupiah(item.amount || 0)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>

          <div className="col-12 col-lg-6">
            <h5>Data Diri</h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Nama Lengkap</td>
                  <td>{data.name}</td>
                </tr>
                <tr>
                  <td>Nomor Identitas</td>
                  <td>{data.id_number}</td>
                </tr>
                <tr>
                  <td>Nomor NPWP</td>
                  <td>{data.npwp_number}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{data.email}</td>
                </tr>
                <tr>
                  <td>Tempat Lahir</td>
                  <td>{data.birthdate_place}</td>
                </tr>
                <tr>
                  <td>Tanggal Lahir</td>
                  <td>{data.birthdate}</td>
                </tr>
                <tr>
                  <td>Jenis Kelamin</td>
                  <td>{data.gender}</td>
                </tr>
                <tr>
                  <td>Nomor Telepon</td>
                  <td>{data.phone_number}</td>
                </tr>
                <tr>
                  <td>Nomor Telepon Alternatif</td>
                  <td>{data.alternate_phone_number}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>{data.address}</td>
                </tr>
                <tr>
                  <td>RT</td>
                  <td>{data.rt}</td>
                </tr>
                <tr>
                  <td>RW</td>
                  <td>{data.rw}</td>
                </tr>
                <tr>
                  <td>Kelurahan</td>
                  <td>{data.addresses && data.addresses.kelurahan}</td>
                </tr>
                <tr>
                  <td>Kecamatan</td>
                  <td>{data.addresses && data.addresses.kecamatan}</td>
                </tr>
                <tr>
                  <td>Kota/Kabupaten</td>
                  <td>{data.addresses && data.addresses.kota_kab}</td>
                </tr>
                <tr>
                  <td>Provinsi</td>
                  <td>{data.addresses && data.addresses.province}</td>
                </tr>
                <tr>
                  <td>Kode Pos</td>
                  <td>{data.addresses && data.addresses.postal_code}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
