import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  createBulkInventories,
  createBulkInventoriesModem,
  createInventoriesUploader,
  createModemsUploader,
} from "../../api/inventory";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import BoxPreviewExcel from "../../components/inventory/transfer/BoxPreviewExcel";
import BackToWarehouseInventoryLink from "../../components/inventory/warehouse/BackToWarehouseLink";
import MainLayout from "../../components/layouts/MainLayout";
import { useWarehouseHooks } from "../../utils/InventoryHooks";
import { usePresetTemplateInventory } from "../../utils/inventory/PresetInventory";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function WarehouseInventoryUploadPage() {
  let { id } = useParams();
  let { hasAccess } = useControlPermission();

  let { filesPreset } = usePresetTemplateInventory();
  let [form, setForm] = useState({ type: "inventory" });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);
  let [modal, setModal] = useState(null);
  let [file, setFile] = useState(null);
  let warehouse = useWarehouseHooks({ id });

  let [previewInventories, setPreviewInventories] = useState([]);
  let [uploadInfo, setUploadInfo] = useState({});

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  const onChangeType = (value) => {
    setForm({ ...form, type: value });
    setFile(null);
    setPreviewInventories([]);
  };

  const onCancelFile = () => {
    setFile(null);
    setPreviewInventories([]);
    setUploadInfo({});
  };

  const onChangeFile = (event) => {
    setFile(event.target.files[0]);
    onChangeUpload(event);
  };

  const onChangeUpload = async (event) => {
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    uploadFileExcel(formData);
  };

  const uploadFileExcel = async (formData) => {
    setLoading(true);

    try {
      if (form.type === "inventory") {
        let { data } = await createInventoriesUploader(formData);
        setPreviewInventories(data.data.inventories);
        setUploadInfo({
          type: data.data.type,
          total_sku: data.data.total_sku,
          total_stock: data.data.total_stock,
        });
      } else if (form.type === "modem") {
        let { data } = await createModemsUploader(formData);
        setPreviewInventories(data.data.inventories);
        setUploadInfo({
          type: data.data.type,
          total_sku: data.data.total_sku,
          total_stock: data.data.total_stock,
        });
      }
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const resetData = () => {
    setFile(null);
    setPreviewInventories([]);
  };

  const bulkAddInventories = async () => {
    setModal("loading");

    try {
      let formData = {
        inventories: previewInventories,
        warehouse_id: warehouse.data.id,
      };

      if (form.type === "inventory") {
        let { data } = await createBulkInventories(formData);
        onSuccess(data.message);
      } else if (form.type === "modem") {
        let { data } = await createBulkInventoriesModem(formData);
        onSuccess(data.message);
      }

      resetData();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setModal("null");
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <BackToWarehouseInventoryLink warehouse={warehouse.data} />
        <h1 className="h3 mb-4 text-gray-800">Unggah Barang Baru {warehouse.data.name}</h1>
        <div className="alert alert-warning">
          <b>Perhatian</b> Mohon unggah berkas file yang sesuai dengan template yang sudah
          disediakan
          <div className="d-flex flex-row flex-wrap" style={{ gap: "10px" }}>
            <a
              href={filesPreset["inventory-upload-template"]}
              download
              className="btn btn-success my-2"
            >
              <i className="fa fa-file-excel"></i> Template Excel Inventory
            </a>
            <a
              href={filesPreset["inventory-modem-upload-template"]}
              download
              className="btn btn-success my-2"
            >
              <i className="fa fa-file-excel"></i> Template Excel Modem
            </a>
          </div>
        </div>
        <AlertBox {...alert} setAlert={setAlert} />
        <div className="row">
          <div className="col-12 col-lg-4">
            <Box title="Form Unggah File Excel Inventory Baru" loading={loading}>
              <div className="form-group">
                <label>Pilih Tipe Inventory</label>
                <select
                  className="form-control"
                  value={form.type}
                  onChange={(event) => onChangeType(event.target.value)}
                >
                  <option value="inventory">Inventory</option>
                  <option value="modem">Modem</option>
                </select>
              </div>
              <FormExcelUploader file={file} onChangeFile={onChangeFile} onCancel={onCancelFile} />
              <div className="form-group d-flex justify-content-end">
                {hasAccess("create-inventory") && (
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => setModal("confirm")}
                    disabled={previewInventories.length === 0}
                  >
                    <i className="fa fa-upload"></i> Input Inventory Baru
                  </button>
                )}
              </div>
            </Box>
          </div>

          <div className="col-12 col-lg-8">
            <BoxPreviewExcel preview_inventories={previewInventories} upload_info={uploadInfo} />
          </div>
        </div>
      </div>

      <ModalConfirm
        show={modal === "confirm"}
        onClose={() => setModal(null)}
        onBulkAdd={() => bulkAddInventories()}
      />
      <LoadingModalUpload show={modal === "loading"} />
    </MainLayout>
  );
}

function FormExcelUploader({ file, onChangeFile, onCancel }) {
  return (
    <>
      <div className="form-group">
        <label>File Excel</label>
        {file ? (
          <div className="alert alert-success">
            <i className="fa fa-file-excel"></i> {file.name}
            <button type="button" className="close" aria-label="Close" onClick={() => onCancel()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : (
          <div className="custom-file">
            <label className="custom-file-label">File Excel ...</label>
            <input
              type="file"
              className="custom-file-input"
              accept=".xlsx"
              onChange={onChangeFile}
            />
          </div>
        )}
      </div>
    </>
  );
}

function ModalConfirm({ show, onClose, onBulkAdd }) {
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    // setForm({name: '', sku: '', unit: '', price: '', inventory_category_id: '', qty: ''});
    setAlert({ show: false, message: "", type: "" });
    onClose();
  };

  const upload = async () => {
    onBulkAdd();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => event.preventDefault()}>
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi Pengunggahan Inventory Baru via Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          Apakah anda yakin untuk melakukan upload via file excel? Anda tidak dapat melakukan
          tindakan kembali jika terjadi kesalahan. Pastikan data anda sudah benar.
          <div className="d-flex justify-content-center py-4">
            <button className="btn btn-lg btn-success mr-2" onClick={() => upload()}>
              Ya, Saya yakin
            </button>
            <button className="btn btn-lg btn-secondary" onClick={() => handleClose()}>
              Tidak
            </button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
}

function LoadingModalUpload({ show }) {
  return (
    <Modal show={show} onHide={() => {}}>
      <div className="alert alert-warning mb-0">
        <h4>
          <i className="fa fa-hourglass"></i> Harap menunggu.
        </h4>
        <span>Inventori sedang diunggah ke sistem ...</span>
      </div>
      {/* <Modal.Body>
      </Modal.Body> */}
    </Modal>
  );
}
