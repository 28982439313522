import { useState, useEffect } from "react";
import {
  getAllOlt,
  getAllOltProfile,
  getOltById,
  getOltProfileById,
  getOltReportById,
  getProjectOltPreset,
  showAllOlt,
  showAllOltProfile,
} from "../../api/infrastructure";

export function useOltListHooks({ filter }) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getOlts = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllOlt({ ...filter });
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getOlts(filter);
  }, [filter]);

  return {
    data,
    meta,
    loading,
    errorMsg,
    getOlts,
  };
}

export function useOltByIdHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getOlt(id);
    }
  }, [id]);

  const getOlt = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getOltById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getOlt,
  };
}

export function useShowAllOltHooks() {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, pageSize: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getShowAllOlt = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await showAllOlt();
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getShowAllOlt();
  }, []);

  return {
    data,
    meta,
    loading,
    errorMsg,
    getShowAllOlt,
  };
}

export function useOltProfileListHooks({ filter }) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getOltProfiles = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllOltProfile({ ...filter });
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getOltProfiles(filter);
  }, [filter]);

  return {
    data,
    meta,
    loading,
    errorMsg,
    getOltProfiles,
  };
}

export function useOltProfileByIdHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getOltProfile(id);
    }
  }, [id]);

  const getOltProfile = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getOltProfileById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getOltProfile,
  };
}

export function useShowAllOltProfilesHooks() {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, pageSize: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getShowAllOltProfile = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await showAllOltProfile();
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getShowAllOltProfile();
  }, []);

  return {
    data,
    meta,
    loading,
    errorMsg,
    getShowAllOltProfile,
  };
}

export function useOltReportsByIdHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getOltReport(id);
    }
  }, [id]);

  const getOltReport = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getOltReportById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getOltReport,
  };
}

export function useProjectOltHooks() {
  const [projects, setProjects] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getOlt = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getProjectOltPreset();
      setProjects(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getOlt();
  }, []);

  return {
    projects,
    loading,
    errorMsg,
    getOlt,
  };
}
