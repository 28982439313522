import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useCityRegionHooks } from "../../utils/RegionHooks";
import { useCommissionListHooks } from "../../utils/finance/PointSalesUtils";
import AlertBox from "../../components/AlertBox";
import { Link } from "react-router-dom";

export default function PointSalesPage() {
  const [formFilter, setFormFilter] = useState({
    type: "",
    name: "",
    date_from: "",
    date_until: "",
  });
  const [filter, setFilter] = useState({
    type: "",
    name: "",
    date_from: "",
    date_until: "",
    province: "",
    kota_kab: "",
    page: 1,
    page_size: 10,
  });
  const regionFilter = useCityRegionHooks();
  const commissions = useCommissionListHooks({ filter });

  useEffect(() => {
    commissions.getAllCommissions(filter);
  }, [filter]);

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const applyFilter = (event) => {
    event.preventDefault();
    setFilter({
      ...filter,
      ...formFilter,
      page: 1,
      kota_kab: regionFilter.city,
      province: regionFilter.province,
    });
  };

  const resetFilter = () => {
    setFormFilter({ type: "", name: "", date_from: "", date_until: "" });
    regionFilter.setProvince("");
    regionFilter.setCity("");
    setFilter({
      type: "",
      name: "",
      date_from: "",
      date_until: "",
      province: "",
      kota_kab: "",
      page: 1,
      page_size: 10,
    });
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  return (
    <MainLayout resource_key="sales">
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Poin Penjualan</h1>
        <Box title="Poin Sales dan Affiliator" loading={commissions.loading}>
          <form onSubmit={(event) => applyFilter(event)}>
            <div className="row mb-4">
              <div className="col-sm-auto">
                <label>Role</label>
                <select
                  className="form-control"
                  value={formFilter.type}
                  onChange={(event) => changeFilter("type", event.target.value)}
                >
                  <option value="">SEMUA</option>
                  <option value="sales">SALES</option>
                  <option value="affiliator">AFFILIATOR</option>
                </select>
              </div>
              <div className="col-sm-2">
                <label>Nama</label>
                <input
                  type="text"
                  className="form-control"
                  value={formFilter.name}
                  onChange={(event) => changeFilter("name", event.target.value)}
                />
              </div>
              <div className="col-sm-2">
                <label>Tanggal Dari</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.date_from}
                  onChange={(event) => changeFilter("date_from", event.target.value)}
                />
              </div>
              <div className="col-sm-2">
                <label>Tanggal Akhir</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.date_until}
                  onChange={(event) => changeFilter("date_until", event.target.value)}
                />
              </div>
              <div className="col-sm-2">
                <label>Provinsi</label>
                <select
                  className="form-control"
                  value={regionFilter.province}
                  onChange={(event) => regionFilter.changeProvince(event.target.value)}
                >
                  <option value="">-- Semua Provinsi --</option>
                  {regionFilter.provinces.map((province, index) => (
                    <option value={province.province} key={index}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-2">
                <label>Kabupaten</label>
                <select
                  className="form-control"
                  value={regionFilter.city}
                  onChange={(event) => regionFilter.changeCity(event.target.value)}
                >
                  <option value="">-- Semua Kabupaten --</option>
                  {regionFilter.cities.map((city, index) => (
                    <option value={city.kota_kab} key={index}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-auto">
                <button className="btn btn-primary" style={{ marginTop: "32px" }}>
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  type="button"
                  style={{ marginTop: "32px" }}
                  onClick={() => resetFilter()}
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
          {commissions.errorMsg && (
            <AlertBox
              show={true}
              message={commissions.errorMsg}
              type={"danger"}
              setAlert={() => {}}
            />
          )}
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nama</th>
                <th>Role</th>
                <th>Lokasi</th>
                <th>Poin</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {commissions.data.length === 0 && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
              {commissions.data.map((commission, index) => (
                <tr key={index}>
                  <td>{commission.referral.name}</td>
                  <td>
                    {commission.referral.roles.length === 0 && "-"}
                    <ul className="px-3">
                      {commission.referral.roles.map((role, index) => (
                        <li key={index}>{role.name}</li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    {commission.referral.addresses.province} -{" "}
                    {commission.referral.addresses.kota_kab}
                  </td>
                  <td>{commission.referral_count}</td>
                  <td>
                    <Link
                      to={`/point-sales/${commission.referral_id}/detail`}
                      className="btn btn-block text-nowrap btn-sm btn-primary"
                    >
                      <i className="fa fa-eye"></i> Lihat Detail Poin
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4">
            {commissions.meta.count > commissions.meta.page_size && (
              <Pagination
                totalpage={Math.ceil(commissions.meta.count / commissions.meta.page_size)}
                selectedpage={commissions.meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(commissions.meta.page + 1)}
                prev={() => gotoPage(commissions.meta.page - 1)}
              />
            )}
          </div>
        </Box>
      </div>
    </MainLayout>
  );
}
