import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateWithdrawByCustomerId } from "../../api/customer";
import { useGetWithdrawInfoById } from "../../utils/CustomerUtilHooks";
import AlertBox from "../AlertBox";
import { convertToRupiah } from "../../tools/numerictools";
import ImageUploader from "../ImageUploader";
import moment from "moment";
import { RequiredAsterisk } from "../FormHelper";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function ModalUpdateWithdrawDeposit({ show, id, onSuccess, onClose }) {
  let { hasAccess } = useControlPermission();

  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const { data, fetched } = useGetWithdrawInfoById({ id });

  let [form, setForm] = useState({
    transfer_date: moment().format("YYYY-MM-DD"),
    bank_name: "",
    bank_account_name: "",
    bank_account_number: "",
    attachment_url: "",
  });

  const resetForm = () => {
    setForm({
      transfer_date: moment().format("YYYY-MM-DD"),
      bank_name: "",
      bank_account_name: "",
      bank_account_number: "",
      attachment_url: "",
    });
    setAlert({ show: false, message: "", type: "" });
  };

  useEffect(() => {
    if (fetched) {
      setForm((prev) => ({
        ...prev,
        transfer_date: data.withdrawal.transfer_date,
        bank_name: data.withdrawal.bank_name,
        bank_account_name: data.withdrawal.bank_account_name,
        bank_account_number: data.withdrawal.bank_account_number,
        attachment_url: data.withdrawal.attachment_url,
      }));
    }
  }, [data, fetched]);

  const submitData = (event) => {
    event.preventDefault();
    let formData = { ...form };

    submitWithdrawDeposit(formData);
  };

  const submitWithdrawDeposit = async (form) => {
    setLoading(true);
    try {
      let response = await updateWithdrawByCustomerId(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url });
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Pengembalian Deposit</Modal.Title>
      </Modal.Header>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Deposit</td>
                <td>Rp. {convertToRupiah(data.deposit_amount || 0)}</td>
              </tr>
            </tbody>
          </table>
          <div className="form-group">
            <label>
              No. Rekening Tujuan
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.bank_account_number}
              onChange={(event) => setForm({ ...form, bank_account_number: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>
              Bank
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.bank_name}
              onChange={(event) => setForm({ ...form, bank_name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>
              Nama Rekening
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.bank_account_name}
              onChange={(event) => setForm({ ...form, bank_account_name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>
              Tanggal Transfer
              <RequiredAsterisk />
            </label>
            <input
              type="date"
              className="form-control"
              required
              value={form.transfer_date}
              onChange={(event) => setForm({ ...form, transfer_date: event.target.value })}
            />
          </div>

          <div className="form-group mb-4">
            <label>Lampiran</label>
            <ImageUploader
              image={form.attachment_url}
              type="attachment_url"
              onChangeImg={onChangeImg}
              // required={true}
            />
          </div>
        </Modal.Body>
        {hasAccess("update-withdrawal-deposit") && (
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={() => handleClose()}
                className="btn btn-secondary mr-1"
              >
                Batal
              </button>
              <button type="submit" className="btn btn-primary" disabled={loading}>
                {loading ? "Menunggu proses ..." : "Simpan Perubahan"}
              </button>
            </div>
          </Modal.Footer>
        )}
      </form>
    </Modal>
  );
}
