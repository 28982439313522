import { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { useCityRegionHooks } from "../../utils/RegionHooks";
import * as api from "../../api/account";
import ImageUploader from "../ImageUploader";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import { useRolesListHooks } from "../../utils/PermissionManageUtils";

export default function ModalCreate(props) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    password: "",
    email: "",
    phone_number: "",
    image_url: "",
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [projectOpt, setProjectOpt] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const [roleOpt, setRoleOpt] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);

  const projects = useGroupListHooks();
  const region = useCityRegionHooks();
  const { roles } = useRolesListHooks();

  useEffect(() => {
    let rolesTemp = [];

    roles.forEach((project) => {
      let temp = {
        value: project.id,
        label: project.name,
      };
      rolesTemp.push(temp);

      return true;
    });

    setRoleOpt(rolesTemp);
  }, [roles]);

  useEffect(() => {
    let projectsTemp = [];

    projects.projects.forEach((project) => {
      let temp = {
        value: project.id,
        label: project.name,
      };
      projectsTemp.push(temp);

      return true;
    });

    setProjectOpt(projectsTemp);
  }, [projects.projects]);

  const submitData = (event) => {
    event.preventDefault();

    let formData = {
      ...form,
      address_id: String(region.city),
      roles: [],
    };

    if (selectedProjects.length > 0) {
      formData.projects = [];
      selectedProjects.forEach((project) => {
        formData.projects.push({
          id: project.value,
        });
      });
    }

    if (selectedRole.length > 0) {
      formData.roles = [];
      selectedRole.forEach((role) => {
        formData.roles.push({
          id: role.value,
        });
      });
    }

    sendData(formData);
  };

  const sendData = async (form) => {
    setLoading(true);
    try {
      let { data } = await api.createAccount({ ...form });
      console.log(data);
      handleClose();
      props.onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url });
  };

  const handleChangeRole = (selected) => {
    setSelectedRole(selected);
  };

  const handleClose = () => {
    // reseting all state
    setForm({ name: "", password: "", email: "", phone_number: "", image_url: "" });
    setSelectedProjects([]);
    setSelectedRole([]);
    setAlert({ show: false, message: "", type: "" });
    region.resetRegion();

    props.setShow("");
  };

  const handleChangeProject = (selected) => {
    setSelectedProjects([...selected]);
  };

  return (
    <Modal show={props.show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Buat Akun Baru</Modal.Title>
      </Modal.Header>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Body>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="nameCreate">
                  {" "}
                  Nama <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form.name}
                  onChange={(event) => setForm({ ...form, name: event.target.value })}
                  id="nameCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="emailCreate">
                  {" "}
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  value={form.email}
                  onChange={(event) => setForm({ ...form, email: event.target.value })}
                  id="emailCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="passwordCreate">
                  {" "}
                  Password <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  value={form.password}
                  onChange={(event) => setForm({ ...form, password: event.target.value })}
                  id="passwordCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumbCreate">
                  Nomor Telp <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form.phone_number}
                  onChange={(event) => setForm({ ...form, phone_number: event.target.value })}
                  id="phoneNumbCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label>Foto Akun</label>
                <ImageUploader
                  image={form.image_url}
                  type="image_url"
                  onChangeImg={onChangeImg}
                  required={false}
                />
              </div>
              <div className="form-group">
                <label htmlFor="provinsi">
                  Provinsi <span className="text-danger">*</span>
                </label>
                <select
                  name="provinsi"
                  id="provinsi"
                  className="form-control"
                  value={region.province}
                  onChange={(event) => region.setProvince(event.target.value)}
                  required
                >
                  <option value="">-- Pilih Provinsi --</option>
                  {region.provinces.map((province, index) => (
                    <option key={index} value={province.province}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="kabupaten">
                  Area Kabupaten/Kota <span className="text-danger">*</span>
                </label>
                <select
                  name="kabupaten"
                  id="kabupaten"
                  className="form-control"
                  value={region.city}
                  onChange={(event) => region.setCity(event.target.value)}
                  required
                >
                  <option value="">-- Pilih Kabupaten/Kota --</option>
                  {region.cities.map((city, index) => (
                    <option key={index} value={city.id}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="project">Kelompok/Project</label>
                <Select
                  isMulti
                  placeholder="Kelompok / Project"
                  onChange={(selected) => handleChangeProject(selected)}
                  options={projectOpt}
                />
              </div>
              <div className="form-group">
                <label htmlFor="project">
                  Multi Role <span className="text-danger">*</span>
                </label>
                <Select
                  value={selectedRole}
                  isMulti
                  isClearable
                  placeholder="Role"
                  required
                  onChange={(selected) => handleChangeRole(selected)}
                  options={roleOpt}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">
            Close
          </button>
          <button className="btn btn-primary" disabled={loading}>
            {loading ? "Membuat akun ..." : "Buat Akun"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
