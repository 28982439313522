import { useState } from "react";
import { Modal } from "react-bootstrap";
import { generateBillingById } from "../../api/customer";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import AlertBox from "../AlertBox";
import { convertToRupiah } from "../../tools/numerictools";

export default function ModalGenerateBillingItem({ show, id, onSuccess, onClose }) {
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const { data } = useGetCustomerHooks({ id });

  // internet package loader
  let [form, setForm] = useState({ notification: 1 });
  let [additionalItems, setAdditionalItems] = useState([{ name: "", price: 0, qty: 0, unit: "" }]);
  const resetForm = () => {
    setForm({ notification: 1 });
    setAdditionalItems([{ name: "", price: 0, qty: 0, unit: "" }]);
    setAlert({ show: false, message: "", type: "" });
  };

  const checkNotification = (e) => {
    if (e.target.checked) {
      setForm({ ...form, notification: 1 });
    } else {
      setForm({ ...form, notification: 0 });
    }
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      type: "ADDITIONAL-ITEM",
      additional_items: [...additionalItems],
      notification: form.notification,
    };

    submitBilling(formData);
  };

  const changeAdditionalItems = (value) => {
    let tempForms = [...additionalItems];
    tempForms = value;
    setAdditionalItems(tempForms);
  };

  const submitBilling = async (form) => {
    setLoading(true);
    try {
      let response = await generateBillingById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };
  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Buat Custom Billing - {data.registration?.registration_number} - {data.name}
        </Modal.Title>
      </Modal.Header>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Body>
          <h5>Data Pelanggan</h5>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Nomor Registrasi</td>
                <td>{data.registration?.registration_number}</td>
              </tr>
              <tr>
                <td>Nama Pelanggan</td>
                <td>{data.name}</td>
              </tr>
            </tbody>
          </table>
          <h5>Item Tambahan</h5>
          <FormAdditionalItems formValues={additionalItems} changeForms={changeAdditionalItems} />
          <div className="my-4">
            <h5>Preview Billing</h5>
            <TablePreviewBilling additionalItems={additionalItems} />
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                type="checkbox"
                checked={form.notification === 1}
                onChange={(event) => checkNotification(event)}
                className="form-check-input"
                id="notification-check"
              />
              <label className="form-check-label" htmlFor="notification-check">
                Beri notifikasi pelanggan
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button type="button" onClick={() => handleClose()} className="btn btn-secondary mr-1">
              Batal
            </button>
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? "Menunggu ..." : "Simpan"}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

function FormAdditionalItems({ formValues, changeForms }) {
  const changeFormSurvey = (index, name, value) => {
    let tempForms = [...formValues];
    tempForms[index][name] = value;
    changeForms(tempForms);
  };

  const addDefault = () => {
    let tempForms = [...formValues];
    tempForms.push({ name: "", price: 0, unit: "", qty: 0 });
    changeForms(tempForms);
  };

  const removeDefault = (index) => {
    if (formValues.length <= 1) {
      return;
    }

    let tempForms = [...formValues];
    if (!(tempForms.length <= 1)) {
      tempForms.splice(index, 1);
      changeForms(tempForms);
    }
  };

  return (
    <>
      {formValues.map((additionalItem, index) => (
        <div className="row" key={index}>
          <div className="col">
            <div className="row">
              <div className="col-12 col-md form-group">
                <label>Item #{index + 1}</label>
                <input
                  type="text"
                  required="true"
                  className="form-control"
                  value={additionalItem.name}
                  onChange={(event) => changeFormSurvey(index, "name", event.target.value)}
                />
              </div>
              <div className="col-12 col-md form-group">
                <label>Satuan item #{index + 1}</label>
                <input
                  type="text"
                  required="true"
                  className="form-control"
                  value={additionalItem.unit}
                  onChange={(event) => changeFormSurvey(index, "unit", event.target.value)}
                />
              </div>
              <div className="col-12 col-md form-group">
                <label>Harga per unit</label>
                <input
                  type="number"
                  required="true"
                  min={1}
                  className="form-control"
                  value={additionalItem.price}
                  onChange={(event) => changeFormSurvey(index, "price", event.target.value)}
                />
              </div>
              <div className="col-12 col-md form-group">
                <label>Jumlah Unit</label>
                <input
                  type="number"
                  required="true"
                  className="form-control"
                  min={1}
                  value={additionalItem.qty}
                  onChange={(event) => changeFormSurvey(index, "qty", event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-auto" style={{ paddingTop: "32px" }}>
            <button
              className="btn btn-sm btn-circle btn-dark"
              onClick={() => removeDefault(index)}
              type="button"
            >
              <i className="fa fa-minus"></i>
            </button>
          </div>
        </div>
      ))}
      <button className="btn btn-light" type="button" onClick={() => addDefault()}>
        <i className="fa fa-plus"></i> Tambah barang
      </button>
    </>
  );
}

function TablePreviewBilling({ additionalItems = [] }) {
  const parseInputNumber = (value) => {
    if (isNaN(value)) {
      return 0;
    }

    return value;
  };

  return (
    <table className="table table-sm table-hover table-bordered">
      <thead>
        <tr>
          <th>No.</th>
          <th>Item</th>
          <th>Jumlah</th>
          <th>Subtotal</th>
        </tr>
      </thead>
      <tbody>
        {additionalItems.length === 0 && (
          <tr>
            <td colSpan={4} className="text-center">
              Belum ada data
            </td>
          </tr>
        )}
        {additionalItems.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.name}</td>
            <td>
              {parseInputNumber(item.qty)} {item.unit}
            </td>
            <td className="text-right">
              Rp. {convertToRupiah(parseInputNumber(item.qty) * parseInputNumber(item.price))}
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={3} className="text-right">
            Total
          </td>
          <td className="text-right">
            Rp.{" "}
            {convertToRupiah(
              additionalItems.reduce(
                (accumulator, current) =>
                  accumulator + parseInputNumber(current.qty) * parseInputNumber(current.price),
                0
              )
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
