import { useState } from "react";
import Box from "../components/Box";
import MainLayout from "../components/layouts/MainLayout";
import { useProfileAuth } from "../utils/ProfileUtils";
import AlertBox from "../components/AlertBox";
import { deleteDisbursementAccount, updateDisbursementAccount } from "../api/account";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import { Modal } from "react-bootstrap";

export default function ProfilPage() {
  let { hasAccess } = useControlPermission();
  const { user, errorMsg, loading, loadUser } = useProfileAuth();

  return (
    <MainLayout resource_key="profile">
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Profil Akun</h1>
        </div>
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
        <div className="row">
          <div className="col-12 col-lg-6">
            <Box title="Profil" loading={loading}>
              <div className="my-2">
                <div className="font-weight-bold">Nama</div>
                <div className="">{user.name}</div>
              </div>
              <div className="my-2">
                <div className="font-weight-bold">Email</div>
                <div className="">{user.email}</div>
              </div>
              <div className="my-2">
                <div className="font-weight-bold">Jabatan Akun</div>
                <ul className="px-4">
                  {user.roles?.map((role, index) => (
                    <li key={index}>{role.name}</li>
                  ))}
                </ul>
              </div>
            </Box>
          </div>
          <div className="col-12 col-lg-4">
            {hasAccess("update-disbursement-account") && (
              <BoxPulsaPro user={user} loadUser={loadUser} />
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

function BoxPulsaPro({ user, loadUser }) {
  const [form, setForm] = useState({
    service: "pulsapro",
    account_id: "",
  });
  const [modal, setModal] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const updateDisbursement = async (form) => {
    setLoading(true);
    try {
      let response = await updateDisbursementAccount(form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      setForm({ service: "pulsapro", account_id: "" });
      setLoading(false);
      setEditMode(false);
      loadUser();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setLoading(false);
      setAlert({ show: true, message, type: "danger" });
    }
  };

  const submitUpdate = (event) => {
    event.preventDefault();
    updateDisbursement(form);
  };

  const onSuccess = (message) => {
    loadUser();
    setAlert({ show: true, message, type: "success" });
  };

  return (
    <Box title="Akun Pulsa Pro">
      <AlertBox {...alert} setAlert={setAlert} />
      {!editMode ? (
        <div className="my-2">
          <div className="font-weight-bold mb-2">
            Akun Pulsa Pro{" "}
            {user.disbursement_service ? (
              <span className="badge badge-success">
                <i className="fa fa-check"></i> Terhubung
              </span>
            ) : null}
          </div>
          <div className="d-flex justify-content-between">
            <span>{user.disbursement_account_id || "Belum ada nomor"}</span>
            {user.disbursement_account_id ? (
              <div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setForm({ ...form, account_id: user.disbursement_account_id });
                    setEditMode(true);
                  }}
                >
                  <i className="fa fa-pencil"></i> Ubah
                </button>
                <button
                  className="btn btn-sm btn-danger ml-2"
                  onClick={() => setModal("disconnect")}
                >
                  <i className="fa fa-times"></i> Hapus
                </button>
              </div>
            ) : (
              <button className="btn btn-sm btn-primary" onClick={() => setEditMode(true)}>
                <i className="fa fa-pencil"></i> Hubungkan Akun
              </button>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="font-weight-bold mb-2">Hubungkan akun Pulsa Pro anda</div>
          <form onSubmit={(event) => submitUpdate(event)}>
            <div className="form-group">
              <label>Nomor Telepon Akun Pulsapro</label>
              <input
                type="number"
                className="form-control"
                required
                value={form.account_id}
                onChange={(event) => setForm({ ...form, account_id: event.target.value })}
                placeholder="Masukan no. telpon akun Pulsa Pro"
              />
            </div>
            <button className="btn btn-primary" disabled={loading} type="submit">
              {loading ? "Menyambungkan ..." : "Hubungkan akun"}
            </button>
            <button
              type="button"
              className="btn btn-secondary ml-2"
              disabled={loading}
              onClick={() => {
                setForm({ ...form, account_id: "" });
                setEditMode(false);
              }}
            >
              Batal
            </button>
          </form>
        </>
      )}
      <ModalDeleteAccount
        show={modal === "disconnect"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
    </Box>
  );
}

function ModalDeleteAccount({ show, onClose, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      onClose();
    }
  };

  const disconnectAccount = async () => {
    setLoading(true);
    try {
      let response = await deleteDisbursementAccount();
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Putuskan akun </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <h5 className="text-gray-800">Apakah anda yakin akan menghapus akun Pulsa Pro anda?</h5>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
          Batal
        </button>
        <button disabled={loading} className="btn btn-danger" onClick={() => disconnectAccount()}>
          <i className="fas fa-times"></i> {loading ? "Memutuskan Akun ..." : "Ya, putuskan akun"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
