export const ADDITIONAL_DOCS = {
  "k3-pre-installation": "Foto Kesiapan K3 Sebelum Pekerjaan",
  "k3-post-installation": "Foto Kesiapan K3 Sesudah Pekerjaan",
  "k3-fat-initial-condition": "Foto Kondisi Awal Tiang Tampak Kabel, DW dan Box FAT",
  "k3-fat-box-before": "Foto Dalam Box FAT Sebelum",
  "k3-fat-box-after": "Foto Dalam Box FAT Sesudah",
  "k3-fat-in-damping-check": "Foto Redaman Masuk  (IN)",
  "k3-fat-out-damping-check": "Foto Redaman Keluar (OUT)",
  "k3-fat-splitter-port-status": "Foto Tampak Port Splitter",
  "k3-fat-spare-cable-arrangement": "Foto Perapihan Spare Kabel",
}