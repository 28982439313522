import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createModemByInventory } from "../../../api/inventory";
import AlertBox from "../../AlertBox";
import { RequiredAsterisk } from "../../FormHelper";

export default function ModalCreateModem({ show, inventoryId, onClose, onSuccess }) {
  let [form, setForm] = useState({
    brand: "",
    type: "",
    serial_number_modem: "",
    serial_number_gpon: "",
  });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({ ...form, serial_number_modem: "", serial_number_gpon: "" });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    submitCategory(form);
  };

  const submitCategory = async (form) => {
    setLoading(true);
    try {
      let response = await createModemByInventory(inventoryId, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Modem Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Brand Modem <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.brand}
              onChange={(event) => setForm({ ...form, brand: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Tipe Modem <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.type}
              onChange={(event) => setForm({ ...form, type: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Serial Number Modem <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.serial_number_modem}
              onChange={(event) => setForm({ ...form, serial_number_modem: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Serial Number GPON <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.serial_number_gpon}
              onChange={(event) => setForm({ ...form, serial_number_gpon: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? (
              "Membuat Modem ..."
            ) : (
              <>
                <i className="fas fa-plus"></i> Buat Modem Baru
              </>
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
