import { useState } from "react";
import { useParams } from "react-router-dom";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import BackToWarehouseInventoryLink from "../../components/inventory/warehouse/BackToWarehouseLink";
import MainLayout from "../../components/layouts/MainLayout";
import { useWarehouseHooks } from "../../utils/InventoryHooks";
import { Modal } from "react-bootstrap";
import {
  useHistoriesOutByWarehouseHooks,
  useInventoryDetailOutgoingHistoryHooks,
} from "../../utils/inventory/InventoryOutUtils";
import Pagination from "../../components/Pagination";
import moment from "moment";
import { LoadingInsideBoxWrapper } from "../../components/Loading";

export default function WarehouseInventoryOutHistoryPage() {
  let { id } = useParams();

  let [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    id: id,
  });

  let warehouse = useWarehouseHooks({ id });
  const { histories, meta, errorMsg, loading } = useHistoriesOutByWarehouseHooks({ filter });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [modal, setModal] = useState(null);
  let [selectedId, setSelectedId] = useState(null);

  const toggleModal = (modal = null, id = null) => {
    setModal(modal);
    setSelectedId(id);
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <BackToWarehouseInventoryLink warehouse={warehouse.data} />
        <h1 className="h3 mb-4 text-gray-800">
          Riwayat Pengeluaran Barang - {warehouse.data.name}
        </h1>
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
        <AlertBox {...alert} setAlert={setAlert} />
        <Box title="Riwayat Pengeluaran Barang" loading={loading}>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Tgl. input</th>
                <th>Waktu Pengeluaran Barang</th>
                <th>Tujuan</th>
                <th>Tipe</th>
                <th>Dilakukan Oleh</th>
                <th>Tujuan Pengeluaran</th>
              </tr>
            </thead>
            <tbody>
              {histories.length === 0 && (
                <tr>
                  <td colSpan={6} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
              {histories.map((history) => (
                <tr key={history.id}>
                  <td>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => toggleModal("detail", history.id)}
                    >
                      <i className="fa fa-eye"></i> Detail
                    </button>
                  </td>
                  <td>{moment.unix(history.createdAt).format("DD MMM YYYY - HH:mm")}</td>
                  <td>
                    {history.input_date
                      ? moment.unix(history.input_date).format("DD MMM YYYY")
                      : "-"}
                  </td>
                  <td>
                    {history.destination_type === "REGISTRATION" &&
                      history.registration?.registration_number}
                    {history.destination_type === "PROJECT" && history.project.name}
                  </td>
                  <td>{history.destination_type}</td>
                  <td>{history.user.name}</td>
                  <td>{history.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {meta.count > meta.page_size && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.page_size)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalDetail
        show={modal === "detail"}
        id={modal === "detail" ? selectedId : null}
        warehouse_id={id}
        onClose={() => toggleModal()}
      />
    </MainLayout>
  );
}

function ModalDetail({ show, onClose, id, warehouse_id }) {
  return (
    <Modal show={show} onHide={() => onClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Riwayat Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataDetail id={id} warehouse_id={warehouse_id} />
      </Modal.Body>
    </Modal>
  );
}

function DataDetail({ id, warehouse_id }) {
  const [filter] = useState({ id: id, warehouse_id: warehouse_id });
  const { detail, loading, errorMsg } = useInventoryDetailOutgoingHistoryHooks({ filter });
  return (
    <LoadingInsideBoxWrapper>
      {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
      {loading && <LoadingInsideBoxWrapper />}
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Nama Inventory</th>
            <th>S/N</th>
            <th>Unit</th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
                <td className="text-center" colSpan={4}>
                  Tidak ada data
                </td>
              </tr> */}
          {detail?.items?.map((detail, index) => (
            <tr key={index}>
              <td>{detail.inventory.sku}</td>
              <td>{detail.inventory.name}</td>
              <td>{detail.modem?.serial_number_modem || "-"}</td>
              <td>
                {detail.qty} {detail.inventory.unit}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </LoadingInsideBoxWrapper>
  );
}
