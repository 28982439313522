import React from "react";

export default function AlertBox({show, type, message, setAlert}) {
  return (
    <>
      {show &&
        <div className={`alert alert-${type} alert-dismissible fade show`} role="alert">
          {message}
          <button type="button" className="close"  onClick={() => setAlert({show: false, message: '', type: ''})} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      }
    </>
  )
}