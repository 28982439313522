import axios from 'axios';
import moment from 'moment/moment';
const base_api = process.env.REACT_APP_API_URL;

export const apiBasic = axios.create({
  baseURL: base_api,
  timeout: 600000
});

export const apiAuth = () => axios.create({
  baseURL: base_api,
  timeout: 600000,
  headers: {
    Authorization: 'JWT '+ localStorage.getItem('token'),
    'X-CLIENT-ID': localStorage.getItem('clientUserId'),
    'X-TIMESTAMP': moment().format(),
  }
});

export const apiUploader = () => axios.create({
  baseURL: base_api,
  timeout: 600000,
  headers: {
    Authorization: 'JWT '+ localStorage.getItem('token'),
    'X-CLIENT-ID': localStorage.getItem('clientUserId'),
    'X-TIMESTAMP': moment().format(),
    'Content-Type': 'multipart/form-data'
  }
});

export const apiDownloadAuth = () => axios.create({
  baseURL: base_api,
  timeout: 600000,
  responseType: 'blob',
  headers: {
    Authorization: 'JWT '+ localStorage.getItem('token'),
    'X-CLIENT-ID': localStorage.getItem('clientUserId'),
    'X-TIMESTAMP': moment().format(),
  }
});

export function login(action) {
  let params = { ...action };
  return apiBasic.post("v1/auths/login", params);
}

export function getProfile() {
  let api = apiAuth();
  return api.get('v1/auths/profile');
}

export function getRegions(params) {
  let api = apiAuth();
  return api.get("v1/presets/address", { params })
}