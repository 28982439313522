import { useState, useEffect } from "react";
import { getModemBySerial } from "../../../api/inventory";

export default function FormCheckModem({ serial, value, onChangeModemSuccess }) {
  const [inputSerial, setInputSerial] = useState("");
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (serial) {
      setInputSerial(serial);
    }
  }, [serial]); // Empty dependency array

  const getModem = async (serial) => {
    setError("");
    setLoading(true);
    setFetched(false);
    try {
      let response = await getModemBySerial({ serial_number_modem: serial });
      onChangeModemSuccess({
        id: response.data.data.id,
        serial_number_gpon: response.data.data.serial_number_gpon,
      });
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return (
    <>
      <div className="row form-group">
        <div className="col">
          <input
            type="text"
            className="form-control"
            placeholder="Serial Modem"
            value={inputSerial}
            onChange={(event) => setInputSerial(event.target.value)}
          />
          {loading && <span className="text-dark">Mengecek modem...</span>}
          {fetched && <span className="text-success">Modem tersedia</span>}
          {error && <span className="text-danger">Modem tidak tersedia ({error})</span>}
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary"
            type="button"
            disabled={loading}
            onClick={() => getModem(inputSerial)}
          >
            {loading ? "Cek ..." : "Cek Modem"}
          </button>
        </div>
      </div>
    </>
  );
}
