import { useState } from "react";
import { usePackTypeList } from "../../utils/InternetPackageUtils";
import Box from "../Box";
import ModalCreateType from "./ModalCreateType";
import ModalDeleteType from "./ModalDeleteType";
import ModalUpdateType from "./ModalUpdateType";
import Pagination from "../Pagination";

export default function BoxTypePack() {
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [selectedId, setSelectedId] = useState("");
  let [filter, setFilter] = useState({ q: "", page: 1, page_size: 10 });
  let [formFilter, setFormFilter] = useState({ q: "" });

  let [showModalCreate, setShowModalCreate] = useState(false);
  let [showModalUpdate, setShowModalUpdate] = useState(false);
  let [showModalDelete, setShowModalDelete] = useState(false);

  let typeData = usePackTypeList({ filter });

  const onSuccess = (show, message) => {
    typeData.getTypes({});
    setAlert({ show, message, type: "success" });
  };

  const toggleOpenModalUpdate = (id = "") => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleOpenModalDelete = (id = "") => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ q: "" });
    setFilter({ q: "", page: 1, page_size: 10 });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <Box title="Tipe Paket" loading={typeData.loading}>
      <div className="row mb-3 justify-content-end">
        <div className="col-auto">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setShowModalCreate(!showModalCreate)}
          >
            <i className="fa fa-plus"></i> Tambah Tipe Paket
          </button>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-auto">
          <label htmlFor="querySearch">Pencarian</label>
          <input
            type="text"
            name="querySearch"
            value={formFilter.q}
            onChange={(event) => changeFilter("q", event.target.value)}
            className="form-control"
            id="querySearch"
          />
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary"
            onClick={() => applyFilter()}
            style={{ marginTop: "32px" }}
          >
            Filter
          </button>
          <button
            className="btn btn-default"
            onClick={() => resetFilter()}
            style={{ marginTop: "32px" }}
          >
            Reset
          </button>
        </div>
      </div>
      {alert.show && (
        <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
          {alert.message}
          <button
            type="button"
            className="close"
            onClick={() => setAlert({ show: false, message: "", type: "" })}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <table className="table table-striped table-bordered table-sm table-hovered">
        <thead>
          <tr className="text-center">
            <th>Tipe Paket</th>
            <th>Deskripsi</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {typeData.types.length === 0 && (
            <tr>
              <td colSpan={3} className="text-center">
                Tidak ada data
              </td>
            </tr>
          )}
          {typeData.types.map((types, index) => (
            <tr key={index}>
              <td>{types.name}</td>
              <td>{types.description}</td>
              <td>
                <button
                  className="mx-1 btn btn-sm btn-primary"
                  onClick={() => toggleOpenModalUpdate(types.id)}
                >
                  <i className="fa fa-pencil-alt"></i>
                </button>
                <button
                  className="mx-1 btn btn-sm btn-danger"
                  onClick={() => toggleOpenModalDelete(types.id)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {typeData.meta.count > typeData.meta.page_size && (
        <Pagination
          totalpage={Math.ceil(typeData.meta.count / typeData.meta.page_size)}
          selectedpage={typeData.meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(typeData.meta.page + 1)}
          prev={() => gotoPage(typeData.meta.page - 1)}
        />
      )}

      <ModalCreateType show={showModalCreate} onClose={setShowModalCreate} onSuccess={onSuccess} />
      <ModalUpdateType
        show={showModalUpdate}
        onClose={toggleOpenModalUpdate}
        id={showModalUpdate ? selectedId : ""}
        onSuccess={onSuccess}
      />
      <ModalDeleteType
        show={showModalDelete}
        onClose={toggleOpenModalDelete}
        id={showModalDelete ? selectedId : ""}
        onSuccess={onSuccess}
      />
    </Box>
  );
}
