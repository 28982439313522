import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getRegistrationInventoryOut, postOutgoingInventoryWarehouse } from "../../api/inventory";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import ModalChooseOutgoingModem from "../../components/inventory/out/ModalChooseOutgoingModem";
import ModalChooseTransferInventories from "../../components/inventory/transfer/ModalChooseTransferInventory";
import BackToWarehouseInventoryLink from "../../components/inventory/warehouse/BackToWarehouseLink";
import MainLayout from "../../components/layouts/MainLayout";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useWarehouseHooks } from "../../utils/InventoryHooks";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";

export default function WarehouseInventoryOutPage() {
  let { id } = useParams();
  let { hasAccess } = useControlPermission();

  let warehouse = useWarehouseHooks({ id });
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({
    destination_type: "",
    id: "",
    description: "",
    input_date: moment().format("YYYY-MM-DD"),
  });
  let [previewInventories, setPreviewInventories] = useState([]);

  let [modal, setModal] = useState(null);

  const onSelectInventory = (item) => {
    let items = [...previewInventories];
    items.push(item);
    setPreviewInventories([...items]);
    setModal(null);
  };

  const onChangeType = (value) => {
    setForm({ ...form, destination_type: value, id: "" });
  };

  const onChangeQty = (value, index) => {
    let items = [...previewInventories];
    items[index].qty = value;
    setPreviewInventories(items);
  };

  const onDeleteItem = (index) => {
    let items = [...previewInventories];
    items.splice(index, 1);
    setPreviewInventories(items);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  const resetData = () => {
    setForm({
      destination_type: "",
      id: "",
      description: "",
      input_date: moment().format("YYYY-MM-DD"),
    });
    setPreviewInventories([]);
  };

  const submitBulk = () => {
    let formData = {
      ...form,
    };

    formData.items = previewInventories.map((inventory) => {
      let temp = {
        id: inventory.id,
        qty: inventory.qty,
      };

      if (inventory.modem_id) {
        temp.modem_id = inventory.modem_id;
      }

      return temp;
    });

    sendInventoryOut(formData);
  };

  const sendInventoryOut = async (formData) => {
    setModal("loading");

    try {
      let message = "";
      let { data } = await postOutgoingInventoryWarehouse(id, formData);
      message = data.message;

      onSuccess(message);
      resetData();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setModal(null);
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <BackToWarehouseInventoryLink warehouse={warehouse.data} />
        <h1 className="h3 mb-4 text-gray-800">Pengeluaran Barang - {warehouse.data.name}</h1>
        {warehouse.error && <div className="alert alert-danger">{warehouse.error}</div>}
        <AlertBox {...alert} setAlert={setAlert} />
        <div className="row">
          <div className="col-12 col-lg-4">
            <Box title="Tujuan Pengeluaran">
              <div className="form-group">
                <label>Tipe</label>
                <select
                  className="form-control"
                  value={form.destination_type}
                  onChange={(event) => onChangeType(event.target.value)}
                >
                  <option value="">--</option>
                  <option value="project">Project</option>
                  <option value="registration">Pelanggan</option>
                </select>
              </div>

              {/* Pilih Project */}
              {form.destination_type === "project" &&
                (warehouse.data.projects?.length !== 0 ? (
                  <ProjectSelectByWarehouse
                    value={form.id}
                    onChange={(value) => setForm({ ...form, id: value })}
                    projects={warehouse.data.projects || []}
                  />
                ) : (
                  <ProjectSelect
                    value={form.id}
                    onChange={(value) => setForm({ ...form, id: value })}
                  />
                ))}
              {/* Pilih Pelanggan */}
              {form.destination_type === "registration" && (
                <UserRegistrationSelect
                  onChange={(value) => setForm({ ...form, id: value })}
                  warehouse_id={id}
                />
              )}
              <div className="form-group">
                <label>Catatan Pengeluaran Inventory</label>
                <textarea
                  className="form-control"
                  rows="4"
                  value={form.description}
                  onChange={(event) => setForm({ ...form, description: event.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Tanggal Pengeluaran</label>
                <input
                  type="date"
                  className="form-control"
                  value={form.input_date}
                  onChange={(event) => setForm({ ...form, input_date: event.target.value })}
                />
              </div>
            </Box>
          </div>
          <div className="col-12 col-lg-8">
            <Box title="Form Pengeluaran Barang">
              <div className="row mt-4">
                <div className="col">
                  <h4>Daftar Inventory Keluar</h4>
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-primary btn-sm mr-2"
                    onClick={() => setModal("choose-modem")}
                  >
                    Daftar Modem
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => setModal("inventories")}
                  >
                    Daftar Inventory
                  </button>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Nomor Serial</th>
                    <th>Nama Barang</th>
                    <th>Kategori</th>
                    <th>Qty</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {previewInventories.length === 0 && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        Belum ada data
                      </td>
                    </tr>
                  )}
                  {previewInventories.map((inventory, index) => (
                    <tr key={index}>
                      <td>{inventory.sku}</td>
                      <td>{inventory.serial_number_modem || "-"}</td>
                      <td>{inventory.brand || inventory.name}</td>
                      <td>{inventory.inventory_category?.name}</td>
                      <td>
                        <div className="row">
                          <div className="col">
                            {inventory.serial_number_modem ? (
                              `${inventory.qty}`
                            ) : (
                              <input
                                type="number"
                                className="form-control"
                                value={inventory.qty}
                                min={1}
                                onChange={(event) => onChangeQty(event.target.value, index)}
                              />
                            )}
                          </div>
                          <div className="col-auto">{inventory.unit}</div>
                        </div>
                      </td>
                      <td>
                        <div className="btn btn-danger btn-sm" onClick={() => onDeleteItem(index)}>
                          <i className="fa fa-times"></i> Hapus
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="row justify-content-end">
                <div className="col-12 col-md-6 d-flex justify-content-end">
                  {hasAccess("create-inventory-warehouse-out") && (
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={() => setModal("confirm")}
                      disabled={previewInventories.length === 0}
                    >
                      <i className="fa fa-paper-plane" /> Keluarkan Inventory
                    </button>
                  )}
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>

      <ModalChooseTransferInventories
        show={modal === "inventories"}
        onClose={() => setModal(null)}
        warehouse_id={id}
        onSelectInventory={onSelectInventory}
        modem={form.type === "modem"}
      />
      <ModalChooseOutgoingModem
        show={modal === "choose-modem"}
        onClose={() => setModal(null)}
        warehouse_id={id}
        onSelectInventory={onSelectInventory}
        modem={true}
      />
      <ModalConfirm
        show={modal === "confirm"}
        onClose={() => setModal(null)}
        onBulkAdd={() => submitBulk()}
      />
      <LoadingModalUpload show={modal === "loading"} />
    </MainLayout>
  );
}

function ProjectSelect({ value, onChange }) {
  const { projects } = useGroupListHooks();

  return (
    <div className="form-group">
      <label>Tujuan</label>
      <select
        className="form-control"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        required
      >
        <option value="">-- project --</option>
        {projects.map((project, index) => (
          <option value={project.id} key={index}>
            {project.name}
          </option>
        ))}
      </select>
    </div>
  );
}

function ProjectSelectByWarehouse({ value, onChange, projects }) {
  return (
    <div className="form-group">
      <label>Tujuan</label>
      <select
        className="form-control"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        required
      >
        <option value="">-- project --</option>
        {projects.map((project, index) => (
          <option value={project.id} key={index}>
            {project.name}
          </option>
        ))}
      </select>
    </div>
  );
}

function UserRegistrationSelect({ onChange, warehouse_id }) {
  let [form, setForm] = useState({ reg_number: "" });

  let [data, setData] = useState({});
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  const getRegistration = async (registration_number) => {
    setData({});
    setError("");
    setLoading(true);
    setFetched(false);

    try {
      let { data } = await getRegistrationInventoryOut(registration_number, {
        warehouse_id: warehouse_id,
      });
      setData(data.data);
      onChange(data.data.id);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  const submitRegistration = (event) => {
    event.preventDefault();
    getRegistration(form.reg_number);
  };

  const onReset = () => {
    setData({});
    onChange("");
    setError("");
    setLoading(false);
    setFetched(false);
  };

  return (
    <div>
      {!fetched && (
        <form onSubmit={(event) => submitRegistration(event)}>
          <div className="form-group">
            <label>Tujuan</label>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nomor Registrasi ..."
                  value={form.reg_number}
                  onChange={(event) => setForm({ ...form, reg_number: event.target.value })}
                />
              </div>
              <div className="col-auto">
                <button className="btn btn-primary" disabled={loading}>
                  {loading ? "Mencari ..." : "Cari"}
                </button>
              </div>
            </div>
            {error && <span className="text-danger">{error}</span>}
          </div>
        </form>
      )}
      {fetched && (
        <>
          <div className="form-group">
            <label>Pelanggan</label>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nomor Registrasi ..."
                  value={form.reg_number}
                  disabled
                />
              </div>
              <div className="col-auto">
                <button type="button" className="btn btn-danger" onClick={() => onReset()}>
                  Batal
                </button>
              </div>
            </div>
          </div>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Registrasi</td>
                <td>{data.registration_number || "-"}</td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>{data.name || "-"}</td>
              </tr>
              <tr>
                <td>Project</td>
                <td>{data.project?.name || "-"}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

function ModalConfirm({ show, onClose, onBulkAdd }) {
  const handleClose = () => {
    onClose();
  };

  const upload = async () => {
    onBulkAdd();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => event.preventDefault()}>
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi Pengeluaran Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Apakah anda yakin untuk melakukan pengeluaran inventory ini? Anda tidak dapat melakukan
          tindakan kembali jika terjadi kesalahan. Pastikan data anda sudah benar!
          <div className="d-flex justify-content-center py-4">
            <button className="btn btn-lg btn-success mr-2" onClick={() => upload()}>
              Ya, Saya yakin
            </button>
            <button className="btn btn-lg btn-secondary" onClick={() => handleClose()}>
              Tidak
            </button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
}

function LoadingModalUpload({ show }) {
  return (
    <Modal show={show} onHide={() => {}}>
      <div className="alert alert-warning mb-0">
        <h4>
          <i className="fa fa-hourglass"></i> Harap menunggu.
        </h4>
        <span>Inventori sedang diproses oleh sistem ...</span>
      </div>
    </Modal>
  );
}
