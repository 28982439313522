import { apiAuth } from "./base";

export function getListSales() {
  let api = apiAuth();
  return api.get(`v1/users/show-all`);
}

export function registerCustomer(data) {
  let api = apiAuth();
  return api.post(`v1/registrations`, data);
}
