import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import AlertBox from "../components/AlertBox";
import Box from "../components/Box";
import ModalAddInventory from "../components/inventory/ModalAddInventory";
import ModalHistoryInventory from "../components/inventory/ModalHistoryInventory";
import MainLayout from "../components/layouts/MainLayout";
import Pagination from "../components/Pagination";
import { convertToRupiah } from "../tools/numerictools";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import {
  useInventoriesByWarehouseHooks,
  useWarehouseHooks,
  useInventoryCategories,
} from "../utils/InventoryHooks";
import ModalHistoryInventoryModem from "../components/inventory/ModalHistoryInventoryModem";
import ModalDownloadWarehouseInventory from "../components/inventory/ModalDownloadWarehouseInventory";

export default function WarehouseInventoryPage() {
  const { id } = useParams();
  let { hasAccess } = useControlPermission();

  let [filter, setFilter] = useState({
    q: "",
    inventory_category_id: "",
    page: 1,
    page_size: 10,
    id: id,
  });
  let [formFilter, setFormFilter] = useState({ q: "", inventory_category_id: "" });
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let categories = useInventoryCategories({ page: 1, page_size: 99 });

  let [selectedId, setSelectedId] = useState("");
  let [showModalDownload, setShowModalDownload] = useState(false);
  let [showModalHistory, setShowModalHistory] = useState(false);
  let [showModalHistoryModem, setShowModalHistoryModem] = useState(false);
  let [showModalCreate, setShowModalCreate] = useState(false);

  let warehouse = useWarehouseHooks({ id });
  let inventories = useInventoriesByWarehouseHooks({ filter });

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ q: "", inventory_category_id: "" });
    setFilter({ q: "", inventory_category_id: "", page: 1, page_size: 10, id: id });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const toggleOpenModalHistory = (id = null) => {
    setSelectedId(id);
    setShowModalHistory(!showModalHistory);
  };

  const toggleOpenModalHistoryModem = (id = null) => {
    setSelectedId(id);
    setShowModalHistoryModem(!showModalHistoryModem);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    setFilter({ ...filter });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <BackToManageWarehouseLink />
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Data Inventory {warehouse.data.name}</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-inventory-warehouse") && (
              <button
                className="btn btn-custom-purple mr-2"
                onClick={() => setShowModalDownload(true)}
              >
                <i className="fa fa-download"></i> Unduh data inventory
              </button>
            )}
            {inventories.fetched &&
              (hasAccess("create-inventory") ||
                hasAccess("transfer-inventory") ||
                hasAccess("create-inventory-warehouse-out") ||
                hasAccess("view-inventory-warehouse-out")) && (
                <Dropdown bsPrefix="d-inline">
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    <i className="fa-solid fa-right-left"></i> Kelola Inventory
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {hasAccess("create-inventory") && (
                      <Dropdown.Item
                        as={Link}
                        to={`/warehouses/${warehouse.data.id}/manual-inventory`}
                      >
                        <span className="">Tambah Inventory</span>
                      </Dropdown.Item>
                    )}
                    {hasAccess("create-inventory") && (
                      <Dropdown.Item
                        as={Link}
                        to={`/warehouses/${warehouse.data.id}/inventory-upload`}
                      >
                        <span className="">Tambah Inventory Baru via Upload Excel</span>
                      </Dropdown.Item>
                    )}
                    {hasAccess("transfer-inventory") && (
                      <Dropdown.Item
                        as={Link}
                        to={`/warehouses/${warehouse.data.id}/manual-transfer`}
                      >
                        <span className="">Manual Transfer Inventory</span>
                      </Dropdown.Item>
                    )}
                    {hasAccess("transfer-inventory") && (
                      <Dropdown.Item
                        as={Link}
                        to={`/warehouses/${warehouse.data.id}/transfer-upload`}
                      >
                        <span className="">Transfer Inventory via Upload Excel</span>
                      </Dropdown.Item>
                    )}
                    {hasAccess("create-inventory-warehouse-out") && (
                      <Dropdown.Item
                        as={Link}
                        to={`/warehouses/${warehouse.data.id}/inventory-out`}
                      >
                        <span className="">Pengeluaran Inventory</span>
                      </Dropdown.Item>
                    )}
                    {hasAccess("view-inventory-warehouse-out") && (
                      <Dropdown.Item
                        as={Link}
                        to={`/warehouses/${warehouse.data.id}/inventory-out-history`}
                      >
                        <span className="">Riwayat Pengeluaran Inventory</span>
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
          </div>
        </div>
        <Box title="Inventory" loading={inventories.loading}>
          <div className="row mb-4">
            <div className="col-auto">
              <label htmlFor="inventory_name">Pencarian</label>
              <input
                type="text"
                name="inventory_name"
                placeholder="SKU / nama inventory .."
                value={formFilter.q}
                onChange={(event) => changeFilter("q", event.target.value)}
                className="form-control"
                id="inventory_name"
              />
            </div>
            <div className="col-auto">
              <label htmlFor="category">Kategori</label>
              <select
                type="text"
                name="category"
                value={formFilter.inventory_category_id}
                onChange={(event) => changeFilter("inventory_category_id", event.target.value)}
                className="form-control"
                id="category"
              >
                <option value="">-- Pilih Kategori --</option>
                {categories.categories.map((category, index) => (
                  <option value={category.id} key={index}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
          </div>
          {warehouse.error && <div className="alert alert-danger">{warehouse.error}</div>}
          {inventories.errorMsg && <div className="alert alert-danger">{inventories.errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Kategori</th>
                  <th>Stock</th>
                  <th>Unit</th>
                  <th>Harga</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {inventories.inventories.length === 0 && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      {" "}
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {inventories.inventories.map((inventory, index) => (
                  <tr key={index}>
                    <td>{inventory.sku}</td>
                    <td>{inventory.name}</td>
                    <td>{inventory.inventory_category.name}</td>
                    <td>{inventory.stock}</td>
                    <td>{inventory.unit}</td>
                    <td>Rp. {convertToRupiah(inventory.price)}</td>
                    <td width={100}>
                      {hasAccess("view-inventory-modem") &&
                        inventory.inventory_category.name.toLowerCase() === "modem" && (
                          <Link
                            to={`/warehouses/${warehouse.data.id}/inventory/${inventory.id}/modems`}
                            className="btn btn-block text-nowrap btn-sm btn-success mr-2"
                          >
                            <i className="fa fa-wifi"></i> Manajemen Modem
                          </Link>
                        )}

                      {hasAccess("view-inventory-history") ? (
                        inventory.inventory_category.name.toLowerCase() === "modem" ? (
                          <button
                            className="btn btn-block btn-sm btn-primary mr-2"
                            onClick={() => toggleOpenModalHistoryModem(inventory.id)}
                          >
                            <i className="fa fa-history"></i> Riwayat Modem
                          </button>
                        ) : (
                          <button
                            className="btn btn-block btn-sm btn-primary mr-2"
                            onClick={() => toggleOpenModalHistory(inventory.id)}
                          >
                            <i className="fa fa-history"></i> Riwayat Barang
                          </button>
                        )
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {inventories.meta.count > inventories.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(inventories.meta.count / inventories.meta.page_size)}
              selectedpage={inventories.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(inventories.meta.page + 1)}
              prev={() => gotoPage(inventories.meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalAddInventory
        show={showModalCreate}
        onClose={() => setShowModalCreate(false)}
        warehouse_id={id}
        onSuccess={onSuccess}
      />
      <ModalDownloadWarehouseInventory
        show={showModalDownload}
        onClose={() => setShowModalDownload(false)}
        warehouse_id={id}
        warehouse_name={warehouse.data?.name || ""}
        onSuccess={onSuccess}
      />
      <ModalHistoryInventoryModem
        show={showModalHistoryModem}
        onClose={toggleOpenModalHistoryModem}
        id={showModalHistoryModem ? selectedId : ""}
        warehouse_id={id}
        onSuccess={onSuccess}
      />
      <ModalHistoryInventory
        show={showModalHistory}
        onClose={toggleOpenModalHistory}
        id={showModalHistory ? selectedId : ""}
        warehouse_id={id}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function BackToManageWarehouseLink() {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={`/warehouses`}>
            <i className="fa-regular fa-circle-left"></i> Kembali ke Manajemen Gudang
          </Link>
        </li>
      </ol>
    </nav>
  );
}
