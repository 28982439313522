import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useAllPromoHooks } from "../../utils/promo/PromoUtil";

export default function PromoPackagePage() {
  let [filter, setFilter] = useState({page: 1, page_size: 10 });

  const promo = useAllPromoHooks({filter});

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Promo Paket Internet</h1>
        </div>

        <Box title="Promo Paket">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Kode</th>
                <th>Discount Type</th>
                <th>Discount Amount</th>
                <th>Periode Billing</th>
                <th>Type</th>
                <th>Produk</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {promo.data.length === 0 && 
                <tr>
                  <td colSpan={7} className="text-center">Tidak ada data</td>
                </tr>
              }
              {promo.data.map((promo, index) => (
                <tr key={index}>
                  <td>{promo.code}</td>
                  <td>{promo.discount_type}</td>
                  <td>{promo.discount_amount}</td>
                  <td>{promo.billing_period}</td>
                  <td>{promo.type}</td>
                  <td>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Produk</th>
                        </tr>
                      </thead>
                      <tbody>
                        {promo.promo_items.map((item, index) => (
                          <tr key={index}>
                            <td>{item.internet_package?.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                  <td>
                    {/* <button className="btn btn-sm btn-block btn-primary">Edit</button>
                    <button className="btn btn-sm btn-block btn-danger">Delete</button> */}
                    -----
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </div>
    </MainLayout>
  )
}