import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, BarChart, Bar} from 'recharts';
import { useTopLocation } from '../../utils/DashboardHooks';
import Box from '../Box';    

export default function BoxTopLocation() {
  const { data, fetched, loading, errorMsg } = useTopLocation();

  let dataParsed = [];

  if (fetched) {
    data.forEach((location) => {
      let tempLoc = {
        name: `${location.addresses.kelurahan}`,
        pendaftar: location.registration_count,
        pelanggan: location.customer_count
      }

      dataParsed.push(tempLoc);
    })
  }

  return (
    <Box title="Lokasi Pendaftaran dan Pelanggan Paling Banyak" loading={loading}>
      {errorMsg && 
        <div className="alert alert-danger">
          {errorMsg}
        </div>
      }

      {fetched && 
        <ResponsiveContainer width="100%" height={400}>
          <BarChart width={730} height={250} data={dataParsed}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="pendaftar" fill="#8884d8" />
            <Bar dataKey="pelanggan" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      }
      <div style={{overflowX: 'auto'}} className="mt-3">
        <table className="table table-sm table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>Kelurahan</th>
              <th>Kecamatan</th>
              <th>Kabupaten</th>
              <th>Provinsi</th>
              <th className="text-center">Jumlah Pendaftar</th>
              <th className="text-center">Jumlah Pelanggan</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 &&
              <tr>
                <td colSpan={6} className="text-center">Tidak ada data yang ditampilkan</td>
              </tr>
            }
            {data.map((location, index) => (
              <tr key={index}>
                <td>{location.addresses.kelurahan}</td>
                <td>{location.addresses.kecamatan}</td>
                <td>{location.addresses.kota_kab}</td>
                <td>{location.addresses.province}</td>
                <td className="text-center">{location.registration_count}</td>
                <td className="text-center">{location.customer_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  )
}