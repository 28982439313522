import moment from "moment";
import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import MainLayout from "../../components/layouts/MainLayout";
import ModalCancelDismantle from "../../components/schedule/ModalCancelDismantle";
import ModalDetailDismantle from "../../components/schedule/ModalDetailDismantle";
import ModalDownloadDismantle from "../../components/schedule/ModalDownloadDismantle";
import ModalNoteSchedule from "../../components/schedule/ModalNoteSchedule";
import ModalAssignDismantle from "../../components/technician/ModalAssignDismantle";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import { useScheduleListHooks } from "../../utils/SchedulesUtil";

export default function DismantleSchedule() {
  let { hasAccess } = useControlPermission();

  let [formFilter, setFormFilter] = useState({
    status: "",
    n: "",
    date_from: "",
    date_until: "",
    project_id: "",
  });
  let [filter, setFilter] = useState({
    type: "dismantle",
    status: "",
    n: "",
    date_from: "",
    date_until: "",
    page: 1,
    page_size: 10,
    project_id: "",
  });

  let schedulesData = useScheduleListHooks({ filter });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [selectedId, setSelectedId] = useState("");
  let [showModalDetail, setShowModalDetail] = useState(false);
  let [showModalAssign, setShowModalAssign] = useState(false);
  let [showModalCancel, setShowModalCancel] = useState(false);
  let [showModalDownload, setShowModalDownload] = useState(false);
  let [showModalNote, setShowModalNote] = useState(false);

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
    schedulesData.getSchedules(filter);
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(!showModalDetail);
  };

  const toggleModalAssign = (id) => {
    setSelectedId(id);
    setShowModalAssign(!showModalAssign);
  };

  const toggleModalCancel = (id) => {
    setSelectedId(id);
    setShowModalCancel(!showModalCancel);
  };

  const toggleModalNote = (id) => {
    setSelectedId(id);
    setShowModalNote(!showModalNote);
  };

  const toggleModalDownload = () => {
    setShowModalDownload(!showModalDownload);
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const applyFilter = () => {
    setFilter({ ...filter, ...formFilter, page: 1 });
  };

  const resetFilter = () => {
    setFormFilter({ status: "", n: "", date_from: "", date_until: "", project_id: "" });
    setFilter({
      ...filter,
      status: "",
      n: "",
      date_from: "",
      date_until: "",
      page: 1,
      project_id: "",
      page_size: 10,
    });
  };

  return (
    <MainLayout resource_key="schedule">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Penjadwalan Dismantle</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-schedule") && (
              <button className="btn btn-primary" onClick={() => toggleModalDownload()}>
                <i className="fas fa-download"></i> Unduh Data Dismantle
              </button>
            )}
          </div>
        </div>

        <Box
          title={`Jadwal Dismantle (Total: ${schedulesData.meta.count})`}
          loading={schedulesData.loading}
        >
          <div className="row mb-4">
            <div className="col-12 col-md-2">
              <label>Status</label>
              <select
                className="form-control"
                value={formFilter.status}
                onChange={(event) => changeFilter("status", event.target.value)}
              >
                <option value="">SEMUA</option>
                <option value="WAITING-NOC">WAITING NOC</option>
                <option value="NOC-DONE">NOC DONE</option>
                <option value="DONE">DONE</option>
                <option value="CANCELED">CANCELED</option>
              </select>
            </div>
            <div className="col-12 col-md-2">
              <label>Pencarian</label>
              <input
                type="text"
                className="form-control"
                value={formFilter.n}
                onChange={(event) => changeFilter("n", event.target.value)}
                placeholder="No. Registrasi / Nama / No. Penguasan"
              />
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label htmlFor="installation_date_until">Project/Site</label>
                <ProjectFilterForm
                  value={formFilter.project_id}
                  onChangeForm={(value) => changeFilter("project_id", value)}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <label>Tanggal Dari</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.date_from}
                onChange={(event) => changeFilter("date_from", event.target.value)}
              />
            </div>
            <div className="col-12 col-md-2">
              <label>Tanggal Sampai</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.date_until}
                onChange={(event) => changeFilter("date_until", event.target.value)}
              />
            </div>
            <div className="col-12 col-sm">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
          </div>
          <AlertBox {...alert} setAlert={setAlert} />
          {schedulesData.errorMsg && (
            <div className="alert alert-danger">{schedulesData.errorMsg}</div>
          )}
          <div style={{ overflowX: "auto" }}>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>No Reg</th>
                  <th>No Penugasan</th>
                  <th>Status</th>
                  <th>Jadwal</th>
                  <th>Nama Pelanggan</th>
                  <th>Alamat</th>
                  <th>Project</th>
                  <th>Teknisi</th>
                </tr>
              </thead>
              <tbody>
                {schedulesData.schedules.length === 0 && (
                  <tr>
                    <td colSpan={9} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {schedulesData.schedules.map((dismantle, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        className="btn btn-sm btn-block btn-info mr-1 text-nowrap"
                        onClick={() => toggleModalDetail(dismantle.id)}
                      >
                        <i className="fa fa-eye"></i> Detail
                      </button>
                      {hasAccess("assign-technician-schedule") &&
                        dismantle.status !== "DONE" &&
                        dismantle.status !== "CANCELED" && (
                          <>
                            <button
                              className="btn btn-sm btn-block btn-primary mr-1 text-nowrap"
                              onClick={() => toggleModalAssign(dismantle.id)}
                            >
                              <i className="fa fa-user-cog"></i> Penugasan
                            </button>
                          </>
                        )}
                      {hasAccess("cancel-schedule") && dismantle.status === "WAITING-NOC" && (
                        <button
                          className="btn btn-sm btn-block btn-danger mr-1 text-nowrap"
                          onClick={() => toggleModalCancel(dismantle.id)}
                        >
                          <i className="fa fa-times"></i> Cancel
                        </button>
                      )}

                      {hasAccess("update-schedule") &&
                        dismantle.status !== "DONE" &&
                        dismantle.status !== "NOT-COVERAGE" && (
                          <button
                            className="btn btn-sm btn-block btn-warning mr-1 text-nowrap"
                            onClick={() => toggleModalNote(dismantle.id)}
                          >
                            <i className="fa fa-solid fa-note-sticky"></i> Catatan Teknisi
                          </button>
                        )}
                    </td>
                    <td>{dismantle.registration.registration_number}</td>
                    <td>{dismantle.installation_schedule_number}</td>
                    <td>{dismantle.status}</td>
                    <td>
                      {dismantle.schedule &&
                        moment(dismantle.schedule).format("DD MMM YYYY - HH:mm")}
                    </td>
                    <td>{dismantle.registration.name}</td>
                    <td>
                      {dismantle.registration.installation_address.address +
                        " RT " +
                        dismantle.registration.installation_address.rt +
                        "/ RW " +
                        dismantle.registration.installation_address.rw +
                        ", " +
                        dismantle.registration.installation_address.addresses.kelurahan +
                        ", " +
                        dismantle.registration.installation_address.addresses.kecamatan +
                        ", " +
                        dismantle.registration.installation_address.addresses.kota_kab +
                        ", " +
                        dismantle.registration.installation_address.addresses.province +
                        ", " +
                        dismantle.registration.installation_address.addresses.postal_code}
                    </td>
                    <td>{dismantle.registration?.project?.name || "-"}</td>
                    <td>
                      <ol className="pl-3">
                        {dismantle.technicians?.map((technician, indexY) => (
                          <li key={indexY}>{technician.name}</li>
                        ))}
                      </ol>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {schedulesData.meta.count > schedulesData.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(schedulesData.meta.count / schedulesData.meta.page_size)}
              selectedpage={schedulesData.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(schedulesData.meta.page + 1)}
              prev={() => gotoPage(schedulesData.meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalDetailDismantle
        show={showModalDetail}
        id={showModalDetail ? selectedId : null}
        setShow={toggleModalDetail}
        onSuccess={onSuccess}
      />
      <ModalAssignDismantle
        show={showModalAssign}
        id={showModalAssign ? selectedId : null}
        setShow={toggleModalAssign}
        onSuccess={onSuccess}
      />
      <ModalCancelDismantle
        show={showModalCancel}
        id={showModalCancel ? selectedId : null}
        setShow={toggleModalCancel}
        onSuccess={onSuccess}
      />
      <ModalNoteSchedule
        show={showModalNote}
        id={showModalNote ? selectedId : null}
        setShow={toggleModalNote}
        onSuccess={onSuccess}
      />
      <ModalDownloadDismantle
        show={showModalDownload}
        setShow={toggleModalDownload}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function ProjectFilterForm({ value, onChangeForm }) {
  const { projects } = useGroupListHooks();
  return (
    <select
      className="form-control"
      id="projectFilter"
      value={value}
      onChange={(event) => onChangeForm(event.target.value)}
    >
      <option value="">SEMUA</option>
      {projects.map((project, index) => (
        <option value={project.id} key={index}>
          {project.name}
        </option>
      ))}
    </select>
  );
}
