import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import AlertBox from "../../components/AlertBox";
import moment from "moment";
import { useReportCustomerPayments } from "../../utils/finance/ReportBillingUtils";
import { convertToRupiah } from "../../tools/numerictools";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { downloadReportCustomerPayments } from "../../api/finance";

export default function ReportBillingSite() {
  const { hasAccess } = useControlPermission();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [formFilter, setFormFilter] = useState({
    confirmed_start_date: moment().startOf("month").format("YYYY-MM-DD"),
    confirmed_end_date: moment().endOf("month").format("YYYY-MM-DD"),
  });

  const [filter, setFilter] = useState({
    confirmed_start_date: moment().startOf("month").format("YYYY-MM-DD"),
    confirmed_end_date: moment().endOf("month").format("YYYY-MM-DD"),
  });

  let { projects, meta, loading, errorMsg } = useReportCustomerPayments({ filter });

  const applyFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
    });
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  const onError = (message) => {
    setAlert({ show: true, message, type: "danger" });
  };

  const resetFilter = () => {
    setFormFilter({
      confirmed_start_date: moment().startOf("month").format("YYYY-MM-DD"),
      confirmed_end_date: moment().endOf("month").format("YYYY-MM-DD"),
    });

    setFilter({
      confirmed_start_date: moment().startOf("month").format("YYYY-MM-DD"),
      confirmed_end_date: moment().endOf("month").format("YYYY-MM-DD"),
    });
  };

  return (
    <MainLayout resource_key="finance">
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Laporan Revenue</h1>
        <Box title="Laporan Revenue per Site" loading={loading}>
          <div className="row">
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Tanggal Mulai</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.confirmed_start_date}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, confirmed_start_date: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Tanggal Akhir</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.confirmed_end_date}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, confirmed_end_date: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
            {hasAccess("export-customer-payment-report") && (
              <div className="col-12 col-md-auto ml-auto" style={{ paddingTop: "32px" }}>
                <ButtonDownloadReport filter={filter} onSuccess={onSuccess} onError={onError} />
              </div>
            )}
          </div>

          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Project/Grup</th>
                <th>Mitra</th>
                <th>Total Pembayaran</th>
              </tr>
            </thead>
            <tbody>
              {projects.length === 0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    Belum ada data
                  </td>
                </tr>
              )}
              {projects.map((project) => (
                <tr key={project.id}>
                  <td>{project.project || "-"}</td>
                  <td>{project.partner || "-"}</td>
                  <td className="text-right">Rp {convertToRupiah(project.total)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="font-weight-bold text-right" colSpan={2}>
                  Total
                </td>
                <td className="font-weight-bold text-right">Rp {convertToRupiah(meta.total)}</td>
              </tr>
            </tfoot>
          </table>
        </Box>
      </div>
    </MainLayout>
  );
}

function ButtonDownloadReport({ filter, onSuccess, onError }) {
  const [loading, setLoading] = useState(false);

  const downloadReport = async () => {
    setLoading(true);
    try {
      let response = await downloadReportCustomerPayments(filter);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Laporan Pembayaran per Site ${filter.confirmed_start_date} sampai ${filter.confirmed_end_date}.xlsx`
      );
      onSuccess(
        `Berhasil mengunduh Laporan Pembayaran per Site ${filter.confirmed_start_date} sampai ${filter.confirmed_end_date}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      onError(message);
    }

    setLoading(false);
  };

  return (
    <button className="btn btn-primary" onClick={() => downloadReport()} disabled={loading}>
      <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
    </button>
  );
}
