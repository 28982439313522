import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateTaskProgressById } from "../../api/projects";
import { useTaskHooks } from "../../utils/project/VendorProjectUtils";
import AlertBox from "../AlertBox";

export default function ModalTaskUpdateProgress({ show, id, onClose, onSuccess }) {
  let [alert, setAlert] = useState({show: false, message: '', type: ''});
  let [form, setForm] = useState({done: 0});
  let [loading, setLoading] = useState(false);

  const taskData = useTaskHooks({ id });
  const handleClose = () => {
    resetForm();
    onClose();
  }

  const resetForm = () => {
    setForm({done: 0});
    setAlert({show: false, message: '', type: ''});
  }

  const updateProgress = async (formData)  => {
    setLoading(true);
    try {
      let response = await updateTaskProgressById(id, formData);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }
    setLoading(false);
  }

  const onSubmitData = (event) => {
    event.preventDefault();
    updateProgress(form)
  }


  return(
    <Modal show={show} onHide={() => handleClose()} size="xs">
      <form onSubmit={event => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Pembaharuan Progress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Deskripsi</td>
                <td>{taskData.data.name}</td>
              </tr>
              <tr>
                <td>Quantity</td>
                <td>{taskData.data.qty}</td>
              </tr>
              <tr>
                <td>Done</td>
                <td>{taskData.data.done}</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col">
              <label>Update Progress</label>
              <input type="number" className="form-control" min={0} value={form.done} onChange={event => setForm({ ...form, done: event.target.value})} />
            </div>
            <div className="col">
              <button className="btn btn-primary" style={{marginTop: '32px'}} disabled={loading}>{loading ? "Updating ..." : "Update"}</button>
            </div>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  )
}