import moment from "moment";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { acceptRequestStockOut } from "../../api/inventory";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import { ModalselectInventory } from "../../components/inventory/out/ModalSelectInventory";
import MainLayout from "../../components/layouts/MainLayout";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useRequestStockId, useWarehousesHooks } from "../../utils/InventoryHooks";

let filterWarehouse = { page: 1, page_size: 100 };

export default function AcceptOutgoingPage() {
  let { id } = useParams();
  let history = useHistory();
  let { hasAccess } = useControlPermission();
  let { warehouses } = useWarehousesHooks({ filter: filterWarehouse });
  let requestData = useRequestStockId({ id });

  let [warehouseId, setWarehouseId] = useState("");
  let [inventories, setInventories] = useState([]);
  let [loading, setLoading] = useState(false);
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [showModalSelect, setShowModalSelect] = useState(false);

  const changeWarehouse = (value) => {
    setWarehouseId(value);
    setInventories([]);
  };
  const submitData = () => {
    if (inventories.length === 0) {
      setAlert({ show: true, message: "Lengkapi data inventory terlebih dahulu", type: "danger" });
      return;
    }

    let formData = {
      inventories: [],
    };

    for (let index = 0; index < inventories.length; index++) {
      let inventory = inventories[index];
      formData.inventories.push({
        id: inventory.id,
        qty: inventory.qty,
        warehouse_id: warehouseId,
      });
    }

    acceptRequest(formData);
  };

  const acceptRequest = async (form) => {
    setLoading(true);
    try {
      let response = await acceptRequestStockOut(id, form);
      setInventories([]);
      window.scrollTo({ top: 0, behavior: "smooth" });
      let message = response.data.message;
      setAlert({
        show: true,
        message: message + " | Kembali ke halaman sebelumnya dalam beberapa saat ...",
        type: "success",
      });
      setTimeout(() => {
        history.push({ pathname: `/warehouses/request`, state: { successMsg: message } });
      }, 2000);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const addInventory = (inventory, qty, modem_id = null) => {
    let temp_inv = [...inventories];
    temp_inv.push({ ...inventory, qty, modem_id });

    setInventories(temp_inv);
  };

  const removeInventory = (index) => {
    let temp_inv = [...inventories];
    temp_inv.splice(index, 1);
    setInventories(temp_inv);
  };

  const onSuccessSelect = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {requestData.error && <div className="alert alert-danger">{requestData.error}</div>}
          </div>
          {requestData.fetched && (
            <>
              <div className="col-md-5">
                <Box title="Data Permintaan">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>Tanggal Masuk</td>
                        <td>
                          {moment.unix(requestData.data.createdAt).format("DD MMMM YYYY - HH:mm")}
                        </td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{requestData.data.status}</td>
                      </tr>
                      <tr>
                        <td>Tipe</td>
                        <td>{requestData.data.type}</td>
                      </tr>
                      {requestData.data.type === "REGISTRATION" && (
                        <tr>
                          <td>Nomor Pendaftaran</td>
                          <td>{requestData.data.registration?.registration_number}</td>
                        </tr>
                      )}
                      {requestData.data.type === "REGISTRATION" && (
                        <tr>
                          <td>Nama Pendaftaran</td>
                          <td>{requestData.data.registration?.name}</td>
                        </tr>
                      )}
                      <tr>
                        <td>Lokasi</td>
                        <td>
                          {requestData.data.type === "REGISTRATION"
                            ? `${requestData.data.registration?.installation_address.addresses.kecamatan} - ${requestData.data.registration?.installation_address.addresses.kota_kab} - ${requestData.data.registration?.installation_address.addresses.province}`
                            : `${requestData.data.project?.addresses.kecamatan} - ${requestData.data.project?.addresses.kota_kab} - ${requestData.data.project?.addresses.province}`}
                        </td>
                      </tr>
                      {requestData.data.type === "REGISTRATION" && (
                        <tr>
                          <td>Catatan</td>
                          <td>{requestData.data.registration?.note || "-"}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <h4>Permintaan Barang</h4>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Inventory</th>
                        <th>Kuantitas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestData.data.type === "REGISTRATION"
                        ? requestData.data.registration_inventories?.map((inventory, indexY) => (
                            <tr key={indexY}>
                              <td>{inventory.name}</td>
                              <td>{inventory.amount}</td>
                            </tr>
                          ))
                        : requestData.data.project_inventories?.map((inventory, indexY) => (
                            <tr key={indexY}>
                              <td>{inventory.name}</td>
                              <td>{inventory.amount}</td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </Box>
              </div>
              <div className="col-md-7">
                <Box title="Inventory" loading={loading}>
                  <AlertBox {...alert} setAlert={setAlert} />
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Pilih Gudang</label>
                        <select
                          className="form-control"
                          onChange={(event) => changeWarehouse(event.target.value)}
                        >
                          <option value="">-- Pilih Gudang --</option>
                          {warehouses.map((warehouse, index) => (
                            <option value={warehouse.id} key={index}>
                              {warehouse.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3" style={{ overflowX: "auto" }}>
                    <table className="table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>SKU</th>
                          <th>Nama Barang</th>
                          <th>Kategori</th>
                          <th>Qty</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {inventories.length === 0 && (
                          <tr>
                            <td colSpan={5} className="text-center">
                              Belum ada data yang dipilih
                            </td>
                          </tr>
                        )}
                        {inventories.map((inventory, index) => (
                          <tr key={index}>
                            <td>{inventory.sku}</td>
                            <td>{inventory.name}</td>
                            <td>{inventory.inventory_category.name}</td>
                            <td>
                              {inventory.qty} {inventory.unit}
                            </td>
                            <td>
                              <button
                                className="btn btn-danger"
                                onClick={() => removeInventory(index)}
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => setShowModalSelect(true)}
                  >
                    <i className="fa fa-plus"></i> Tambah Barang
                  </button>
                  <div className="d-flex justify-content-end">
                    {requestData.fetched && hasAccess("accept-inventory-out-request") && (
                      <button
                        disabled={loading}
                        type="button"
                        className="btn btn-success"
                        onClick={() => submitData()}
                      >
                        <i className="fa fa-check-double"></i>{" "}
                        {loading ? "Menyimpan ..." : "Konfirmasi Permintaan"}
                      </button>
                    )}
                  </div>
                </Box>
              </div>
            </>
          )}
        </div>
      </div>

      <ModalselectInventory
        show={showModalSelect}
        onClose={() => setShowModalSelect(false)}
        addInventory={addInventory}
        warehouse_id={warehouseId}
        onSuccess={onSuccessSelect}
      />
    </MainLayout>
  );
}
