import { apiAuth, apiDownloadAuth, apiUploader } from "./base";

export function getWarehouses(params) {
  let api = apiAuth();
  return api.get(`v1/warehouses`, { params });
}

export function createWarehouse(data) {
  let api = apiAuth();
  return api.post(`v1/warehouses`, data);
}

export function getWarehouseById(id) {
  let api = apiAuth();
  return api.get(`v1/warehouses/${id}`);
}

export function updateWarehouseById(id, data) {
  let api = apiAuth();
  return api.put(`v1/warehouses/${id}`, data);
}

export function deleteWarehouseById(id) {
  let api = apiAuth();
  return api.delete(`v1/warehouses/${id}`);
}

export function addProjectToWarehouseById(id, data) {
  let api = apiAuth();
  return api.post(`v1/warehouses/${id}/projects/${data.project_id}`);
}

export function removeProjectToWarehouseById(id, data) {
  let api = apiAuth();
  return api.delete(`v1/warehouses/${id}/projects/${data.project_id}`);
}

export function getInventoriesByWarehouse(id, params) {
  let api = apiAuth();
  return api.get(`v1/warehouses/${id}/inventories`, { params });
}

export function getModemByWarehouseId(id, params) {
  let api = apiAuth();
  return api.get(`v1/warehouses/${id}/modems`, { params });
}

export function createInventory(data) {
  let api = apiAuth();
  return api.post(`v1/inventories`, data);
}

export function getInventoryById(id) {
  let api = apiAuth();
  return api.get(`v1/inventories/${id}`);
}

export function getInventoryInById(id, params) {
  let api = apiAuth();
  return api.get(`v1/inventories/${id}/histories/stock-in`, { params });
}

export function getInventoryOutById(id, params) {
  let api = apiAuth();
  return api.get(`v1/inventories/${id}/histories/stock-out`, { params });
}

export function getInventoryHistoryDetailById(id, type, historyId, params) {
  let api = apiAuth();
  return api.get(`v1/inventories/${id}/histories/${type}/${historyId}`, { params });
}

export function updateInventoryById(id, data) {
  let api = apiAuth();
  return api.put(`v1/inventories/${id}`, data);
}

export function deleteInventoryById(id) {
  let api = apiAuth();
  return api.delete(`v1/inventories/${id}`);
}

export function getModemByInventory(id, params) {
  let api = apiAuth();
  return api.get(`v1/inventories/${id}/modems`, { params });
}

export function createModemByInventory(id, data) {
  let api = apiAuth();
  return api.post(`v1/inventories/${id}/modem`, data);
}

export function getModemByInventoryId(inventoryId, id) {
  let api = apiAuth();
  return api.get(`v1/inventories/${inventoryId}/modems/${id}`);
}

export function updateModemById(inventoryId, id, data) {
  let api = apiAuth();
  return api.put(`v1/inventories/${inventoryId}/modems/${id}`, data);
}

export function setupModemDevice(id, data) {
  let api = apiAuth();
  return api.put(`v1/inventories/inventory-modem/${id}/setting`, data);
}

export function getAllModems(params) {
  let api = apiAuth();
  return api.get(`v1/modems`, { params });
}

export function getAllHistoriesModems(params) {
  let api = apiAuth();
  return api.get(`v1/modems/histories`, { params });
}

export function getModemById(id) {
  let api = apiAuth();
  return api.get(`v1/modems/${id}`);
}

export function setupModemByRegNumber(id, data) {
  let api = apiAuth();
  return api.put(`v1/registrations/${id}/modem/setting`, data);
}

export function getSetupModem(params) {
  let api = apiAuth();
  return api.get(`v2/inventories/inventory-modem/settings`, { params });
}

export function downloadSetupModem(params = {}) {
  let api = apiDownloadAuth();
  return api.get("v2/inventories/inventory-modem/settings/xlsx", { params });
}

export function downloadDataModem(params = {}) {
  let api = apiDownloadAuth();
  return api.get("v1/modems/export/xlsx", { params });
}

export function downloadHistoryModem(params = {}) {
  let api = apiDownloadAuth();
  return api.get("v1/modems/histories/export/xlsx", { params });
}

export function getUnsavedModem(params) {
  let api = apiAuth();
  return api.get(`v2/inventories/inventory-modem/unsettings`, { params });
}

export function getCategories(params) {
  let api = apiAuth();
  return api.get(`v1/inventory-categories`, { params });
}

export function createCategory(data) {
  let api = apiAuth();
  return api.post(`v1/inventory-categories`, data);
}

export function getCategoryById(id) {
  let api = apiAuth();
  return api.get(`v1/inventory-categories/${id}`);
}

export function updateCategoryById(id, data) {
  let api = apiAuth();
  return api.put(`v1/inventory-categories/${id}`, data);
}

export function deleteCategoryById(id) {
  let api = apiAuth();
  return api.delete(`v1/inventory-categories/${id}`);
}

export function getRequestStockout(params) {
  let api = apiAuth();
  return api.get(`v1/inventories/inventory-out/request`, { params });
}

export function getRequestStockById(id) {
  let api = apiAuth();
  return api.get(`v1/inventories/inventory-out/${id}`);
}

export function acceptRequestStockOut(id, data) {
  let api = apiAuth();
  return api.put(`v1/inventories/inventory-out/${id}/accept`, data);
}

export function getModemBySerial(params) {
  let api = apiAuth();
  return api.get(`v2/inventories/inventory-modem`, { params });
}

export function returnInventories(data) {
  let api = apiAuth();
  return api.post(`v1/inventories/return`, data);
}

export function presetsTemplateInventory() {
  let api = apiAuth();
  return api.get(`v1/presets/excel-file-templates`);
}

export function createInventoriesUploader(formdata, config = {}) {
  let api = apiUploader();
  return api.post("v1/inventories/uploads/inventory", formdata, config);
}

export function getInventoriesMaster(params) {
  let api = apiAuth();
  return api.get(`v1/inventories`, { params })
}

export function createInventoryMaster(data) {
  let api = apiAuth();
  return api.post(`v1/inventories`, data);
}

export function createBulkInventories(data) {
  let api = apiAuth();
  return api.post(`v1/inventories/bulk-create/inventory`, data);
}

export function transferBulkInventories(data) {
  let api = apiAuth();
  return api.post(`v1/inventories/bulk-transfer/inventory`, data);
}

export function createModemsUploader(formdata, config = {}) {
  let api = apiUploader();
  return api.post("v1/inventories/uploads/inventory-modem", formdata, config);
}

export function createBulkInventoriesModem(data) {
  let api = apiAuth();
  return api.post(`v1/inventories/bulk-create/inventory-modem`, data);
}

export function transferBulkInventoriesModem(data) {
  let api = apiAuth();
  return api.post(`v1/inventories/bulk-transfer/inventory-modem`, data);
}

export function downloadWarehouseInventories(id, params = {}) {
  let api = apiDownloadAuth();
  return api.get(`v1/warehouses/${id}/inventories/export/xlsx`, { params });
}

export function getRegistrationInventoryOut(reg_number, params) {
  let api = apiAuth();
  return api.get(`v1/presets/registration/${reg_number}`, { params });
}

export function postOutgoingInventoryWarehouse(id, data) {
  let api = apiAuth();
  return api.post(`v1/warehouses/${id}/inventory-out`, data);
}

export function getOutgoingInventoriesWarehouseHistories(id, params) {
  let api = apiAuth();
  return api.get(`v1/warehouses/${id}/inventory-out`, { params });
}

export function getDetailOutgoingHistory(id, warehouseId) {
  let api = apiAuth();
  return api.get(`v1/warehouses/${warehouseId}/inventory-out/${id}`);
}

