import { Modal } from "react-bootstrap";

export default function ModalAccountTechnician({ show, onClose, account }) {
  const handleClose = () => {
    onClose();
  }

  return(
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Akun Data Teknisi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {account && 
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Nama Teknisi</td>
                <td>
                  {account.name}
                </td>
              </tr>
              <tr>
                <td>Nomor KTP</td>
                <td>
                  {account.id_card_number}
                </td>
              </tr>
              <tr>
                <td>Username</td>
                <td>
                  <b>{account.username}</b>
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>
                  <b>{account.email}</b>
                </td>
              </tr>
              <tr>
                <td>password</td>
                <td>
                  <b>{account.account?.password}</b>
                </td>
              </tr>
            </tbody>
          </table>
        }
      </Modal.Body>
    </Modal>
  )
}