import { useState } from "react";
import Box from "../../components/Box";
import ModalAddProjectsSite from "../../components/infrastrucure/ModalAddProjectsSite";
import MainLayout from "../../components/layouts/MainLayout";
import ModalDownloadPerformanceReport from "../../components/infrastrucure/ModalDownloadPerformanceReport";
import { Link } from "react-router-dom";
import AlertBox from "../../components/AlertBox";
import { useProjectPerformanceListHooks } from "../../utils/infrastructure/ProjectPerformanceUtil";
import ModalUpdateProjectSite from "../../components/infrastrucure/ModalUpdateProjectSite";
import ModalDeleteProjectSite from "../../components/infrastrucure/ModalDeteleProjectSite";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import Pagination from "../../components/Pagination";
import MonthYearPicker from "../../components/MonthYearPicker";

export default function ReportPerformancePage() {
  const { projects } = useGroupListHooks();

  let [formFilter, setFormFilter] = useState({
    project_id: "",
    start_month: "",
    end_month: "",
  });
  let [filter, setFilter] = useState({
    project_id: "",
    start_month: "",
    end_month: "",
    page: 1,
    page_size: 10,
  });

  const { infraProjects, loading, meta, errorMsg, getInfrastructures } =
    useProjectPerformanceListHooks({
      filter,
    });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [selectedId, setSelectedId] = useState("");
  let [showModalCreate, setShowModalCreate] = useState(false);
  let [showModalUpdate, setShowModalUpdate] = useState(false);
  let [showModalDelete, setShowModalDelete] = useState(false);
  let [showModalDownload, setShowModalDownload] = useState(false);

  const toggleOpenModalUpdate = (id = null) => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleOpenModalDelete = (id = null) => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const changeMonthYearFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ project_id: "", start_month: "", end_month: "" });
    setFilter({ project_id: "", page: 1, page_size: 10 });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const onSuccess = (show, message) => {
    setAlert({ show, message, type: "success" });
    getInfrastructures(filter);
  };

  return (
    <MainLayout resource_key="infrastructure">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Project Performance</h1>
          </div>
          <div className="col-auto">
            <button className="btn btn-primary mr-1" onClick={() => setShowModalCreate(true)}>
              <i className="fa fa-plus"></i> Tambah Site
            </button>
            <button className="btn btn-primary" onClick={() => setShowModalDownload(true)}>
              <i className="fa fa-download"></i> Unduh Report
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title="Report" loading={loading}>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="projectFilter">Grup/Project</label>
                    <select
                      className="form-control"
                      id="projectFilter"
                      value={formFilter.project_id}
                      onChange={(event) => changeFilter("project_id", event.target.value)}
                    >
                      <option value="">SEMUA</option>
                      {projects.map((project, index) => (
                        <option value={project.id} key={index}>
                          {project.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Bulan/Tahun mulai</label>
                    <MonthYearPicker
                      value={formFilter.start_month}
                      changeMonthYear={(value) => changeMonthYearFilter("start_month", value)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Bulan/Tahun Akhir</label>
                    <MonthYearPicker
                      value={formFilter.end_month}
                      changeMonthYear={(value) => changeMonthYearFilter("end_month", value)}
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-primary"
                    onClick={() => applyFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => resetFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <AlertBox {...alert} setAlert={setAlert} />
              <AlertBox
                show={errorMsg !== ""}
                type="danger"
                message={errorMsg}
                setAlert={() => {}}
              />

              <div className="auto-horizontal-scroll">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Site/Project</th>
                      <th>Active Date</th>
                      <th>Uptime days since Start</th>
                      <th>Downtime (second)</th>
                      <th>SLA</th>
                      <th>HomePass</th>
                      <th>Active PPPOE</th>
                      <th>CSR</th>
                      <th>Inactive PPPOE</th>
                      <th>Occupation Rate</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {infraProjects.length === 0 && (
                      <tr>
                        <td className="text-center" colSpan={11}>
                          Tidak ada data
                        </td>
                      </tr>
                    )}
                    {infraProjects.map((infra, index) => (
                      <tr key={index}>
                        <td>{infra.project.name}</td>
                        <td>{infra.active_at}</td>
                        <td>{infra.uptime_days}</td>
                        <td>{infra.downtime}</td>
                        <td>{infra.uptime_percentage}</td>
                        <td>{infra.homepass}</td>
                        <td>{infra.project?.active_pppoe || 0}</td>
                        <td>{infra.project?.csr || 0}</td>
                        <td>{infra.project?.inactive_pppoe || 0}</td>
                        <td>{infra.project?.occupation_rate || "0%"}</td>
                        <td>
                          <Link
                            className="btn btn-sm btn-custom-orange text-nowrap mb-1 mr-2"
                            to={{
                              pathname: `/infrastructure/report/${infra.id}/project`,
                              search:
                                filter.start_month || filter.end_month
                                  ? `?start_month=${filter.start_month}&end_month=${filter.end_month}`
                                  : "",
                            }}
                          >
                            <i className="fa fa-file-lines"></i> Report
                          </Link>
                          <button
                            className="btn btn-sm mb-1 mr-2 btn-info"
                            onClick={() => toggleOpenModalUpdate(infra.id)}
                          >
                            <i className="fa fa-pencil"></i> Edit
                          </button>
                          <button
                            className="btn btn-sm btn-danger mb-1 mr-2 text-nowrap"
                            onClick={() => toggleOpenModalDelete(infra.id)}
                          >
                            <i className="fa fa-trash"></i> Hapus
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {meta.count > meta.page_size && (
                <Pagination
                  totalpage={Math.ceil(meta.count / meta.page_size)}
                  selectedpage={meta.page}
                  clickpage={(page) => gotoPage(page)}
                  next={() => gotoPage(meta.page + 1)}
                  prev={() => gotoPage(meta.page - 1)}
                />
              )}
            </Box>
          </div>
        </div>
      </div>

      <ModalDownloadPerformanceReport
        show={showModalDownload}
        onClose={setShowModalDownload}
        onSuccess={onSuccess}
      />
      <ModalAddProjectsSite
        show={showModalCreate}
        onClose={setShowModalCreate}
        onSuccess={onSuccess}
      />
      <ModalUpdateProjectSite
        show={showModalUpdate}
        onClose={toggleOpenModalUpdate}
        id={showModalUpdate ? selectedId : ""}
        onSuccess={onSuccess}
      />
      <ModalDeleteProjectSite
        show={showModalDelete}
        onClose={toggleOpenModalDelete}
        id={showModalDelete ? selectedId : ""}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
