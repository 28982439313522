import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateHistoryById } from "../../../api/finance";
import { useBillingByIdNumber } from "../../../utils/finance/TransferHistoriesUtils";
import AlertBox from "../../AlertBox";

export default function ModalManualMatch({ show, id, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({status: 'manual-match', billing_number: ''});
  const [regNum, setRegNum] = useState('');
  const [alert, setAlert] = useState({show: false, message: '', type: 'danger'});
  const {billings, loading: billingloading, getBilling, resetList} = useBillingByIdNumber();

  const confirmHistoryTransfer = async (form) => {
    setLoading(true);
    try {
      let response = await updateHistoryById(id, form)
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }

    setLoading(false);
  }

  const submitUpdate = (event) => {
    event.preventDefault();
    confirmHistoryTransfer(form);
  }

  const handleClose = () => {
    setForm({status: 'manual-match', billing_number: ''});
    setAlert({show: false, message: '', type: 'danger'});
    setRegNum('');
    resetList();
    onClose();
  }

  const findBillByRegNumber = () => {
    getBilling(regNum);
    setForm({ ...form, billing_number: ''});
    resetList();
  }

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Manual Match</Modal.Title>
      </Modal.Header>
      <form onSubmit={event => submitUpdate(event)}>
        <Modal.Body>
          <AlertBox { ...alert } setAlert={setAlert} />
          <div className="form-group">
            <label>Cari No. Reg/No. Pelanggan</label>
            <div className="row">
              <div className="col">
                <input type="text" className="form-control" placeholder="Masukan No. Reg/No. Pelanggan" value={regNum} onChange={event => setRegNum(event.target.value)} />
              </div>
              <div className="col-auto">
                <button className="btn btn-primary" type="button" onClick={() => findBillByRegNumber()} disabled={billingloading}>{billingloading ? "Mencari ..." : "Cari"}</button>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="billingconfirm">Billing</label>
            <select id="billingconfirm" required className="form-control" value={form.billing_number} onChange={event => setForm({ ...form, billing_number: event.target.value})}>
              <option value="">-- Pilih Billing --</option>
              {billings.map((billing, index) => (
                <option key={index} value={billing.billing_number}>{billing.billing_number} ({billing.final_amount})</option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>Batal</button>
          <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Menunggu ...' : 'Simpan Perubahan'}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}