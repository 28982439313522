import { useEffect, useState, useCallback } from "react";
import {
  getCoverageRegistrationNumbers,
  getInstallationRegistrationNumbers,
  getModemByRegistrationNumber,
  getRegistratioByNumber,
  getRegistrationList,
  getRegistrationSchedules,
} from "../api/user";

export function useRegisteredCostumer({ filter }) {
  const [costumers, setCostumers] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getCostumers = useCallback(async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getRegistrationList({ ...filter });
      setCostumers(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getCostumers(filter);
  }, [getCostumers, filter]);

  return {
    costumers,
    meta,
    loading,
    errorMsg,
    getCostumers,
  };
}

export function useRegisteredSchedules({ filter }) {
  const [costumers, setCostumers] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getCostumers = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getRegistrationSchedules({ ...filter });
      setCostumers(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getCostumers(filter);
  }, [filter]);

  return {
    costumers,
    meta,
    loading,
    errorMsg,
    getCostumers,
  };
}

export function useRegisteredHooks() {
  let [data, setData] = useState({});
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  const getRegisteredData = async (registration_number) => {
    setFetched(false);
    setData({});
    setError("");
    setLoading(true);

    try {
      let response = await getRegistratioByNumber({ registration_number: registration_number });
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  const reset = () => {
    setFetched(false);
    setData({});
  };

  return {
    data,
    fetched,
    error,
    reset,
    loading,
    getRegisteredData,
  };
}

export function useCoverageRegistrationHooks() {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  const getData = async () => {
    try {
      let { data } = await getCoverageRegistrationNumbers();
      setData(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    data,
    error,
    getData,
  };
}

export function useInstallationRegistrationHooks() {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  const getData = async () => {
    try {
      let { data } = await getInstallationRegistrationNumbers();
      setData(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    data,
    error,
    getData,
  };
}

export function useInstallationModemHooks() {
  const [data, setData] = useState({});
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState("");

  const getData = async (registration_number) => {
    setData({});
    setFetched(false);
    setError("");
    try {
      let { data } = await getModemByRegistrationNumber(registration_number);
      setData(data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }
  };

  return {
    data,
    error,
    fetched,
    getData,
  };
}
