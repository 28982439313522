import { apiAuth } from "./base";

export function getAllRadiusNas(params) {
  let api = apiAuth();
  return api.get('v1/radius/nas', { params });
}

export function getAllFramedPools(params) {
  let api = apiAuth();
  return api.get('v1/radius/framed-pool', { params });
}
