import { useShowAllOltProfilesHooks } from "../../utils/infrastructure/OltUtils";

export function OLTProfileForm({ value, onChangeValue, ...rest }) {
  const { data } = useShowAllOltProfilesHooks();

  return (
    <select
      className="form-control"
      value={value}
      onChange={(event) => onChangeValue(event.target.value)}
      {...rest}
    >
      <option value="">-- Pilih OLT Profil --</option>
      {data.map((olt_profile) => (
        <option key={olt_profile.id} value={olt_profile.id}>
          {olt_profile.name}
        </option>
      ))}
    </select>
  );
}
