import { useState, useEffect } from "react";
import {
  getDefaultInventory,
  getKPISetting,
  getListProvinceStatus,
  getPaymentSetting,
  getPresetSurveyDefaultItem,
} from "../api/setting";

export function useDefaultInventory() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getInventorySetting();
  }, []);

  const getInventorySetting = async () => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getDefaultInventory();
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getInventorySetting,
  };
}

export function useKpi() {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getKpiSetting();
  }, []);

  const getKpiSetting = async () => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getKPISetting();
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getKpiSetting,
  };
}

export function useProvincesCoverageHooks() {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getProvince();
  }, []);

  const getProvince = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getListProvinceStatus();
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getProvince,
  };
}

export function useSurveyDefaultItems() {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getDefaultItem();
  }, []);

  const getDefaultItem = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getPresetSurveyDefaultItem();
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getDefaultItem,
  };
}

export function usePaymentSettingHook() {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getPaymentList();
  }, []);

  const getPaymentList = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getPaymentSetting();
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getPaymentList,
  };
}
