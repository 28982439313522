import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { resetBillingById } from "../../api/finance";
import { convertToRupiah } from "../../tools/numerictools";
import { useBillingIdHooks } from "../../utils/finance/BillingUtils";
import AlertBox from "../AlertBox";
import { BILLING_TYPE } from "../../tools/billingdatatools";

export default function ModalResetBilling({ show, id, onSuccess, onClose }) {
  const { data } = useBillingIdHooks({ id });

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      onClose();
    }
  };

  const resetBilling = async () => {
    setLoading(true);
    try {
      let { data } = await resetBillingById(id);
      onSuccess(data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Batalkan Pembayaran</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />

        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Nomor Billing</td>
              <td>{data.billing_number}</td>
            </tr>
            <tr>
              <td>Tipe Billing</td>
              <td>{BILLING_TYPE[data.type]}</td>
            </tr>
            <tr>
              <td>Total Pembayaran</td>
              <td>{data.final_amount && `Rp. ${convertToRupiah(data.final_amount)}`}</td>
            </tr>
            <tr>
              <td>Nomor</td>
              <td>
                {(data.type === "CUSTOMER" ||
                  data.type === "CUSTOMER-RELOCATE" ||
                  data.type === "ADDITIONAL-ITEM") &&
                  data.customer?.customer_number}
                {data.type === "REGISTRATION" && data.registration?.registration_number}
              </td>
            </tr>
            <tr>
              <td>Nama</td>
              <td>
                {(data.type === "CUSTOMER" ||
                  data.type === "CUSTOMER-RELOCATE" ||
                  data.type === "ADDITIONAL-ITEM") &&
                  data.customer?.name}
                {data.type === "REGISTRATION" && data.registration?.name}
              </td>
            </tr>
            <tr>
              <td>Alamat</td>
              <td>
                {data.type === "REGISTRATION" &&
                  `${data.registration.installation_address.address} ${data.registration.installation_address.rt}/${data.registration.installation_address.rw}, ${data.registration.installation_address.addresses.kelurahan}, ${data.registration.installation_address.addresses.kecamatan}, ${data.registration.installation_address.addresses.kota_kab}, ${data.registration.installation_address.addresses.province}, ${data.registration.installation_address.addresses.postal_code}`}
                {(data.type === "CUSTOMER" ||
                  data.type === "CUSTOMER-RELOCATE" ||
                  data.type === "ADDITIONAL-ITEM") &&
                  `${data.customer.installation_address.address} ${data.customer.installation_address.rt}/${data.customer.installation_address.rw}, ${data.customer.installation_address.addresses.kelurahan}, ${data.customer.installation_address.addresses.kecamatan}, ${data.customer.installation_address.addresses.kota_kab}, ${data.customer.installation_address.addresses.province}, ${data.customer.installation_address.addresses.postal_code}`}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>
          Tidak
        </button>
        <button className="btn btn-danger" onClick={() => resetBilling()} disabled={loading}>
          {loading ? "Menunggu ..." : "Ya"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
