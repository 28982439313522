import { useState } from "react";
import { Modal } from "react-bootstrap";
import { deleteTaskById } from "../../api/projects";
import { useTaskHooks } from "../../utils/project/VendorProjectUtils";
import AlertBox from "../AlertBox";

export default function ModalTaskDelete({ show, id, onClose, onSuccess }) {
  const taskData = useTaskHooks({ id });
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  let [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      setAlert({show: false, message: '', type: ''});
      onClose();
    }
  }

  const onDeleteTask = async () => {
    setLoading(true);
    try {
      let response = await deleteTaskById(id);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }

    setLoading(false);
  }

  return(
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Hapus Data Task "{taskData.data.name}"</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <h5 className="text-gray-800">Apakah anda yakin akan menghapus task "{taskData.data.name}"?</h5>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>Tidak</button>
        <button className="btn btn-danger" onClick={() => onDeleteTask()} disabled={loading}>{loading ? 'Menghapus ...' : 'Ya'}</button>
      </Modal.Footer>
    </Modal>
  )
}