import { useEffect, useState } from "react";
import Toggle from "react-toggle";
import { savePaymentSetting } from "../../api/setting";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { usePaymentSettingHook } from "../../utils/SettingUtils";
import AlertBox from "../AlertBox";
import Box from "../Box";

export default function BoxPaymentMethodSetting() {
  let { hasAccess } = useControlPermission();

  let { data, loading, getPaymentList } = usePaymentSettingHook();
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState([
    {
      name: "",
      status: "on",
      fee: 0,
    },
  ]);

  useEffect(() => {
    if (data.value) {
      let tempForm = [];

      data.value.forEach((item) => {
        tempForm.push({
          name: item.name,
          status: item.status,
          fee: item.fee,
        });
      });

      setForm(tempForm);
    }
  }, [data]);

  const changeValueForm = (field, index, value) => {
    let tempForm = [...form];
    tempForm[index][field] = value;
    setForm(tempForm);
  };

  const onSubmitForm = (event) => {
    event.preventDefault();

    let formData = {};
    formData["payment-method"] = form;

    sendData(formData);
  };

  const sendData = async (form) => {
    try {
      let response = await savePaymentSetting(form);
      setAlert({ show: true, message: response.data.message, type: "success" });
      getPaymentList();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message, type: "danger" });
    }
  };

  return (
    <Box title="Setting Payment Method" loading={loading}>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => onSubmitForm(event)}>
        <table className="table table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th>Payment Method</th>
              <th>Biaya Layanan</th>
              <th>On/Off</th>
            </tr>
          </thead>
          <tbody>
            {form.map((payment_method, index) => (
              <tr key={index}>
                <td>{payment_method.name}</td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={payment_method.fee}
                    min={0}
                    required
                    onChange={(event) => changeValueForm("fee", index, event.target.value)}
                  />
                </td>
                <td>
                  <Toggle
                    checked={payment_method.status === "on"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        changeValueForm("status", index, "on");
                      } else {
                        changeValueForm("status", index, "off");
                      }
                    }}
                    disabled={!hasAccess("update-setting")}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {hasAccess("update-setting") && (
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary">Simpan Perubahan</button>
          </div>
        )}
      </form>
    </Box>
  );
}
