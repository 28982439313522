import { useEffect, useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import Pagination from "../../components/Pagination";
import ModalPartnerCreate from "../../components/project/partner/ModalPartnerCreate";
import ModalPartnerDelete from "../../components/project/partner/ModalPartnerDelete";
import ModalPartnerUpdate from "../../components/project/partner/ModalPartnerUpdate";
import { usePartnerListHooks } from "../../utils/project/PartnerUtils";

export default function ManagePartnersPage() {
  let [formFilter, setFormFilter] = useState({ q: "" });
  const [filter, setFilter] = useState({ q: "", page: 1, page_size: 10 });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [selectedId, setSelectedId] = useState(null);

  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const { partners, meta, loading, getPartners, errorMsg } = usePartnerListHooks();

  useEffect(() => {
    if (errorMsg) {
      setAlert({ show: true, message: errorMsg, type: "danger" });
    }
  }, [errorMsg]);

  useEffect(() => {
    getPartners(filter);
  }, [filter, getPartners]);

  const applyFilter = () => {
    setFilter({ ...filter, ...formFilter, page: 1 });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const resetFilter = () => {
    setFilter({ q: "", page: 1, page_size: 10 });
    setFormFilter({ q: "" });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const toggleModalUpdate = (id = null) => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleModalDelete = (id = null) => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const onSuccess = (show, message) => {
    setAlert({ show, message, type: "success" });
    setFilter({ ...filter });
  };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Mitra</h1>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary" onClick={() => setShowModalCreate(true)}>
                <i className="fa fa-plus"></i> Tambah Mitra
              </button>
            </div>
          </div>
        </div>
        <Box title="Daftar Mitra" loading={loading}>
          <div className="mb-4">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Pencarian</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formFilter.q}
                    onChange={(event) => changeFilter("q", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm">
                <button
                  className="btn btn-primary"
                  onClick={() => applyFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => resetFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="auto-horizontal-scroll">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Mitra</th>
                  <th>Kode</th>
                  <th>Project</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {partners.length === 0 && (
                  <tr>
                    <td colSpan={3} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {partners.map((partner, index) => (
                  <tr key={index}>
                    <td>{partner.name}</td>
                    <td>{partner.code || "-"}</td>
                    <td>
                      <ol className="pl-3">
                        {partner.projects?.map((project, indexY) => (
                          <li key={indexY}>{project.name}</li>
                        ))}
                      </ol>
                    </td>
                    <td>
                      <button
                        className="btn btn-info btn-sm m-1"
                        onClick={() => toggleModalUpdate(partner.id)}
                      >
                        <i className="fa fa-pencil-alt"></i> Edit
                      </button>
                      <button
                        className="btn btn-sm btn-danger m-1"
                        onClick={() => toggleModalDelete(partner.id)}
                      >
                        <i className="fa fa-trash"></i> Hapus
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {meta.count > meta.page_size && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.page_size)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalPartnerCreate
        show={showModalCreate}
        onClose={() => setShowModalCreate(!showModalCreate)}
        onSuccess={onSuccess}
      />
      <ModalPartnerUpdate
        show={showModalUpdate}
        onClose={toggleModalUpdate}
        id={showModalUpdate ? selectedId : null}
        onSuccess={onSuccess}
      />
      <ModalPartnerDelete
        show={showModalDelete}
        onClose={toggleModalDelete}
        id={showModalDelete ? selectedId : null}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
