import { useState, useEffect } from "react";
import {
  getDetailOutgoingHistory,
  getOutgoingInventoriesWarehouseHistories,
} from "../../api/inventory";

export function useHistoriesOutByWarehouseHooks({ filter }) {
  const [histories, setHistories] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const fetchHistories = async (filter = {}) => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      const { data } = await getOutgoingInventoriesWarehouseHistories(filter.id, { ...filter });
      setHistories(data.data);
      setMeta(data.pagination);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchHistories(filter);
    }
  }, [filter]);

  return {
    histories,
    meta,
    loading,
    errorMsg,
    fetchHistories,
    fetched,
  };
}

export function useInventoryDetailOutgoingHistoryHooks({ filter }) {
  const [detail, setDetail] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchDetail = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getDetailOutgoingHistory(filter.id, filter.warehouse_id);
      setDetail(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchDetail(filter);
    }
  }, [filter]);

  return {
    detail,
    loading,
    errorMsg,
    fetchDetail,
  };
}
