import { apiAuth } from "./base";

export function getProfile() {
  let api = apiAuth();
  return api.get('v1/auths/profile');
}

export function getAccounts(params) {
  let api = apiAuth();
  return api.get('v1/users', { params });
}

export function getAccountById(id) {
  let api = apiAuth();
  return api.get(`v1/users/${id}`);
}

export function getRoles() {
  let api = apiAuth();
  return api.get('v1/users/roles');
}

export function createAccount(data) {
  let api = apiAuth();
  return api.post('v1/users', data);
}

export function updateAccount(id, data) {
  let api = apiAuth();
  return api.put(`v1/users/${id}`, data);
}

export function updatePassword(id, data) {
  let api = apiAuth();
  return api.put(`v1/users/${id}/reset-password`, data);
}

export function createProjectUser(data) {
  let api = apiAuth();
  return api.post(`v1/users/${data.user_id}/projects/${data.project_id}`);
}

export function removeProjectUserById(data) {
  let api = apiAuth();
  return api.delete(`v1/users/${data.user_id}/projects/${data.project_id}`);
}

export function updateDisbursementAccount(data) {
  let api = apiAuth();
  return api.put(`v1/users/disbursement-account`, data);
}


export function deleteDisbursementAccount() {
  let api = apiAuth();
  return api.delete(`v1/users/disbursement-account`);
}
