import { useEffect, useState } from "react";
import { getAllProjects, getListProjects, getProjectById, getProjectInventoryById } from '../api/projects';

export function useProjectListHooks({filter}) {
  const [projects, setProjects] = useState([]);
  const [meta, setMeta] = useState({count: 0, page: 1, page_size: 10});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getTechnicians = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('');
    try {
      const { data } = await getAllProjects({ ...filter });
      setProjects(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    getTechnicians(filter)
  }, [filter])

  return {
    projects,
    meta,
    loading,
    errorMsg,
    getTechnicians
  }
}

export function useProjectHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getProject(id)
    }
  }, [id]);

  const getProject = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false)
    try {
      let response = await getProjectById(id);
      setData(response.data.data);
      setFetched(true)
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setError(data.message)
      } else {
        setError("Something Error Happened")
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getProject,
  }
}

export function useGroupListHooks() {
  const [projects, setProjects] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getProjects = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('');
    try {
      const { data } = await getListProjects({ ...filter });
      setProjects(data.data);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    getProjects();
  }, [])

  return {
    projects,
    loading,
    errorMsg,
    getProjects
  }
}


export function useProjectInventoryHooks({ id }) {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getProjectInventory(id)
    }
  }, [id]);

  const getProjectInventory = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false)
    try {
      let response = await getProjectInventoryById(id);
      setData(response.data.data);
      setFetched(true)
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setError(data.message)
      } else {
        setError("Something Error Happened")
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getProjectInventory,
  }
}
