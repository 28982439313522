import { useState } from "react";
import {
  getAllInfrastructures,
  getIncidentById,
  getInfrastructureById,
} from "../../api/infrastructure";
import { useEffect } from "react";

export function useProjectPerformanceListHooks({ filter }) {
  const [infraProjects, setInfraProjects] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getInfrastructures = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllInfrastructures({ ...filter });
      setInfraProjects(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getInfrastructures(filter);
  }, [filter]);

  return {
    infraProjects,
    meta,
    loading,
    errorMsg,
    getInfrastructures,
  };
}

export function useProjectPerformanceHooks({ id, start_month, end_month }) {
  let [data, setData] = useState({});
  const [incidents, setIncidents] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getInfra(id, { start_month: start_month, end_month: end_month });
    }
  }, [id, start_month, end_month]);

  const getInfra = async (id, params) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getInfrastructureById(id, params);
      setData(response.data.data);
      setIncidents(response.data.data.incidents);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    incidents,
    fetched,
    success,
    error,
    loading,
    getInfra,
  };
}

export function useIncidentHook({ id, report_id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getIncident(id, report_id);
    }
  }, [id, report_id]);

  const getIncident = async (id, report_id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getIncidentById(id, report_id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getIncident,
  };
}
