import { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  useInventoriesByWarehouseHooks,
  useModemByInventoryHooks,
} from "../../../utils/InventoryHooks";
import { LoadingInsideBox } from "../../Loading";
import Pagination from "../../Pagination";

export default function ModalChooseTransferModem({
  show,
  onSelectInventory,
  warehouse_id,
  modem,
  onClose,
}) {
  let [modemId, setModemId] = useState(null);
  let [selectedSku, setSelectedSku] = useState("");

  const handleClose = () => {
    setModemId(null);
    setSelectedSku("");
    onClose();
  };

  const selectSku = (inventory) => {
    setModemId(inventory.id);
    setSelectedSku(inventory.sku);
  };
  const selectModem = (selected_modem) => {
    let inventory = { ...selected_modem, sku: selectedSku };
    onSelectInventory(inventory);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Cari Modem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12 col-md-6">
            <TableInventories
              modem={modem}
              modem_id={modemId}
              warehouse_id={warehouse_id}
              selectSku={(inventory) => selectSku(inventory)}
            />
          </div>
          <div className="col-12 col-md-6">
            {!!modemId && (
              <TableModem
                modem_id={modemId}
                warehouse_id={warehouse_id}
                onSelectInventory={selectModem}
              />
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function TableInventories({ modem, warehouse_id, selectSku, modem_id }) {
  let [formFilter, setFormFilter] = useState({ q: "" });
  let [filter, setFilter] = useState({
    q: "",
    page: 1,
    page_size: 5,
    id: warehouse_id,
    modem: modem,
  });

  let { inventories: data, loading, meta, error } = useInventoriesByWarehouseHooks({ filter });

  const onSubmitSearch = (event) => {
    event.preventDefault();
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <div className="loading-data-div">
      {error && <div className="alert alert-danger">{error}</div>}
      {loading && <LoadingInsideBox />}
      <form onSubmit={(event) => onSubmitSearch(event)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Pencarian</label>
              <input
                type="text"
                placeholder="SKU / nama ..."
                className="form-control"
                value={formFilter.q}
                onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="form-group" style={{ paddingTop: "30px" }}>
              <button className="btn btn-primary">Cari</button>
            </div>
          </div>
        </div>
      </form>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Nama</th>
            <th>Unit</th>
            <th>Kategori</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr>
              <td colSpan={5} className="text-center">
                Tidak ada data
              </td>
            </tr>
          )}
          {data.map((inventory) => (
            <tr key={inventory.id}>
              <td>{inventory.sku}</td>
              <td>{inventory.name}</td>
              <td>{inventory.unit}</td>
              <td>{inventory.inventory_category?.name}</td>
              <td>
                {modem_id === inventory.id ? (
                  <button className="btn btn-success">
                    <i className="fa fa-angle-right"></i>
                  </button>
                ) : (
                  <button className="btn btn-primary" onClick={() => selectSku(inventory)}>
                    <i className="fa-solid fa-magnifying-glass-arrow-right"></i>
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {meta.count > meta.page_size && (
        <Pagination
          totalpage={Math.ceil(meta.count / meta.page_size)}
          selectedpage={meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(meta.page + 1)}
          prev={() => gotoPage(meta.page - 1)}
        />
      )}
    </div>
  );
}

function TableModem({ modem_id, warehouse_id, onSelectInventory }) {
  let [filter, setFilter] = useState({
    name: "",
    is_available: "",
    registration_number: "",
    serial_number: "",
    page: 1,
    warehouse_status: 1,
    page_size: 10,
    warehouse_id: warehouse_id,
  });
  let [formFilter, setFormFilter] = useState({
    name: "",
    is_available: "",
    registration_number: "",
    serial_number: "",
  });

  let modems = useModemByInventoryHooks({ id: modem_id, filter });

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ name: "", is_available: "", registration_number: "", serial_number: "" });
    setFilter({
      name: "",
      is_available: "",
      registration_number: "",
      serial_number: "",
      warehouse_status: 1,
      page: 1,
      page_size: 10,
      warehouse_id: warehouse_id,
    });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col">
          <label>Serial Number</label>
          <input
            type="text"
            className="form-control"
            value={formFilter.serial_number}
            onChange={(event) =>
              setFormFilter({ ...formFilter, serial_number: event.target.value })
            }
          />
        </div>
        <div className="col">
          <label>Ketersediaan</label>
          <select
            name="availability"
            value={formFilter.is_available}
            onChange={(event) => setFormFilter({ ...formFilter, is_available: event.target.value })}
            className="form-control"
            id="registration_type"
          >
            <option value="">SEMUA</option>
            <option value="true">Tersedia</option>
            <option value="false">Tidak Tersedia</option>
          </select>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary"
            style={{ marginTop: "32px" }}
            onClick={() => applyFilter()}
          >
            Filter
          </button>
          <button
            className="btn btn-default"
            style={{ marginTop: "32px" }}
            onClick={() => resetFilter()}
          >
            Reset
          </button>
        </div>
      </div>
      <div className="auto-horizontal-scroll mb-3">
        <table className="table table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>Brand</th>
              <th>Type</th>
              <th>S/N Modem</th>
              <th>S/N GPON</th>
              <th>Ketersediaan</th>
              <th>Status Gudang</th>
            </tr>
          </thead>
          <tbody>
            {modems.inventories.length === 0 && (
              <tr>
                <td colSpan={7} className="text-center">
                  Tidak ada data yang ditampilkan
                </td>
              </tr>
            )}
            {modems.inventories.map((modem, index) => (
              <tr key={index}>
                <td>
                  <button
                    className="btn btn-info text-nowrap btn-sm"
                    onClick={() => onSelectInventory(modem)}
                  >
                    <i className="fa-solid fa-check"></i> Transfer
                  </button>
                </td>
                <td>{modem.brand}</td>
                <td>{modem.type}</td>
                <td>{modem.serial_number_modem}</td>
                <td>{modem.serial_number_gpon}</td>
                <td>
                  {modem.is_used ? (
                    <span className="badge badge-danger">Tidak Tersedia</span>
                  ) : (
                    <span className="badge badge-success">Tersedia</span>
                  )}
                </td>
                <td>
                  {modem.warehouse_status ? (
                    <span className="badge badge-success">Stok Tersedia</span>
                  ) : (
                    <span className="badge badge-danger">Stok Tidak Tersedia</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {modems.meta.count > modems.meta.page_size && (
        <Pagination
          totalpage={Math.ceil(modems.meta.count / modems.meta.page_size)}
          selectedpage={modems.meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(modems.meta.page + 1)}
          prev={() => gotoPage(modems.meta.page - 1)}
        />
      )}
    </div>
  );
}
