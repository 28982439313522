import { useEffect } from "react";
import { useState } from "react";
import { getAllSchedules, getSchedulesById, showBastSchedule } from "../api/schedules";

export function useScheduleListHooks({ filter }) {
  const [schedules, setSchedules] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getSchedules = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllSchedules({ ...filter });
      setSchedules(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getSchedules(filter);
  }, [filter]);

  return {
    schedules,
    meta,
    loading,
    errorMsg,
    getSchedules,
  };
}

export function useBastScheduleListHooks({ filter }) {
  const [schedules, setSchedules] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllDone = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await showBastSchedule(filter);
      setSchedules(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllDone(filter);
  }, [filter]);

  return {
    schedules,
    loading,
    errorMsg,
    getAllDone,
  };
}

export function useScheduleDismantleHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getSchedule(id);
    }
  }, [id]);

  const getSchedule = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSchedulesById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  const resetData = () => {
    setSuccess(null);
    setFetched(false);
    setData({});
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getSchedule,
    resetData,
  };
}

export function useScheduleDetailHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getSchedule(id);
    }
  }, [id]);

  const getSchedule = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSchedulesById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  const resetData = () => {
    setSuccess(null);
    setFetched(false);
    setData({});
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getSchedule,
    resetData,
  };
}
