import { useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { downloadUserRegistration } from "../../api/user";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import useRegionHooks from "../../utils/RegionHooks";
// import { useListSales } from "../../utils/sales/SalesUtilsHooks";

export default function ModalDownload(props) {
  const [formFilter, setFormFilter] = useState({
    status: "",
    date_from: moment().subtract(6, "months").format("YYYY-MM-DD"),
    date_until: moment().format("YYYY-MM-DD"),
    registration_type: "",
    referral: "",
    project_id: "",
    confirmed_date: "",
    survey_status: "",
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  const region = useRegionHooks();
  const { projects } = useGroupListHooks();
  // const { sales } = useListSales();

  const handleClose = () => {
    setFormFilter({
      status: "",
      date_from: moment().subtract(6, "months").format("YYYY-MM-DD"),
      date_until: moment().format("YYYY-MM-DD"),
      registration_type: "",
      referral: "",
      project_id: "",
      confirmed_date: "",
      survey_status: "",
    });
    region.resetRegion();
    props.setShow("");
  };

  const downloadFile = async () => {
    setLoading(true);

    let location = {
      province: region.province,
      kota_kab: region.city,
      kecamatan: region.district,
      kelurahan: region.addressId,
    };

    try {
      let response = await downloadUserRegistration({ ...formFilter, ...location });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Registrasi.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  const handleChangeFilterDate = (value, type = "date_from") => {
    if (type === "date_from" && value) {
      let diff = moment(formFilter.date_until).diff(moment(value), "months", true);
      if (diff > 6) {
        setFormFilter({
          ...formFilter,
          date_from: value,
          date_until: moment(value).add(6, "months").format("YYYY-MM-DD"),
        });
      } else {
        setFormFilter({ ...formFilter, date_from: value });
      }
    }

    if (type === "date_until" && value) {
      let diff = moment(value).diff(moment(formFilter.date_from), "months", true);
      if (diff > 6) {
        setFormFilter({
          ...formFilter,
          date_from: moment(value).subtract(6, "months").format("YYYY-MM-DD"),
          date_until: value,
        });
      } else {
        setFormFilter({ ...formFilter, date_until: value });
      }
    }
  };

  return (
    <Modal show={props.show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Unduh Data Pendaftaran</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert.show && (
          <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
            {alert.message}
            <button
              type="button"
              className="close"
              onClick={() => setAlert({ show: false, message: "", type: "" })}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}

        {loading && (
          <div className={`alert alert-warning alert-dismissible fade show`} role="alert">
            Loading
            <button
              type="button"
              className="close"
              onClick={() => setLoading(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="status">Status</label>
          <select
            name="status"
            id="status"
            className="form-control"
            value={formFilter.status}
            onChange={(event) => setFormFilter({ ...formFilter, status: event.target.value })}
          >
            <option value="">-- Pilih Status --</option>
            {props.statuses.map((type_status) => (
              <option value={type_status.id} key={type_status.id}>
                {type_status.display_name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Status Survey</label>
          <select
            className="form-control"
            value={formFilter.survey_status}
            onChange={(event) =>
              setFormFilter({ ...formFilter, survey_status: event.target.value })
            }
          >
            <option value="">Semua</option>
            <option value="no-survey">Belum Survey</option>
            <option value="survey-unpaid">Sudah Survey - Belum Bayar</option>
            <option value="survey-paid">Sudah Survey - Siap Pasang</option>
            <option value="survey-uninstallable">Sudah Survey - Ditolak</option>
            <option value="survey-canceled">Sudah Survey - Dibatalkan</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="date_from">Tanggal Dari</label>
          <input
            type="date"
            className="form-control"
            value={formFilter.date_from}
            onChange={(event) => handleChangeFilterDate(event.target.value, "date_from")}
            max={formFilter.date_until}
          />
        </div>
        <div className="form-group">
          <label htmlFor="date_until">Tanggal Ke</label>
          <input
            type="date"
            className="form-control"
            value={formFilter.date_until}
            onChange={(event) => handleChangeFilterDate(event.target.value, "date_until")}
            min={formFilter.date_from}
            max={moment().format("YYYY-MM-DD")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Tipe Registrasi</label>
          <select
            className="form-control"
            value={formFilter.registration_type}
            onChange={(event) =>
              setFormFilter({ ...formFilter, registration_type: event.target.value })
            }
          >
            <option value="">SEMUA</option>
            <option value="INSTALLED">Pelanggan Lama</option>
            <option value="INSTALLATION_REQUEST">Pelanggan Baru</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="">Sales</label>
          <input
            type="text"
            className="form-control"
            value={formFilter.referral}
            onChange={(event) => setFormFilter({ ...formFilter, referral: event.target.value })}
          />
          {/* <select className="form-control" value={formFilter.referral} onChange={event => setFormFilter({ ...formFilter, referral: event.target.value })}>
            <option value="">SEMUA SALES</option>
            {sales.map((sales, index) => (
              <option key={index} value={sales.name}>{sales.name}</option>
            ))}
          </select> */}
        </div>
        {/* <div className="form-group">
          <label htmlFor="date_from">Tanggal Konfirmasi Pembayaran</label>
          <input type="date" className="form-control" value={formFilter.confirmed_date} onChange={event => setFormFilter({ ...formFilter, confirmed_date: event.target.value })} />
        </div> */}
        <div className="form-group">
          <label htmlFor="project_group">Kelompok/Project</label>
          <select
            className="input form-control"
            id="project_group"
            value={formFilter.project_id}
            onChange={(event) => setFormFilter({ ...formFilter, project_id: event.target.value })}
          >
            <option value="">-- Kelompok/Project --</option>
            {projects.map((project, index) => (
              <option key={index} value={project.id}>
                {project.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="provinsi">Provinsi</label>
          <select
            name="provinsi"
            id="provinsi"
            className="form-control"
            value={region.province}
            onChange={(event) => region.changeProvince(event.target.value)}
          >
            <option value="">-- Pilih Provinsi --</option>
            {region.provinces.map((province, index) => (
              <option key={index} value={province.province}>
                {province.province}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="kabupaten">Kabupaten/Kota</label>
          <select
            name="kabupaten"
            id="kabupaten"
            className="form-control"
            value={region.city}
            onChange={(event) => region.changeCity(event.target.value)}
          >
            <option value="">-- Pilih Kabupaten/Kota --</option>
            {region.cities.map((city, index) => (
              <option key={index} value={city.kota_kab}>
                {city.kota_kab}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="kecamatan">Kecamatan</label>
          <select
            name="kecamatan"
            id="kecamatan"
            className="form-control"
            value={region.district}
            onChange={(event) => region.changeDistrict(event.target.value)}
          >
            <option value="">-- Pilih Kecamatan --</option>
            {region.districts.map((district, index) => (
              <option key={index} value={district.kecamatan}>
                {district.kecamatan}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="kelurahan">Kelurahan</label>
          <select
            name="kelurahan"
            id="kelurahan"
            className="form-control"
            value={region.addressId}
            onChange={(event) => region.setAddressId(event.target.value)}
          >
            <option value="">-- Pilih Kelurahan --</option>
            {region.addresslist.map((kelurahan, index) => (
              <option key={index} value={kelurahan.kelurahan}>
                {kelurahan.kelurahan}
              </option>
            ))}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => downloadFile()} disabled={loading}>
          <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
