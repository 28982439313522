import { useState } from "react";
import { useInternetPacakageList } from "../../utils/InternetPackageUtils";
import { convertToRupiah } from "../../tools/numerictools";
import Box from "../Box";
import Pagination from "../Pagination";

export default function BoxPackHome() {
  let [filter, setFilter] = useState({
    internet_package_type_id: "",
    name: "",
    page_size: 10,
    page: 1,
  });
  // let [ formFilter, setFormFilter ] = useState({internet_package_type_id: ''});
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let internetPacakages = useInternetPacakageList({ filter });
  // let typePkg = usePackTypeList({filter: {show_all: true}})

  // const onSubmitFilter = () => {
  //   setFilter({ ...filter, ...formFilter, page: 1 });
  // }

  // const onResetFilter = () => {
  //   setFilter({name: '', page_size: 10, page: 1});
  //   setFormFilter({name: '', internet_package_type_id: ''})
  // }

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <Box title="Daftar Paket" loading={internetPacakages.loading}>
      {/* <div className="mb-4">
        <div className="row">
          <div className="col-2">
            <label htmlFor="typeFilter">Tipe Paket</label>
            <select className="form-control" id="typeFilter" value={formFilter.internet_package_type_id} onChange={event => setFormFilter({ ...formFilter, internet_package_type_id: event.target.value })}>
              <option value="">SEMUA</option>
              {typePkg.types.map((types, index) =>(
                <option value={types.id} key={index}>{types.name}</option>
              ))}
            </select>
          </div>
          <div className="col-auto">
            <label className="d-block">&nbsp;</label>
            <button className="btn btn-primary" onClick={() => onSubmitFilter()}>Filter</button>
            <button className="btn btn-default" onClick={() => onResetFilter()}>Reset</button>
          </div>
        </div>
      </div> */}

      {alert.show && (
        <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
          {alert.message}
          <button
            type="button"
            className="close"
            onClick={() => setAlert({ show: false, message: "", type: "" })}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}

      <div style={{ overflowX: "auto" }}>
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr className="text-center">
              <th>Nama Paket</th>
              <th>Tipe Paket</th>
              <th>Varian Paket</th>
              <th>Deskripsi</th>
              <th>Berulang</th>
              <th>Trial</th>
              <th>Harga</th>
              <th>Biaya Pemasangan</th>
              <th>Deposit</th>
            </tr>
          </thead>
          <tbody>
            {internetPacakages.packages.length === 0 && (
              <tr>
                <td colSpan={8} className="text-center">
                  Tidak ada data yang ditampilkan
                </td>
              </tr>
            )}
            {internetPacakages.packages.map((pack, index) => (
              <tr key={index}>
                <td>{pack.name}</td>
                <td>{pack.internet_package_type ? pack.internet_package_type.name : "-"}</td>
                <td>{pack.internet_package_varian ? pack.internet_package_varian.name : "-"}</td>
                <td style={{ whiteSpace: "normal" }}>{pack.description}</td>
                <td>{pack.recurring ? "Ya" : "Tidak"}</td>
                <td>{pack.trial ? "Ya" : "Tidak"}</td>
                <td className="text-nowrap">
                  {pack.normal_price > pack.price && (
                    <>
                      <span className="text-xs text-muted">
                        <s>Rp {convertToRupiah(pack.normal_price)}</s>
                      </span>{" "}
                      <br />
                    </>
                  )}{" "}
                  Rp {convertToRupiah(pack.price)}
                </td>
                <td>Rp {convertToRupiah(pack.installation_cost)}</td>
                <td>Rp {convertToRupiah(pack.deposit_amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {internetPacakages.meta.count > internetPacakages.meta.page_size && (
        <Pagination
          totalpage={Math.ceil(internetPacakages.meta.count / internetPacakages.meta.page_size)}
          selectedpage={internetPacakages.meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(internetPacakages.meta.page + 1)}
          prev={() => gotoPage(internetPacakages.meta.page - 1)}
        />
      )}
    </Box>
  );
}
