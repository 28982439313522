import { useState, useEffect, createContext, useContext } from "react";
import {
  getActivitiesByCustomerId,
  getAllCustomerActivities,
  getBillingsByCustomerId,
  getSingleActivityByCustomerId,
  getTicketsByCustomerId,
} from "../../api/customer";

export const CustomerContext = createContext(null);
// const { customer: data, loading, error, fetched } = useCustomerContext();
export function useCustomerContext() {
  const context = useContext(CustomerContext);
  if (context === null) {
    throw new Error("useCustomerContext must be used within a CustomerContext.Provider");
  }
  return context;
}

export function useAllCustomerActivitiesHooks({ filter }) {
  const [activities, setActivities] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getActivities = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllCustomerActivities({ ...filter });
      setActivities(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getActivities(filter);
  }, [filter]);

  return {
    activities,
    meta,
    loading,
    errorMsg,
    getActivities,
  };
}

export function useCustomerActivities({ filter }) {
  const [activities, setActivities] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const fetchActivities = async (filter = {}) => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      const { id, ...filtered } = filter;
      const { data } = await getActivitiesByCustomerId(id, { ...filtered });
      setActivities(data.data);
      setMeta(data.pagination);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchActivities(filter);
    }
  }, [filter]);

  return {
    activities,
    meta,
    loading,
    errorMsg,
    fetchActivities,
    fetched,
  };
}

export function useCustomerTickets({ filter }) {
  const [tickets, setTickets] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const fetchTickets = async (filter = {}) => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      const { id, ...filtered } = filter;
      const { data } = await getTicketsByCustomerId(id, { ...filtered });
      setTickets(data.data);
      setMeta(data.pagination);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchTickets(filter);
    }
  }, [filter]);

  return {
    tickets,
    meta,
    loading,
    errorMsg,
    fetchTickets,
    fetched,
  };
}

export function useCustomerBillings({ filter }) {
  const [billings, setBillings] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const fetchBillings = async (filter = {}) => {
    setLoading(true);
    setFetched(false);
    setErrorMsg("");
    try {
      const { id, ...filtered } = filter;
      const { data } = await getBillingsByCustomerId(id, { ...filtered });
      setBillings(data.data);
      setMeta(data.pagination);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchBillings(filter);
    }
  }, [filter]);

  return {
    billings,
    meta,
    loading,
    errorMsg,
    fetchBillings,
    fetched,
  };
}

export function useDetailActivityHooks({ id, customer_id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getDetailActivity(id, customer_id);
    }
  }, [id, customer_id]);

  const getDetailActivity = async (id, customer_id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getSingleActivityByCustomerId(id, customer_id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getDetailActivity,
  };
}
