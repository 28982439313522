import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../../AlertBox";
import { RequiredAsterisk } from "../../FormHelper";
import { updateOltProfileById } from "../../../api/infrastructure";
import { useOltProfileByIdHooks } from "../../../utils/infrastructure/OltUtils";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../../Loading";

export default function ModalUpdateOltProfile({ id, show, onClose, onSuccess }) {
  return (
    <Modal show={show} onHide={() => onClose()}>
      <FormUpdateOltProfile id={id} onSuccess={onSuccess} onClose={() => onClose()} />
    </Modal>
  );
}

function FormUpdateOltProfile({ id, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [form, setForm] = useState({
    name: "",
    upstream: "",
    downstream: "",
  });

  let { data, fetched, error, loading: loadingData } = useOltProfileByIdHooks({ id });
  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
    };
    sendUpdateOlt(formData);
  };

  useEffect(() => {
    if (fetched) {
      setForm((prev) => ({
        ...prev,
        name: data.name,
        upstream: data.upstream,
        downstream: data.downstream,
      }));
    }
  }, [data, fetched]);

  const sendUpdateOlt = async (form) => {
    setLoading(true);
    try {
      let response = await updateOltProfileById(id, form);
      onSuccess(response.data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profil OLT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingInsideBoxWrapper>
            {(loading || loadingData) && <LoadingInsideBox />}
            {error && <div className="alert alert-danger">{error}</div>}
            <AlertBox {...alert} setAlert={setAlert} />
            <div className="form-group">
              <label htmlFor="">
                Nama OLT Profile <RequiredAsterisk />{" "}
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={form.name}
                onChange={(event) => setForm({ ...form, name: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">
                Upstream <RequiredAsterisk />{" "}
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={form.upstream}
                onChange={(event) => setForm({ ...form, upstream: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">
                Downstream <RequiredAsterisk />{" "}
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={form.downstream}
                onChange={(event) => setForm({ ...form, downstream: event.target.value })}
              />
            </div>
          </LoadingInsideBoxWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
            Close
          </button>
          <button type="submit" disabled={loading} className="btn btn-primary">
            <i className="fas fa-plus"></i>{" "}
            {loading ? "Update Profil OLT ..." : "Update Profil OLT"}
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}
