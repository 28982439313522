import { useEffect, useState } from "react";
import { getAllTickets, getTicketById } from "../../api/ticket";

export function useAllTicketsHooks({ filter }) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({count: 0, page: 1, page_size: 10});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchTickets = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('')
    try {
      const { data } = await getAllTickets({ ...filter });
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  return {
    data,
    meta,
    loading,
    errorMsg,
    fetchTickets
  }
}

export function useTicketbyIdHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getTicket(id)
    }
  }, [id]);

  const getTicket = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false)
    try {
      let response = await getTicketById(id);
      setData(response.data.data);
      setFetched(true)
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setError(data.message)
      } else {
        setError("Something Error Happened")
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getTicket,
  }
}
