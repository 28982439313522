import { useState } from "react";
import { useCustomerTickets } from "../../../utils/customer/CustomerRelationUtils";
import Pagination from "../../Pagination";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../../Loading";
import AlertBox from "../../AlertBox";
import moment from "moment";
import ModalReportTicketing from "../../ticketing/ModalReportTicketing";
import { TicketStatusBadge } from "./Activitiesbadge";

export default function CustomerTicketList({ id }) {
  const [modal, setModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [formFilter, setFormFilter] = useState({ status: "", type: "" });
  const [filter, setFilter] = useState({ status: "", type: "", id, page: 1, page_size: 25 });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const { tickets, meta, errorMsg, loading } = useCustomerTickets({ filter });

  function applyFilter() {
    let form_filter = {
      ...filter,
      ...formFilter,
      page: 1,
    };
    setFilter(form_filter);
  }

  const resetFilter = () => {
    setFormFilter({
      status: "",
      type: "",
    });
    setFilter({
      status: "",
      type: "",
      page: 1,
      page_size: 25,
      id: id,
    });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, message: message, type: "success" });
  };

  return (
    <div className="pt-4">
      <LoadingInsideBoxWrapper>
        {loading && <LoadingInsideBox />}

        <div className="row mb-4">
          <div className="col-12 col-md-2">
            <label htmlFor="customer_number">Status</label>
            <select
              className="form-control"
              value={formFilter.status}
              onChange={(event) => setFormFilter({ ...formFilter, status: event.target.value })}
            >
              <option value="">SEMUA</option>
              <option value="open">OPEN</option>
              <option value="working">WORKING</option>
              <option value="closed">CLOSED</option>
            </select>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label htmlFor="role">Tipe</label>
              <select
                name="type"
                value={formFilter.type}
                onChange={(event) => setFormFilter({ ...formFilter, type: event.target.value })}
                className="form-control"
                id="type"
              >
                <option value="">Semua</option>
                <option value="follow-up">Follow Up</option>
                <option value="technical">Technical</option>
                <option value="marketing">Marketing</option>
                <option value="billing">Billing</option>
              </select>
            </div>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={() => applyFilter()}
              style={{ marginTop: "32px" }}
            >
              Filter
            </button>
            <button
              className="btn btn-default"
              onClick={() => resetFilter()}
              style={{ marginTop: "32px" }}
            >
              Reset
            </button>
          </div>
        </div>

        <AlertBox {...alert} setAlert={setAlert} />
        {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : null}
        <div style={{ overflowX: "auto" }}>
          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Nomor Tiket</th>
                <th className="text-nowrap">Nama Pelanggan</th>
                <th>Nomor Aktivitas</th>
                <th>Activity Type</th>
                <th>Activity Code</th>
                <th>Event Date</th>
                <th>Kendala</th>
                <th>Status</th>
                <th>Priority</th>
                <th>Escalate to</th>
                <th>Result Follow Up</th>
              </tr>
            </thead>
            <tbody>
              {tickets.length === 0 && (
                <tr>
                  <td colSpan={12} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
              {tickets.map((ticket) => (
                <tr key={ticket.id}>
                  <td>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        setSelectedId(ticket.id);
                        setModal("ticket-progress");
                      }}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </td>
                  <td className="text-nowrap">{ticket.ticket_number}</td>
                  <td style={{ width: "200px" }}>{ticket.name}</td>
                  <td className="text-nowrap">{ticket.activity?.activity_number || "-"}</td>
                  <td className="text-nowrap">{ticket.activity?.activity_type || "-"}</td>
                  <td className="text-nowrap">
                    {ticket.activity
                      ? `${ticket.activity.activity_code} - ${ticket.activity.activity_code_desc}`
                      : "-"}
                  </td>
                  <td className="text-nowrap">
                    {ticket.createdAt
                      ? moment.unix(ticket.createdAt).format("DD MMM YYYY - HH:mm")
                      : "-"}
                  </td>
                  <td style={{ maxWidth: "300px" }}>
                    {ticket.ticket_details[ticket.ticket_details.length - 1].description || "-"}
                  </td>
                  <td>
                    <TicketStatusBadge status={ticket.status} />
                  </td>
                  <td>
                    {ticket.priority === "low" && <span className="badge badge-info">Low</span>}
                    {ticket.priority === "medium" && (
                      <span className="badge badge-warning">Medium</span>
                    )}
                    {ticket.priority === "high" && <span className="badge badge-danger">High</span>}
                  </td>
                  <td>{ticket.role}</td>
                  <td style={{ minWidth: "300px" }}>
                    {ticket.ticket_details.length > 1
                      ? `(${ticket.ticket_details[0].worked_by}) - ${ticket.ticket_details[0].description}`
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {meta.count > meta.page_size && (
          <Pagination
            totalpage={Math.ceil(meta.count / meta.page_size)}
            selectedpage={meta.page}
            clickpage={(page) => gotoPage(page)}
            next={() => gotoPage(meta.page + 1)}
            prev={() => gotoPage(meta.page - 1)}
          />
        )}
      </LoadingInsideBoxWrapper>

      <ModalReportTicketing
        show={modal === "ticket-progress"}
        id={modal === "ticket-progress" ? selectedId : ""}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
    </div>
  );
}
