import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateProjectVendorById } from "../../api/projects";
import { useProjectVendorHooks } from "../../utils/project/VendorProjectUtils";
import AlertBox from "../AlertBox";

export default function ModalProjectVendorUpdate({ show, id, onClose, onSuccess }) {
  const vendorData = useProjectVendorHooks({id});
  let [alert, setAlert] = useState({show: false, message: '', type: ''});
  let [form, setForm] = useState({pic: '', description: '', po_date: ''});
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (vendorData.fetched) {
      setForm({
        pic: vendorData.data.pic,
        description: vendorData.data.description || '',
        po_date: moment.unix(vendorData.data.createdAt).format('YYYY-MM-DD')
      })
    }
  }, [vendorData.data.description, vendorData.data.pic, vendorData.fetched]);

  const handleClose = () => {
    resetForm();
    onClose();
  }

  const resetForm = () => {
    setForm({pic: '', description: '',po_date: ''});
    setAlert({show: false, message: '', type: ''});
  }

  const updateProjectVendor = async (formData)  => {
    setLoading(true);
    try {
      let response = await updateProjectVendorById(id, formData);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }
    setLoading(false);
  }

  const onSubmitData = (event) => {
    event.preventDefault();
    updateProjectVendor(form)
  }


  return(
    <Modal show={show} onHide={() => handleClose()} size="xs">
      <form onSubmit={event => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Detail Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label>PIC</label>
            <input type="text" required className="form-control" value={form.pic} onChange={event => setForm({ ...form, pic: event.target.value})} />
          </div>
          <div className="form-group">
            <label htmlFor="vendordesc">Deskripsi</label>
            <textarea value={form.description} onChange={event => setForm({ ...form, description: event.target.value})} className="form-control"/>
          </div>
          <div className="form-group">
            <label>Tanggal PO</label>
            <input type="date" required className="form-control" value={form.po_date} onChange={event => setForm({ ...form, po_date: event.target.value})} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">Close</button>
          <button className="btn btn-primary" disabled={loading}>{loading ? "Menyimpan ..." : "Simpan Perubahan"}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}