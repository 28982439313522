import { useState } from "react";
import MainLayout from "../components/layouts/MainLayout";
import Box from "../components/Box";
import { useWarehousesHooks } from "../utils/InventoryHooks";
import ModalCreateWarehouse from "../components/inventory/warehouse/ModalCreateWarehouse";
import AlertBox from "../components/AlertBox";
import ModalUpdateWarehouse from "../components/inventory/warehouse/ModalUpdateWarehouse";
import Pagination from "../components/Pagination";
import ModalDeleteWarehouse from "../components/inventory/warehouse/ModalDeleteWarehouse";
import { Link } from "react-router-dom";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import ModalProjectWarehouse from "../components/inventory/warehouse/ModalProjectWarehouse";

export default function WarehousesPage() {
  let { hasAccess } = useControlPermission();

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [filter, setFilter] = useState({ name: "", page: 1, page_size: 10 });
  let [formFilter, setFormFilter] = useState({ name: "" });
  let [selectedId, setSelectedId] = useState("");

  let [showModalCreate, setShowModalCreate] = useState(false);
  let [showModalProject, setShowModalProject] = useState(false);
  let [showModalUpdate, setShowModalUpdate] = useState(false);
  let [showModalDelete, setShowModalDelete] = useState(false);

  let warehouses = useWarehousesHooks({ filter });

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ name: "" });
    setFilter({ name: "", page: 1, page_size: 10 });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const toggleOpenModalUpdate = (id = null) => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleOpenModalProject = (id = null) => {
    setSelectedId(id);
    setShowModalProject(!showModalProject);

    if (!id) {
      warehouses.fetchWarehouses(filter);
    }
  };

  const toggleOpenModalDelete = (id = null) => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    setFilter({ ...filter });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Data Gudang</h1>
          </div>
          <div className="col-auto">
            {hasAccess("create-warehouse") && (
              <button className="btn btn-primary" onClick={() => setShowModalCreate(true)}>
                <i className="fa fa-user-plus"></i> Buat Gudang Baru
              </button>
            )}
          </div>
        </div>
        <Box title="Data Gudang" loading={warehouses.loading}>
          <div className="row mb-4">
            <div className="col-auto">
              <label htmlFor="warehouse_name">Nama Gudang</label>
              <input
                type="text"
                name="warehouse_name"
                value={formFilter.name}
                onChange={(event) => changeFilter("name", event.target.value)}
                className="form-control"
                id="warehouse_name"
              />
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
          </div>
          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Alamat</th>
                  <th>Deskripsi</th>
                  <th>Project</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {warehouses.warehouses.length === 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      Tidak ada yang ditampilkan
                    </td>
                  </tr>
                )}
                {warehouses.warehouses.map((warehouse, index) => (
                  <tr key={index}>
                    <td>{warehouse.name}</td>
                    <td>
                      {warehouse.address}, {warehouse.addresses.kelurahan},{" "}
                      {warehouse.addresses.kecamatan}, {warehouse.addresses.kota_kab},{" "}
                      {warehouse.addresses.province}, {warehouse.addresses.postal_code}
                    </td>
                    <td>{warehouse.description}</td>
                    <td>
                      {warehouse.projects.length === 0 && "-"}
                      <ul className="px-3">
                        {warehouse.projects.map((project, index) => (
                          <li key={index}>{project.name}</li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      {hasAccess("view-inventory-warehouse") && (
                        <Link
                          to={`/warehouses/${warehouse.id}/inventory`}
                          className="btn btn-block text-nowrap btn-sm btn-primary"
                        >
                          <i className="fa fa-eye"></i> Lihat Inventory
                        </Link>
                      )}
                      {hasAccess("manage-warehouse-project") && (
                        <button
                          className="btn btn-sm btn-block btn-custom-purple text-nowrap mb-1 mr-1"
                          onClick={() => toggleOpenModalProject(warehouse.id)}
                        >
                          <i className="fa fa-warehouse"></i> Kelola Project
                        </button>
                      )}
                      {hasAccess("update-warehouse") && (
                        <button
                          className="btn btn-block text-nowrap btn-sm btn-info"
                          onClick={() => toggleOpenModalUpdate(warehouse.id)}
                        >
                          <i className="fa fa-pencil-alt"></i> Edit Gudang
                        </button>
                      )}
                      {hasAccess("delete-warehouse") && (
                        <button
                          className="btn btn-block text-nowrap btn-sm btn-danger"
                          onClick={() => toggleOpenModalDelete(warehouse.id)}
                        >
                          <i className="fa fa-trash"></i> Hapus Gudang
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {warehouses.meta.count > warehouses.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(warehouses.meta.count / warehouses.meta.page_size)}
              selectedpage={warehouses.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(warehouses.meta.page + 1)}
              prev={() => gotoPage(warehouses.meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalCreateWarehouse
        show={showModalCreate}
        onClose={() => setShowModalCreate(false)}
        onSuccess={onSuccess}
      />
      <ModalUpdateWarehouse
        show={showModalUpdate}
        onClose={toggleOpenModalUpdate}
        id={showModalUpdate ? selectedId : null}
        onSuccess={onSuccess}
      />
      <ModalDeleteWarehouse
        show={showModalDelete}
        onClose={toggleOpenModalDelete}
        id={showModalDelete ? selectedId : null}
        onSuccess={onSuccess}
      />
      <ModalProjectWarehouse
        show={showModalProject}
        onClose={toggleOpenModalProject}
        id={showModalProject ? selectedId : null}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
