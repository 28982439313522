import { useState } from "react";
import { Modal } from "react-bootstrap";
import { downloadHistoryModem } from "../../../api/inventory";
import AlertBox from "../../AlertBox";

export default function ModalDownloadHistoryModem({ show, onClose, onSuccess }) {
  let [form, setForm] = useState({ n: "" });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({ n: "" });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    downloadDataModem(form);
  };

  const downloadDataModem = async (form) => {
    setLoading(true);
    try {
      let response = await downloadHistoryModem({ ...form });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Riwayat Modem Setup.xlsx");
      onSuccess("Berhasil mengunduh Data Riwayat Modem Setup.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };
  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Download Data Riwayat Modem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label>SN Modem/GPON/No. Reg</label>
            <input
              type="text"
              className="form-control"
              value={form.n}
              onChange={(event) => setForm({ ...form, n: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" type="button" onClick={() => handleClose()}>
            Close
          </button>
          <button className="btn btn-primary" type="submit" disabled={loading}>
            <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
