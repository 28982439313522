import { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  useInventoryDetailHistoryHooks,
  useInventoryHooks,
  useInventoryInHooks,
  useInventoryOutHooks,
} from "../../utils/InventoryHooks";
import AlertBox from "../AlertBox";
import { LoadingInsideBox } from "../Loading";
import moment from "moment";
import Pagination from "../Pagination";
import { CONDITION_INVENTORY } from "../../tools/inventorytools";

export default function ModalHistoryInventoryModem({ show, onClose, id, warehouse_id }) {
  const { data } = useInventoryHooks({ id });
  const [tab, setTab] = useState("in");
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Riwayat Keluar Barang: {data.sku} - {data.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item">
            <button className={`nav-link ${tab === "in" && "active"}`} onClick={() => setTab("in")}>
              Barang Masuk
            </button>
          </li>
          <li className="nav-item" onClick={() => setTab("out")}>
            <button
              className={`nav-link ${tab === "out" && "active"}`}
              onClick={() => setTab("out")}
            >
              Barang Keluar
            </button>
          </li>
        </ul>
        {tab === "in" && <TableInventoryIn id={id} unit={data.unit} warehouse_id={warehouse_id} />}
        {tab === "out" && (
          <TableInventoryOut id={id} unit={data.unit} warehouse_id={warehouse_id} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function TableInventoryIn({ id, unit, warehouse_id }) {
  let [selected, setSelected] = useState(null);
  let [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    id: id,
    warehouse_id: warehouse_id,
  });

  let dataIn = useInventoryInHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="loading-data-div">
          <h5>Data Barang Masuk</h5>
          {dataIn.loading && <LoadingInsideBox />}
          <div style={{ overflowX: "scroll" }}>
            <table className="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>Waktu Masuk</th>
                  <th>Qty</th>
                  <th>Tipe</th>
                  <th>Proses</th>
                  <th>Sumber</th>
                  <th>Status</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
                {dataIn.histories.length === 0 && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      Tidak ada data ditampilkan
                    </td>
                  </tr>
                )}
                {dataIn.histories?.map((history, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        className={`btn btn-sm ${
                          selected === history.id ? "btn-success" : "btn-primary"
                        }`}
                        onClick={() => setSelected(history.id)}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                    </td>
                    <td>{moment(history.datetime).format("DD MMMM YYYY - HH:mm")}</td>
                    <td>
                      {history.qty} {unit}
                    </td>
                    <td>{history.type}</td>
                    <td>{history.process}</td>
                    <td>{history.source_warehouse?.name || "-"}</td>
                    <td>{CONDITION_INVENTORY[history.status]}</td>
                    <td>{history.user?.name || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {dataIn.meta.count > dataIn.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(dataIn.meta.count / dataIn.meta.page_size)}
              selectedpage={dataIn.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(dataIn.meta.page + 1)}
              prev={() => gotoPage(dataIn.meta.page - 1)}
            />
          )}
        </div>
      </div>
      <div className="col-12 col-md-6">
        {selected ? (
          <ModemHistory id={id} history_id={selected} type={"stock-in"} key={selected} />
        ) : (
          <div className="card card-body text-center">Tidak ada data</div>
        )}
      </div>
    </div>
  );
}

function TableInventoryOut({ id, unit, warehouse_id }) {
  let [selected, setSelected] = useState(null);
  let [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    id: id,
    warehouse_id: warehouse_id,
  });

  let dataOut = useInventoryOutHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <div className="loading-data-div">
      <h5>Data Barang Keluar</h5>
      {dataOut.loading && <LoadingInsideBox />}
      <div className="row">
        <div className="col-12 col-md-6">
          <div style={{ overflowX: "scroll" }}>
            <table className="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>Waktu Keluar</th>
                  <th>Qty</th>
                  <th>Proses</th>
                  <th>Tujuan</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
                {dataOut.histories.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      Tidak ada data ditampilkan
                    </td>
                  </tr>
                )}
                {dataOut.histories?.map((history, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        className={`btn btn-sm ${
                          selected === history.id ? "btn-success" : "btn-primary"
                        }`}
                        onClick={() => setSelected(history.id)}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                    </td>
                    <td>{moment(history.datetime).format("DD MMMM YYYY - HH:mm")}</td>
                    <td>
                      {history.qty} {unit}
                    </td>
                    <td>{history.process}</td>
                    <td>
                      {history.destination_type === "REGISTRATION" &&
                        history.registration?.registration_number}
                      {history.destination_type === "PROJECT" && history.project.name}
                      {history.destination_warehouse !== null && history.destination_warehouse.name}
                    </td>
                    <td>{history.user?.name || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-md-6">
          {selected ? (
            <ModemHistory id={id} history_id={selected} type={"stock-out"} key={selected} />
          ) : (
            <div className="card card-body text-center">Tidak ada data</div>
          )}
        </div>
      </div>
      {dataOut.meta.count > dataOut.meta.page_size && (
        <Pagination
          totalpage={Math.ceil(dataOut.meta.count / dataOut.meta.page_size)}
          selectedpage={dataOut.meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(dataOut.meta.page + 1)}
          prev={() => gotoPage(dataOut.meta.page - 1)}
        />
      )}
    </div>
  );
}

function ModemHistory({ id, type, history_id }) {
  // filter not working if using props, it will trigger infinite loop
  // eslint-disable-next-line no-unused-vars
  let [filter, setFilter] = useState({
    id: id,
    history_id: history_id,
    type: type,
    page_size: 10,
    page: 1,
  });

  let { histories, loading } = useInventoryDetailHistoryHooks({ filter });

  return (
    <div className="loading-data-div">
      {loading && <LoadingInsideBox />}
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>SN Modem</th>
            <th>SN GPON</th>
          </tr>
        </thead>
        <tbody>
          {histories.map((history, index) => (
            <tr key={index}>
              <td>{history.serial_number_modem}</td>
              <td>{history.serial_number_gpon}</td>
            </tr>
          ))}
          {histories.length === 0 && (
            <tr>
              <td colSpan={3} className="text-center">
                Tidak Ada Detail Riwayat
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
