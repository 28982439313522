import { useState } from "react";
import { Modal } from "react-bootstrap";
import { deleteInventoryById } from "../../api/inventory";
import { useInventoryHooks } from "../../utils/InventoryHooks";

export default function ModalDeleteInventory({ show, id, onClose, onSuccess }) {
  let { data } = useInventoryHooks({ id });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      onClose();
    }
  };

  const onDeletePack = async () => {
    setLoading(true);
    try {
      await deleteInventoryById(id);
      onSuccess(`Success delete inventory ${data.name}`);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Hapus Data Inventory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert.show && (
          <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
            {alert.message}
            <button
              type="button"
              className="close"
              onClick={() => setAlert({ show: false, message: "", type: "" })}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        <h5 className="text-gray-800">Apakah anda yakin akan menghapus inventory "{data.name}"?</h5>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>
          Tidak
        </button>
        <button className="btn btn-danger" onClick={() => onDeletePack()} disabled={loading}>
          {loading ? "Menunggu ..." : "Ya"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
