import { useState } from "react";
import { Modal } from "react-bootstrap";
import { generatePdfAssignmentLink } from "../../api/user";

export default function ModalGenerateAssignment({ show, id, setShow, type, onSuccess }) {
  let [form, setForm] = useState({location: '', manager: ''});
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({show: false, message: '', type: ''});

  const handleClose = () => {
    setForm({location: '', manager: ''});
    setAlert({show: false, message: '', type: ''})
    setShow('');
  }

  const submitData = (event) => {
    event.preventDefault();
    downloadAssignmentPdf(id, form);
  }

  const downloadAssignmentPdf = async (id, form) => {
    setLoading(true);
    try {
      let { data } = await generatePdfAssignmentLink(id, { ...form, type: type});
      let url = data.data.url;
      window.open(url, '_blank');
      onSuccess(data.message);
      handleClose();
    } catch (error) {
      let errorMsg = '';
      if (error.response) {
        let { data } =  error.response;
        errorMsg  = data.message;
      } else {
        errorMsg = "Terjadi permasalahan dalam generate"
      }

      setAlert({show: true, message: errorMsg, type: 'danger'})
    }
    setLoading(false);
  }

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={event => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Generate Surat Penugasan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.show &&
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button type="button" className="close"  onClick={() => setAlert({show: false, message: '', type: ''})} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          }

          <div className="form-group">
            <label htmlFor="">Lokasi</label>
            <input type="text" className="form-control" required value={form.location} onChange={event => setForm({ ...form, location: event.target.value })} />
          </div>
          <div className="form-group">
            <label htmlFor="">Nama Manager</label>
            <input type="text" className="form-control" required value={form.manager} onChange={event => setForm({ ...form, manager: event.target.value })} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>Close</button>
          <button type="submit" className="btn btn-primary"><i className="fas fa-pdf"></i> Generate Surat Penugasan</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}