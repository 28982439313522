import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { updateBillingById } from "../../api/finance";
import { useBillingIdHooks } from "../../utils/finance/BillingUtils";
import AlertBox from "../AlertBox";
import { convertToRupiah } from "../../tools/numerictools";
import moment from "moment";

export default function ModalUpdateBilling({ show, id, onSuccess, onClose }) {
  const [form, setForm] = useState({ status: "", amount: "", start_period: "", end_period: "" });
  const [alert, setAlert] = useState({ show: false, message: "", type: "danger" });
  const [loading, setLoading] = useState(false);
  const { data, fetched } = useBillingIdHooks({ id });

  useEffect(() => {
    if (fetched) {
      setForm({
        status: data.status,
        amount: "",
        start_period: "",
        end_period: "",
      });
    }
  }, [data, fetched]);

  const updateBilling = async (form) => {
    setLoading(true);
    try {
      let response = await updateBillingById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const submitUpdate = (event) => {
    event.preventDefault();
    updateBilling(form);
  };

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "danger" });
    onClose();
  };

  const internetPackPrice = (priceForm, internetPackPrice) => {
    if (priceForm) {
      return priceForm;
    }

    if (internetPackPrice) {
      return internetPackPrice;
    }

    return 0;
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Update Billing {data.billing_number}</Modal.Title>
      </Modal.Header>
      <form onSubmit={(event) => submitUpdate(event)}>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Nomor Billing</td>
                <td>{data.billing_number}</td>
              </tr>
              <tr>
                <td>Nomor</td>
                <td>
                  {(data.type === "CUSTOMER" ||
                    data.type === "CUSTOMER-RELOCATE" ||
                    data.type === "ADDITIONAL-ITEM") &&
                    data.customer?.customer_number}
                  {data.type === "REGISTRATION" && data.registration?.registration_number}
                </td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>
                  {(data.type === "CUSTOMER" ||
                    data.type === "CUSTOMER-RELOCATE" ||
                    data.type === "ADDITIONAL-ITEM") &&
                    data.customer?.name}
                  {data.type === "REGISTRATION" && data.registration?.name}
                </td>
              </tr>
              <tr>
                <td>Paket</td>
                <td>{data.internet_package?.name || "-"}</td>
              </tr>
              <tr>
                <td>Harga Paket</td>
                <td>Rp {convertToRupiah(data.internet_package?.price || 0)}</td>
              </tr>
            </tbody>
          </table>
          {["REGISTRATION", "CUSTOMER"].includes(data.type) && (
            <div className="form-group">
              <label htmlFor="amountUpdate">Harga Paket</label>
              <input
                type="number"
                className="form-control"
                name="amountUpdate"
                id="amountUpdate"
                value={form.amount}
                onChange={(event) => setForm({ ...form, amount: event.target.value })}
              />
              <small>Ubah jika ingin mengubah harga paket yang berlaku pada tagihan ini</small>
            </div>
          )}
          {["CUSTOMER"].includes(data.type) && (
            <>
              <div className="form-group">
                <label>Periode Tanggal Mulai</label>
                <input
                  type="date"
                  className="form-control"
                  value={form.start_period}
                  required={!!form.end_period}
                  min={moment().format("YYYY-MM-DD")}
                  max={form.end_period}
                  onChange={(event) => setForm({ ...form, start_period: event.target.value })}
                />
                <small>Hanya diisi untuk mengganti awal periode layanan internet</small>
              </div>
              <div className="form-group">
                <label>Periode Tanggal Akhir</label>
                <input
                  type="date"
                  className="form-control"
                  value={form.end_period}
                  min={form.start_period}
                  required={!!form.start_period}
                  onChange={(event) => setForm({ ...form, end_period: event.target.value })}
                />
                <small>Hanya diisi untuk mengganti akhir periode layanan internet</small>
              </div>
            </>
          )}
          <div className="form-group">
            <label htmlFor="statusTypeUpate">Status</label>
            <select
              name="statusTypeUpate"
              id="statusTypeUpate"
              className="form-control"
              value={form.status}
              onChange={(event) => setForm({ ...form, status: event.target.value })}
            >
              <option value="PAID">PAID</option>
              <option value="UNPAID">UNPAID</option>
              <option value="CANCELED">CANCELED</option>
            </select>
          </div>
          <h5 className="mt-4">Preview Tagihan Baru</h5>
          <table className="table table-borderless table-sm">
            <tbody>
              <tr>
                <td>Paket</td>
                <td className="text-right">
                  Rp. {convertToRupiah(internetPackPrice(form.amount, data.amount || 0))}
                </td>
              </tr>
              <tr>
                <td>Biaya Instalasi</td>
                <td className="text-right">Rp. {convertToRupiah(data.installation_cost || 0)}</td>
              </tr>
              <tr>
                <td>Additional Amount</td>
                <td className="text-right">Rp. {convertToRupiah(data.additional_amount || 0)}</td>
              </tr>
              <tr>
                <td>Admin Fee</td>
                <td className="text-right">Rp. {convertToRupiah(data.admin_fee || 0)}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td className="text-right">
                  Rp.{" "}
                  {convertToRupiah(
                    internetPackPrice(parseInt(form.amount), data.amount || 0) +
                      (data.installation_cost || 0) +
                      (data.admin_fee || 0) +
                      (data.additional_amount || 0)
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Batal
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? "Menunggu ..." : "Simpan Perubahan"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
