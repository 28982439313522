import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import AlertBox from "../../components/AlertBox";
import moment from "moment";
import { useAllUserAccounts } from "../../utils/usermobile/UserMobileUtils";
import Pagination from "../../components/Pagination";
import { Modal } from "react-bootstrap";

export default function UserMobilePage() {
  const [formFilter, setFormFilter] = useState({
    keyWord: "",
  });
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    keyWord: "",
  });

  const { data, meta, loading, errorMsg } = useAllUserAccounts({ filter });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [modal, setModal] = useState(null);
  const [selected, setSelected] = useState({});

  const selectModal = (modalName, selectedData) => {
    setModal(modalName);
    setSelected(selectedData);
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const applyFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
      page: 1,
    });
  };

  const resetFilter = () => {
    setFormFilter({
      keyWord: "",
    });
    setFilter({
      keyWord: "",
      page: 1,
      page_size: 10,
    });
  };

  return (
    <MainLayout data="app-user">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Manajemen Pengguna Aplikasi MyViberlink</h1>
            </div>
            <div className="col-auto"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Box title={`Pengguna Aplikasi (Total: ${meta.count})`} loading={loading}>
              <AlertBox {...alert} setAlert={setAlert} />
              <div className="row">
                <div className="col-12 col-md-2">
                  <div className="form-group">
                    <label>Pencarian</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formFilter.keyWord}
                      onChange={(event) =>
                        setFormFilter({ ...formFilter, keyWord: event.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-sm">
                  <button
                    className="btn btn-primary"
                    onClick={() => applyFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => resetFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>

              {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}

              <div style={{ overflowX: "auto" }}>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Foto</th>
                      <th>Nama</th>
                      <th>Email</th>
                      <th>No. HP</th>
                      <th>Tgl. Daftar</th>
                      <th>Status</th>
                      <th>No. Registrasi Utama</th>
                      <th>Jumlah Registrasi Lain Terhubung</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length === 0 && (
                      <tr>
                        {" "}
                        <td colSpan={9} className="text-center">
                          Tidak ada data
                        </td>
                      </tr>
                    )}
                    {data.map((account) => (
                      <tr key={account.id}>
                        <td>
                          <button
                            className="btn btn-sm btn-primary btn-block"
                            onClick={() => {
                              selectModal("detail-account", account);
                            }}
                          >
                            <i className="fa fa-eye"></i>
                          </button>
                          {/* <button className="btn btn-sm btn-info btn-block">
                          Linked Registration
                        </button>
                        <button className="btn btn-sm btn-danger btn-block">Hapus</button> */}
                        </td>
                        <td>
                          {account.photo ? (
                            <img
                              src={account.photo}
                              alt={account.fname}
                              style={{ width: "100px" }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>{account.fname}</td>
                        <td>{account.email}</td>
                        <td>{account.phone}</td>
                        <td>{moment.unix(account.createdAt).format("DD MMM YYYY")}</td>
                        <td>{account.status_desc}</td>
                        <td>{account.registration_number || "-"}</td>
                        <td>{account.others_account.length}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mt-4">
                {meta.count > meta.pageSize && (
                  <Pagination
                    totalpage={Math.ceil(meta.count / meta.pageSize)}
                    selectedpage={meta.page}
                    clickpage={(page) => gotoPage(page)}
                    next={() => gotoPage(meta.page + 1)}
                    prev={() => gotoPage(meta.page - 1)}
                  />
                )}
              </div>
            </Box>
          </div>
        </div>
      </div>

      <ModalDetailAccount
        show={modal === "detail-account"}
        onClose={() => {
          setModal("");
        }}
        account={selected}
      />
    </MainLayout>
  );
}

function ModalDetailAccount({ show, account, onClose }) {
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Detail Akun</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <h5>Detail Akun Pengguna</h5>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Photo</td>
              <td>
                {account.photo ? (
                  <img src={account.photo} alt={account.fname} style={{ width: "100px" }} />
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <td style={{ width: "200px" }}>Nama</td>
              <td>{account.fname || "-"}</td>
            </tr>
            <tr>
              <td>No. HP</td>
              <td>{account.phone || "-"}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{account.email || "-"}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{account.status_desc || "-"}</td>
            </tr>
            <tr>
              <td>No. registrasi Utama</td>
              <td>{account.registration_number || "-"}</td>
            </tr>
            <tr>
              <td>No. Pelanggan Utama</td>
              <td>{account.customer_number || "-"}</td>
            </tr>
          </tbody>
        </table>
        <h5>Registrasi Lain yang Terhubung</h5>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>No. Reg</th>
              <th>Tanggal Terhubung</th>
            </tr>
          </thead>
          <tbody>
            {account.others_account?.length ? (
              account.others_account.map((other) => (
                <tr key={other.id}>
                  <td>{other.registration_number}</td>
                  <td>
                    {other.createdAt ? moment.unix(other.createdAt).format("DD MMM YYYY") : "-"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>Tidak ada registrasi lain</td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}
