import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateInventoryById } from "../../api/inventory";
import { useInventoryCategories, useInventoryHooks } from "../../utils/InventoryHooks";
import AlertBox from "../AlertBox";

export default function ModalEditInventory({ show, onClose, id, onSuccess }) {
  const { data, fetched } = useInventoryHooks({ id });
  let [form, setForm] = useState({
    name: "",
    sku: "",
    unit: "",
    price: "",
    inventory_category_id: "",
  });
  let categories = useInventoryCategories({ page: 1, page_size: 99 });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    if (fetched) {
      setForm({
        name: data.name,
        sku: data.sku,
        unit: data.unit,
        price: data.price,
        inventory_category_id: data.inventory_category_id,
      });
    }
  }, [fetched]);

  const handleClose = () => {
    setForm({ name: "", sku: "", unit: "", price: "", inventory_category_id: "" });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
    };
    submitCategory(formData);
  };

  const submitCategory = async (form) => {
    setLoading(true);
    try {
      let response = await updateInventoryById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Data Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Nama Inventory <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              SKU Inventory <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.sku}
              onChange={(event) => setForm({ ...form, sku: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Unit <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.unit}
              onChange={(event) => setForm({ ...form, unit: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Harga <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.price}
              onChange={(event) => setForm({ ...form, price: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Kategori <span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              required
              value={form.inventory_category_id}
              onChange={(event) => setForm({ ...form, inventory_category_id: event.target.value })}
            >
              <option value="">-- Pilih Kategori --</option>
              {categories.categories.map((category, index) => (
                <option value={category.id} key={index}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-save"></i> Simpan Perubahan
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
