import { useState } from "react";
import { Link } from "react-router-dom";
import AlertBox from "../components/AlertBox";
import Box from "../components/Box";
import Pagination from "../components/Pagination";
import MainLayout from "../components/layouts/MainLayout";
import ModalProjectCreate from "../components/project/ModalProjectCreate";
import ModalProjectDelete from "../components/project/ModalProjectDelete";
import ModalProjectDetail from "../components/project/ModalProjectDetail";
import ModalProjectInventory from "../components/project/ModalProjectInventory";
import ModalProjectUpdate from "../components/project/ModalProjectUpdate";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import { useProjectListHooks } from "../utils/ProjectsUtilHooks";

export default function ProjectPage() {
  let { hasAccess } = useControlPermission();

  let [formFilter, setFormFilter] = useState({ q: "" });
  let [filter, setFilter] = useState({ q: "", page: 1, page_size: 10 });
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [selectedId, setSelectedId] = useState(null);
  let [showModalCreate, setShowModalCreate] = useState(false);
  let [showModalUpdate, setShowModalUpdate] = useState(false);
  let [showModalDelete, setShowModalDelete] = useState(false);
  let [showModalDetail, setShowModalDetail] = useState(false);
  let [showModalInventory, setShowModalInventory] = useState(false);

  const projectData = useProjectListHooks({ filter });

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ q: "" });
    setFilter({ q: "", page: 1, page_size: 10 });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const onSuccess = (show, message) => {
    setAlert({ show, message, type: "success" });
    setFilter({ ...filter });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const toggleOpenModalDetail = (id = null) => {
    setSelectedId(id);
    setShowModalDetail(!showModalDetail);
  };

  const toggleOpenModalUpdate = (id = null) => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleOpenModalDelete = (id = null) => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const toggleOpenModalInventory = (id = null) => {
    setSelectedId(id);
    setShowModalInventory(!showModalInventory);
  };

  return (
    <MainLayout resource_key="manage-project">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Manajemen Project</h1>
            </div>
            <div className="col-auto">
              {hasAccess("create-project") && (
                <button className="btn btn-primary" onClick={() => setShowModalCreate(true)}>
                  <i className="fa fa-plus"></i> Tambah Project
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Data Project" loading={projectData.loading}>
          <div className="mb-4">
            <div className="row">
              <div className="col-auto">
                <label htmlFor="project_name">Pencarian</label>
                <input
                  type="text"
                  name="project_name"
                  placeholder="Nama Project / Mitra"
                  value={formFilter.q}
                  onChange={(event) => changeFilter("q", event.target.value)}
                  className="form-control"
                  id="project_name"
                />
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-primary"
                  onClick={() => applyFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => resetFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />
          <div className="auto-horizontal-scroll">
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>Nama Project</th>
                  <th>Mitra</th>
                  <th>Aktivasi oleh Pengguna</th>
                  <th>Framed Pool</th>
                  <th>OLT</th>
                  <th>VLAN</th>
                  <th>VLAN Profile</th>
                  <th>Pencairan Komisi</th>
                </tr>
              </thead>
              <tbody>
                {projectData.projects.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={6}>
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {projectData.projects.map((project, index) => (
                  <tr key={index}>
                    <td style={{ width: "100px" }}>
                      {hasAccess("view-project-vendor") && (
                        <Link
                          to={`/project/${project.id}/work`}
                          className="btn text-nowrap btn-sm btn-block btn-warning mb-2"
                        >
                          <i className="fa fa-person-digging"></i> Pekerjaan
                        </Link>
                      )}
                      <button
                        className="btn btn-sm btn-block btn-primary text-nowrap mb-1 mr-1"
                        onClick={() => toggleOpenModalDetail(project.id)}
                      >
                        <i className="fa fa-eye"></i> Lihat Detail
                      </button>
                      {hasAccess("update-project") && (
                        <button
                          className="btn btn-sm btn-block btn-info mb-1 mr-1"
                          onClick={() => toggleOpenModalUpdate(project.id)}
                        >
                          <i className="fa fa-pencil-alt"></i> Edit
                        </button>
                      )}
                      {hasAccess("view-project-inventories") && (
                        <button
                          className="btn btn-sm btn-block btn-custom-orange text-nowrap mb-1 mr-1"
                          onClick={() => toggleOpenModalInventory(project.id)}
                        >
                          <i className="fa fa-boxes-stacked"></i> Ubah Inventory
                        </button>
                      )}
                      {hasAccess("delete-project") && (
                        <button
                          className="btn btn-sm btn-block btn-danger mb-1 mr-1"
                          onClick={() => toggleOpenModalDelete(project.id)}
                        >
                          <i className="fa fa-trash"></i> Hapus
                        </button>
                      )}
                    </td>
                    <td className="text-nowrap">{project.name}</td>
                    <td className="text-nowrap">{project.partner?.name || "-"}</td>
                    <td className="text-nowrap">{project.user_activation ? "Ya" : "Tidak"}</td>
                    <td className="text-nowrap">{project.framed_pool || "-"}</td>
                    <td className="text-nowrap">{project.olt ? `${project.olt.name}` : "-"}</td>
                    <td className="text-nowrap">{project.vlan || "-"}</td>
                    <td className="text-nowrap">{project.vlan_profile || "-"}</td>
                    <td>{project.commission_disbursement ? "Ya" : "Tidak"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {projectData.meta.count > projectData.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(projectData.meta.count / projectData.meta.page_size)}
              selectedpage={projectData.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(projectData.meta.page + 1)}
              prev={() => gotoPage(projectData.meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalProjectCreate
        show={showModalCreate}
        onClose={() => setShowModalCreate(!showModalCreate)}
        onSuccess={onSuccess}
      />
      <ModalProjectDetail
        show={showModalDetail}
        onClose={toggleOpenModalDetail}
        id={showModalDetail ? selectedId : null}
      />
      <ModalProjectUpdate
        show={showModalUpdate}
        onClose={toggleOpenModalUpdate}
        id={showModalUpdate ? selectedId : null}
        onSuccess={onSuccess}
      />
      <ModalProjectInventory
        show={showModalInventory}
        onClose={toggleOpenModalInventory}
        id={showModalInventory ? selectedId : null}
        onSuccess={onSuccess}
      />
      <ModalProjectDelete
        show={showModalDelete}
        onClose={toggleOpenModalDelete}
        id={showModalDelete ? selectedId : null}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
