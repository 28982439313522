import { useEffect, useState } from "react";
import * as api from "../api/account";

export function useAccountManageHooks({ filter }) {
  const [accounts, setAccounts] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const getAccounts = async (filter = {}) => {
    setLoading(true);
    setError("");
    try {
      let { data } = await api.getAccounts(filter);
      setAccounts(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getAccounts(filter);
  }, [filter]);

  const refreshData = () => {
    getAccounts();
  };

  return {
    accounts,
    loading,
    refreshData,
    getAccounts,
    meta,
    error,
  };
}

export function useAccountById({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getAccount(id);
    }
  }, [id]);

  const getAccount = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await api.getAccountById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getAccount,
  };
}

export function useRolesHooks() {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const getRoles = async () => {
      try {
        let { data } = await api.getRoles();
        setRoles(data.data);
      } catch (error) {
        if (error.response) {
          let { data } = error.response;
          setError(data.message);
        } else {
          setError("Something Error Happened");
        }
      }
    };

    getRoles();
  }, []);

  return {
    roles,
    error,
  };
}
