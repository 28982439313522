import { useState, useEffect } from "react";
import { getProfile } from "../api/account";

export function useProfileAuth() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const loadUser = async () => {
    setLoading(true);
    try {
      let { data } = await getProfile();
      setUser(data.data);
    } catch (error) {
      if (error.response) {
        if (
          error.response?.data.code === 401 &&
          error.response?.data.message === "Access token expired!"
        ) {
          setErrorMsg("Sesi Telah berakhir!");
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      loadUser();
    }
  }, []);

  return {
    user,
    loading,
    errorMsg,
    loadUser,
  };
}
