import { useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../AlertBox";
import { downloadPerformanceProject } from "../../api/infrastructure";
import MonthYearPicker from "../MonthYearPicker";

export default function ModalDownloadPerformanceReport({ show, onClose, onSuccess }) {
  let [form, setForm] = useState({ start_month: "", end_month: "" });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({ start_month: "", end_month: "" });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const changeMonthYearFilter = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const submitData = (event) => {
    event.preventDefault();
    downloadDataPerformance(form);
  };

  const downloadDataPerformance = async (form) => {
    setLoading(true);
    try {
      let response = await downloadPerformanceProject({ ...form });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Report Project Performance.xlsx");
      onSuccess(true, "Berhasil mengunduh Report Project Performance.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };
  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Download Project Performance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          {/* <div className="form-group">
            <label>Project/Grup</label>
            <select
              className="form-control"
              value={form.project_id}
              onChange={(event) => setForm({ ...form, project_id: event.target.value })}
              disabled
            >
              <option value="">SEMUA</option>
              {projects.map((project, index) => (
                <option value={project.id} key={index}>
                  {project.name}
                </option>
              ))}
            </select>
          </div> */}
          <div className="form-group">
            <label>Bulan/Tahun mulai</label>
            <MonthYearPicker
              value={form.start_month}
              changeMonthYear={(value) => changeMonthYearFilter("start_month", value)}
            />
          </div>
          <div className="form-group">
            <label>Bulan/Tahun Akhir</label>
            <MonthYearPicker
              value={form.end_month}
              changeMonthYear={(value) => changeMonthYearFilter("end_month", value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" type="button" onClick={() => handleClose()}>
            Close
          </button>
          <button className="btn btn-primary" type="submit" disabled={loading}>
            <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
