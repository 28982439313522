import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateCategoryById } from "../../../api/inventory";
import { useCategoryId } from "../../../utils/InventoryHooks";
import AlertBox from "../../AlertBox";

export default function ModalEditCategories({ show, id, onClose, onSuccess }) {
  let { data, fetched } = useCategoryId({ id });
  let [form, setForm] = useState({ name: "", description: "" });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    setForm({
      name: data.name,
      description: data.description,
    });
  }, [fetched]);

  const handleClose = () => {
    setForm({ name: "", description: "" });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    updateCategories(form);
  };

  const updateCategories = async (form) => {
    setLoading(true);
    try {
      let response = await updateCategoryById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Data Kategori</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Nama Kategori <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi <span className="text-danger">*</span>
            </label>
            <textarea
              type="text"
              className="form-control"
              required
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-save"></i> Simpan Perubahan
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
