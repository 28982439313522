import { useState } from "react";
import { useInventoryInHooks } from "../../utils/InventoryHooks";
import moment from "moment";
import Pagination from "../Pagination";
import { LoadingInsideBox } from "../Loading";
import { CONDITION_INVENTORY } from "../../tools/inventorytools";

export default function TableInventoryIn({ id, unit, warehouse_id }) {
  let [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    id: id,
    warehouse_id: warehouse_id,
  });

  let dataIn = useInventoryInHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <div className="loading-data-div">
      <h5>Data Barang Masuk</h5>
      {dataIn.loading && <LoadingInsideBox />}
      <table className="table table-hover table-striped table-bordered">
        <thead>
          <tr>
            <th>Waktu Masuk</th>
            <th>Qty</th>
            <th>Tipe</th>
            <th>Proses</th>
            <th>Sumber Gudang</th>
            <th>Status</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          {dataIn.histories.length === 0 && (
            <tr>
              <td colSpan={7} className="text-center">
                Tidak ada data ditampilkan
              </td>
            </tr>
          )}
          {dataIn.histories?.map((history, index) => (
            <tr key={index}>
              <td>{moment(history.datetime).format("DD MMMM YYYY - HH:mm")}</td>
              <td>
                {history.qty} {unit}
              </td>
              <td>{history.type}</td>
              <td>{history.process}</td>
              <td>{history.source_warehouse?.name || "-"}</td>
              <td>{CONDITION_INVENTORY[history.status]}</td>
              <td>{history.user?.name || "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {dataIn.meta.count > dataIn.meta.page_size && (
        <Pagination
          totalpage={Math.ceil(dataIn.meta.count / dataIn.meta.page_size)}
          selectedpage={dataIn.meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(dataIn.meta.page + 1)}
          prev={() => gotoPage(dataIn.meta.page - 1)}
        />
      )}
    </div>
  );
}
