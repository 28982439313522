import { useState } from "react";
import { Modal } from "react-bootstrap";
import { downloadBilling } from "../../api/finance";
import { useCityRegionHooks } from "../../utils/RegionHooks";
import AlertBox from "../AlertBox";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";

export default function ModalDownloadBilling({ show, onClose, onSuccess }) {
  const [formFilter, setFormFilter] = useState({
    status: "",
    type: "",
    payment_method: "",
    payment_provider: "",
    confirmed_start_date: "",
    confirmed_end_date: "",
    project_id: "",
  });

  const regionFilter = useCityRegionHooks();
  const { projects } = useGroupListHooks();

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    resetFilter();
    onClose();
  };

  const resetFilter = () => {
    setFormFilter({
      status: "",
      type: "",
      payment_method: "",
      payment_provider: "",
      confirmed_start_date: "",
      confirmed_end_date: "",
      project_id: "",
    });
    regionFilter.resetRegion();
  };

  const downloadFile = async () => {
    setLoading(true);

    let location = {
      province: regionFilter.province,
      kota_kab: regionFilter.city,
    };

    try {
      let response = await downloadBilling({ ...formFilter, ...location });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Billing.xlsx");
      onSuccess("Berhasil mengunduh Data Billing.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Download Data Tagihan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />

        <form>
          <div className="form-group">
            <label>Tipe</label>
            <select
              name="type"
              className="form-control"
              id="status"
              value={formFilter.type}
              onChange={(event) => setFormFilter({ ...formFilter, type: event.target.value })}
            >
              <option value="">SEMUA</option>
              <option value="REGISTRATION">REGISTRATION</option>
              <option value="CUSTOMER">CUSTOMER</option>
            </select>
          </div>
          <div className="form-group">
            <label>Status</label>
            <select
              name="status"
              className="form-control"
              id="status"
              value={formFilter.status}
              onChange={(event) => setFormFilter({ ...formFilter, status: event.target.value })}
            >
              <option value="">SEMUA</option>
              <option value="PAID">PAID</option>
              <option value="UNPAID">UNPAID</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="registration_type">Metode Pembayaran</label>
            <select
              name="status"
              value={formFilter.payment_method}
              onChange={(event) =>
                setFormFilter({
                  ...formFilter,
                  payment_method: event.target.value,
                  payment_provider: "",
                })
              }
              className="form-control"
              id="status"
            >
              <option value="">SEMUA</option>
              <option value="bank-transfer">Bank Transfer</option>
              <option value="adakita">Adakita</option>
              <option value="virtual-account">Virtual Account</option>
              <option value="alfamart">Alfamart</option>
              <option value="pulsapro">Pulsapro</option>
              <option value="dukodu">Dukodu</option>
              <option value="indomaret">Indomaret</option>
              <option value="qris">QRIS</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="registration_type">VA Payment Provider</label>
            <select
              name="status"
              value={formFilter.payment_provider}
              disabled={formFilter.payment_method !== "virtual-account"}
              onChange={(event) =>
                setFormFilter({ ...formFilter, payment_provider: event.target.value })
              }
              className="form-control"
              id="status"
            >
              <option value="">SEMUA</option>
              <option value="bca">BCA</option>
              <option value="winpay">WINPAY</option>
              <option value="nicepay">NICEPAY</option>
            </select>
          </div>
          <div className="form-group">
            <label>Tanggal Konfirm Dari</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.confirmed_start_date}
              onChange={(event) =>
                setFormFilter({ ...formFilter, confirmed_start_date: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Tanggal Konfirm Sampai</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.confirmed_end_date}
              onChange={(event) =>
                setFormFilter({ ...formFilter, confirmed_end_date: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="project_group">Kelompok/Project</label>
            <select
              className="input form-control"
              id="project_group"
              value={formFilter.project_id}
              onChange={(event) => setFormFilter({ ...formFilter, project_id: event.target.value })}
            >
              <option value="">-- Kelompok/Project --</option>
              {projects.map((project, index) => (
                <option key={index} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Provinsi</label>
            <select
              className="form-control"
              value={regionFilter.province}
              onChange={(event) => regionFilter.changeProvince(event.target.value)}
            >
              <option value="">-- Semua Provinsi --</option>
              {regionFilter.provinces.map((province, index) => (
                <option value={province.province} key={index}>
                  {province.province}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Kabupaten</label>
            <select
              className="form-control"
              value={regionFilter.city}
              onChange={(event) => regionFilter.changeCity(event.target.value)}
            >
              <option value="">-- Semua Kabupaten --</option>
              {regionFilter.cities.map((city, index) => (
                <option value={city.kota_kab} key={index}>
                  {city.kota_kab}
                </option>
              ))}
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => downloadFile()} disabled={loading}>
          <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
