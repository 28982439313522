import { useState } from "react";
import { Modal } from "react-bootstrap";
import { cancelScheduleDismantleById } from "../../api/schedules";
import { useScheduleDismantleHooks } from "../../utils/SchedulesUtil";
import AlertBox from "../AlertBox";

export default function ModalCancelDismantle({ show, id, setShow, onSuccess }) {
  const { data, loading: dataLoading } = useScheduleDismantleHooks({ id });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      setShow();
    }
  };

  const cancelDismantle = async () => {
    setLoading(true);
    try {
      let form = { status: "canceled" };
      let response = await cancelScheduleDismantleById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Batalkan Dismantle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <h5 className="text-gray-800">
          Apakah anda yakin untuk membatalkan proses dismantle customer "
          {data.registration?.registration_number} - {data.registration?.name}"?
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>
          Tidak
        </button>
        <button
          className="btn btn-danger"
          onClick={() => cancelDismantle()}
          disabled={loading || dataLoading}
        >
          {loading ? "Menunggu ..." : "Ya"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
