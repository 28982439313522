import { useState } from "react";
import { useCityRegionHooks } from "../../utils/RegionHooks";
import { useTechnicianListHooks } from "../../utils/TechniciansUtils";
import Pagination from "../Pagination";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../Loading";

export default function ListTechnician({ assigned, add, max = 2 }) {
  let [filter, setFilter] = useState({
    role: "technician",
    name: "",
    page_size: 10,
    page: 1,
    kota_kab: "",
    province: "",
  });
  const regionFilter = useCityRegionHooks();
  let [formFilter, setFormFilter] = useState({ name: "" });

  let technicianData = useTechnicianListHooks({ filter });

  const onChangeProvinceFilter = (value) => {
    regionFilter.setProvince(value);
    regionFilter.setCity("");
  };

  const onSubmitFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
      page: 1,
      kota_kab: regionFilter.city,
      province: regionFilter.province,
    });
  };

  const onResetFilter = () => {
    setFilter({ role: "technician", name: "", page_size: 10, page: 1, kota_kab: "", province: "" });
    regionFilter.resetRegion();
    setFormFilter({ name: "" });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const assignTechnician = (technician) => {
    if (assigned.length < max) {
      add([...assigned, technician]);
    }
  };

  const checkAlreadyAdded = (id) => {
    let already = false;
    assigned.forEach((technician) => {
      if (technician.id === id) {
        already = true;
      }
    });

    return already;
  };

  return (
    <LoadingInsideBoxWrapper>
      {technicianData.loading && <LoadingInsideBox />}
      <div className="mb-4">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="namefilter">Nama</label>
              <input
                type="text"
                className="form-control"
                id="namefilter"
                value={formFilter.name}
                onChange={(event) => setFormFilter({ ...formFilter, name: event.target.value })}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Provinsi</label>
              <select
                className="form-control"
                value={regionFilter.province}
                onChange={(event) => onChangeProvinceFilter(event.target.value)}
              >
                <option value="">-- Semua Provinsi --</option>
                {regionFilter.provinces.map((province, index) => (
                  <option value={province.province} key={index}>
                    {province.province}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Kabupaten</label>
              <select
                className="form-control"
                value={regionFilter.city}
                onChange={(event) => regionFilter.setCity(event.target.value)}
              >
                <option value="">-- Semua Kabupaten --</option>
                {regionFilter.cities.map((city, index) => (
                  <option value={city.kota_kab} key={index}>
                    {city.kota_kab}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-auto">
            <label className="d-block">&nbsp;</label>
            <button className="btn btn-primary" onClick={() => onSubmitFilter()}>
              Filter
            </button>
            <button className="btn btn-default" onClick={() => onResetFilter()}>
              Reset
            </button>
          </div>
        </div>
      </div>
      <table className="table table-striped tabel-hover table-bordered">
        <thead>
          <tr>
            <th>Nama</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {technicianData.technicians.map((technician, index) => (
            <tr key={index}>
              <td>{technician.name}</td>
              <td>
                <ul className="pl-3">
                  {technician.roles?.map((role) => (
                    <li key={role.id}>{role.name}</li>
                  ))}
                </ul>
              </td>
              <td>
                {checkAlreadyAdded(technician.id) ? (
                  <button className="btn btn-sm btn-success" disabled>
                    <i className="fa fa-user-check"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => assignTechnician(technician)}
                  >
                    <i className="fa fa-user-plus"></i>
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {technicianData.meta.count > technicianData.meta.page_size && (
        <Pagination
          totalpage={Math.ceil(technicianData.meta.count / technicianData.meta.page_size)}
          selectedpage={technicianData.meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(technicianData.meta.page + 1)}
          prev={() => gotoPage(technicianData.meta.page - 1)}
        />
      )}
    </LoadingInsideBoxWrapper>
  );
}
