import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import ImageUploader from "../../components/ImageUploader";
import AlertBox from "../../components/AlertBox";
import { useBastScheduleListHooks, useScheduleDismantleHooks } from "../../utils/SchedulesUtil";
import { bastDismantleModem } from "../../api/schedules";
import DropdownSelectHelper from "../../components/technician/DropdownSelectHelper";
import ResponsiveImageUploader from "../../components/ResponsiveImageUploader";
import { ADDITIONAL_DOCS } from "../../tools/BastDictionary";

export default function ReportBastRelocatePages() {
  let [filter, setFilter] = useState({ type: "relocate" });

  let { schedules } = useBastScheduleListHooks({ filter });
  let [number, setNumber] = useState("");
  let [loading, setLoading] = useState(false);
  let {
    data: relocateData,
    getSchedule,
    fetched: relocateDataFetched,
    loading: relocateDataLoading,
    error: relocateDataError,
    resetData: resetrelocateData,
  } = useScheduleDismantleHooks({ number });
  let [form, setForm] = useState({
    material_note: "",
    k3_pre_install: "",
    k3_post_install: "",
    signature: "",
    id_image_url: "",
    modem_image_url: "",
    pillar_image_url: "",
    bast_image_url: "",
    speed_test_image_url: "",
  });
  let [helper, setHelper] = useState(null);

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
    setFilter({ type: "relocate" });
    resetData();
  };

  const resetData = () => {
    // reset data
    resetrelocateData();
    // reset form
    setNumber("");
    setForm({
      material_note: "",
      signature: "",
      id_image_url: "",
      modem_image_url: "",
      pillar_image_url: "",
      bast_image_url: "",
      speed_test_image_url: "",
    });
  };

  let [additionalDocs, setAdditionalDocs] = useState({
    "k3-pre-installation": "",
    "k3-post-installation": "",
    "k3-fat-initial-condition": "",
    "k3-fat-box-before": "",
    "k3-fat-box-after": "",
    "k3-fat-in-damping-check": "",
    "k3-fat-out-damping-check": "",
    "k3-fat-splitter-port-status": "",
    "k3-fat-spare-cable-arrangement": "",
  });

  const sendReport = async (form_data) => {
    setLoading(true);
    try {
      let { data } = await bastDismantleModem(relocateData.id, form_data);
      onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  const onChangeRegNumber = (value) => {
    setNumber(value);
    getSchedule(value);
  };

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url });
  };

  const onChangeAdditionalImg = (type, image_url) => {
    setAdditionalDocs({ ...additionalDocs, [type]: image_url });
  };

  const onSubmitData = (event) => {
    event.preventDefault();

    let formData = {
      material_note: form.material_note,
      helper_id: helper?.value || "",
      attachments: [
        {
          type: "signature",
          url: form.signature,
        },
        {
          type: "modem",
          url: form.modem_image_url,
        },
        {
          type: "pillar",
          url: form.pillar_image_url,
        },
        {
          type: "speed-test",
          url: form.speed_test_image_url,
        },
      ],
    };

    if (relocateData.registration.project?.partner?.code === "ICONNET") {
      let addtionalAttachments = Object.entries(additionalDocs).map(([type, value]) => ({
        type,
        url: value,
      }));

      formData.attachments = [...formData.attachments, ...addtionalAttachments];
    }

    sendReport(formData);
  };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">BAST Relokasi</h1>
        </div>

        {/* Selector */}
        <form>
          <div className="row">
            <div className="col-auto">
              <div className="form-group">
                <label htmlFor="registration_number">Nomor Registrasi</label>
                <select
                  className="form-control"
                  value={number}
                  onChange={(event) => onChangeRegNumber(event.target.value)}
                >
                  <option value="" disabled>
                    Pilih Nomor Registrasi
                  </option>
                  {schedules.map((schedule, index) => (
                    <option value={schedule.id} key={index}>
                      {schedule.registration_number}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </form>

        {/* Card Box */}
        {!relocateDataFetched && (
          <div className="card mb-4 border-left-info">
            <div className="card-body">
              Masukan <b>nomor registrasi</b> terlebih dahulu
            </div>
          </div>
        )}

        {relocateDataLoading && (
          <AlertBox
            show={true}
            message="Memuat data dismantle"
            type="warning"
            setAlert={() => {}}
          />
        )}

        {relocateDataError && (
          <AlertBox show={true} message={relocateDataError} type="danger" setAlert={() => {}} />
        )}

        <div className="row">
          <div className="col">
            <AlertBox {...alert} setAlert={setAlert} />
          </div>
        </div>

        {/* Content */}
        {relocateDataFetched && (
          <div className="row">
            <div className="col-sm-4">
              <Box title="Data Pelanggan">
                <table className="table table-bordered">
                  <tr>
                    <td colSpan={2}>
                      <b>Data Pelanggan</b>
                    </td>
                  </tr>
                  <tr>
                    <td>No. Reg</td>
                    <td>{relocateData.registration?.registration_number || "-"}</td>
                  </tr>
                  <tr>
                    <td>Nama Pelanggan</td>
                    <td>{relocateData.registration?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>Alamat Instalasi</td>
                    <td>
                      {relocateData.registration?.installation_address.address +
                        " RT " +
                        relocateData.registration?.installation_address.rt +
                        "/RW " +
                        relocateData.registration?.installation_address.rw +
                        ", " +
                        relocateData.registration?.installation_address.kelurahan +
                        ", " +
                        relocateData.registration?.installation_address.kecamatan +
                        ", " +
                        relocateData.registration?.installation_address.kota_kab +
                        ", " +
                        relocateData.registration?.installation_address.province +
                        ", " +
                        relocateData.registration?.installation_address.postal_code}
                    </td>
                  </tr>
                  <tr>
                    <td>No. Telp</td>
                    <td>{relocateData.registration?.phone_number || "-"}</td>
                  </tr>
                  <tr>
                    <td>Status Relocate</td>
                    <td>{relocateData.status || "-"}</td>
                  </tr>
                  <tr>
                    <td>Nama Teknisi</td>
                    <td>
                      <ol className="pl-3">
                        {relocateData.technicians?.map((technician, indexY) => (
                          <li key={indexY}>{technician.name}</li>
                        ))}
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b>Data Modem</b>
                    </td>
                  </tr>
                  <tr>
                    <td>SN Modem</td>
                    <td>
                      {relocateData.registration?.inventory_modem_pair?.modem.serial_number_modem ||
                        "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>GPON</td>
                    <td>
                      {relocateData.registration?.inventory_modem_pair?.modem.serial_number_gpon ||
                        "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Framed Pool</td>
                    <td>
                      {relocateData.registration?.inventory_modem_pair?.modem.framed_pool || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>VLAN</td>
                    <td>{relocateData.registration?.inventory_modem_pair?.modem.vlan || "-"}</td>
                  </tr>
                  <tr>
                    <td>ODP</td>
                    <td>{relocateData.registration?.inventory_modem_pair?.modem.odp || "-"}</td>
                  </tr>
                  <tr>
                    <td>ODC</td>
                    <td>{relocateData.registration?.inventory_modem_pair?.modem.odc || "-"}</td>
                  </tr>
                  <tr>
                    <td>OLT</td>
                    <td>{relocateData.registration?.inventory_modem_pair?.modem.olt || "-"}</td>
                  </tr>
                </table>
              </Box>
            </div>
            <div className="col-sm-6">
              <Box title="Form BAST Relokasi">
                <form onSubmit={(event) => onSubmitData(event)}>
                  <div className="form-group">
                    <label>Catatan Material</label>
                    <textarea
                      rows="5"
                      className="form-control"
                      value={form.material_note}
                      onChange={(event) => setForm({ ...form, material_note: event.target.value })}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label>Helper</label>
                    <DropdownSelectHelper
                      value={helper}
                      onChangeValue={(selected) => setHelper(selected)}
                    />
                  </div>
                  {/* Form K3 */}
                  {relocateData.registration.project?.partner?.code === "ICONNET" ? (
                    <div className="py-4">
                      <h4>Dokumen K3</h4>
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group mb-4">
                            <label>{ADDITIONAL_DOCS["k3-pre-installation"]}</label>
                            <ResponsiveImageUploader
                              image={additionalDocs["k3-pre-installation"]}
                              type="k3-pre-installation"
                              onChangeImg={onChangeAdditionalImg}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group mb-4">
                            <label>{ADDITIONAL_DOCS["k3-post-installation"]}</label>
                            <ResponsiveImageUploader
                              image={additionalDocs["k3-post-installation"]}
                              type="k3-post-installation"
                              onChangeImg={onChangeAdditionalImg}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group mb-4">
                            <label>{ADDITIONAL_DOCS["k3-fat-initial-condition"]}</label>
                            <ResponsiveImageUploader
                              image={additionalDocs["k3-fat-initial-condition"]}
                              type="k3-fat-initial-condition"
                              onChangeImg={onChangeAdditionalImg}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group mb-4">
                            <label>{ADDITIONAL_DOCS["k3-fat-box-before"]}</label>
                            <ResponsiveImageUploader
                              image={additionalDocs["k3-fat-box-before"]}
                              type="k3-fat-box-before"
                              onChangeImg={onChangeAdditionalImg}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group mb-4">
                            <label>{ADDITIONAL_DOCS["k3-fat-box-after"]}</label>
                            <ResponsiveImageUploader
                              image={additionalDocs["k3-fat-box-after"]}
                              type="k3-fat-box-after"
                              onChangeImg={onChangeAdditionalImg}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group mb-4">
                            <label>{ADDITIONAL_DOCS["k3-fat-in-damping-check"]}</label>
                            <ResponsiveImageUploader
                              image={additionalDocs["k3-fat-in-damping-check"]}
                              type="k3-fat-in-damping-check"
                              onChangeImg={onChangeAdditionalImg}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group mb-4">
                            <label>{ADDITIONAL_DOCS["k3-fat-out-damping-check"]}</label>
                            <ResponsiveImageUploader
                              image={additionalDocs["k3-fat-out-damping-check"]}
                              type="k3-fat-out-damping-check"
                              onChangeImg={onChangeAdditionalImg}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group mb-4">
                            <label>{ADDITIONAL_DOCS["k3-fat-splitter-port-status"]}</label>
                            <ResponsiveImageUploader
                              image={additionalDocs["k3-fat-splitter-port-status"]}
                              type="k3-fat-splitter-port-status"
                              onChangeImg={onChangeAdditionalImg}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group mb-4">
                            <label>{ADDITIONAL_DOCS["k3-fat-spare-cable-arrangement"]}</label>
                            <ResponsiveImageUploader
                              image={additionalDocs["k3-fat-spare-cable-arrangement"]}
                              type="k3-fat-spare-cable-arrangement"
                              onChangeImg={onChangeAdditionalImg}
                              required={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="py-4">
                    <h4>Dokumen Pendukung</h4>
                  </div>
                  <div className="form-group mb-4">
                    <label>Foto Lokasi Modem</label>
                    <ImageUploader
                      image={form.modem_image_url}
                      type="modem_image_url"
                      onChangeImg={onChangeImg}
                      required={true}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>Foto Tiang Jaringan</label>
                    <ImageUploader
                      image={form.pillar_image_url}
                      type="pillar_image_url"
                      onChangeImg={onChangeImg}
                      required={true}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>Hasil Uji Kecepatan Internet</label>
                    <ImageUploader
                      image={form.speed_test_image_url}
                      type="speed_test_image_url"
                      onChangeImg={onChangeImg}
                      required={true}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>Tanda Tangan Pelanggan</label>
                    <ImageUploader
                      image={form.signature}
                      type="signature"
                      onChangeImg={onChangeImg}
                      required={true}
                    />
                  </div>
                  <button className="btn btn-primary" type="submit">
                    {loading ? "Menyimpan ... " : "Simpan BAST Relokasi"}
                  </button>
                </form>
              </Box>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
}
