import { useState } from "react";
import { Modal } from "react-bootstrap";
import Box from "../../components/Box";
import AlertBox from "../../components/AlertBox";
import MainLayout from "../../components/layouts/MainLayout";
import Pagination from "../../components/Pagination";
import { useInventoryMasterHooks } from "../../utils/inventory/InventoryMasterUtils";
import { useInventoryCategories } from "../../utils/InventoryHooks";
import { createInventoryMaster } from "../../api/inventory";
import ModalEditInventory from "../../components/inventory/ModalEditInventory";
import ModalDeleteInventory from "../../components/inventory/ModalDeleteInventory";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function InventoryMasterPage() {
  let categories = useInventoryCategories({ page: 1, page_size: 99 });
  let { hasAccess } = useControlPermission();

  let [formFilter, setFormFilter] = useState({ q: "", inventory_category_id: "" });
  let [filter, setFilter] = useState({ q: "", inventory_category_id: "", page: 1, page_size: 10 });
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [modal, setModal] = useState(null);
  let [selectedId, setSelectedId] = useState(null);
  let { data, loading, meta, error } = useInventoryMasterHooks({ filter });

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    setFilter({ ...filter });
  };

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ q: "", inventory_category_id: "" });
    setFilter({ q: "", inventory_category_id: "", page: 1, page_size: 10 });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Inventory Master</h1>
          </div>
          <div className="col-auto">
            {hasAccess("create-inventory-master") && (
              <button className="btn btn-primary" onClick={() => setModal("add-inventory")}>
                <i className="fa fa-plus"></i> Tambah Inventory
              </button>
            )}
          </div>
        </div>
        <Box title="Inventory Master" loading={loading}>
          <div className="row mb-4">
            <div className="col-auto">
              <label htmlFor="inventory_name">Pencarian</label>
              <input
                type="text"
                name="inventory_q"
                value={formFilter.q}
                onChange={(event) => changeFilter("q", event.target.value)}
                className="form-control"
                id="inventory_q"
                placeholder="SKU / Nama .."
              />
            </div>
            <div className="col-auto">
              <label htmlFor="category">Kategori</label>
              <select
                type="text"
                name="category"
                value={formFilter.inventory_category_id}
                onChange={(event) => changeFilter("inventory_category_id", event.target.value)}
                className="form-control"
                id="category"
              >
                <option value="">-- Pilih Kategori --</option>
                {categories.categories.map((category, index) => (
                  <option value={category.id} key={index}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />
          {!!error && <div className="alert alert-danger">{error}</div>}
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Nama</th>
                <th>Unit</th>
                <th>Harga</th>
                <th>Kategori</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 && (
                <tr>
                  <td colSpan={6} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
              {data.map((inventory) => (
                <tr key={inventory.id}>
                  <td>{inventory.sku}</td>
                  <td>{inventory.name}</td>
                  <td>{inventory.unit}</td>
                  <td>{inventory.price}</td>
                  <td>{inventory.category.name}</td>
                  <td>
                    {hasAccess("update-inventory-master") && (
                      <button
                        className="btn btn-sm btn-block btn-info"
                        onClick={() => {
                          setSelectedId(inventory.id);
                          setModal("edit-inventory");
                        }}
                      >
                        <i className="fa fa-pencil"></i> Edit
                      </button>
                    )}
                    {hasAccess("delete-inventory-master") && (
                      <button
                        className="btn btn-sm btn-block btn-danger"
                        onClick={() => {
                          setSelectedId(inventory.id);
                          setModal("delete-inventory");
                        }}
                      >
                        <i className="fa fa-trash"></i> Hapus
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {meta.count > meta.page_size && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.page_size)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalAddInventory
        show={modal === "add-inventory"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
      <ModalEditInventory
        show={modal === "edit-inventory"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
        id={modal === "edit-inventory" ? selectedId : ""}
      />
      <ModalDeleteInventory
        show={modal === "delete-inventory"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
        id={modal === "delete-inventory" ? selectedId : ""}
      />
    </MainLayout>
  );
}

function ModalAddInventory({ show, onClose, onSuccess }) {
  let [form, setForm] = useState({
    name: "",
    sku: "",
    unit: "",
    price: "",
    inventory_category_id: "",
  });
  let categories = useInventoryCategories({ page: 1, page_size: 99 });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({ name: "", sku: "", unit: "", price: "", inventory_category_id: "" });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
    };
    submitCategory(formData);
  };

  const submitCategory = async (form) => {
    setLoading(true);
    try {
      let response = await createInventoryMaster(form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Inventory Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Nama Inventory <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              SKU Inventory <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.sku}
              onChange={(event) => setForm({ ...form, sku: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Unit <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.unit}
              onChange={(event) => setForm({ ...form, unit: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Harga <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.price}
              onChange={(event) => setForm({ ...form, price: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Kategori <span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              required
              value={form.inventory_category_id}
              onChange={(event) => setForm({ ...form, inventory_category_id: event.target.value })}
            >
              <option value="">-- Pilih Kategori --</option>
              {categories.categories.map((category, index) => (
                <option value={category.id} key={index}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? (
              <>Harap menunggu ...</>
            ) : (
              <>
                <i className="fas fa-plus"></i> Buat Inventory Baru
              </>
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
