import { apiAuth, apiDownloadAuth } from "./base";

export function updateRegistrationById(id, data) {
  let api = apiAuth();
  return api.put(`v1/registrations/${id}`, data);
}

export function getAllCustomers(params) {
  let api = apiAuth();
  return api.get('v1/customers', { params });
}

export function getCustomerById(id) {
  let api = apiAuth();
  return api.get(`v1/customers/${id}`);
}

export function updateCustomerById(id, data) {
  let api = apiAuth();
  return api.put(`v1/customers/${id}`, data);
}

export function getAllTypes(params) {
  let api = apiAuth();
  return api.get('v1/customers/types', { params });
}

export function createType(data) {
  let api = apiAuth();
  return api.post('v1/customers/types', data);
}

export function getTypeById(id) {
  let api = apiAuth();
  return api.get(`v1/customers/types/${id}`);
}

export function updateTypeById(id, data) {
  let api = apiAuth();
  return api.put(`v1/customers/types/${id}`, data);
}

export function deleteTypeById(id) {
  let api = apiAuth();
  return api.delete(`v1/customers/types/${id}`);
}

export function getAllStatuses(params) {
  let api = apiAuth();
  return api.get('v1/customers/status', { params });
}

export function createStatus(data) {
  let api = apiAuth();
  return api.post('v1/customers/status', data);
}

export function getStatusById(id) {
  let api = apiAuth();
  return api.get(`v1/customers/status/${id}`);
}

export function updateStatusById(id, data) {
  let api = apiAuth();
  return api.put(`v1/customers/status/${id}`, data);
}

export function deleteStatusById(id) {
  let api = apiAuth();
  return api.delete(`v1/customers/status/${id}`);
}

export function updatePackageCustomerById(id, data) {
  let api = apiAuth();
  return api.put(`v1/customers/${id}/internet-package`, data);
}

export function updateDueDateCustomerById(id, data) {
  let api = apiAuth();
  return api.put(`v1/customers/${id}/due-date`, data);
}

export function getAllRequestChangePackage(params) {
  let api = apiAuth();
  return api.get('v1/customers/change-package-request', { params });
}

export function generateBillingById(id, data) {
  let api = apiAuth();
  return api.post(`v1/customers/${id}/billing`, data);
}

export function downloadCustomer(params = {}) {
  let api =  apiDownloadAuth();
  return api.get("v1/customers/export/xlsx", {params});
}

export function requestDismantleByCustomerId(id, data) {
  let api = apiAuth();
  return api.post(`v1/customers/${id}/dismantle`, data);
}

export function requestRelocateByCustomerId(id, data) {
  let api = apiAuth();
  return api.post(`v1/customers/${id}/relocate`, data);
}

export function getCustomerLogById(id, params) {
  let api = apiAuth();
  return api.get(`v1/customers/${id}/logs`, { params });
}

export function withdrawDepositByCustomerId(id, data) {
  let api = apiAuth();
  return api.post(`v1/customers/${id}/deposit-withdrawal`, data);
}

export function getWithdrawInfoByCustomerId(id) {
  let api = apiAuth();
  return api.get(`v1/customers/${id}/deposit`);
}

export function updateWithdrawByCustomerId(id, data) {
  let api = apiAuth();
  return api.put(`v1/customers/${id}/deposit-withdrawal`, data);
}

export function restartCustomerModem(id) {
  let api = apiAuth();
  return api.post(`v1/customers/${id}/restart-modem`);
}

export function checkCustomerModem(id) {
  let api = apiAuth();
  return api.get(`v1/customers/${id}/modem-info`);
}

export function activateCustomerModem(id) {
  let api = apiAuth();
  return api.post(`v1/customers/${id}/activation`);
}

export function getPresetActivityCodes() {
  let api = apiAuth();
  return api.get(`v1/presets/customers/activity-codes`);
}

export function getPresetActivityTypes() {
  let api = apiAuth();
  return api.get(`v1/presets/customers/activity-types`);
}

export function getAllCustomerActivities(params) {
  let api = apiAuth();
  return api.get(`v1/customers/activities`, { params });
}

export function getActivitiesByCustomerId(id, params) {
  let api = apiAuth();
  return api.get(`v1/customers/${id}/activities`, { params });
}

export function getBillingsByCustomerId(id, params) {
  let api = apiAuth();
  return api.get(`v1/customers/${id}/billings`, { params });
}

export function getTicketsByCustomerId(id, params) {
  let api = apiAuth();
  return api.get(`v1/customers/${id}/tickets`, { params });
}

export function createActivityByCustomerId(id, data) {
  let api = apiAuth();
  return api.post(`v1/customers/${id}/activities`, data);
}

export function getSingleActivityByCustomerId(id, customer_id, params) {
  let api = apiAuth();
  return api.get(`v1/customers/${customer_id}/activities/${id}`, { params });
}

export function updateSingleActivityByCustomerId(id, customer_id, data) {
  let api = apiAuth();
  return api.put(`v1/customers/${customer_id}/activities/${id}`, data)
}

export function downloadActivitiesCustomer(params = {}) {
  let api =  apiDownloadAuth();
  return api.get("v1/customers/activities/export/xlsx", {params});
}