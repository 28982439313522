import { useState } from "react";
import { Modal } from "react-bootstrap";
import { downloadHistoryTechnician } from "../../api/technician";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import AlertBox from "../AlertBox";

export default function ModalDownloadHistoryTechnician({ show, onClose, onSuccess }) {
  const [formFilter, setFormFilter] = useState({
    name: "",
    date_from: "",
    date_until: "",
    referral_name: "",
    project: "",
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const { projects } = useGroupListHooks();

  const handleClose = () => {
    resetFilter();
    onClose();
  };

  const resetFilter = () => {
    setFormFilter({
      status: "",
      type: "",
      date_from: "",
      date_until: "",
      referral_name: "",
      project: "",
    });
  };

  const downloadFile = async () => {
    setLoading(true);

    try {
      let response = await downloadHistoryTechnician({ ...formFilter });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Riwayat Pemasangan Teknisi.xlsx");
      onSuccess("Berhasil mengunduh Data Riwayat Pemasangan Teknisi.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Download Data Riwayat Pemasangan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />

        <form>
          <div className="form-group">
            <label>Nama Teknisi</label>
            <input
              type="text"
              className="form-control"
              value={formFilter.name}
              onChange={(event) => setFormFilter({ ...formFilter, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Tanggal Mulai</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.date_from}
              onChange={(event) => setFormFilter({ ...formFilter, date_from: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Tanggal Sampai</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.date_until}
              onChange={(event) => setFormFilter({ ...formFilter, date_until: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Nama Sales</label>
            <input
              type="text"
              className="form-control"
              value={formFilter.referral_name}
              onChange={(event) =>
                setFormFilter({ ...formFilter, referral_name: event.target.value })
              }
            />
          </div>
          <label htmlFor="project_group">Kelompok/Project</label>
          <select
            className="input form-control"
            id="project_group"
            value={formFilter.project}
            onChange={(event) => setFormFilter({ ...formFilter, project: event.target.value })}
          >
            <option value="">-- Kelompok/Project --</option>
            {projects.map((project, index) => (
              <option key={index} value={project.id}>
                {project.name}
              </option>
            ))}
          </select>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => downloadFile()} disabled={loading}>
          <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
