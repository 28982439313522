export const convertToRupiah = (angka) => {
  var rupiah = '';		
  var angkarev = angka.toString().split('').reverse().join('');
  for(var i = 0; i < angkarev.length; i++) if(i%3 === 0) rupiah += angkarev.substr(i,3)+'.';
  return rupiah.split('',rupiah.length-1).reverse().join('');
}

export const parseToIndoNumber = (angka) => {
  var bilangan = '';		
  var angkarev = angka.toString().split('').reverse().join('');
  for(var i = 0; i < angkarev.length; i++) if(i%3 === 0) bilangan += angkarev.substr(i,3)+'.';
  return bilangan.split('',bilangan.length-1).reverse().join('');
}

export function convertDecimalToComma(percentStr) {
  // Remove any spaces and ensure we're working with a string
  const cleaned = String(percentStr).trim();
  
  // Replace decimal point with comma
  return cleaned.replace('.', ',');
}