import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import NotFoundPage from "./pages/NotFound";
import { router } from "./router"
import { ProvideControlPermission } from "./utils/ActionPermissionUtils";
import { ProvideAuth } from "./utils/AuthHooks";
import { ProvidePermittedMenu } from "./utils/DynamicMenuUtils";

function App() {
  return (
    <ProvideAuth>
      <ProvidePermittedMenu>
        <ProvideControlPermission>
          <Router>
            <Switch>
              {router.map((route, index) => (
                <Route exact path={route.path} component={route.component} key={index} />
              ))}
              <Route exact path="/">
                {!localStorage.getItem('token') ? <Redirect to='/login' /> : <Redirect to='/home' />}
              </Route>
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </ProvideControlPermission>
      </ProvidePermittedMenu>
    </ProvideAuth>
  );
}

export default App;
