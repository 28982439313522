import { useDashboardHooks } from "../../utils/DashboardHooks";

const IconColorStatus = {
  'ACCEPTED': {
    color: 'info',
    icon: 'fa-user-check'
  },
  'PROCESSED': {
    color: 'info',
    icon: 'fa-tasks'
  },
  'REJECTED': {
    color: 'danger',
    icon: 'fa-users-slash'
  },
  'WAITING_PAYMENT': {
    color: 'warning',
    icon: 'fa-file-invoice-dollar'
  },
  'PAYMENT_SUCCESS': {
    color: 'success',
    icon: 'fa-money-bill-wave'
  },
  'WAITING_INSTALLATION': {
    color: 'primary',
    icon: 'fa-calendar-day'
  },
  'INSTALLATION_COMPLETE': {
    color: 'success',
    icon: 'fa-check-double'
  },
  'CANCELED': {
    color: 'danger',
    icon: 'fa-user-slash'
  },
  'DISMANTLED': {
    color: 'danger',
    icon: 'fa-rectangle-xmark'
  },
}

export default function RegistrationStat() {
  const { statistics } = useDashboardHooks();

  return (
    <div className="row">
      {statistics.map((stat, index) => (
        <div className="col-xl-3 col-md-6 mb-4" key={index}>
          <div className={`card border-left-${IconColorStatus[stat.status.name].color} shadow h-100 py-2`}>
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className={`text-xs font-weight-bold text-${IconColorStatus[stat.status.name].color}  text-uppercase mb-1`}>
                    {stat.status.display_name}</div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{stat.status_count}</div>
                </div>
                <div className="col-auto">
                  <i className={`fas ${IconColorStatus[stat.status.name].icon} fa-2x text-gray-300`}></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}