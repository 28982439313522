import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateTaskById } from "../../api/projects";
import { useTaskHooks } from "../../utils/project/VendorProjectUtils";
import AlertBox from "../AlertBox";

export default function ModalTaskUpdate({ show, id, onClose, onSuccess }) {
  let [alert, setAlert] = useState({show: false, message: '', type: ''});
  let [form, setForm] = useState({name: '', qty: 0});
  let [loading, setLoading] = useState(false);

  const taskData = useTaskHooks({ id });
  useEffect(() => {
    if (taskData.fetched) {
      setForm({
        name: taskData.data.name,
        qty: taskData.data.qty,
      })
    }
  }, [taskData.data.name, taskData.data.qty, taskData.fetched])
  const handleClose = () => {
    resetForm();
    onClose();
  }

  const resetForm = () => {
    setForm({name: '', qty: 0});
    setAlert({show: false, message: '', type: ''});
  }

  const updateProgress = async (formData)  => {
    setLoading(true);
    try {
      let response = await updateTaskById(id, formData);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }
    setLoading(false);
  }

  const onSubmitData = (event) => {
    event.preventDefault();
    updateProgress(form)
  }


  return(
    <Modal show={show} onHide={() => handleClose()} size="xs">
      <form onSubmit={event => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Detail Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label>Nama Pekerjaan</label>
            <input type="text" required className="form-control" value={form.name} onChange={event => setForm({ ...form, name: event.target.value})} />
          </div>
          <div className="form-group">
            <label>Quantity</label>
            <input type="number" required min={1} className="form-control" value={form.qty} onChange={event => setForm({ ...form, qty: event.target.value})} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">Close</button>
          <button className="btn btn-primary" disabled={loading}>{loading ? "Menyimpan ..." : "Simpan Perubahan"}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}