import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useState, useEffect } from "react";
import { useAuditLogs } from "../../utils/LogUtilHooks";
import moment from "moment";
import Pagination from "../../components/Pagination";
import { LOG_TYPE } from "../../tools/audittools";

export default function AuditLogsPage() {
  let [formFilter, setFormFilter] = useState({ action_type: "", q: "" });
  let [filter, setFilter] = useState({ action_type: "", q: "", page: 1, page_size: 30 });
  let { logs, loading, meta, getLogs } = useAuditLogs();

  useEffect(() => {
    getLogs(filter);
  }, [filter]);

  function applyFilter() {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  }

  const resetFilter = () => {
    setFormFilter({ action_type: "", q: "" });
    setFilter({ action_type: "", q: "", page: 1, page_size: 30 });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Audit Log</h1>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title="Log Sistem" loading={loading}>
              <div className="row mb-4">
                <div className="col-12 col-md-2">
                  <label>Pencarian</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formFilter.q}
                    onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
                    placeholder="Nama akun/Request ..."
                  />
                </div>
                <div className="col-12 col-md-2">
                  <label>Tipe</label>
                  <select
                    className="form-control"
                    value={formFilter.action_type}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, action_type: event.target.value })
                    }
                  >
                    <option value="">ALL</option>
                    {Object.keys(LOG_TYPE).map((key) => (
                      <option value={key} key={key}>
                        {LOG_TYPE[key]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-primary"
                    onClick={() => applyFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => resetFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div style={{ overflowX: "auto", marginBottom: "10px" }}>
                <table className="table table-sm table-striped table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>Waktu</th>
                      <th>Aksi</th>
                      <th>Pengubah</th>
                      <th>Role</th>
                      <th>Log</th>
                      <th>Change Log</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.length === 0 && (
                      <tr>
                        <td colSpan={6} className="text-center">
                          Tidak ada data
                        </td>
                      </tr>
                    )}
                    {logs.map((sys_log, index) => (
                      <tr key={index}>
                        <td className="text-nowrap">
                          {moment.unix(sys_log.createdAt).format("DD MMM YYYY - HH:mm:ss")}
                        </td>
                        <td>{LOG_TYPE[sys_log.action_type] || sys_log.action_type}</td>
                        <td className="text-nowrap">{sys_log.user?.name || "-"}</td>
                        <td>
                          {sys_log.user?.roles?.length === 0 && "-"}
                          <ul className="px-3">
                            {sys_log.user?.roles?.map((role, index) => (
                              <li key={index}>{role.name}</li>
                            ))}
                          </ul>
                        </td>
                        <td className="text-wrap">{sys_log.request}</td>
                        <td className="text-wrap">{sys_log.change_log}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {meta.count > meta.page_size && (
                <Pagination
                  totalpage={Math.ceil(meta.count / meta.page_size)}
                  selectedpage={meta.page}
                  clickpage={(page) => gotoPage(page)}
                  next={() => gotoPage(meta.page + 1)}
                  prev={() => gotoPage(meta.page - 1)}
                />
              )}
            </Box>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
