import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { addProjectToWarehouseById, removeProjectToWarehouseById } from "../../../api/inventory";
import { useWarehouseHooks } from "../../../utils/InventoryHooks";
import { useGroupListHooks } from "../../../utils/ProjectsUtilHooks";
import AlertBox from "../../AlertBox";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../../Loading";

export default function ModalProjectWarehouse({ show, id, onClose }) {
  let { data, getWarehouse, loading: loadingDetailWarehouse, error } = useWarehouseHooks({ id });
  let [loading, setLoading] = useState(false);
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [projectOpt, setProjectOpt] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  const projects = useGroupListHooks();

  useEffect(() => {
    let projectsTemp = [];

    projects.projects.forEach((project) => {
      let temp = {
        value: project.id,
        label: project.name,
      };
      projectsTemp.push(temp);

      return true;
    });

    setProjectOpt(projectsTemp);
  }, [projects.projects]);

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const handleChangeProject = (selected) => {
    setSelectedProject(selected);
  };

  const onSuccessUpdate = () => {
    setSelectedProject(null);
    getWarehouse(id);
  };

  const addProject = async (event) => {
    event.preventDefault();
    setLoading(true);
    let formData = {
      project_id: selectedProject?.value || "",
    };

    try {
      let { data } = await addProjectToWarehouseById(id, formData);
      setAlert({ show: true, message: data.message, type: "success" });
      onSuccessUpdate();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message: message, type: "danger" });
    }

    setLoading(false);
  };

  const removeProject = async (project_id) => {
    setLoading(true);
    let formData = {
      project_id,
    };
    try {
      let { data } = await removeProjectToWarehouseById(id, formData);
      setAlert({ show: true, message: data.message, type: "success" });
      onSuccessUpdate();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Kelola Project untuk {data.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoadingInsideBoxWrapper>
          <AlertBox {...alert} setAlert={setAlert} />
          {error && <div className="alert alert-danger">{error}</div>}
          {(loading || loadingDetailWarehouse) && <LoadingInsideBox />}
          <table className="table table-bordered">
            <tbody>
              {(!data.projects || data.projects?.length === 0) && (
                <tr>
                  <td colSpan={2} className="text-center">
                    Tidak ada Grup/Project
                  </td>
                </tr>
              )}
              {data.projects?.map((project, index) => (
                <tr key={index}>
                  <td>{project.name}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => removeProject(project.id)}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <form onSubmit={(event) => addProject(event)}>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="project">Kelompok/Project</label>
                  <Select
                    value={selectedProject}
                    isClearable
                    placeholder="Kelompok / Project"
                    required
                    onChange={(selected) => handleChangeProject(selected)}
                    options={projectOpt}
                  />
                </div>
              </div>
              <div className="col-auto" style={{ paddingTop: "32px" }}>
                <button className="btn btn-primary" disabled={selectedProject === null}>
                  Tambah
                </button>
              </div>
            </div>
          </form>
        </LoadingInsideBoxWrapper>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
