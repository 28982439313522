import { useState, useEffect } from "react";
import { useUnsavedModemListHooks } from "../../../utils/InventoryHooks";
import Box from "../../Box";
import Pagination from "../../Pagination";

export default function BoxUnsavedModem() {
  const [formFilter, setFormFilter] = useState({ name: "", registration_number: "" });
  const [filter, setFilter] = useState({
    name: "",
    registration_number: "",
    page: 1,
    page_size: 10,
  });

  const modemlist = useUnsavedModemListHooks({});

  useEffect(() => {
    modemlist.fetchUnsavedModem(filter);
  }, [filter]);

  const applyFilter = () => {
    setFilter({ ...filter, ...formFilter, page: 1 });
  };

  const resetFilter = () => {
    setFormFilter({ name: "", registration_number: "" });
    setFilter({ name: "", registration_number: "", page: 1, page_size: 10 });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <Box title="Modem yang belum tersetup" loading={modemlist.loading}>
      <div className="row mb-4">
        <div className="col-sm-3">
          <label>No. Reg</label>
          <input
            type="text"
            className="form-control"
            value={formFilter.registration_number}
            onChange={(event) =>
              setFormFilter({ ...formFilter, registration_number: event.target.value })
            }
          />
        </div>
        <div className="col-sm-3">
          <label>Nama</label>
          <input
            type="text"
            className="form-control"
            value={formFilter.name}
            onChange={(event) => setFormFilter({ ...formFilter, name: event.target.value })}
          />
        </div>
        <div className="col-sm-3">
          <button
            className="btn btn-primary"
            style={{ marginTop: "32px" }}
            onClick={() => applyFilter()}
          >
            Filter
          </button>
          <button className="btn" style={{ marginTop: "32px" }} onClick={() => resetFilter()}>
            Reset
          </button>
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>No. Reg</th>
              <th>Nama</th>
              <th>Paket</th>
            </tr>
          </thead>
          <tbody>
            {modemlist.modems.length === 0 && (
              <tr>
                <td colSpan={4} className="text-center">
                  Tidak ada data
                </td>
              </tr>
            )}
            {modemlist.modems.map((modem, index) => (
              <tr key={index}>
                <td>{modem.registration_number}</td>
                <td>{modem.name}</td>
                <td>
                  {modem.internet_package?.name} (
                  {modem.internet_package?.internet_package_varian.amount}{" "}
                  {modem.internet_package?.internet_package_varian.unit})
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {modemlist.meta.count > modemlist.meta.page_size && (
        <Pagination
          totalpage={Math.ceil(modemlist.meta.count / modemlist.meta.page_size)}
          selectedpage={modemlist.meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(modemlist.meta.page + 1)}
          prev={() => gotoPage(modemlist.meta.page - 1)}
        />
      )}
    </Box>
  );
}
