import moment from "moment";
import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import MainLayout from "../../components/layouts/MainLayout";
import { ModalCheckCoverageRelocate } from "../../components/schedule/ModalCheckCoverageRelocate";
import ModalDetailRelocate from "../../components/schedule/ModalDetailRelocate";
import ModalNoteSchedule from "../../components/schedule/ModalNoteSchedule";
import ModalAssignRelocate from "../../components/technician/ModalAssignRelocate";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import { useScheduleListHooks } from "../../utils/SchedulesUtil";

export default function RelocateSchedule() {
  let { hasAccess } = useControlPermission();

  let [formFilter, setFormFilter] = useState({
    status: "",
    n: "",
    project_id: "",
    date_from: "",
    date_until: "",
  });
  let [filter, setFilter] = useState({
    type: "relocate",
    status: "",
    n: "",
    project_id: "",
    date_from: "",
    date_until: "",
    page: 1,
    page_size: 10,
  });

  let schedulesData = useScheduleListHooks({ filter });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [selectedId, setSelectedId] = useState("");
  let [showModalDetail, setShowModalDetail] = useState(false);
  let [showModalAssign, setShowModalAssign] = useState(false);
  let [showModalCheck, setShowModalCheck] = useState(false);
  let [showModalNote, setShowModalNote] = useState(false);

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
    schedulesData.getSchedules(filter);
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(!showModalDetail);
  };

  const toggleModalAssign = (id) => {
    setSelectedId(id);
    setShowModalAssign(!showModalAssign);
  };

  const toggleModalCheck = (id) => {
    setSelectedId(id);
    setShowModalCheck(!showModalCheck);
  };

  const toggleModalNote = (id) => {
    setSelectedId(id);
    setShowModalNote(!showModalNote);
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const applyFilter = () => {
    setFilter({ ...filter, ...formFilter, page: 1 });
  };

  const resetFilter = () => {
    setFormFilter({ status: "", n: "", date_from: "", date_until: "" });
    setFilter({
      ...filter,
      status: "",
      n: "",
      date_from: "",
      date_until: "",
      page: 1,
      page_size: 10,
    });
  };

  return (
    <MainLayout resource_key="schedule">
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Penjadwalan Relokasi</h1>
        </div>

        <Box
          title={`Jadwal Relokasi (Total: ${schedulesData.meta.count})`}
          loading={schedulesData.loading}
        >
          <div className="row mb-4">
            <div className="col-12 col-md-2">
              <label>Status</label>
              <select
                className="form-control"
                value={formFilter.status}
                onChange={(event) => changeFilter("status", event.target.value)}
              >
                <option value="">SEMUA</option>
                <option value="CHECK-COVERAGE">CHECK COVERAGE</option>
                <option value="WAITING-PAYMENT">WAITING PAYMENT</option>
                <option value="NOT-COVERAGE">NOT COVERAGE</option>
                <option value="WAITING-NOC">WAITING NOC</option>
                <option value="NOC-DONE">NOC DONE</option>
                <option value="DONE">DONE</option>
              </select>
            </div>
            <div className="col-12 col-md-2">
              <label>Pencarian</label>
              <input
                type="text"
                className="form-control"
                value={formFilter.n}
                onChange={(event) => changeFilter("n", event.target.value)}
                placeholder="No. Registrasi / Nama / No. Penguasan"
              />
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label htmlFor="installation_date_until">Project/Site</label>
                <ProjectFilterForm
                  value={formFilter.project_id}
                  onChangeForm={(value) => changeFilter("project_id", value)}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <label>Tanggal Dari</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.date_from}
                onChange={(event) => changeFilter("date_from", event.target.value)}
              />
            </div>
            <div className="col-12 col-md-2">
              <label>Tanggal Sampai</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.date_until}
                onChange={(event) => changeFilter("date_until", event.target.value)}
              />
            </div>
            <div className="col-12 col-md">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
          </div>
          <AlertBox {...alert} setAlert={setAlert} />
          {schedulesData.errorMsg && (
            <div className="alert alert-danger">{schedulesData.errorMsg}</div>
          )}
          <div style={{ overflowX: "auto" }}>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>No Reg</th>
                  <th>No Penugasan</th>
                  <th>Status</th>
                  <th>Jadwal</th>
                  <th>Nama Pelanggan</th>
                  <th>Alamat Relokasi</th>
                  <th>Project</th>
                  <th>Teknisi</th>
                </tr>
              </thead>
              <tbody>
                {schedulesData.schedules.length === 0 && (
                  <tr>
                    <td colSpan={9} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {schedulesData.schedules.map((relocateData, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        className="btn btn-sm btn-block btn-info mr-1 text-nowrap"
                        onClick={() => toggleModalDetail(relocateData.id)}
                      >
                        <i className="fa fa-eye"></i> Detail
                      </button>

                      {hasAccess("check-coverage-schedule") &&
                        relocateData.status === "CHECK-COVERAGE" && (
                          <button
                            className="btn btn-sm btn-block btn-warning mr-1 text-nowrap"
                            onClick={() => toggleModalCheck(relocateData.id)}
                          >
                            <i className="fas fa-search-location"></i> Pengecekan Coverage
                          </button>
                        )}

                      {hasAccess("assign-technician-schedule") &&
                        relocateData.status !== "DONE" &&
                        relocateData.status !== "NOT-COVERAGE" && (
                          <button
                            className="btn btn-sm btn-block btn-primary mr-1 text-nowrap"
                            onClick={() => toggleModalAssign(relocateData.id)}
                          >
                            <i className="fa fa-user-cog"></i> Penugasan
                          </button>
                        )}

                      {hasAccess("update-schedule") &&
                        relocateData.status !== "DONE" &&
                        relocateData.status !== "NOT-COVERAGE" && (
                          <button
                            className="btn btn-sm btn-block btn-custom-orange mr-1 text-nowrap"
                            onClick={() => toggleModalNote(relocateData.id)}
                          >
                            <i className="fa fa-solid fa-note-sticky"></i> Catatan Teknisi
                          </button>
                        )}
                    </td>
                    <td>{relocateData.registration.registration_number}</td>
                    <td>{relocateData.installation_schedule_number}</td>
                    <td>{relocateData.status}</td>
                    <td>
                      {relocateData.schedule &&
                        moment(relocateData.schedule).format("DD MMM YYYY - HH:mm")}
                    </td>
                    <td>{relocateData.registration.name}</td>
                    <td>
                      {relocateData.address +
                        " RT " +
                        relocateData.rt +
                        "/RW " +
                        relocateData.rw +
                        ", " +
                        relocateData.addresses.kelurahan +
                        ", " +
                        relocateData.addresses.kecamatan +
                        ", " +
                        relocateData.addresses.kota_kab +
                        ", " +
                        relocateData.addresses.province +
                        ", " +
                        relocateData.addresses.postal_code}
                    </td>
                    <td>{relocateData.registration?.project?.name || "-"}</td>
                    <td>
                      <ol className="pl-3">
                        {relocateData.technicians?.map((technician, indexY) => (
                          <li key={indexY}>{technician.name}</li>
                        ))}
                      </ol>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {schedulesData.meta.count > schedulesData.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(schedulesData.meta.count / schedulesData.meta.page_size)}
              selectedpage={schedulesData.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(schedulesData.meta.page + 1)}
              prev={() => gotoPage(schedulesData.meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalDetailRelocate
        show={showModalDetail}
        id={showModalDetail ? selectedId : null}
        setShow={toggleModalDetail}
        onSuccess={onSuccess}
      />
      <ModalAssignRelocate
        show={showModalAssign}
        id={showModalAssign ? selectedId : null}
        setShow={toggleModalAssign}
        onSuccess={onSuccess}
      />
      <ModalCheckCoverageRelocate
        show={showModalCheck}
        id={showModalCheck ? selectedId : null}
        setShow={toggleModalCheck}
        onSuccess={onSuccess}
      />
      <ModalNoteSchedule
        show={showModalNote}
        id={showModalNote ? selectedId : null}
        setShow={toggleModalNote}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function ProjectFilterForm({ value, onChangeForm }) {
  const { projects } = useGroupListHooks();
  return (
    <select
      className="form-control"
      id="projectFilter"
      value={value}
      onChange={(event) => onChangeForm(event.target.value)}
    >
      <option value="">SEMUA</option>
      {projects.map((project, index) => (
        <option value={project.id} key={index}>
          {project.name}
        </option>
      ))}
    </select>
  );
}
