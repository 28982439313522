import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { createVendorTask } from "../../api/projects";
import AlertBox from "../AlertBox";
import { RequiredAsterisk } from "../FormHelper";

export default function ModalTaskCreate({ show, onClose, onSuccess }) {
  // using UUID project-vendor
  const { id } = useParams();

  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  const [form, setForm] = useState({name: '', qty: '', done: ''});
  const handleClose = () => {
    resetForm();
    onClose();
  }

  const resetForm = () => {
    setForm({name: '', qty: '', done: ''})
    setAlert({show: false, message: '', type: ''});
  }

  const sendDataTask = async (formData)  => {
    try {
      let response = await createVendorTask(id, formData);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }
  }

  const onSubmitData = (event) => {
    event.preventDefault();
    sendDataTask(form)
  }

  return(
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <form onSubmit={event => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Pekerjaan Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <div className="row mb-2">
              <div className="col-7">
                <label>Nama Pekerjaan <RequiredAsterisk /></label>
                <input type="text" className="form-control" value={form.name} onChange={event => setForm({ ...form, name: event.target.value})} />
              </div>
              <div className="col">
                <label>Quantity <RequiredAsterisk /></label>
                <input type="number" className="form-control" min={0} value={form.qty} onChange={event => setForm({ ...form, qty: event.target.value})}/>
              </div>
              <div className="col">
                <label>Done <RequiredAsterisk /></label>
                <input type="number" className="form-control" min={0} value={form.done} onChange={event => setForm({ ...form, done: event.target.value})} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">Close</button>
          <button className="btn btn-primary">Buat Pekerjaan Baru</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}