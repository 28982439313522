import { useEffect, useState, useContext, createContext } from "react";
import * as api from "../api/base";
import { getAllNotif, getUnreadNotif, readAllNotif } from "../api/auth";

export function useProvideAuth() {
  const [user, setUser] = useState({});
  const [notifCount, setNotifCount]=useState(0);
  const [notifications, setNotifications]=useState([]);
  const [isAuthenticated, setAuthenticated ] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  const login = async ( form ) => {
    try {
      const  { data } = await api.login({username: form.username, password: form.password})
      if (data.data.token.access_token) {
        localStorage.setItem('token', data.data.token.access_token);
        localStorage.setItem('clientUserId', window.btoa(data.data.user.id));
        setAuthenticated(true);
        loadUser();
        getUnreadNotifications();
      } else {
        // this.setState({error: data.message})
      }
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
  }

  const getUnreadNotifications = async () => {
    try {
      const  { data } = await getUnreadNotif();
      setNotifCount(data.data.unread);
    } catch (error) {
      console.log(error)
      if (error.response) {
        let { data } =  error.response;
        if (error.response?.data.code === 401 && error.response?.data.message === "Access token expired!") {
          setErrorMsg("Sesi Telah berakhir!")
        } else {
          setErrorMsg(data.message)
        }
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
  }


  const getAllNotifications = async () => {
    try {
      const  { data } = await getAllNotif();
      setNotifications(data.data);
    } catch (error) {
      if (error.response) {
        if (error.response?.data.code === 401 && error.response?.data.message === "Access token expired!") {
          setErrorMsg("Sesi Telah berakhir!")
        } else {
          setErrorMsg(error.response?.message)
        }
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
  }

  const readAllNotifications = async () => {
    try {
      const  { data } = await readAllNotif();
      getUnreadNotifications();
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        if (error.response?.data.code === 401 && error.response?.data.message === "Access token expired!") {
          setErrorMsg("Sesi Telah berakhir!")
        } else {
          setErrorMsg(data.message)
        }
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
  }


  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('clientUserId');
    setAuthenticated(false);
    setUser({});
    setErrorMsg("");
    setNotifCount(0);
  }

  const loadUser = async () => {
    try {
      let { data } = await api.getProfile();
      setUser(data.data)
    } catch (error) {
      if (error.response) {
        if (error.response?.data.code === 401 && error.response?.data.message === "Access token expired!") {
          logout()
          setErrorMsg("Sesi Telah berakhir!")
        }
      }
    }
  }

  useEffect(() => {
    if(localStorage.getItem('token')) {
      loadUser();
      getUnreadNotifications();
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [])

  return {
    user,
    notifCount,
    notifications,
    getUnreadNotifications,
    getAllNotifications,
    readAllNotifications,
    errorMsg,
    isAuthenticated,
    login,
    logout
  }
}

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};