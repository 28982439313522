import moment from "moment/moment";
import { useState } from "react";
import Pagination from "../../Pagination";
import { useAuth } from "../../../utils/AuthHooks";
import { useScheduleListHooks } from "../../../utils/SchedulesUtil";
import AlertBox from "../../AlertBox";
import Box from "../../Box";
import ModalDetailDismantle from "../../schedule/ModalDetailDismantle";
import ModalAcceptDismantleModem from "./ModalAcceptDismantleModem";
import ModalDeclineDismantleModem from "./ModalDeclineDismantleModem";

export default function BoxDismantleModem() {
  const auth = useAuth();

  let [formFilter, setFormFilter] = useState({
    status: "WAITING-NOC",
    n: "",
    date_from: "",
    date_until: "",
  });
  let [filter, setFilter] = useState({
    type: "dismantle",
    status: "WAITING-NOC",
    n: "",
    date_from: "",
    date_until: "",
    page: 1,
    page_size: 10,
  });

  let schedulesData = useScheduleListHooks({ filter });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [selectedId, setSelectedId] = useState("");
  let [showModalDetail, setShowModalDetail] = useState(false);
  let [showModalDismantle, setShowModalDismantle] = useState(false);
  let [showModalDecline, setShowModalDecline] = useState(false);

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
    schedulesData.getSchedules(filter);
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const toggleModalDismantle = (id) => {
    setSelectedId(id);
    setShowModalDismantle(!showModalDismantle);
  };

  const toggleModalDecline = (id) => {
    setSelectedId(id);
    setShowModalDecline(!showModalDecline);
  };

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(!showModalDetail);
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const applyFilter = () => {
    setFilter({ ...filter, ...formFilter, page: 1 });
  };

  const resetFilter = () => {
    setFormFilter({ status: "WAITING-NOC", n: "", date_from: "", date_until: "" });
    setFilter({
      ...filter,
      status: "WAITING-NOC",
      n: "",
      date_from: "",
      date_until: "",
      page: 1,
      page_size: 10,
    });
  };

  const disabledByRole = (roles = [], allowed = []) => {
    return roles.some((role) => allowed.includes(role.name));
  };

  return (
    <>
      <Box title="Permintaan Dismantle" loading={schedulesData.loading}>
        <AlertBox {...alert} setAlert={setAlert} />
        <div style={{ overflowX: "auto" }}>
          <div className="row mb-4">
            <div className="col">
              <label>Pencarian</label>
              <input
                type="text"
                className="form-control"
                value={formFilter.n}
                onChange={(event) => changeFilter("n", event.target.value)}
                placeholder="No. Registrasi / Nama / No. Penguasan"
              />
            </div>
            <div className="col">
              <label>Status</label>
              <select
                className="form-control"
                value={formFilter.status}
                onChange={(event) => changeFilter("status", event.target.value)}
              >
                <option value="">SEMUA</option>
                <option value="WAITING-NOC">WAITING NOC</option>
                <option value="NOC-DONE">NOC DONE</option>
                <option value="DONE">DONE</option>
                <option value="CANCELED">CANCELED</option>
              </select>
            </div>
            <div className="col">
              <label>Tanggal Dari</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.date_from}
                onChange={(event) => changeFilter("date_from", event.target.value)}
              />
            </div>
            <div className="col">
              <label>Tanggal Sampai</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.date_until}
                onChange={(event) => changeFilter("date_until", event.target.value)}
              />
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
          </div>
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Aksi Terminate</th>
                <th>Status</th>
                <th>No Reg</th>
                <th>Nama Pelanggan</th>
                <th>Jadwal</th>
                <th>S/N Modem</th>
              </tr>
            </thead>
            <tbody>
              {schedulesData.schedules.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
              {schedulesData.schedules.map((dismantle, index) => (
                <tr key={index}>
                  <td>
                    <button
                      className="btn btn-sm btn-block btn-info mr-1 text-nowrap"
                      onClick={() => toggleModalDetail(dismantle.id)}
                    >
                      <i className="fa fa-eye"></i> Detail
                    </button>
                    {!disabledByRole(auth.user.roles, ["technician"]) &&
                      dismantle.status === "NOC-DONE" && (
                        <button
                          className="btn btn-sm btn-block btn-success mr-1 text-nowrap"
                          onClick={() => toggleModalDecline(dismantle.id)}
                        >
                          <i className="fa fa-check"></i> Cancel Dismantle
                        </button>
                      )}
                    {!disabledByRole(auth.user.roles, ["technician"]) &&
                      dismantle.status === "WAITING-NOC" && (
                        <button
                          className="btn btn-sm btn-block btn-danger mr-1 text-nowrap"
                          onClick={() => toggleModalDismantle(dismantle.id)}
                        >
                          <i className="fa fa-power-off"></i> Dismantle
                        </button>
                      )}
                  </td>
                  <td>
                    {dismantle.registration.inventory_modem_pair?.modem.automation ? (
                      <div className="badge badge-success">Auto</div>
                    ) : (
                      <div className="badge badge-warning">Manual</div>
                    )}
                  </td>
                  <td>{dismantle.status}</td>
                  <td>{dismantle.registration.registration_number}</td>
                  <td>{dismantle.registration.name}</td>
                  <td>
                    {dismantle.schedule && moment(dismantle.schedule).format("DD MMM YYYY - HH:mm")}
                  </td>
                  <td>{dismantle.registration.inventory_modem_pair?.modem.serial_number_modem}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {schedulesData.meta.count > schedulesData.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(schedulesData.meta.count / schedulesData.meta.page_size)}
              selectedpage={schedulesData.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(schedulesData.meta.page + 1)}
              prev={() => gotoPage(schedulesData.meta.page - 1)}
            />
          )}
        </div>
      </Box>

      <ModalAcceptDismantleModem
        show={showModalDismantle}
        id={showModalDismantle ? selectedId : null}
        setShow={toggleModalDismantle}
        onSuccess={onSuccess}
      />
      <ModalDeclineDismantleModem
        show={showModalDecline}
        id={showModalDecline ? selectedId : null}
        setShow={toggleModalDecline}
        onSuccess={onSuccess}
      />
      <ModalDetailDismantle
        show={showModalDetail}
        id={showModalDetail ? selectedId : null}
        setShow={toggleModalDetail}
        onSuccess={onSuccess}
      />
    </>
  );
}
