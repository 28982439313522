import { useState, useEffect } from "react";
import { presetsTemplateInventory } from "../../api/inventory";

export function usePresetTemplateInventory() {
  let [filesPreset, setFilesPreset] = useState([]);
  let [errorMsg, setErrorMsg] = useState("");
  let [loading, setLoading] = useState(false);

  const getFilesPreset = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await presetsTemplateInventory();
      setFilesPreset(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getFilesPreset();
  }, []);

  return {
    filesPreset,
    loading,
    errorMsg,
  };
}
