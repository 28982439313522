import { useState, useEffect } from "react";
import MainLayout from "../components/layouts/MainLayout";
import Box from "../components/Box";
import { useInventoryCategories } from "../utils/InventoryHooks";
import AlertBox from "../components/AlertBox";
import Pagination from "../components/Pagination";
import ModalAddCategories from "../components/inventory/categories/ModalAddCategories";
import ModalEditCategories from "../components/inventory/categories/ModalEditCategories";
import ModalDeleteCategories from "../components/inventory/categories/ModalDeleteCategories";
import { useControlPermission } from "../utils/ActionPermissionUtils";

export default function CategoryInventoryPage() {
  let { hasAccess } = useControlPermission();

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [filter, setFilter] = useState({ page: 1, page_size: 10 });
  let [selectedId, setSelectedId] = useState("");

  let [showModalCreate, setShowModalCreate] = useState(false);
  let [showModalUpdate, setShowModalUpdate] = useState(false);
  let [showModalDelete, setShowModalDelete] = useState(false);

  let { categories, meta, loading, errorMsg, fetchInventoryCategories } = useInventoryCategories({
    filter,
  });

  useEffect(() => {
    fetchInventoryCategories(filter);
  }, [filter]);

  const toggleOpenModalUpdate = (id = null) => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleOpenModalDelete = (id = null) => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    setFilter({ ...filter });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Data Kategori</h1>
          </div>
          <div className="col-auto">
            {hasAccess("create-inventory-category") && (
              <button className="btn btn-primary" onClick={() => setShowModalCreate(true)}>
                <i className="fa fa-user-plus"></i> Buat Kategori
              </button>
            )}
          </div>
        </div>
        <Box title="Data Kategori" loading={loading}>
          <AlertBox {...alert} setAlert={setAlert} />
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Nama</th>
                <th>Deskripsi</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {categories.length === 0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    Tidak ada yang ditampilkan
                  </td>
                </tr>
              )}
              {categories.map((category, index) => (
                <tr key={index}>
                  <td>{category.name}</td>
                  <td>{category.description}</td>
                  <td>
                    {hasAccess("update-inventory-category") && (
                      <button
                        className="btn text-nowrap btn-sm btn-info mx-2"
                        onClick={() => toggleOpenModalUpdate(category.id)}
                      >
                        <i className="fa fa-pencil-alt"></i> Edit
                      </button>
                    )}
                    {hasAccess("delete-inventory-category") && (
                      <button
                        className="btn text-nowrap btn-sm btn-danger"
                        onClick={() => toggleOpenModalDelete(category.id)}
                      >
                        <i className="fa fa-trash"></i> Hapus
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {meta.count > meta.pageSize && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.pageSize)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}

          <ModalAddCategories
            show={showModalCreate}
            onClose={setShowModalCreate}
            onSuccess={onSuccess}
          />
          <ModalEditCategories
            show={showModalUpdate}
            onClose={toggleOpenModalUpdate}
            id={selectedId}
            onSuccess={onSuccess}
          />
          <ModalDeleteCategories
            show={showModalDelete}
            onClose={toggleOpenModalDelete}
            id={selectedId}
            onSuccess={onSuccess}
          />
        </Box>
      </div>
    </MainLayout>
  );
}
