import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../../AlertBox";
import { RequiredAsterisk } from "../../FormHelper";
import { updateOltById } from "../../../api/infrastructure";
import { useOltByIdHooks } from "../../../utils/infrastructure/OltUtils";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../../Loading";

export default function ModalUpdateOlt({ id, show, onClose, onSuccess }) {
  return (
    <Modal show={show} onHide={() => onClose()}>
      <FormUpdateOlt id={id} onSuccess={onSuccess} onClose={() => onClose()} />
    </Modal>
  );
}

function FormUpdateOlt({ id, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [form, setForm] = useState({
    name: "",
    ip: "",
    username: "",
    password: "",
    port: "",
    description: "",
  });

  let { data, fetched, error, loading: loadingData } = useOltByIdHooks({ id });
  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
    };
    sendUpdateOlt(formData);
  };

  useEffect(() => {
    if (fetched) {
      setForm((prev) => ({
        ...prev,
        name: data.name,
        ip: data.ip,
        username: data.username,
        password: data.password,
        port: data.port,
        description: data.description || "",
      }));
    }
  }, [data, fetched]);

  const sendUpdateOlt = async (form) => {
    setLoading(true);
    try {
      let response = await updateOltById(id, form);
      onSuccess(response.data.message);
      onClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit OLT </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingInsideBoxWrapper>
            {(loading || loadingData) && <LoadingInsideBox />}
            {error && <div className="alert alert-danger">{error}</div>}
            <AlertBox {...alert} setAlert={setAlert} />
            <div className="form-group">
              <label htmlFor="">
                Nama OLT <RequiredAsterisk />{" "}
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={form.name}
                onChange={(event) => setForm({ ...form, name: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">
                IP OLT <RequiredAsterisk />{" "}
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={form.ip}
                onChange={(event) => setForm({ ...form, ip: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">
                Port OLT <RequiredAsterisk />{" "}
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={form.port}
                onChange={(event) => setForm({ ...form, port: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">
                Username <RequiredAsterisk />{" "}
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={form.username}
                onChange={(event) => setForm({ ...form, username: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">
                Password <RequiredAsterisk />{" "}
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={form.password}
                onChange={(event) => setForm({ ...form, password: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">
                Deskripsi <RequiredAsterisk />{" "}
              </label>
              <textarea
                className="form-control"
                rows={4}
                value={form.description}
                onChange={(event) => setForm({ ...form, description: event.target.value })}
              />
            </div>
          </LoadingInsideBoxWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
            Close
          </button>
          <button type="submit" disabled={loading} className="btn btn-primary">
            <i className="fas fa-plus"></i> {loading ? "Update OLT ..." : "Update OLT"}
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}
