import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { downloadCustomer } from "../../api/customer";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import AlertBox from "../AlertBox";

export default function ModalDownloadExpiredCustomer({ show, onClose, onSuccess }) {
  const { projects } = useGroupListHooks();
  const [formFilter, setFormFilter] = useState({
    followed_up: "",
    plan_start: "",
    plan_end: "",
    active_from: "",
    active_until: "",
    overdue: 2,
    project_id: "",
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    resetFilter();
    onClose();
  };

  const resetFilter = () => {
    setFormFilter({
      followed_up: "",
      plan_start: "",
      plan_end: "",
      active_from: "",
      active_until: "",
      overdue: 2,
      project_id: "",
    });
  };

  const downloadFile = async () => {
    setLoading(true);

    try {
      let response = await downloadCustomer({ ...formFilter });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      let downloadDate = moment().format("DD-MM-YYYY");
      link.setAttribute("download", `Data Pelanggan Belum Bayar ${downloadDate}.xlsx`);
      onSuccess(`Berhasil mengunduh Data Pelanggan Belum Bayar ${downloadDate}.xlsx`);
      handleClose();
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Download Data Pelanggan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />

        <form>
          {/* <div className="form-group">
            <label>Tanggal Mulai Paket</label>
            <input type="date" className="form-control" value={formFilter.plan_start} onChange={event => setFormFilter({ ...formFilter, plan_start: event.target.value})} />
          </div>
          <div className="form-group">
            <label>Tanggal Akhir Paket</label>
            <input type="date" className="form-control" value={formFilter.plan_end} onChange={event => setFormFilter({ ...formFilter, plan_end: event.target.value})} />  
          </div> */}
          <div className="form-group">
            <label htmlFor="package_name">Follow Up</label>
            <select
              className="form-control input"
              value={formFilter.followed_up}
              onChange={(event) =>
                setFormFilter({ ...formFilter, followed_up: event.target.value })
              }
            >
              <option value="">SEMUA</option>
              <option value="true">Sudah Follow Up</option>
              <option value="false">Belum Follow Up</option>
            </select>
          </div>
          <div className="form-group">
            <label>Tanggal Aktif Mulai</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.active_from}
              onChange={(event) =>
                setFormFilter({ ...formFilter, active_from: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Tanggal Aktif Akhir</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.active_until}
              onChange={(event) =>
                setFormFilter({ ...formFilter, active_until: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Keterlambatan</label>
            <input
              type="number"
              className="form-control"
              value={formFilter.overdue}
              onChange={(event) => setFormFilter({ ...formFilter, overdue: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="projectFilter">Grup/Project</label>
            <select
              className="form-control"
              id="projectFilter"
              value={formFilter.project_id}
              onChange={(event) => setFormFilter({ ...formFilter, project_id: event.target.value })}
            >
              <option value="">SEMUA</option>
              {projects.map((project, index) => (
                <option value={project.id} key={index}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => downloadFile()} disabled={loading}>
          <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
