import { useEffect, useState } from "react";
import { getBouncedEmailList } from "../../api/setting";

export function useBouncedEmailListHooks({ filter }) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getBouncedEmail = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getBouncedEmailList({ ...filter });
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getBouncedEmail(filter);
  }, [filter]);

  return {
    data,
    meta,
    loading,
    errorMsg,
    getBouncedEmail,
  };
}
