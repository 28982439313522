import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip, Cell, Label } from "recharts";
import { useOnlineCustomerReport } from "../../utils/DashboardHooks";
import Box from "../Box";

// const data = [
//   { name: "aktif", value: 1340 },
//   { name: "isolir", value: 300 },
//   { name: "dismantle", value: 100 },
// ];

const COLORS = {
  'aktif': '#28a745',
  'isolir': '#ffc107',
  'dismantle': '#dc3545'
}


// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

function CustomLabel({viewBox, value1}){
  const {cx, cy} = viewBox;
  return (
    <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
      <tspan alignmentBaseline="middle" fontSize="35" style={{fontWeight: 'bold', display: 'block'}}>{value1}</tspan>
    </text>
  )
}

export default function BoxOnlineUsers() {
  const { data, fetched, loading, errorMsg } = useOnlineCustomerReport();

  let activePercentage = 0;

  if (fetched) {
    data.forEach((stat) => {
      if (stat.status === 'aktif') {
        activePercentage = stat.percentage
      }
    })
  }

  return (
    <Box title="Pengguna Aktif" loading={loading}>
      {errorMsg && 
        <div className="alert alert-danger">
          {errorMsg}
        </div>
      }

      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <PieChart>
            <Tooltip />
            <Pie 
              data={data}
              nameKey="status"
              dataKey="count" 
              fill="#8884d8" 
              // label={renderCustomizedLabel} 
              labelLine={false} 
              innerRadius={90} 
              outerRadius={120}
            >
              <>
                {
                  data.map((entry, index) => <Cell key={index} fill={COLORS[entry.status]}/>)
                }
                <Label width={30} position="center"
                  content={<CustomLabel value1={`${activePercentage} %`}/>}>
                </Label>
              </>
            </Pie>
            <Legend 
              layout="horizontal" 
              align="center"
              payload={
                data.map(
                  (item) => ({
                    id: item.status,
                    type: "circle",
                    value: `${item.status} (${item.count})`,
                    color: COLORS[item.status]
                  })
                )
              }
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Box>
  );
}
