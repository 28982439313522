import { useEffect, useState } from 'react';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import AlertBox from '../AlertBox';
import { useAccountById } from '../../utils/AccountManageHooks';
import * as api from '../../api/account';
import { useGroupListHooks } from '../../utils/ProjectsUtilHooks';
import { LoadingInsideBox, LoadingInsideBoxWrapper } from '../Loading';

export default function ModalUpdateGroup({ account, show, setShow }) {
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  const [loading, setLoading] = useState(false);
  const [projectOpt, setProjectOpt] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const projects = useGroupListHooks();

  let { data, getAccount, loading: loadingAccount } = useAccountById({id: account.id});

  useEffect(() => {
    let projectsTemp = [];

    projects.projects.forEach((project) => {
      let temp = {
        value: project.id,
        label: project.name
      }
      projectsTemp.push(temp);

      return true;
    })

    setProjectOpt(projectsTemp);
  }, [projects.projects])

  const onSuccessUpdate = () => {
    setSelectedProject(null);
    getAccount(account.id)
  }


  const addProject = async (event) => {
    event.preventDefault();
    setLoading(true);
    let formData = {
      user_id: account.id,
      project_id: selectedProject?.value || ''
    }

    try {
      let { data } = await api.createProjectUser(formData);
      setAlert({show: true, message: data.message, type: 'success'});
      onSuccessUpdate();
    } catch (error) {
      let message = '';
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message  = "Something Error Happened"
      }
      setAlert({show: true, message: message, type: 'danger'});
    }
    setLoading(false);
  }

  const removeProject = async (id) => {
    setLoading(true);
    let formData = {
      user_id: account.id,
      project_id: id
    }
    try {
      let { data } = await api.removeProjectUserById(formData);
      setAlert({show: true, message: data.message, type: 'success'});
      onSuccessUpdate();
    } catch (error) {
      let message = '';
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message  = "Something Error Happened"
      }
      setAlert({show: true, message: message, type: 'danger'});
    }
    setLoading(false);
  }

  const handleClose = () => {
    setSelectedProject(null)
    setAlert({show: false, message: '', type: 'danger'});
    setShow()
  }

  const handleChangeProject = (selected) => {
    setSelectedProject(selected)
  }

  return(
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Grup Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <LoadingInsideBoxWrapper>
          {(loading || loadingAccount) && <LoadingInsideBox />}          
          <table className='table table-bordered'>
            <tbody>
              {(!data.projects || data.projects?.length === 0) && 
                <tr>
                  <td colSpan={2} className="text-center">Tidak ada Grup/Project</td>
                </tr>
              }
              {data.projects?.map((project, index) => (
                <tr key={index}>
                  <td>{project.name}</td>
                  <td>
                    <button className="btn btn-sm btn-danger" onClick={() => removeProject(project.id)}><i className="fa fa-times"></i></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <form onSubmit={event => addProject(event)}>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="project">Kelompok/Project</label>
                  <Select value={selectedProject} isClearable placeholder="Kelompok / Project" required onChange={selected => handleChangeProject(selected)} options={projectOpt} />
                </div>
              </div>
              <div className="col-auto" style={{paddingTop: '32px'}}>
                <button className='btn btn-primary' disabled={selectedProject === null}>Tambah</button>
              </div>
            </div>
          </form>
        </LoadingInsideBoxWrapper>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()} type="button">Close</button>
      </Modal.Footer>
    </Modal>
  )
}