import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useState } from "react";
import Pagination from "../../components/Pagination";
import { useBouncedEmailListHooks } from "../../utils/settings/BouncedEmailHooks";
import moment from "moment";
import ModalCreateNewBounced from "../../components/setting/ModalCreateNewBounced";
import AlertBox from "../../components/AlertBox";
import { Modal } from "react-bootstrap";
import { deleteBouncedEmail, whitelistBouncedEmail } from "../../api/setting";

export default function BounceEmailPage() {
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [formFilter, setFormFilter] = useState({ type: "", q: "" });
  const [filter, setFilter] = useState({ type: "", q: "", page: 1, page_size: 20 });
  const [modal, setModal] = useState("");
  const [selected, setSelected] = useState({});

  const { data, loading, meta } = useBouncedEmailListHooks({ filter });

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ type: "", q: "" });
    setFilter({ type: "", q: "", page: 1, page_size: 20 });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    setFilter({ ...filter });
  };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Bounced Email List</h1>
          <button className="btn btn-primary" onClick={() => setModal("create")}>
            <i className="fa fa-plus"></i> Tambah Bounced Email
          </button>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title={`Bounced Email (Total: ${meta.count})`} loading={loading}>
              <div className="row mb-4">
                <div className="col-12 col-md-2">
                  <label>Pencarian</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formFilter.q}
                    onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
                    placeholder="Email ..."
                  />
                </div>
                <div className="col-12 col-md-2">
                  <label>Tipe</label>
                  <select
                    className="form-control"
                    value={formFilter.type}
                    onChange={(event) => setFormFilter({ ...formFilter, type: event.target.value })}
                  >
                    <option value="">ALL</option>
                    <option value="email">EMAIL</option>
                    <option value="domain">DOMAIN</option>
                  </select>
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-primary"
                    onClick={() => applyFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => resetFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <AlertBox {...alert} setAlert={setAlert} />
              <div style={{ overflowX: "auto", marginBottom: "10px" }}>
                <table className="table table-sm table-striped table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>Aksi</th>
                      <th>Wkt. Ditambahkan</th>
                      <th>Email</th>
                      <th>Type</th>
                      <th>Auto/Manual</th>
                      <th>Whitelist</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length === 0 ? (
                      <tr>
                        <td colSpan={6} className="text-center">
                          Tidak ada data
                        </td>
                      </tr>
                    ) : null}
                    {data.map((email) => (
                      <tr key={email.id}>
                        <td className="text-center">
                          <button
                            className="btn btn-sm btn-primary mr-1 "
                            onClick={() => {
                              setSelected(email);
                              setModal("detail");
                            }}
                          >
                            <i className="fa fa-eye"></i>
                          </button>
                          {email.is_manually_added ? (
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                setSelected(email);
                                setModal("delete");
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          ) : null}
                        </td>
                        <td className="text-nowrap">
                          {moment.unix(email.added_time).format("DD MMM YYYY - HH:mm")}
                        </td>
                        <td className="text-nowrap">{email.address}</td>
                        <td>{email.type}</td>
                        <td>{email.is_manually_added ? "Manual" : "Auto"}</td>
                        <td>
                          {email.is_manually_added ? (
                            "---"
                          ) : (
                            <WhitelistBtn email={email} onSuccess={onSuccess} />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {meta.count > meta.page_size && (
                <Pagination
                  totalpage={Math.ceil(meta.count / meta.page_size)}
                  selectedpage={meta.page}
                  clickpage={(page) => gotoPage(page)}
                  next={() => gotoPage(meta.page + 1)}
                  prev={() => gotoPage(meta.page - 1)}
                />
              )}
            </Box>
          </div>
        </div>
      </div>

      <ModalCreateNewBounced
        show={modal === "create"}
        onClose={() => {
          setModal("");
        }}
        onSuccess={onSuccess}
      />
      <ModalDetailBounced
        show={modal === "detail"}
        data={modal === "detail" ? selected : null}
        onClose={() => {
          setModal("");
        }}
      />
      <ModalDeleteBounced
        show={modal === "delete"}
        data={modal === "delete" ? selected : null}
        onClose={() => {
          setModal("");
        }}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function ModalDetailBounced({ show, data, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Detail Email Bounced</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Alamat</td>
              <td>{data?.address}</td>
            </tr>
            <tr>
              <td>Tipe</td>
              <td>{data?.type}</td>
            </tr>
            <tr>
              <td>Ditambahkan pada</td>
              <td className="text-nowrap">
                {data?.added_time
                  ? moment.unix(data?.added_time).format("DD MMM YYYY - HH:mm")
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Deskripsi</td>
              <td>{data?.description}</td>
            </tr>
            <tr>
              <td>Input</td>
              <td>{data?.is_manually_added ? "Manual" : "Auto"}</td>
            </tr>
            <tr>
              <td>Whitelisted</td>
              <td>{data?.is_whitelisted ? "Ya" : "Tidak"}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}

function ModalDeleteBounced({ show, data, onClose, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      onClose();
    }
  };

  const deleteBounced = async () => {
    setLoading(true);
    try {
      let response = await deleteBouncedEmail(data.id);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Email Bounced?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        Apakah anda yakin mengahpus data "{data?.address}" ini?
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
          Close
        </button>
        <button className="btn btn-danger" disabled={loading} onClick={() => deleteBounced()}>
          <i className="fas fa-trash"></i>{" "}
          {loading ? "Menghapus Bounced Email ..." : "Hapus Bounced Email"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function WhitelistBtn({ email, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const changeWhitelist = async (formData) => {
    setErrorMsg("");
    setLoading(true);
    try {
      let { data } = await whitelistBouncedEmail(email.id, formData);
      onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setErrorMsg(message);
    }
    setLoading(false);
  };

  return (
    <>
      {email.is_whitelisted ? (
        <button
          className="btn btn-sm btn-block btn-danger"
          disabled={loading}
          onClick={() => changeWhitelist({ is_whitelisted: 0 })}
        >
          <i className="fa fa-ban"></i> {loading ? "Menutup ..." : "Tutup"}
        </button>
      ) : (
        <button
          className="btn btn-sm btn-block btn-light"
          disabled={loading}
          onClick={() => changeWhitelist({ is_whitelisted: 1 })}
        >
          <i className="fa fa-lock-open"></i> {loading ? "Membuka ..." : "Buka"}
        </button>
      )}

      {errorMsg && (
        <span className="text-sm text-danger d-block">
          <i className="fa fa-triangle-exclamation"></i> Error goes here
        </span>
      )}
    </>
  );
}
