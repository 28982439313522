import { useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../../AlertBox";
import { RequiredAsterisk } from "../../FormHelper";
import { createOlt } from "../../../api/infrastructure";

export default function ModalCreateOlt({ show, onClose, onSuccess }) {
  let [form, setForm] = useState({
    name: "",
    ip: "",
    username: "",
    password: "",
    port: "",
    description: "",
  });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({ name: "", ip: "", username: "", password: "", port: "", description: "" });
    setAlert({ show: false, message: "", type: "" });
    onClose();
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
    };
    sendCreateOlt(formData);
  };

  const sendCreateOlt = async (form) => {
    setLoading(true);
    try {
      let response = await createOlt(form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat OLT Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Nama OLT <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              IP OLT <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.ip}
              onChange={(event) => setForm({ ...form, ip: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Port OLT <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.port}
              onChange={(event) => setForm({ ...form, port: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Username <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.username}
              onChange={(event) => setForm({ ...form, username: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Password <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.password}
              onChange={(event) => setForm({ ...form, password: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi <RequiredAsterisk />{" "}
            </label>
            <textarea
              className="form-control"
              rows={4}
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" disabled={loading} className="btn btn-primary">
            <i className="fas fa-plus"></i> {loading ? "Membuat OLT ..." : "Buat OLT Baru"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
