import moment from "moment";
import { useState } from "react";
import {
  useCustomerActivities,
  useCustomerContext,
} from "../../../utils/customer/CustomerRelationUtils";
import AlertBox from "../../AlertBox";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../../Loading";
import Pagination from "../../Pagination";
import { ModalCreateActivities, ModalUpdateActivities } from "./ModalManageActivities";
import { ActivityStatusBadge, TicketStatusBadge } from "./Activitiesbadge";
import { useControlPermission } from "../../../utils/ActionPermissionUtils";

export default function CustomerActivitesList({ id }) {
  const { customer } = useCustomerContext();
  let { hasAccess } = useControlPermission();

  const [modal, setModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [formFilter, setFormFilter] = useState({ status: "" });
  const [filter, setFilter] = useState({ status: "", id, page: 1, page_size: 25 });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const { activities, meta, errorMsg, loading } = useCustomerActivities({ filter });

  function applyFilter() {
    let form_filter = {
      ...filter,
      ...formFilter,
      page: 1,
    };
    setFilter(form_filter);
  }

  const resetFilter = () => {
    setFormFilter({
      status: "",
    });
    setFilter({
      status: "",
      page: 1,
      page_size: 25,
      id: id,
    });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const onSuccess = (message) => {
    setFilter({ ...filter });
    setAlert({ show: true, message: message, type: "success" });
  };

  return (
    <div className="pt-4">
      <LoadingInsideBoxWrapper>
        {loading && <LoadingInsideBox />}
        <div className="py-2 flex-gap justify-content-end">
          {customer.is_active !== 2 &&
          customer.is_active !== 4 &&
          hasAccess("create-customer-crm-activity") ? (
            <button className="btn btn-primary" onClick={() => setModal("create-activity")}>
              <i className="fa fa-plus"></i> Buat Aktivitas Baru
            </button>
          ) : null}
          {/* <button className="btn btn-custom-purple" onClick={() => setModal("create-activity")}>
            <i className="fa fa-download"></i> Download
          </button> */}
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-2">
            <label htmlFor="customer_number">Status</label>
            <select
              className="form-control"
              value={formFilter.status}
              onChange={(event) => setFormFilter({ ...formFilter, status: event.target.value })}
            >
              <option value="">SEMUA</option>
              <option value="open">OPEN</option>
              <option value="in-progress">IN PROGRESS</option>
              <option value="closed">CLOSED</option>
            </select>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={() => applyFilter()}
              style={{ marginTop: "32px" }}
            >
              Filter
            </button>
            <button
              className="btn btn-default"
              onClick={() => resetFilter()}
              style={{ marginTop: "32px" }}
            >
              Reset
            </button>
          </div>
        </div>

        <AlertBox {...alert} setAlert={setAlert} />
        {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : null}
        <div style={{ overflowX: "auto" }}>
          <table className="table table-bordered table-hover table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Status</th>
                <th>No. Activity</th>
                <th>Activity Type</th>
                <th>Activity Code</th>
                <th>Note</th>
                <th>Solusi</th>
                <th>No. Tiket</th>
                <th>Dibuat oleh</th>
                <th>Dibuat pada</th>
                <th>Ditutup oleh</th>
                <th>Ditutup pada</th>
              </tr>
            </thead>
            <tbody>
              {activities.length === 0 ? (
                <tr>
                  <td colSpan={14} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              ) : null}
              {activities.map((activity) => (
                <tr key={activity.id}>
                  <td>
                    <div className="flex-gap">
                      {hasAccess("update-customer-crm-activity") && activity.status !== "closed" ? (
                        <button
                          className="btn btn-sm btn-info"
                          onClick={() => {
                            setModal("update-activity");
                            setSelectedId(activity.id);
                          }}
                        >
                          <i className="fa fa-pencil"></i>
                        </button>
                      ) : null}
                    </div>
                  </td>
                  <td className="text-nowrap">
                    <ActivityStatusBadge status={activity.status} />
                  </td>
                  <td className="text-nowrap">{activity.activity_number}</td>
                  <td className="text-nowrap">{activity.activity_type}</td>
                  <td className="text-nowrap">
                    {activity.activity_code} - {activity.activity_code_desc}
                  </td>
                  <td style={{ minWidth: "200px" }}>{activity.note}</td>
                  <td style={{ minWidth: "200px" }}>{activity.solution || "-"}</td>
                  <td className="text-nowrap">
                    {activity.ticket_number ? (
                      <>
                        <span>{activity.ticket_number}</span>{" "}
                        <TicketStatusBadge status={activity.ticket_status} />
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{activity.created_by}</td>
                  <td className="text-nowrap">
                    {moment.unix(activity.created_at).format("DD MMM YYYY - HH:mm")}
                  </td>
                  <td className="text-nowrap">{activity.closed_by || "-"}</td>
                  <td className="text-nowrap">
                    {activity.closed_at
                      ? moment.unix(activity.closed_at).format("DD MMM YYYY - HH:mm")
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {meta.count > meta.page_size && (
          <Pagination
            totalpage={Math.ceil(meta.count / meta.page_size)}
            selectedpage={meta.page}
            clickpage={(page) => gotoPage(page)}
            next={() => gotoPage(meta.page + 1)}
            prev={() => gotoPage(meta.page - 1)}
          />
        )}
      </LoadingInsideBoxWrapper>
      <ModalCreateActivities
        show={modal === "create-activity"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
      <ModalUpdateActivities
        show={modal === "update-activity"}
        id={selectedId}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
    </div>
  );
}
