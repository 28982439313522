import { useRef, useState } from "react";
import { Collapse } from "react-bootstrap";
import { registerCustomer } from "../../api/sales";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { convertToRupiah } from "../../tools/numerictools";
import { useAuth } from "../../utils/AuthHooks";
import { useInternetPackageListHooks } from "../../utils/InternetPackageUtils";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import useRegionHooks from "../../utils/RegionHooks";

export default function RegistrationBySalesPage() {
  let { user } = useAuth();

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);

  const internetPackage = useInternetPackageListHooks();
  const { projects } = useGroupListHooks();

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    gender: "",
    phone_number: "",
    alternate_phone_number: "",
    id_number: "",
    id_number_type: "id_card",
    customer_number: "",
    npwp_number: "",
    address_id: "",
    internet_package_id: "",
    registration_type: "INSTALLATION_REQUEST",
    birthdate: "",
    birthdate_place: "",
    building_ownership_status: "",
    project_id: "",
  });

  const userRegion = useRegionHooks();
  const installRegion = useRegionHooks();
  const [sameAddress, setSameAddress] = useState(false);

  const registerFormRef = useRef(null);
  const scrollToForm = () => registerFormRef.current.scrollIntoView({ behavior: "smooth" });

  const handleChange = (name, value) => {
    setUserData({ ...userData, [name]: value });
  };

  function resetForm() {
    // reset user data
    setUserData({
      name: "",
      email: "",
      gender: "",
      phone_number: "",
      alternate_phone_number: "",
      id_number: "",
      id_number_type: "id_card",
      customer_number: "",
      npwp_number: "",
      address_id: "",
      internet_package_id: "",
      registration_type: "INSTALLATION_REQUEST",
      birthdate: "",
      birthdate_place: "",
      building_ownership_status: "",
      project_id: "",
    });
    // reset user region
    userRegion.resetRegion();
    // reset install region
    installRegion.resetRegion();
  }

  async function registerData(form) {
    setLoading(true);
    try {
      let { data } = await registerCustomer(form);
      setAlert({ show: true, message: "Berhasil: " + data.message, type: "success" });
      resetForm();
    } catch (error) {
      let message = "Sesuatu kesalahan sedang terjadi";

      if (error.response) {
        message = error.response.data.message;
      }
      setAlert({ show: true, message: message, type: "danger" });
    }

    scrollToForm();
    setLoading(false);
  }

  function handleSubmit(event) {
    event.preventDefault();
    let form = {
      ...userData,
      address: userRegion.address,
      address_id: userRegion.addressId,
      rt: userRegion.rt,
      rw: userRegion.rw,
      installation_address: {},
    };

    if (userData.registration_type === "INSTALLATION_REQUEST") {
      delete form.customer_number;
    }

    if (sameAddress) {
      form.installation_address = {
        address: userRegion.address,
        rt: userRegion.rt,
        rw: userRegion.rw,
        address_id: userRegion.addressId,
      };
    } else {
      form.installation_address = {
        address: installRegion.address,
        rt: installRegion.rt,
        rw: installRegion.rw,
        address_id: installRegion.addressId,
      };
    }
    registerData(form);
  }

  const allowPublicFacilitiesInput = (roles = [], allowed = []) => {
    return roles.some((role) => allowed.includes(role.name));
  };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Pendaftaran Pelanggan</h1>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-8 col-12">
            <Box title="Form Pendaftaran" loading={false}>
              <AlertBox {...alert} setAlert={setAlert} />

              <form onSubmit={(event) => handleSubmit(event)} ref={registerFormRef}>
                <h4>Data Pelanggan</h4>
                <div className="form-group">
                  <label>Pilihan Paket</label>
                  <select
                    className="form-control input"
                    required
                    value={userData.internet_package_id}
                    onChange={(event) => handleChange("internet_package_id", event.target.value)}
                  >
                    <option value="" disabled>
                      {" "}
                      -- Pilih Paket -- (Nama Paket - Kecepatan - Harga - Biaya Pemasangan -
                      Deposit)
                    </option>
                    {internetPackage.data.map((pack, index) => (
                      <option key={index} value={pack.id}>
                        {pack.name} - ({pack.internet_package_varian.name}) - Rp{" "}
                        {convertToRupiah(pack.price)} - Rp {convertToRupiah(pack.installation_cost)}{" "}
                        - Rp {convertToRupiah(pack.deposit_amount)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="name">
                    Nama Lengkap <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    required
                    value={userData.name}
                    onChange={(event) => handleChange("name", event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="your_email">
                    Nomor Identitas <span className="text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-sm">
                      <label>
                        <input
                          type="radio"
                          name="id_number_type"
                          checked={userData.id_number_type === "id_card"}
                          onChange={() => handleChange("id_number_type", "id_card")}
                        />{" "}
                        KTP
                      </label>
                    </div>
                    <div className="col-sm">
                      <label>
                        <input
                          type="radio"
                          name="id_number_type"
                          checked={userData.id_number_type === "passport"}
                          onChange={() => handleChange("id_number_type", "passport")}
                        />{" "}
                        Passpor
                      </label>
                    </div>
                  </div>
                  <input
                    type="text"
                    name="id_number"
                    id="id_number"
                    className="form-control"
                    required
                    value={userData.id_number}
                    onChange={(event) => handleChange("id_number", event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="npwp_number">Nomor NPWP</label>
                  <input
                    type="text"
                    name="npwp_number"
                    id="npwp_number"
                    className="form-control"
                    value={userData.npwp_number}
                    onChange={(event) => handleChange("npwp_number", event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    required
                    value={userData.email}
                    onChange={(event) => handleChange("email", event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="birthdate_place">
                    Tempat Lahir <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="birthdate_place"
                    id="birthdate_place"
                    className="form-control"
                    required
                    value={userData.birthdate_place}
                    onChange={(event) => handleChange("birthdate_place", event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="birthdate">
                    Tanggal Lahir <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    name="birthdate"
                    id="birthdate"
                    className="form-control"
                    value={userData.birthdate}
                    onChange={(event) => handleChange("birthdate", event.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="gender">
                    Jenis Kelamin <span className="text-danger">*</span>
                  </label>
                  <select
                    className="input form-control"
                    name="gender"
                    id="gender"
                    value={userData.gender}
                    onChange={(event) => handleChange("gender", event.target.value)}
                  >
                    <option value="">-- Pilih Jenis Kelamin --</option>
                    <option value="L">Laki-laki</option>
                    <option value="P">Perempuan</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="phone_number">
                    Nomor Telepon <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    value={userData.phone_number}
                    onChange={(event) => handleChange("phone_number", event.target.value)}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone_number">Nomor Telepon Alternatif</label>
                  <input
                    type="text"
                    name="alternate_phone_number"
                    id="alternate_phone_number"
                    value={userData.alternate_phone_number}
                    onChange={(event) => handleChange("alternate_phone_number", event.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">
                    Alamat Lengkap <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    className="form-control"
                    value={userRegion.address}
                    onChange={(event) => userRegion.setAddress(event.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="addressRT">
                    RT (3 Digit) <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="addressRT"
                    id="addressRT"
                    className="form-control"
                    value={userRegion.rt}
                    onChange={(event) => userRegion.setRt(event.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="addressRW">
                    RW (3 Digit) <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="addressRW"
                    id="addressRW"
                    className="form-control"
                    value={userRegion.rw}
                    onChange={(event) => userRegion.setRw(event.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="user_province">
                    Provinsi <span className="text-danger">*</span>
                  </label>
                  <select
                    className="input form-control"
                    id="user_province"
                    required
                    value={userRegion.province}
                    onChange={(event) => userRegion.setProvince(event.target.value)}
                  >
                    <option value="">-- Pilih Provinsi --</option>
                    {userRegion.provinces.map((province, index) => (
                      <option key={index} value={province.province}>
                        {province.province}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="user_city">
                    Kota/Kabupaten <span className="text-danger">*</span>
                  </label>
                  <select
                    className="input form-control"
                    id="user_city"
                    required
                    value={userRegion.city}
                    onChange={(event) => userRegion.setCity(event.target.value)}
                  >
                    <option value="">-- Pilih Kota / Kab --</option>
                    {userRegion.cities.map((city, index) => (
                      <option key={index} value={city.kota_kab}>
                        {city.kota_kab}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="your_email">
                    Kecamatan <span className="text-danger">*</span>
                  </label>
                  <select
                    className="input form-control"
                    id="user_district"
                    required
                    value={userRegion.district}
                    onChange={(event) => userRegion.setDistrict(event.target.value)}
                  >
                    <option value="">-- Pilih Kecamatan --</option>
                    {userRegion.districts.map((district, index) => (
                      <option key={index} value={district.kecamatan}>
                        {district.kecamatan}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="user_kelurahan">
                    Kelurahan <span className="text-danger">*</span>
                  </label>
                  <select
                    className="input form-control"
                    id="user_kelurahan"
                    required
                    value={userRegion.addressId}
                    onChange={(event) => userRegion.setAddressId(event.target.value)}
                  >
                    <option value="">-- Pilih Kelurahan --</option>
                    {userRegion.addresslist.map((kelurahan, index) => (
                      <option key={index} value={kelurahan.id}>
                        {kelurahan.kelurahan} ({kelurahan.postal_code})
                      </option>
                    ))}
                  </select>
                </div>

                <h4 className="mt-4 mb-2">Data Pemasangan</h4>
                <div className="form-check px-1">
                  <input
                    type="checkbox"
                    id="same-address"
                    name="checkbox"
                    className="mr-2"
                    checked={sameAddress}
                    onChange={() => setSameAddress(!sameAddress)}
                  />
                  <label htmlFor="same-address">
                    Data alamat pemasangan sama dengan alamat pelanggan
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="building_ownership_status">
                    Status Lokasi Pemasangan<span className="text-danger">*</span>
                  </label>
                  <select
                    className="input form-control"
                    id="building_ownership_status"
                    value={userData.building_ownership_status}
                    onChange={(event) =>
                      handleChange("building_ownership_status", event.target.value)
                    }
                  >
                    <option value="">-- Status --</option>
                    <option value="OWN">Milik Sendiri</option>
                    <option value="RENT">Sewa</option>
                    <option value="BOARDING">Kost</option>
                    {allowPublicFacilitiesInput(user.roles, ["admin", "cs"]) && (
                      <option value="PUBLIC_FACILITIES">Fasilitas Umum</option>
                    )}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="building_ownership_status">Kelompok/Project</label>
                  <select
                    className="input form-control"
                    id="building_ownership_status"
                    value={userData.project_id}
                    onChange={(event) => handleChange("project_id", event.target.value)}
                  >
                    <option value="">-- Kelompok/Project --</option>
                    {projects.map((project, index) => (
                      <option key={index} value={project.id}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Collapsible install address */}
                <Collapse in={!sameAddress}>
                  <div>
                    <div className="form-group">
                      <label htmlFor="install_address">
                        Alamat Lengkap <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="install_address"
                        id="install_address"
                        className="form-control"
                        required={!sameAddress}
                        value={installRegion.address}
                        onChange={(event) => installRegion.setAddress(event.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="install_addressRT">
                        RT (3 Digit) <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="install_addressRT"
                        id="install_addressRT"
                        required={!sameAddress}
                        className="form-control"
                        value={installRegion.rt}
                        onChange={(event) => installRegion.setRt(event.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="install_addressRW">
                        RW (3 Digit) <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="install_addressRW"
                        id="install_addressRW"
                        required={!sameAddress}
                        className="form-control"
                        value={installRegion.rw}
                        onChange={(event) => installRegion.setRw(event.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="install_province">
                        Provinsi <span className="text-danger">*</span>
                      </label>
                      <select
                        className="input form-control"
                        id="install_province"
                        required={!sameAddress}
                        value={installRegion.province}
                        onChange={(event) => installRegion.setProvince(event.target.value)}
                      >
                        <option value="">-- Pilih Provinsi --</option>
                        {installRegion.provinces.map((province, index) => (
                          <option key={index} value={province.province}>
                            {province.province}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="install_city">
                        Kota/Kabupaten <span className="text-danger">*</span>
                      </label>
                      <select
                        className="input form-control"
                        id="install_city"
                        required={!sameAddress}
                        value={installRegion.city}
                        onChange={(event) => installRegion.setCity(event.target.value)}
                      >
                        <option value="">-- Pilih Kota / Kab --</option>
                        {installRegion.cities.map((city, index) => (
                          <option key={index} value={city.kota_kab}>
                            {city.kota_kab}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="install_district">
                        Kecamatan <span className="text-danger">*</span>
                      </label>
                      <select
                        className="input form-control"
                        id="install_district"
                        required={!sameAddress}
                        value={installRegion.district}
                        onChange={(event) => installRegion.setDistrict(event.target.value)}
                      >
                        <option value="">-- Pilih Kecamatan --</option>
                        {installRegion.districts.map((district, index) => (
                          <option key={index} value={district.kecamatan}>
                            {district.kecamatan}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="install_kelurahan">
                        Kelurahan <span className="text-danger">*</span>
                      </label>
                      <select
                        className="input form-control"
                        id="install_kelurahan"
                        required={!sameAddress}
                        value={installRegion.addressId}
                        onChange={(event) => installRegion.setAddressId(event.target.value)}
                      >
                        <option value="">-- Pilih Kelurahan --</option>
                        {installRegion.addresslist.map((kelurahan, index) => (
                          <option key={index} value={kelurahan.id}>
                            {kelurahan.kelurahan} ({kelurahan.postal_code})
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Collapse>

                <div className="form-group-last">
                  <input
                    type="submit"
                    name="register"
                    className="btn btn-primary"
                    value={loading ? "Menunggu ..." : "Simpan"}
                    disabled={loading}
                  />
                </div>
              </form>
            </Box>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
