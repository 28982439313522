import { useState } from "react";
import Box from "../components/Box";
import Pagination from "../components/Pagination";
import ModalCreatePack from "../components/internetpacks/ModalCreatePack";
import ModalDeletePack from "../components/internetpacks/ModalDeletPack";
import ModalInternetPackageGroup from "../components/internetpacks/ModalInternetPackageGroup";
import ModalUpdatePack from "../components/internetpacks/ModalUpdatePack";
import MainLayout from "../components/layouts/MainLayout";
import { convertToRupiah } from "../tools/numerictools";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import {
  useInternetPacakageList,
  usePackTypeList,
  useVariantsList,
} from "../utils/InternetPackageUtils";
import { useGroupListHooks } from "../utils/ProjectsUtilHooks";

let filterVariants = { show_all: true };

export default function InternetPackagePage() {
  let { hasAccess } = useControlPermission();

  let [filter, setFilter] = useState({
    internet_package_type_id: "",
    internet_package_varian_id: "",
    name: "",
    project_id: "",
    public_access: "",
    trial: "",
    page_size: 10,
    page: 1,
  });
  let [formFilter, setFormFilter] = useState({
    internet_package_type_id: "",
    internet_package_varian_id: "",
    name: "",
    project_id: "",
    public_access: "",
    trial: "",
  });
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [selectedId, setSelectedId] = useState("");

  let [showModalCreate, setShowModalCreate] = useState(false);
  let [showModalUpdate, setShowModalUpdate] = useState(false);
  let [showModalDelete, setShowModalDelete] = useState(false);
  let [showModalGroup, setShowModalGroup] = useState(false);

  let internetPacakages = useInternetPacakageList({ filter });
  const typePkg = usePackTypeList({ filter: filterVariants });
  const variantPkg = useVariantsList({ filter: filterVariants });
  const { projects } = useGroupListHooks();

  const onSubmitFilter = () => {
    setFilter({ ...filter, ...formFilter, page: 1 });
  };

  const onResetFilter = () => {
    setFilter({
      internet_package_type_id: "",
      internet_package_varian_id: "",
      name: "",
      project_id: "",
      public_access: "",
      trial: "",
      page_size: 10,
      page: 1,
    });
    setFormFilter({
      internet_package_type_id: "",
      internet_package_varian_id: "",
      name: "",
      project_id: "",
      public_access: "",
      trial: "",
    });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const onSuccess = (show, message) => {
    internetPacakages.getPackages(filter);
    setAlert({ show, message, type: "success" });
  };

  const toggleOpenModalUpdate = (id = "") => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleOpenModalDelete = (id = "") => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const toggleOpenModalGroup = (id = "") => {
    setSelectedId(id);
    setShowModalGroup(!showModalGroup);
  };

  return (
    <MainLayout resource_key="manage-package">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Manajemen Paket</h1>
            </div>
            <div className="col-auto">
              {hasAccess("create-package") && (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowModalCreate(!showModalCreate)}
                >
                  <i className="fa fa-plus"></i> Tambah Paket
                </button>
              )}
            </div>
          </div>
        </div>

        <Box title="Daftar Paket" loading={internetPacakages.loading}>
          <div className="mb-4">
            <div className="row">
              <div className="col-12 col-lg-2">
                <label htmlFor="nameFilter">Nama Paket</label>
                <input
                  type="text"
                  id="nameFilter"
                  className="form-control"
                  value={formFilter.name}
                  onChange={(event) => setFormFilter({ ...formFilter, name: event.target.value })}
                />
              </div>
              <div className="col-12 col-lg-2">
                <label htmlFor="typeFilter">Tipe Paket</label>
                <select
                  className="form-control"
                  id="typeFilter"
                  value={formFilter.internet_package_type_id}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, internet_package_type_id: event.target.value })
                  }
                >
                  <option value="">SEMUA</option>
                  {typePkg.types.map((types, index) => (
                    <option value={types.id} key={index}>
                      {types.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-lg-2">
                <label htmlFor="varianFilter">Varian Paket</label>
                <select
                  className="form-control"
                  id="varianFilter"
                  value={formFilter.internet_package_varian_id}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, internet_package_varian_id: event.target.value })
                  }
                >
                  <option value="">SEMUA</option>
                  {variantPkg.variants.map((variant, index) => (
                    <option value={variant.id} key={index}>
                      {variant.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-lg-2">
                <label htmlFor="projectFilter">Grup/Project</label>
                <select
                  className="form-control"
                  id="projectFilter"
                  value={formFilter.project_id}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, project_id: event.target.value })
                  }
                >
                  <option value="">SEMUA</option>
                  {projects.map((project, index) => (
                    <option value={project.id} key={index}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-lg-2">
                <label htmlFor="publicFilter">Publik</label>
                <select
                  className="form-control"
                  id="publicFilter"
                  value={formFilter.public_access}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, public_access: event.target.value })
                  }
                >
                  <option value="">SEMUA</option>
                  <option value="true">PUBLIK</option>
                  <option value="false">TIDAK PUBLIK</option>
                </select>
              </div>
              <div className="col-12 col-lg-2">
                <label htmlFor="trialFilter">Trial</label>
                <select
                  className="form-control"
                  id="trialFilter"
                  value={formFilter.trial}
                  onChange={(event) => setFormFilter({ ...formFilter, trial: event.target.value })}
                >
                  <option value="">SEMUA</option>
                  <option value="true">TRIAL</option>
                  <option value="false">TIDAK TRIAL</option>
                </select>
              </div>
              <div className="col-12 col-lg-2">
                <label className="d-block">&nbsp;</label>
                <button className="btn btn-primary" onClick={() => onSubmitFilter()}>
                  Filter
                </button>
                <button className="btn btn-default" onClick={() => onResetFilter()}>
                  Reset
                </button>
              </div>
            </div>
          </div>

          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div style={{ overflowX: "scroll" }}>
            <table className="table table-striped table-bordered table-nowrap">
              <thead>
                <tr className="text-center">
                  <th></th>
                  <th>Prioritas</th>
                  <th>Nama Paket</th>
                  <th>Tipe</th>
                  <th>Varian Paket</th>
                  <th>Publik</th>
                  <th>Deskripsi</th>
                  <th>Berulang</th>
                  <th>Trial</th>
                  <th>Harga Normal</th>
                  <th>Harga Jual</th>
                  <th>Biaya Pemasangan</th>
                  <th>Deposit</th>
                  <th>Project</th>
                </tr>
              </thead>
              <tbody>
                {internetPacakages.packages.length === 0 && (
                  <tr>
                    <td colSpan={13} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {internetPacakages.packages.map((pack, index) => (
                  <tr key={index}>
                    <td>
                      {hasAccess("manage-package-project") && (
                        <button
                          className="btn btn-sm btn-info btn-block"
                          onClick={() => toggleOpenModalGroup(pack.id)}
                        >
                          <i className="fa fa-layer-group"></i> Kelola Grup
                        </button>
                      )}
                      {hasAccess("update-package") && (
                        <button
                          className="btn btn-sm btn-primary btn-block"
                          onClick={() => toggleOpenModalUpdate(pack.id)}
                        >
                          <i className="fa fa-pencil-alt"></i> Edit
                        </button>
                      )}
                      {hasAccess("delete-package") && (
                        <button
                          className="btn btn-sm btn-danger btn-block"
                          onClick={() => toggleOpenModalDelete(pack.id)}
                        >
                          <i className="fa fa-trash"></i> Hapus
                        </button>
                      )}
                    </td>
                    <td>{pack.priority}</td>
                    <td>{pack.name}</td>
                    <td>{pack.internet_package_type ? pack.internet_package_type.name : "-"}</td>
                    <td>
                      {pack.internet_package_varian ? pack.internet_package_varian.name : "-"}
                    </td>
                    <td>{pack.public_access ? "Ya" : "Tidak"}</td>
                    <td style={{ whiteSpace: "normal" }}>{pack.description}</td>
                    <td>{pack.recurring ? "Ya" : "Tidak"}</td>
                    <td>{pack.trial ? "Ya" : "Tidak"}</td>
                    <td>Rp {convertToRupiah(pack.normal_price)}</td>
                    <td>Rp {convertToRupiah(pack.price)}</td>
                    <td>Rp {convertToRupiah(pack.installation_cost)}</td>
                    <td>Rp {convertToRupiah(pack.deposit_amount)}</td>
                    <td className="text-nowrap">
                      {pack.projects.length !== 0 ? (
                        <ul className="px-3">
                          {pack.projects.map((project) => (
                            <li key={project.id}>{project.project.name}</li>
                          ))}
                        </ul>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {internetPacakages.meta.count > internetPacakages.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(internetPacakages.meta.count / internetPacakages.meta.page_size)}
              selectedpage={internetPacakages.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(internetPacakages.meta.page + 1)}
              prev={() => gotoPage(internetPacakages.meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalCreatePack show={showModalCreate} onClose={setShowModalCreate} onSuccess={onSuccess} />
      <ModalUpdatePack
        show={showModalUpdate}
        onClose={toggleOpenModalUpdate}
        id={showModalUpdate ? selectedId : ""}
        onSuccess={onSuccess}
      />
      <ModalDeletePack
        show={showModalDelete}
        onClose={toggleOpenModalDelete}
        id={showModalDelete ? selectedId : ""}
        onSuccess={onSuccess}
      />
      <ModalInternetPackageGroup
        show={showModalGroup}
        onClose={toggleOpenModalGroup}
        id={showModalGroup ? selectedId : ""}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
