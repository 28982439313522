import { apiAuth, apiDownloadAuth } from "./base";

export function getAllProjects(params) {
  let api = apiAuth();
  return api.get(`v1/projects`, { params });
}

export function createProject(data) {
  let api = apiAuth();
  return api.post(`v1/projects`, data);
}

export function getProjectById(id) {
  let api = apiAuth();
  return api.get(`v1/projects/${id}`);
}

export function getProjectInventoryById(id) {
  let api = apiAuth();
  return api.get(`v1/projects/${id}/inventories`);
}

export function updateProjectInventoryById(id, data) {
  let api = apiAuth();
  return api.put(`v1/projects/${id}/inventories`, data);
}

export function updateProjectById(id, data) {
  let api = apiAuth();
  return api.put(`v1/projects/${id}`, data);
}

export function deleteProjectnById(id) {
  let api = apiAuth();
  return api.delete(`v1/projects/${id}`);
}

export function getListProjects() {
  let api = apiAuth();
  return api.get(`v1/projects/show-all`);
}

export function getAllVendors(params) {
  let api = apiAuth();
  return api.get(`v1/vendors`, { params });
}

export function getVendors(params) {
  let api = apiAuth();
  return api.get(`v1/vendors`, { params });
}

export function getVendorById(id) {
  let api = apiAuth();
  return api.get(`v1/vendors/${id}`);
}

export function createVendor(data) {
  let api = apiAuth();
  return api.post(`v1/vendors`, data);
}

export function updateVendorById(id, data) {
  let api = apiAuth();
  return api.put(`v1/vendors/${id}`, data);
}

export function deleteVendorById(id) {
  let api = apiAuth();
  return api.delete(`v1/vendors/${id}`);
}

export function showAllVendor() {
  let api = apiAuth();
  return api.get(`v1/vendors/show-all`);
}

export function getVendorsByProjectId(id) {
  let api = apiAuth();
  return api.get(`v1/projects/${id}/vendors`);
}

export function createVendorsByProjectId(id, data) {
  let api = apiAuth();
  return api.post(`v1/projects/${id}/vendors`, data);
}

export function getProjectVendorById(id) {
  let api = apiAuth();
  return api.get(`v1/projects/vendors/${id}`);
}

export function updateProjectVendorById(id, data) {
  let api = apiAuth();
  return api.put(`v1/projects/vendors/${id}`, data);
}

export function deleteProjectVendorById(id) {
  let api = apiAuth();
  return api.delete(`v1/projects/vendors/${id}`);
}

export function getVendorTask(id) {
  let api = apiAuth();
  return api.get(`v1/vendors/vendor-tasks/${id}`);
}

export function createVendorTask(id, data) {
  let api = apiAuth();
  return api.post(`v1/vendors/vendor-tasks/${id}`, data);
}

export function updateTaskProgressById(id, data) {
  let api = apiAuth();
  return api.put(`v1/vendors/reports/${id}`, data);
}

export function downloadVendorTasks(id, params) {
  let api = apiDownloadAuth();
  return api.get(`/v1/vendors/vendor-tasks/${id}/xlsx`, { params });
}

export function getTaskById(id) {
  let api = apiAuth();
  return api.get(`v1/vendors/tasks/${id}`);
}

export function updateTaskById(id, data) {
  let api = apiAuth();
  return api.put(`v1/vendors/tasks/${id}`, data);
}

export function deleteTaskById(id) {
  let api = apiAuth();
  return api.delete(`v1/vendors/tasks/${id}`);
}

export function getAllPartners(params) {
  let api = apiAuth();
  return api.get(`v1/partners`, { params });
}

export function getPartnerById(id) {
  let api = apiAuth();
  return api.get(`v1/partners/${id}`);
}

export function createPartner(data) {
  let api = apiAuth();
  return api.post(`v1/partners`, data);
}

export function updatePartnerById(id, data) {
  let api = apiAuth();
  return api.put(`v1/partners/${id}`, data);
}

export function deletePartnerById(id) {
  let api = apiAuth();
  return api.delete(`v1/partners/${id}`);
}

export function presetsAllPartners() {
  let api = apiAuth();
  return api.get(`v1/presets/partner`);
}

export function getAllCustomerPartnerInfra(params) {
  let api = apiAuth();
  return api.get(`v1/partner-infrastructures/customers`, { params });
}

export function downloadCustomerPartnerInfra(params) {
  let api = apiDownloadAuth();
  return api.get(`v1/partner-infrastructures/customers/export/xlsx`, { params });
}

export function getAllBillingPartnerInfra(params) {
  let api = apiAuth();
  return api.get(`v1/partner-infrastructures/billings`, { params });
}

export function downloadBillingPartnerInfra(params) {
  let api = apiDownloadAuth();
  return api.get(`v1/partner-infrastructures/billings/export/xlsx`, { params });
}

export function getAllSplitterPartnerInfra(params) {
  let api = apiAuth();
  return api.get(`v1/partner-infrastructures/odp-splitters`, { params });
}

export function presetPartnerOdpSplitter(params) {
  let api = apiAuth();
  return api.get(`v1/presets/odp-splitter`, { params });
}

export function checkProvisioningFromPartnerInfra(id) {
  let api = apiAuth();
  return api.put(`v1/partner-infrastructures/provisionings/${id}/status`);
}

export function getAllProvisioningFromPartnerInfra(params) {
  let api = apiAuth();
  return api.get(`v1/partner-infrastructures/provisionings`, { params });
}
