import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateTypeById } from "../../api/internetpackage";
import { useTypePackHooks } from "../../utils/InternetPackageUtils";

export default function ModalUpdateType({ show, id, onClose, onSuccess }) {
  let [form, setForm] = useState({name: '', description: ''});
  let [loading, setLoading] = useState(false);
  let [alert, setAlert] = useState({show: false, message: '', type: ''});


  let typePkg = useTypePackHooks({ id });

  useEffect(() => {
    if (typePkg.fetched) {
      setForm({
        name: typePkg.data.name,
        description: typePkg.data.description,
      })
    }
  }, [typePkg.fetched])

  useEffect(() => {
    if (typePkg.error) {
      setAlert({show: true, message: typePkg.error, type: 'danger'})
    }
  }, [typePkg.error])

  const handleClose = () => {
    setForm({name: '', description: ''});
    setAlert({show: false, message: '', type: ''})
    onClose(false);
  }

  const submitData = (event) => {
    event.preventDefault();
    updatePackage(form);
  }

  const updatePackage = async (form) => {
    setLoading(true);
    try {
      let response = await updateTypeById(id, form);
      onSuccess(true, response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }

    setLoading(false);
  }

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={event => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Ubah Data Paket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.show &&
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button type="button" className="close"  onClick={() => setAlert({show: false, message: '', type: ''})} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          }

          <div className="form-group">
            <label htmlFor="">Nama Tipe Paket <span className="text-danger">*</span></label>
            <input type="text" className="form-control" required value={form.name} onChange={event => setForm({ ...form, name: event.target.value})} />
          </div>
          <div className="form-group">
            <label htmlFor="">Deskripsi <span className="text-danger">*</span></label>
            <textarea type="text" className="form-control" required value={form.description} onChange={event => setForm({ ...form, description: event.target.value})} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>Close</button>
          <button type="submit" className="btn btn-primary" disabled={loading}><i className="fas fa-save"></i> Simpan Perubahan</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}