import { apiAuth } from "./base";

export function getStatisticRegistration() {
  let api = apiAuth();
  return api.get(`v1/registrations/reports/statistic`);
}

export function getTopLocation () {
  let api = apiAuth();
  return api.get(`v1/registrations/reports/location`);
}

export function getTrafficReports () {
  let api = apiAuth();
  return api.get(`v1/reports/traffic`);
}

export function getTrafficStats (params) {
  let api = apiAuth();
  return api.get(`v1/reports/traffic-stats`, { params });
}


export function getOnlineCustomer () {
  let api = apiAuth();
  return api.get(`v1/reports/customer`);
}

export function getStatisticCustomer () {
  let api = apiAuth();
  return api.get(`v1/reports/customer-stats`);
}