import moment from "moment";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { downloadVendorTasks } from "../../api/projects";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import ModalTaskCreate from "../../components/project/ModalTaskCreate";
import ModalTaskDelete from "../../components/project/ModalTaskDelete";
import ModalTaskUpdate from "../../components/project/ModalTaskUpdate";
import ModalTaskUpdateProgress from "../../components/project/ModalTaskUpdateProgress";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import {
  useProjectVendorHooks,
  useVendorTasksByProjectHooks,
} from "../../utils/project/VendorProjectUtils";

export default function ProjectVendorReportPage() {
  let { hasAccess } = useControlPermission();
  const { id } = useParams();

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [selectedId, setSelectedId] = useState(null);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalProgress, setShowModalProgress] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const taskList = useVendorTasksByProjectHooks({ id });
  const vendor = useProjectVendorHooks({ id });

  useEffect(() => {
    taskList.fetchTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModalUpdate = (id) => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleModalProgress = (id) => {
    setSelectedId(id);
    setShowModalProgress(!showModalProgress);
  };

  const toggleModalDelete = (id) => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    taskList.fetchTask();
  };

  const onError = (message) => {
    setAlert({ show: true, message, type: "danger" });
  };

  return (
    <MainLayout resource_key="manage-project">
      <div className="container-fluid">
        {vendor.data.project && <BackToProjectVendorLink project={vendor.data.project} />}
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Progress Report</h1>
            </div>
            <div className="col-auto">
              {vendor.fetched && hasAccess("export-project-vendor-task") && (
                <DownloadReportButton
                  vendor={vendor.data}
                  onSuccess={onSuccess}
                  onError={onError}
                />
              )}
            </div>
          </div>
        </div>
        <Box title="Progress Report" loading={taskList.loading || vendor.loading}>
          <AlertBox {...alert} setAlert={setAlert} />
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td>Vendor</td>
                <td>{vendor.data.vendor?.name || "-"}</td>
              </tr>
              <tr>
                <td>Alamat</td>
                <td>
                  {vendor.fetched
                    ? `
                      ${vendor.data.vendor.address}, ${vendor.data.vendor.addresses.kelurahan}, ${vendor.data.vendor.addresses.kecamatan}
                      , ${vendor.data.vendor.addresses.kota_kab}, ${vendor.data.vendor.addresses.province}, ${vendor.data.vendor.addresses.postal_code}
                    `
                    : "-"}
                </td>
              </tr>
              <tr>
                <td>PIC</td>
                <td>{vendor.data.pic || "-"}</td>
              </tr>
              <tr>
                <td>Project</td>
                <td>{vendor.data.project?.name}</td>
              </tr>
              <tr>
                <td>Tanggal PO</td>
                <td>
                  {vendor.data.createdAt &&
                    moment.unix(vendor.data.createdAt).format("DD MMMM YYYY")}
                </td>
              </tr>
              <tr>
                <td>Tanggal Pembaruan</td>
                <td>
                  {vendor.data.last_update &&
                    moment.unix(vendor.data.last_update).format("DD MMMM YYYY")}
                </td>
              </tr>
              <tr>
                <td>Persentase</td>
                <td>{vendor.data.percentage}</td>
              </tr>
            </tbody>
          </table>
          <div className="row justify-content-end mb-3">
            <div className="col-auto">
              {hasAccess("create-project-vendor-task") && (
                <button className="btn btn-primary btn-sm" onClick={() => setShowModalCreate(true)}>
                  <i className="fa fa-plus"></i> Tambah Pekerjaan
                </button>
              )}
            </div>
          </div>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>No</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Done</th>
                <th>Percentage</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {taskList.tasks.length === 0 && (
                <tr>
                  <td colSpan={6} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
              {taskList.tasks.map((task, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{task.name}</td>
                  <td>{task.qty}</td>
                  <td>{task.done}</td>
                  <td>{task.percentage}</td>
                  <td>
                    {hasAccess("update-project-vendor-task-progress") && (
                      <button
                        className="btn btn-sm btn-primary mr-2"
                        onClick={() => toggleModalProgress(task.id)}
                      >
                        <i className="fa fa-bars-progress"></i> Update Progress
                      </button>
                    )}
                    {hasAccess("update-project-vendor-task") && (
                      <button
                        className="btn btn-sm btn-info mr-2"
                        onClick={() => toggleModalUpdate(task.id)}
                      >
                        <i className="fa fa-pencil-alt"></i> Edit
                      </button>
                    )}
                    {hasAccess("delete-project-vendor-task") && (
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => toggleModalDelete(task.id)}
                      >
                        <i className="fa fa-trash"></i> Hapus
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </div>

      <ModalTaskCreate
        show={showModalCreate}
        onClose={() => setShowModalCreate(!showModalCreate)}
        onSuccess={onSuccess}
      />
      <ModalTaskUpdate
        show={showModalUpdate}
        id={showModalUpdate ? selectedId : null}
        onClose={() => toggleModalUpdate()}
        onSuccess={onSuccess}
      />
      <ModalTaskUpdateProgress
        show={showModalProgress}
        id={showModalProgress ? selectedId : null}
        onClose={() => toggleModalProgress()}
        onSuccess={onSuccess}
      />
      <ModalTaskDelete
        show={showModalDelete}
        id={showModalDelete ? selectedId : null}
        onClose={() => toggleModalDelete()}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function BackToProjectVendorLink({ project }) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={`/project/${project.id}/work`}>
            <i className="fa-regular fa-circle-left"></i> Kembali ke Halaman Pekerjaan Proyek "
            {project.name}"
          </Link>
        </li>
      </ol>
    </nav>
  );
}

function DownloadReportButton({ vendor, onSuccess, onError }) {
  const [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    setLoading(true);
    try {
      let response = await downloadVendorTasks(vendor.id);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Progress Report - ${vendor.vendor.name}.xlsx`);
      onSuccess(`Berhasil mengunduh Progress Report - ${vendor.vendor.name}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      onError(message);
    }
    setLoading(false);
  };

  return (
    <button className="btn btn-primary" disabled={loading} onClick={() => downloadFile()}>
      <i className="fa fa-download"></i> {loading ? "Downloading ..." : "Download Progress Report"}
    </button>
  );
}
