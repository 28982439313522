import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { downloadTransferList } from "../../../api/finance";
import AlertBox from "../../AlertBox";

export default function ModalDownloadTransferList({ show, onClose, onSuccess }) {
  const [formFilter, setFormFilter] = useState({ q: "", n: "", type: "CREDIT", date: "" });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    resetFilter();
    onClose();
  };

  const resetFilter = () => {
    setFormFilter({ q: "", n: "", type: "CREDIT", date: "" });
  };

  const downloadFile = async () => {
    setLoading(true);
    try {
      let response = await downloadTransferList({
        ...formFilter,
        date: formFilter.date ? moment(formFilter.date).format("DD-MM-YYYY") : "",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Auto Transfer List.xlsx");
      onSuccess("Berhasil mengunduh Data Auto Transfer List.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.message) {
        message = error.message;
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Download Data Auto Transfer List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form>
          <div className="form-group">
            <label>No. Billing / Transfer Amount</label>
            <input
              type="text"
              className="form-control"
              value={formFilter.n}
              onChange={(event) => changeFilter("n", event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="remark">Remark</label>
            <input
              type="text"
              className="form-control"
              value={formFilter.q}
              onChange={(event) => changeFilter("q", event.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Tipe Transaksi</label>
            <select
              name="status"
              value={formFilter.type}
              onChange={(event) => changeFilter("type", event.target.value)}
              className="form-control"
              id="status"
            >
              <option value="CREDIT">CREDIT</option>
              <option value="DEBIT">DEBIT</option>
              <option value="">SEMUA</option>
            </select>
          </div>
          <div className="form-group">
            <label>Status</label>
            <select
              name="status"
              value={formFilter.status}
              onChange={(event) => changeFilter("status", event.target.value)}
              className="form-control"
              id="status"
            >
              <option value="">SEMUA</option>
              <option value="unprocessed">Belum Diproses</option>
              <option value="auto-confirm">Auto Confirm</option>
              <option value="manual-confirm">Manual Confirm</option>
              <option value="manual-match">Manual Match</option>
            </select>
          </div>
          <div className="form-group">
            <label>Transaction Date</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.date}
              onChange={(event) => changeFilter("date", event.target.value)}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => downloadFile()} disabled={loading}>
          <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
