import { useEffect, useState } from "react";
import { getAllVendors, getVendorById, showAllVendor } from "../../api/projects";

export function useVendorListHooks() {
  const [vendors, setVendors] = useState([]);
  const [meta, setMeta] = useState({count: 0, page: 1, page_size: 10});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getVendors = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('');
    try {
      const { data } = await getAllVendors({ ...filter });
      setVendors(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  return {
    vendors,
    meta,
    loading,
    errorMsg,
    getVendors
  }
}

export function useVendorHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getVendor(id)
    }
  }, [id]);

  const getVendor = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false)
    try {
      let response = await getVendorById(id);
      setData(response.data.data);
      setFetched(true)
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setError(data.message)
      } else {
        setError("Something Error Happened")
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getVendor,
  }
}

export function useAllVendorsHooks() {
  const [vendors, setVendors] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getVendors = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('');
    try {
      const { data } = await showAllVendor({ ...filter });
      setVendors(data.data);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    getVendors();
  }, [])

  return {
    vendors,
    loading,
    errorMsg,
    getVendors
  }
}