import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import { useEffect } from "react";
import Select from "react-select";
import AlertBox from "../AlertBox";
import { updateInfrastructureById } from "../../api/infrastructure";
import { useProjectPerformanceHooks } from "../../utils/infrastructure/ProjectPerformanceUtil";

export default function ModalUpdateProjectSite({ show, id, onClose, onSuccess }) {
  const { data, fetched } = useProjectPerformanceHooks({ id });
  let [form, setForm] = useState({
    description: "",
    homepass: 0,
    active_at: "",
  });

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [projectOpt, setProjectOpt] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const projects = useGroupListHooks();

  useEffect(() => {
    if (fetched) {
      setSelectedProject({ value: data.project.id, label: data.project.name });
      setForm({
        description: data.description,
        homepass: data.homepass,
        active_at: data.active_at,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetched]);

  const handleClose = () => {
    setForm({ description: "", homepass: 0, active_at: "" });
    setSelectedProject(null);
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = {
      ...form,
      project_id: selectedProject?.value,
    };
    updateProject(formData);
  };

  const updateProject = async (form) => {
    setLoading(true);
    try {
      let response = await updateInfrastructureById(id, { ...form });
      onSuccess(true, response.data.message, response.data.data);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  useEffect(() => {
    let projectsTemp = [];

    projects.projects.forEach((project) => {
      let temp = {
        value: project.id,
        label: project.name,
      };
      projectsTemp.push(temp);

      return true;
    });

    setProjectOpt(projectsTemp);
  }, [projects.projects]);

  const handleChangeProject = (selected) => {
    setSelectedProject(selected);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Project Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />

          <div className="form-group">
            <label htmlFor="project">Kelompok/Project</label>
            <Select
              value={selectedProject}
              isClearable
              placeholder="Kelompok / Project"
              required
              onChange={(selected) => handleChangeProject(selected)}
              options={projectOpt}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Tanggal Aktif <span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              required
              value={form.active_at}
              onChange={(event) => setForm({ ...form, active_at: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Jumlah Homepass <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.homepass}
              onChange={(event) => setForm({ ...form, homepass: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi <span className="text-danger">*</span>
            </label>
            <textarea
              type="text"
              className="form-control"
              required
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-plus"></i> Ubah Data Project Site
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
