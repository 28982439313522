import { useEffect } from "react";
import { useHistory } from "react-router";
import { menu } from "../../menu"
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useAuth } from "../../utils/AuthHooks";
import Navbar from './navbar';
import Sidebar from "./sidebar"

export default function MainLayout(props) {
  const {isAuthenticated} = useAuth();
  let history = useHistory();
  const {getPermission} = useControlPermission();

  useEffect(() => {
    if(isAuthenticated === false) {
      history.push('/login')
    }
  }, [isAuthenticated, history])

  useEffect(() => {
    if (props.resource_key) {
      getPermission(props.resource_key)
    }
  }, [getPermission, props.resource_key])

  return (
    <div id="wrapper">
      <Sidebar menu={menu} />
      <div id="content-wrapper" className="d-flex flex-column">
        <div className="content">
          <Navbar />
        </div>
        {props.children}
      </div>
    </div>
  )
}