import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { updateAppAlert } from "../../api/setting";

export default function ModalUpdateAppAlert({ show, selected, onClose, onSuccess }) {
  const [form, setForm] = useState({ title: "", description: "", short_desc: "", status: 1 });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setForm((prev) => ({
        ...prev,
        title: selected.title,
        short_desc: selected.short_desc || "",
        description: selected.description,
        status: selected.status,
      }));
    }
  }, [show, selected]);

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      onClose();
    }
  };

  const submitData = (event) => {
    event.preventDefault();
    onUpdateAppAlert(form);
  };

  const onUpdateAppAlert = async (formData) => {
    setLoading(true);
    try {
      let response = await updateAppAlert(selected.id, formData);
      console.log(response.data);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Pengumuman </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="">
              Judul Pengumuman <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.title}
              maxLength={50}
              onChange={(event) => setForm({ ...form, title: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi Pendek <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.short_desc}
              maxLength={150}
              onChange={(event) => setForm({ ...form, short_desc: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi Pengumuman <span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control"
              required
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Status Keaktifan <span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              required
              value={form.status}
              onChange={(event) => setForm({ ...form, status: event.target.value })}
            >
              <option value="1">Aktif</option>
              <option value="0">Tidak Aktif</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-plus"></i> Simpan Perubahan
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
