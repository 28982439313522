import { createContext, useContext, useEffect } from "react";
import { useState } from "react";
import { getPermissionsByAuth } from "../api/roleaccess";
import { useAuth } from "./AuthHooks";

export function usePermittedMenu() {
  const [permitted, setPermitted] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  const getMenus = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('');
    try {
      const { data } = await getPermissionsByAuth({ ...filter, type: 'menu' });
      
      let permitTemp = {};
      data.data.forEach(menu => {
        permitTemp[menu.name] = true
      });

      setPermitted({ ...permitTemp })
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  // useEffect(() => {
  //   getMenus();
  // }, [])

  useEffect(() => {
    if(auth.isAuthenticated === true) {
      // Refetch menus
      getMenus();
    }

    if(auth.isAuthenticated === false) {
      // is user logged out, set permitted data to empty object
      setPermitted({})
    }
  }, [auth.isAuthenticated])

  return {
    permitted,
    loading,
    errorMsg,
    getMenus
  }
}

const permittedMenuContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvidePermittedMenu({ children }) {
  const menu = usePermittedMenu();
  return <permittedMenuContext.Provider value={menu}>{children}</permittedMenuContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const usePermittedMenuContext = () => {
  return useContext(permittedMenuContext);
};