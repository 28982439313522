import { useEffect, useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import AlertBox from "../../components/AlertBox";
import { useOltReportsByIdHooks, useProjectOltHooks } from "../../utils/infrastructure/OltUtils";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import {
  downloadOltCard,
  syncOltCard,
  updateOltCard,
  updateOltPort,
} from "../../api/infrastructure";
import moment from "moment";

const colorMapPort = (value) => {
  if (value < 21) return "table-success";
  if (value < 41) return "table-warning";
  return "table-danger";
};

export default function OltSlotPage() {
  const { hasAccess } = useControlPermission();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [syncLoading, setSyncLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedPort, setSelectedPort] = useState(null);
  const [modal, setModal] = useState("");

  const { projects } = useProjectOltHooks();
  const {
    data: oltCards,
    fetched,
    loading,
    errorMsg,
    getOltReport,
  } = useOltReportsByIdHooks({ id: selectedId });

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    getOltReport(selectedId);
  };

  const onError = (message) => {
    setAlert({ show: true, message, type: "danger" });
    getOltReport(selectedId);
  };

  const onSuccessDownload = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  const onErrorDownload = (message) => {
    setAlert({ show: true, message, type: "danger" });
  };

  return (
    <MainLayout resource_key="infrastructure">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Ketersediaan OLT</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <div className="form-group">
              <label>Project</label>
              <select
                className="form-control"
                value={selectedId}
                onChange={(event) => setSelectedId(event.target.value)}
              >
                <option value="" disabled>
                  Pilih Project
                </option>
                {projects.map((project, index) => (
                  <option value={project.olt_id} key={index}>
                    {project.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title={`OLT Card`} loading={loading}>
              <table className="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <td style={{ width: "150px" }}>OLT Name</td>
                    <td className="font-weight-bold">{oltCards.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>IP OLT</td>
                    <td className="font-weight-bold">{oltCards.ip || "-"}</td>
                  </tr>
                  <tr>
                    <td>Port OLT</td>
                    <td className="font-weight-bold">{oltCards.port || "-"}</td>
                  </tr>
                  {hasAccess("export-olt-report") && fetched && (
                    <tr>
                      <td>Download</td>
                      <td>
                        <ButtonDownloadReport
                          olt_name={oltCards.name}
                          olt_id={selectedId}
                          onSuccess={onSuccessDownload}
                          onError={onErrorDownload}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
              <AlertBox {...alert} setAlert={setAlert} />
              <div className="auto-horizontal-scroll">
                <table className="table table-bordered table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-nowrap" style={{ position: "sticky", left: "0px" }}>
                        Card
                      </th>
                      {oltCards.port_info?.map((port_info) => (
                        <th className="text-nowrap text-center" key={port_info.number}>
                          Port {port_info.number}
                          <button
                            className="btn btn-sm btn-primary ml-2"
                            onClick={() => {
                              setSelectedPort(port_info);
                              setModal("update-port");
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </button>
                        </th>
                      ))}
                      <th className="text-nowrap text-center">Total</th>
                      <th className="text-nowrap text-center">Occupation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!oltCards.cards && (
                      <tr>
                        <td colSpan={19}>Belum ada data</td>
                      </tr>
                    )}
                    {oltCards.cards?.map((card, index) => (
                      <tr key={index} className="text-center">
                        <td
                          className="text-left text-nowrap font-weight-bold bg-white"
                          style={{ position: "sticky", left: "0px" }}
                        >
                          <span className="d-flex justify-content-between">
                            {card.number ? `Card ${card.number}` : "-"}
                            <div className="pl-2">
                              {hasAccess("sync-olt-report") && card.number ? (
                                <ButtonSyncCard
                                  card={card}
                                  olt_id={selectedId}
                                  onSuccess={onSuccess}
                                  processing={syncLoading}
                                  onProcess={(value) => setSyncLoading(value)}
                                  onError={onError}
                                />
                              ) : (
                                <></>
                              )}
                              {hasAccess("update-olt-report") && (
                                <button
                                  className="btn btn-sm btn-primary ml-2"
                                  onClick={() => {
                                    setSelectedCard(card);
                                    setModal("update");
                                  }}
                                >
                                  <i className="fa fa-pencil"></i>
                                </button>
                              )}
                            </div>
                          </span>
                        </td>
                        {card.ports.map((port, indexY) => (
                          <td
                            key={indexY}
                            className={`${card.number > 0 ? colorMapPort(port.slot_usage) : ""}`}
                          >
                            {card.number > 0 ? port.slot_usage : "-"}
                          </td>
                        ))}
                        <td className="font-weight-bold">
                          {card.number > 0 ? card.total_slot_usage : "-"}
                        </td>
                        <td className="font-weight-bold">
                          {card.number > 0 ? card.ocuppation_rate : "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </div>
        </div>
      </div>

      <ModalEditCard
        show={modal === "update"}
        onClose={() => {
          setSelectedCard(null);
          setModal("");
        }}
        olt_id={selectedId}
        card={selectedCard}
        onSuccess={onSuccess}
      />
      <ModalEditPort
        show={modal === "update-port"}
        onClose={() => {
          setSelectedPort(null);
          setModal("");
        }}
        olt_id={selectedId}
        port={selectedPort}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function ModalEditCard({ card, show, olt_id, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [action, setAction] = useState("");

  const [form, setForm] = useState({
    number: 0,
  });

  useEffect(() => {
    if (card !== null) {
      setForm((prev) => ({
        ...prev,
        number: card.number,
      }));
    }
  }, [card]);

  const updateCard = async (form) => {
    setLoading(true);
    try {
      let response = await updateOltCard(olt_id, card.id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const submitUpdate = (event) => {
    event.preventDefault();
    setAction("update");
    updateCard(form);
  };

  const submitDeactivate = () => {
    const formData = { ...form };
    setAction("deactivate");
    formData.deactivate = "true";
    updateCard(formData);
  };

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Edit Card {card?.number}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form onSubmit={submitUpdate}>
          <div className="form-group">
            <label>Nomor Card</label>
            <input
              type="number"
              min={1}
              className="form-control"
              value={form.number}
              onChange={(event) => setForm({ ...form, number: event.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <button className="btn btn-primary btn-block" disabled={loading}>
              {loading && action === "update" ? "Menyimpan perubahan ..." : "Simpan Perubahan"}
            </button>
          </div>
        </form>
        {card?.number ? (
          <>
            <hr />
            <button
              className="btn-danger btn-block btn"
              onClick={() => submitDeactivate()}
              disabled={loading}
            >
              {loading && action === "deactivate" ? "Menghapus card ..." : "Hapus Card"}
            </button>
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}

function ModalEditPort({ port, show, olt_id, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [form, setForm] = useState({
    remark: "",
    connector_type: "",
  });

  useEffect(() => {
    if (port !== null) {
      setForm((prev) => ({
        ...prev,
        remark: port.remark,
        connector_type: port.connector_type,
      }));
    }
  }, [port]);

  const updatePort = async (form) => {
    setLoading(true);
    try {
      let response = await updateOltPort(olt_id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const submitUpdate = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
      number: port.number,
    };
    updatePort(formData);
  };

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    setForm({ remark: "", connector_type: "" });
    onClose(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Edit Port {port?.number}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form onSubmit={submitUpdate}>
          <div className="form-group">
            <label>Tipe Connector</label>
            <select
              className="form-control"
              value={form.connector_type}
              onChange={(event) => setForm({ ...form, connector_type: event.target.value })}
            >
              <option value="">- Tipe Port -</option>
              <option value="sc/upc">sc/upc</option>
              <option value="lc/upc">lc/upc</option>
              <option value="sc/apc">sc/apc</option>
              <option value="lc/apc">lc/apc</option>
            </select>
          </div>
          <div className="form-group">
            <label>Remark</label>
            <textarea
              className="form-control"
              value={form.remark}
              onChange={(event) => setForm({ ...form, remark: event.target.value })}
            ></textarea>
          </div>
          <div className="form-group">
            <button className="btn btn-primary btn-block" disabled={loading}>
              {loading ? "Menyimpan perubahan ..." : "Simpan Perubahan"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

function ButtonSyncCard({ card, olt_id, processing, onSuccess, onProcess, onError }) {
  const [loading, setLoading] = useState(false);

  const syncCard = async () => {
    setLoading(true);
    onProcess(true);
    try {
      let response = await syncOltCard(olt_id, card.id);
      onProcess(false);
      setLoading(false);

      // on sucess will trigger re render, the loading must be turn false before tell parent to false
      onSuccess(response.data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      onProcess(false);
      setLoading(false);
      onError(message);
    }
  };

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250 }}
      overlay={(props) => (
        <Tooltip id={`button-tooltip-${card.id}`} {...props}>
          Terakhir Update:{" "}
          {card.last_sync ? moment.unix(card.last_sync).format("DD MMM YYYY - HH:mm") : "-"}
        </Tooltip>
      )}
    >
      <button
        className="btn btn-sm btn-info"
        onClick={() => syncCard()}
        disabled={loading || processing}
      >
        {loading ? (
          <Spinner animation="border" variant="light" size="sm" />
        ) : (
          <i className="fa fa-refresh"></i>
        )}
      </button>
    </OverlayTrigger>
  );
}

function ButtonDownloadReport({ olt_name, olt_id, onSuccess, onError }) {
  const [loading, setLoading] = useState(false);

  const downloadDataOltSlot = async () => {
    setLoading(true);
    try {
      let response = await downloadOltCard(olt_id);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Ketersediaan OLT - ${olt_name}.xlsx`);
      onSuccess(`Berhasil mengunduh Ketersediaan OLT - ${olt_name}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      onError(message);
    }

    setLoading(false);
  };

  return (
    <button className="btn btn-primary" onClick={() => downloadDataOltSlot()} disabled={loading}>
      <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
    </button>
  );
}
