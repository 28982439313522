import moment from "moment";
import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import ModalDownloadHistoryModem from "../../components/inventory/modem/ModalDownloadHistoryModem";
import MainLayout from "../../components/layouts/MainLayout";
import { useModemHistoryListHooks } from "../../utils/inventory/ModemUtils";

export default function HistoryModemPage() {
  let [filter, setFilter] = useState({
    n: "",
    available: "",
    page: 1,
    page_size: 10,
    history: true,
  });
  let [formFilter, setFormFilter] = useState({ n: "" });

  let { modems, meta, loading, errorMsg } = useModemHistoryListHooks({ filter });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [showModalDownload, setShowModalDownload] = useState(false);

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
  };

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ n: "" });
    setFilter({ n: "", page: 1, page_size: 10, history: true });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  // const onSuccess = (message) => {
  //   setAlert({ show: true, message: message, type: "success" });
  // };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Riwayat Instalasi Modem</h1>
          </div>
          <div className="col-auto">
            <button className="btn btn-primary" onClick={() => setShowModalDownload(true)}>
              <i className="fas fa-download"></i> Unduh Riwayat Modem
            </button>
          </div>
        </div>
        <Box title="Daftar Modem" loading={loading}>
          <div className="row mb-4">
            <div className="col-sm-2">
              <label>SN Modem/GPON/No. Reg</label>
              <input
                type="text"
                className="form-control"
                value={formFilter.n}
                onChange={(event) => setFormFilter({ ...formFilter, n: event.target.value })}
              />
            </div>
            <div className="col-sm-3">
              <button
                className="btn btn-primary"
                style={{ marginTop: "32px" }}
                onClick={() => applyFilter()}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                style={{ marginTop: "32px" }}
                onClick={() => resetFilter()}
              >
                Reset
              </button>
            </div>
          </div>

          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />

          <div className="auto-horizontal-scroll mb-3">
            <table className="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th>S/N Modem</th>
                  <th>S/N GPON</th>
                  <th>No. Registrasi</th>
                  <th>Nama</th>
                  <th>Tgl. Setup</th>
                  <th>ODP</th>
                  <th>ODC</th>
                  <th>OLT</th>
                  <th>Framed Pool</th>
                </tr>
              </thead>
              <tbody>
                {modems.length === 0 && (
                  <tr>
                    <td colSpan={9} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {modems.map((modem, index) => (
                  <tr key={index}>
                    <td>{modem.modem?.serial_number_modem}</td>
                    <td>{modem.modem?.serial_number_gpon}</td>
                    <td>{modem.registration?.registration_number || "-"}</td>
                    <td>{modem.registration?.name || "-"}</td>
                    <td className="text-nowrap">
                      {moment.unix(modem.createdAt).format("DD MMM YYYY")}
                    </td>
                    <td>{modem.odp}</td>
                    <td>{modem.odc}</td>
                    <td>{modem.olt}</td>
                    <td>{modem.framed_pool}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {meta.count > meta.page_size && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.page_size)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalDownloadHistoryModem
        show={showModalDownload}
        onClose={() => setShowModalDownload(false)}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
