import { useState, useEffect } from "react";
import { getAllAppBanner } from "../../api/setting";

export function useAllAppBannerHooks() {
  let [data, setData] = useState([]);
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllBanner();
  }, []);

  const getAllBanner = async () => {
    setData([]);
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getAllAppBanner();
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getAllBanner,
  };
}
