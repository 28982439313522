import moment from "moment/moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Pagination from "../../Pagination";
import { updateScheduleDismantleById } from "../../../api/schedules";
import { useAuth } from "../../../utils/AuthHooks";
import { useScheduleDismantleHooks, useScheduleListHooks } from "../../../utils/SchedulesUtil";
import AlertBox from "../../AlertBox";
import Box from "../../Box";
import ModalDetailRelocate from "../../schedule/ModalDetailRelocate";

export default function BoxRelocateModem() {
  const auth = useAuth();

  let [formFilter, setFormFilter] = useState({
    status: "WAITING-NOC",
    n: "",
    date_from: "",
    date_until: "",
  });
  let [filter, setFilter] = useState({
    type: "relocate",
    status: "WAITING-NOC",
    n: "",
    date_from: "",
    date_until: "",
    page: 1,
    page_size: 10,
  });

  let schedulesData = useScheduleListHooks({ filter });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [selectedId, setSelectedId] = useState("");
  let [showModalDetail, setShowModalDetail] = useState(false);
  let [showModalRelocate, setShowModalRelocate] = useState(false);

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
    schedulesData.getSchedules(filter);
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const toggleModalRelocate = (id) => {
    setSelectedId(id);
    setShowModalRelocate(!showModalRelocate);
  };

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(!showModalDetail);
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const applyFilter = () => {
    setFilter({ ...filter, ...formFilter, page: 1 });
  };

  const resetFilter = () => {
    setFormFilter({ status: "WAITING-NOC", n: "", date_from: "", date_until: "" });
    setFilter({
      ...filter,
      status: "WAITING-NOC",
      n: "",
      date_from: "",
      date_until: "",
      page: 1,
      page_size: 10,
    });
  };

  const disabledByRole = (roles = [], allowed = []) => {
    return roles.some((role) => allowed.includes(role.name));
  };

  return (
    <>
      <Box title="Permintaan Relocate" loading={schedulesData.loading}>
        <AlertBox {...alert} setAlert={setAlert} />
        <div style={{ overflowX: "auto" }}>
          <div className="row mb-4">
            <div className="col">
              <label>Pencarian</label>
              <input
                type="text"
                className="form-control"
                value={formFilter.n}
                onChange={(event) => changeFilter("n", event.target.value)}
                placeholder="No. Registrasi / Nama / No. Penguasan"
              />
            </div>
            <div className="col">
              <label>Status</label>
              <select
                className="form-control"
                value={formFilter.status}
                onChange={(event) => changeFilter("status", event.target.value)}
              >
                <option value="">SEMUA</option>
                <option value="CHECK-COVERAGE">CHECK COVERAGE</option>
                <option value="WAITING-PAYMENT">WAITING PAYMENT</option>
                <option value="NOT-COVERAGE">NOT COVERAGE</option>
                <option value="WAITING-NOC">WAITING NOC</option>
                <option value="NOC-DONE">NOC DONE</option>
                <option value="DONE">DONE</option>
              </select>
            </div>
            <div className="col">
              <label>Tanggal Dari</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.date_from}
                onChange={(event) => changeFilter("date_from", event.target.value)}
              />
            </div>
            <div className="col">
              <label>Tanggal Sampai</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.date_until}
                onChange={(event) => changeFilter("date_until", event.target.value)}
              />
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
          </div>
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Status</th>
                <th>No Reg</th>
                <th>Nama Pelanggan</th>
                <th>Jadwal</th>
                <th>S/N Modem</th>
              </tr>
            </thead>
            <tbody>
              {schedulesData.schedules.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
              {schedulesData.schedules.map((relocate, index) => (
                <tr key={index}>
                  <td>
                    <button
                      className="btn btn-sm btn-block btn-info mr-1 text-nowrap"
                      onClick={() => toggleModalDetail(relocate.id)}
                    >
                      <i className="fa fa-eye"></i> Detail
                    </button>
                    {/* {((auth.user.role === 'NOC' || auth.user.role === 'ADMIN') && relocate.status === "NOC-DONE") && 
                        <button className="btn btn-sm btn-block btn-success mr-1 text-nowrap" onClick={() => toggleModalDecline(relocate.id)}><i className="fa fa-check"></i> Cancel Dismantle</button>
                      } */}
                    {!disabledByRole(auth.user.roles, ["technician"]) &&
                      relocate.status === "WAITING-NOC" && (
                        <button
                          className="btn btn-sm btn-block btn-warning mr-1 text-nowrap"
                          onClick={() => toggleModalRelocate(relocate.id)}
                        >
                          <i className="fas fa-location-arrow"></i> Siap Relocate
                        </button>
                      )}
                  </td>
                  <td>{relocate.status}</td>
                  <td>{relocate.registration.registration_number}</td>
                  <td>{relocate.registration.name}</td>
                  <td>
                    {relocate.schedule && moment(relocate.schedule).format("DD MMM YYYY - HH:mm")}
                  </td>
                  <td>{relocate.registration.inventory_modem_pair?.modem.serial_number_modem}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {schedulesData.meta.count > schedulesData.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(schedulesData.meta.count / schedulesData.meta.page_size)}
              selectedpage={schedulesData.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(schedulesData.meta.page + 1)}
              prev={() => gotoPage(schedulesData.meta.page - 1)}
            />
          )}
        </div>
      </Box>

      <ModalDetailRelocate
        show={showModalDetail}
        id={showModalDetail ? selectedId : null}
        setShow={toggleModalDetail}
        onSuccess={onSuccess}
      />
      <ModalAcceptRelocateModem
        show={showModalRelocate}
        id={showModalRelocate ? selectedId : null}
        setShow={toggleModalRelocate}
        onSuccess={onSuccess}
      />
    </>
  );
}

function ModalAcceptRelocateModem({ show, id, setShow, onSuccess }) {
  const { data, loading: dataLoading } = useScheduleDismantleHooks({ id });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      setShow();
    }
  };

  const acceptRelocate = async () => {
    setLoading(true);
    try {
      let form = { status: "noc-done" };
      let response = await updateScheduleDismantleById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Terima Permintaan Relokasi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <h5 className="text-gray-800">
          Apakah anda yakin sudah melakukan proses relokasi customer "
          {data.registration?.registration_number} - {data.registration?.name}"?
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>
          Tidak
        </button>
        <button
          className="btn btn-danger"
          onClick={() => acceptRelocate()}
          disabled={loading || dataLoading}
        >
          {loading ? "Menunggu ..." : "Ya"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
