import { useState } from "react";
import { useCustomerBillings } from "../../../utils/customer/CustomerRelationUtils";
import moment from "moment";
import AlertBox from "../../AlertBox";
import Pagination from "../../Pagination";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../../Loading";
import { convertToRupiah } from "../../../tools/numerictools";
import { BILLING_TYPE } from "../../../tools/billingdatatools";

export default function CustomerBillingsList({ id }) {
  const [formFilter, setFormFilter] = useState({
    status: "",
    type: "",
    payment_method: "",
    confirmed_start_date: "",
    confirmed_end_date: "",
    amt_from: "",
    amt_until: "",
    payment_provider: "",
    created_start_date: "",
    created_end_date: "",
    n: "",
  });
  const [filter, setFilter] = useState({
    status: "",
    type: "",
    payment_method: "",
    confirmed_start_date: "",
    confirmed_end_date: "",
    amt_from: "",
    amt_until: "",
    payment_provider: "",
    created_start_date: "",
    created_end_date: "",
    n: "",
    id,
    page: 1,
    page_size: 25,
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const { billings, meta, errorMsg, loading } = useCustomerBillings({ filter });

  function applyFilter() {
    let form_filter = {
      ...filter,
      ...formFilter,
      page: 1,
    };
    setFilter(form_filter);
  }

  const resetFilter = () => {
    setFormFilter({
      status: "",
      type: "",
      payment_method: "",
      confirmed_start_date: "",
      confirmed_end_date: "",
      amt_from: "",
      amt_until: "",
      payment_provider: "",
      created_start_date: "",
      created_end_date: "",
      n: "",
    });
    setFilter({
      status: "",
      type: "",
      payment_method: "",
      confirmed_start_date: "",
      confirmed_end_date: "",
      amt_from: "",
      amt_until: "",
      payment_provider: "",
      created_start_date: "",
      created_end_date: "",
      n: "",
      page: 1,
      page_size: 25,
      id: id,
    });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <div className="pt-4">
      <LoadingInsideBoxWrapper>
        {loading && <LoadingInsideBox />}
        <div className="row mb-4">
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label htmlFor="registration_type">Nomor</label>
              <input
                type="text"
                className="form-control"
                value={formFilter.n}
                onChange={(event) => setFormFilter({ ...formFilter, n: event.target.value })}
              />
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label htmlFor="registration_type">Tipe</label>
              <select
                name="type"
                value={formFilter.type}
                onChange={(event) => setFormFilter({ ...formFilter, type: event.target.value })}
                className="form-control"
                id="status"
              >
                <option value="">SEMUA</option>
                <option value="REGISTRATION">REGISTRATION</option>
                <option value="CUSTOMER">CUSTOMER</option>
                <option value="CUSTOMER-RELOCATE">CUSTOMER RELOCATE</option>
                <option value="ADDITIONAL-ITEM">CUSTOM BILLING</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-md-2">
            <label htmlFor="customer_number">Status</label>
            <select
              className="form-control"
              value={formFilter.status}
              onChange={(event) => setFormFilter({ ...formFilter, status: event.target.value })}
            >
              <option value="">SEMUA</option>
              <option value="PAID">PAID</option>
              <option value="UNPAID">UNPAID</option>
              <option value="CANCELED">CANCELED</option>
            </select>
          </div>

          <div className="col-12 col-md-2">
            <div className="form-group">
              <label>Metode Pembayaran</label>
              <select
                name="status"
                value={formFilter.payment_method}
                onChange={(event) =>
                  setFormFilter({
                    ...formFilter,
                    payment_method: event.target.value,
                    payment_provider: "",
                  })
                }
                className="form-control"
              >
                <option value="">SEMUA</option>
                <option value="bank-transfer">Bank Transfer</option>
                <option value="adakita">Adakita</option>
                <option value="virtual-account">Virtual Account</option>
                <option value="alfamart">Alfamart</option>
                <option value="pulsapro">Pulsapro</option>
                <option value="dukodu">Dukodu</option>
                <option value="indomaret">Indomaret</option>
                <option value="qris">QRIS</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label htmlFor="registration_type">VA Payment Provider</label>
              <select
                name="status"
                value={formFilter.payment_provider}
                disabled={formFilter.payment_method !== "virtual-account"}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, payment_provider: event.target.value })
                }
                className="form-control"
                id="status"
              >
                <option value="">SEMUA</option>
                <option value="bca">BCA</option>
                <option value="winpay">WINPAY</option>
                <option value="nicepay">NICEPAY</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label>Tanggal Konfirm Dari</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.confirmed_start_date}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, confirmed_start_date: event.target.value })
                }
              />
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label>Tanggal Konfirm Sampai</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.confirmed_end_date}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, confirmed_end_date: event.target.value })
                }
              />
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label>Total Pembayaran Minimal</label>
              <input
                type="number"
                className="form-control"
                value={formFilter.amt_from}
                onChange={(event) => setFormFilter({ ...formFilter, amt_from: event.target.value })}
              />
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label>Total Pembayaran Maksimal</label>
              <input
                type="number"
                className="form-control"
                value={formFilter.amt_until}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, amt_until: event.target.value })
                }
              />
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label>Tanggal Billing Dari</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.created_start_date}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, created_start_date: event.target.value })
                }
              />
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label>Tanggal Billing Sampai</label>
              <input
                type="date"
                className="form-control"
                value={formFilter.created_end_date}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, created_end_date: event.target.value })
                }
              />
            </div>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={() => applyFilter()}
              style={{ marginTop: "32px" }}
            >
              Filter
            </button>
            <button
              className="btn btn-default"
              onClick={() => resetFilter()}
              style={{ marginTop: "32px" }}
            >
              Reset
            </button>
          </div>
        </div>

        <AlertBox {...alert} setAlert={setAlert} />
        {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : null}
        <div style={{ overflowX: "auto" }}>
          <table className="table table-bordered table-hover table-striped">
            <thead>
              <tr>
                <th>No. Billing</th>
                <th>Nama</th>
                <th>Total Pembayaran</th>
                <th>Status</th>
                <th>Jatuh Tempo Pembayaran</th>
                <th>Jatuh Tempo Layanan</th>
                <th>Periode Berikutnya</th>
                <th>Konfirmasi Pembayaran</th>
                <th>Metode</th>
                <th>Paket</th>
                <th>Tipe</th>
              </tr>
            </thead>
            <tbody>
              {billings.length === 0 ? (
                <tr>
                  <td colSpan={12} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              ) : null}
              {billings.map((billing) => (
                <tr key={billing.id}>
                  <td className="text-nowrap">{billing.billing_number}</td>
                  {/* Nama Pelanggan */}
                  <td className="text-nowrap">
                    {billing.type === "REGISTRATION"
                      ? billing.registration.name
                      : billing.customer.name}
                  </td>
                  {/* Total Pembayaran */}
                  <td className="text-nowrap">Rp. {convertToRupiah(billing.final_amount)}</td>
                  {/* Status */}
                  <td>{billing.status}</td>
                  {/* Jatuh Tempo Pembayaran */}
                  <td className="text-nowrap">
                    {moment.unix(billing.due_date).format("DD MMM YYYY")}
                  </td>
                  {/* Jatuh Tempo Layanan */}
                  <td className="text-nowrap">
                    {billing.type === "REGISTRATION"
                      ? "-"
                      : billing.plan_end_date
                      ? moment.unix(billing.plan_end_date).format("DD MMM YYYY")
                      : "-"}
                  </td>
                  {/* Periode Berikutnya */}
                  <td className="text-nowrap">
                    {billing.payment?.start_period
                      ? moment(billing.payment.start_period).format("DD MMM YYYY") +
                        " - " +
                        moment(billing.payment.end_period).format("DD MMM YYYY")
                      : "-"}
                  </td>
                  {/* Konfirmasi Pembayaran */}
                  <td className="text-nowrap">
                    {billing.status === "UNPAID"
                      ? "-"
                      : billing.confirmed_date
                      ? moment.unix(billing.confirmed_date).format("DD MMM YYYY - HH:mm")
                      : "-"}
                  </td>
                  {/* Metode */}
                  <td className="text-nowrap">{billing.payment.payment_method || "-"}</td>
                  {/* Paket */}
                  <td>{billing.internet_package.name}</td>
                  {/* Tipe Billing */}
                  <td>{BILLING_TYPE[billing.type] || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {meta.count > meta.page_size && (
          <Pagination
            totalpage={Math.ceil(meta.count / meta.page_size)}
            selectedpage={meta.page}
            clickpage={(page) => gotoPage(page)}
            next={() => gotoPage(meta.page + 1)}
            prev={() => gotoPage(meta.page - 1)}
          />
        )}
      </LoadingInsideBoxWrapper>
    </div>
  );
}
