import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import ModalAddReportUptime from "../../components/infrastrucure/ModalAddReportUptime";
import AlertBox from "../../components/AlertBox";
import { useProjectPerformanceHooks } from "../../utils/infrastructure/ProjectPerformanceUtil";
import moment from "moment";
import ModalDeleteIncident from "../../components/infrastrucure/ModalDeleteIncident";
import ModalUpdateIncident from "../../components/infrastrucure/ModalUpdateIncident";

export default function ProjectUptimePage() {
  const { id } = useParams();
  const { search } = useLocation();

  let [paramsData, setParamsData] = useState({ id: "", start_month: "", end_month: "" });
  let { data, incidents, loading, error, getInfra } = useProjectPerformanceHooks({ ...paramsData });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [selectedId, setSelectedId] = useState("");
  let [showModalCreate, setShowModalCreate] = useState(false);
  let [showModalUpdate, setShowModalUpdate] = useState(false);
  let [showModalDelete, setShowModalDelete] = useState(false);

  useEffect(() => {
    let searchParams = new URLSearchParams(search);
    setParamsData({
      id: id,
      start_month: searchParams.get("start_month"),
      end_month: searchParams.get("end_month"),
    });
  }, [id, search]);

  const toggleOpenModalUpdate = (id = null) => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleOpenModalDelete = (id = null) => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const onSuccess = (show, message) => {
    setAlert({ show, message, type: "success" });
    getInfra(id, { start_month: paramsData.start_month, end_month: paramsData.end_month });
  };

  return (
    <MainLayout resource_key="infrastructure">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Uptime Report {data.project?.name}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title={`Daftar Insiden ${data.project?.name}`} loading={loading}>
              <AlertBox {...alert} setAlert={setAlert} />
              <AlertBox show={!!error} type="danger" message={error} setAlert={() => {}} />
              {/* Info Table */}
              <table className="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <td colSpan={3}>
                      <b>Informasi Project</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Project</td>
                    <td>:</td>
                    <td>{data.project?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td width={150}>Homepass</td>
                    <td width={1}>:</td>
                    <td>{data.homepass || "-"}</td>
                  </tr>
                  <tr>
                    <td>Active PPPOE</td>
                    <td>:</td>
                    <td>{data.project?.active_pppoe || 0}</td>
                  </tr>
                  <tr>
                    <td>CSR</td>
                    <td>:</td>
                    <td>{data.project?.csr || 0}</td>
                  </tr>
                  <tr>
                    <td>Inactive PPPOE</td>
                    <td>:</td>
                    <td>{data.project?.inactive_pppoe || 0}</td>
                  </tr>
                  <tr>
                    <td>Occupation Rate</td>
                    <td>:</td>
                    <td>{data.project?.occupation_rate || "0%"}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>:</td>
                    <td>{data.description || "-"}</td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col"></div>
                <div className="col-auto mb-2">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => setShowModalCreate(true)}
                  >
                    <i className="fa fa-plus"></i> Tambah Report Up/Down Time
                  </button>
                </div>
              </div>

              <div className="auto-horizontal-scroll">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Down</th>
                      <th>Up</th>
                      <th>Durasi</th>
                      <th>Keterangan</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {incidents.length === 0 && (
                      <tr>
                        <td colSpan={6} className="text-center">
                          Tidak ada data
                        </td>
                      </tr>
                    )}
                    {incidents.map((incident) => (
                      <tr key={incident.id}>
                        <td className="text-nowrap">
                          {moment(incident.date, "YYYY-MM-DD").format("DD MMM YYYY")}
                        </td>
                        <td className="text-nowrap">
                          {moment.unix(incident.down).format("DD MMM YYYY - HH:mm:ss")}
                        </td>
                        <td className="text-nowrap">
                          {incident.up === null
                            ? "-"
                            : moment.unix(incident.up).format("DD MMM YYYY - HH:mm:ss")}
                        </td>
                        <td className="text-nowrap">{incident.duration}</td>
                        <td>{incident.description}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-block btn-info mb-1 text-nowrap"
                            onClick={() => toggleOpenModalUpdate(incident.id)}
                          >
                            <i className="fa fa-pencil"></i> Edit
                          </button>
                          <button
                            className="btn btn-sm btn-block btn-danger mb-1 text-nowrap"
                            onClick={() => toggleOpenModalDelete(incident.id)}
                          >
                            <i className="fa fa-trash"></i> Hapus
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </div>
        </div>
      </div>

      <ModalAddReportUptime
        report_id={id}
        show={showModalCreate}
        onClose={setShowModalCreate}
        onSuccess={onSuccess}
      />
      <ModalUpdateIncident
        report_id={id}
        show={showModalUpdate}
        onClose={toggleOpenModalUpdate}
        id={showModalUpdate ? selectedId : ""}
        onSuccess={onSuccess}
      />
      <ModalDeleteIncident
        report_id={id}
        show={showModalDelete}
        onClose={toggleOpenModalDelete}
        id={showModalDelete ? selectedId : ""}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
