import { useEffect, useState } from "react";
import { getListSales } from "../../api/sales";

export function useListSales() {
  const [sales, setSales] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getSales = async () => {
    setLoading(true);
    setErrorMsg('');
    try {
      const { data } = await getListSales();
      setSales(data.data);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    getSales();
  }, [])

  return {
    sales,
    loading,
    errorMsg,
    getSales
  }
}