import { useState } from "react";
import { awsUploader } from "../api/uploader";

export default function ResponsiveImageUploader({ type, image, required, onChangeImg }) {
  let [success, setSuccess] = useState(false);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState("");

  const uploadFile = async (formData) => {
    setLoading(true);
    setSuccess(false);
    setError("");
    try {
      let { data } = await awsUploader(formData);
      setSuccess(true);
      onChangeImg(type, data.data.image_url);
    } catch (error) {
      console.error(error);
      setError("Terjadi masalah");
    }

    setLoading(false);
  };

  const onChangeImage = (event) => {
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    uploadFile(formData);
  };

  return (
    <div className="row">
      <div className="col-12 mb-2 ">
        <img
          src={image ? image : "https://via.placeholder.com/600x400.jpg?text=Tidak+Ada+Gambar"}
          className={`img-thumbnail ${success && "border-success"} ${error ? "border-danger" : ""}`}
          alt="..."
          style={{ width: "200px" }}
        ></img>
        {loading && (
          <div className="spinner-border text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
      <div className="col-12">
        <div className="custom-file" style={{ zIndex: 0 }}>
          <label className="custom-file-label" htmlFor="customFile">
            Pilih file
          </label>
          <input
            type="file"
            required={required}
            className="custom-file-input is-valid"
            onChange={(event) => onChangeImage(event)}
            accept="image/*"
          />
          {success && (
            <div className="d-flex justify-content-between py-1">
              <span className="text-success text-sm mt-2">
                <i className="fa fa-check"></i> Berhasil Upload!
              </span>
            </div>
          )}
          {error && (
            <div className="text-danger text-sm mt-2">
              <i className="fa fa-exclamation-triangle"></i> {error}{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
