import { apiAuth, apiDownloadAuth } from "./base";

export function getRegistrationStatuses() {
  let api = apiAuth();
  return api.get('v1/registrations/status');
}

export function getRegistrationList(params) {
  let api = apiAuth();
  return api.get('v1/registrations', { params });
}

export function getRegistrationSchedules(params) {
  let api = apiAuth();
  return api.get('v1/registrations/schedules', { params });
}

export function getRegistratioById(params) {
  let api = apiAuth();
  return api.get(`v1/registrations/${params.id}`);
}

export function getCoverageRegistrationNumbers() {
  let api = apiAuth();
  return api.get(`v1/registrations/coverages`);
}

export function getInstallationRegistrationNumbers() {
  let api = apiAuth();
  return api.get(`v1/registrations/installations`);
}

export function getRegistratioByNumber(params) {
  let api = apiAuth();
  return api.get(`v1/registrations/registration/${params.registration_number}`);
}

export function getRegistrationSetupModem(params) {
  let api = apiAuth();
  return api.get(`v2/registrations/registration/${params.registration_number}`);
}

export function getModemByRegistrationNumber(registration_number) {
  let api = apiAuth();
  return api.get(`v1/registrations/registration/${registration_number}/modem`)
}

export function updateRegistrationStatus(id, params) {
  let api = apiAuth();
  return api.put(`v1/registrations/${id}/status`, params);
}

export function updateScheduleTechnician(id, data) {
  let api = apiAuth();
  return api.put(`v1/registrations/${id}/schedule`, data);
}

export function confirmRegistration(id) {
  let api = apiAuth();
  return api.put(`v1/registrations/${id}/confirm`);
}

export function downloadUserRegistration(params = {}) {
  let api =  apiDownloadAuth();
  return api.get("v1/registrations/export/xlsx", {params});
}

export function generatePdfAssignmentLink(id, form) {
  let api =  apiAuth();
  return api.post(`v1/registrations/${id}/letter/assignment`, form);
}

export function sendReportCoverage(id, form) {
  let api =  apiAuth();
  return api.put(`v1/registrations/${id}/coverage`, form);
}

export function sendReportInstallation(id, form) {
  let api =  apiAuth();
  return api.put(`v1/registrations/${id}/installation`, form);
}

export function getRegistrationLogById(id, params) {
  let api = apiAuth();
  return api.get(`v1/registrations/${id}/logs`, { params });
}