import { useState } from "react";
import { Modal } from "react-bootstrap";
import { generateBillingById } from "../../api/customer";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import AlertBox from "../AlertBox";
import { convertToRupiah } from "../../tools/numerictools";

export default function ModalGenerateBilling({ show, id, onSuccess, onClose }) {
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const { data } = useGetCustomerHooks({ id });

  let [form, setForm] = useState({ amount: "", start_date: "", end_date: "", notification: 1 });

  const resetForm = () => {
    setForm({ amount: "", art_date: "", end_date: "", notification: 1 });
    setAlert({ show: false, message: "", type: "" });
  };

  const checkNotification = (e) => {
    if (e.target.checked) {
      setForm({ ...form, notification: 1 });
    } else {
      setForm({ ...form, notification: 0 });
    }
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      type: "CUSTOMER",
      amount: form.amount,
      start_date: form.start_date,
      end_date: form.end_date,
      notification: form.notification,
    };

    submitBilling(formData);
  };

  const submitBilling = async (form) => {
    setLoading(true);
    try {
      let response = await generateBillingById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };
  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          Generate Billing - {data.registration?.registration_number} - {data.name}
        </Modal.Title>
      </Modal.Header>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Body>
          <h5>Data Paket</h5>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Nama Paket</td>
                <td>{data.internet_package?.name}</td>
              </tr>
              <tr>
                <td>Tipe Paket</td>
                <td>{data.internet_package?.internet_package_type.name}</td>
              </tr>
              <tr>
                <td>Varian Paket</td>
                <td>{data.internet_package?.internet_package_varian.name}</td>
              </tr>
              <tr>
                <td>Biaya Paket</td>
                <td>Rp. {convertToRupiah(data.internet_package?.price || 0)}</td>
              </tr>
              <tr>
                <td>Durasi Paket</td>
                <td>{data.internet_package?.internet_package_varian.validity_period} hari</td>
              </tr>
            </tbody>
          </table>
          <div className="form-group">
            <label>Harga Paket</label>
            <input
              type="number"
              className="form-control"
              value={form.amount}
              onChange={(event) => setForm({ ...form, amount: event.target.value })}
            />
            <small>Isi jika ingin mengubah harga paket yang berlaku pada tagihan ini</small>
          </div>
          <div className="form-group">
            <label>Tanggal Mulai</label>
            <input
              type="date"
              className="form-control"
              required
              value={form.start_date}
              onChange={(event) => setForm({ ...form, start_date: event.target.value })}
              max={form.end_date}
            />
          </div>
          <div className="form-group">
            <label>Tanggal Berakhir</label>
            <input
              type="date"
              className="form-control"
              required
              value={form.end_date}
              onChange={(event) => setForm({ ...form, end_date: event.target.value })}
              min={form.start_date}
            />
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                type="checkbox"
                checked={form.notification === 1}
                onChange={(event) => checkNotification(event)}
                class="form-check-input"
                id="notification-check"
              />
              <label className="form-check-label" htmlFor="notification-check">
                Beri notifikasi pelanggan
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button type="button" onClick={() => handleClose()} className="btn btn-secondary mr-1">
              Batal
            </button>
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? "Menunggu ..." : "Simpan"}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
