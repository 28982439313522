import { useState } from "react";
import { Modal } from "react-bootstrap";
import { downloadSchedule } from "../../api/schedules";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import AlertBox from "../AlertBox";

export default function ModalDownloadDismantle({ show, setShow, onSuccess }) {
  const [formFilter, setFormFilter] = useState({
    dismantle_date_from: "",
    dismantle_date_until: "",
    project_id: "",
    status: "",
    type: "dismantle",
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const { projects } = useGroupListHooks();

  const handleClose = () => {
    resetFilter();
    setShow();
  };

  const resetFilter = () => {
    setFormFilter({
      dismantle_date_from: "",
      dismantle_date_until: "",
      project_id: "",
      status: "",
      type: "dismantle",
    });
    setAlert({ show: false, message: "", type: "" });
  };

  const downloadFile = async () => {
    setLoading(true);

    try {
      let response = await downloadSchedule({ ...formFilter });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Penjadwalan Dismantle.xlsx");
      onSuccess("Berhasil mengunduh Data Penjadwalan Dismantle.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Download Penjadwalan Dismantle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form>
          <div className="form-group">
            <label>Tanggal BAST Dismantle Mulai</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.dismantle_date_from}
              onChange={(event) =>
                setFormFilter({ ...formFilter, dismantle_date_from: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Tanggal BAST Dismantle Akhir</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.dismantle_date_until}
              onChange={(event) =>
                setFormFilter({ ...formFilter, dismantle_date_until: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="project_group">Kelompok/Project</label>
            <select
              className="input form-control"
              id="project_group"
              value={formFilter.project_id}
              onChange={(event) => setFormFilter({ ...formFilter, project_id: event.target.value })}
            >
              <option value="">-- Kelompok/Project --</option>
              {projects.map((project, index) => (
                <option key={index} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Status</label>
            <select
              className="form-control"
              value={formFilter.status}
              onChange={(event) => setFormFilter({ ...formFilter, status: event.target.value })}
            >
              <option value="">SEMUA</option>
              <option value="WAITING-NOC">WAITING NOC</option>
              <option value="NOC-DONE">NOC DONE</option>
              <option value="DONE">DONE</option>
              <option value="CANCELED">CANCELED</option>
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => downloadFile()} disabled={loading}>
          <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
