import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import AlertBox from "../../components/AlertBox";
import moment from "moment";
import { convertToRupiah } from "../../tools/numerictools";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { downloadReportCommission, transferCommission } from "../../api/finance";
import { Modal } from "react-bootstrap";
import { useReportCommissionsHooks } from "../../utils/finance/CommissionsUtils";
import Pagination from "../../components/Pagination";

const withdrawStatusArr = ["Belum Diproses", "Berhasil Transfer", "Gagal Transfer"];
const withdrawColorArr = ["warning", "success", "danger"];

export default function ReportCommissions() {
  const { hasAccess } = useControlPermission();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [selected, setSelected] = useState(null);
  const [modal, setModal] = useState(null);

  const [formFilter, setFormFilter] = useState({
    role: "",
    q: "",
    withdrawal_status: "",
    installation_start_date: "",
    installation_end_date: "",
    withdrawal_start_date: "",
    withdrawal_end_date: "",
  });

  const [filter, setFilter] = useState({
    type: "",
    q: "",
    withdrawal_status: "",
    installation_start_date: "",
    installation_end_date: "",
    withdrawal_start_date: "",
    withdrawal_end_date: "",
    page: 1,
    page_size: 20,
  });

  let { data: commissions, meta, loading, errorMsg } = useReportCommissionsHooks({ filter });

  const applyFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
    });
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const onSuccess = (message) => {
    applyFilter();
    setAlert({ show: true, message, type: "success" });
  };

  const onError = (message) => {
    setAlert({ show: true, message, type: "danger" });
  };

  const resetFilter = () => {
    setFormFilter({
      role: "",
      q: "",
      withdrawal_status: "",
      installation_start_date: "",
      installation_end_date: "",
      withdrawal_start_date: "",
      withdrawal_end_date: "",
    });

    setFilter({
      role: "",
      q: "",
      withdrawal_status: "",
      installation_start_date: "",
      installation_end_date: "",
      withdrawal_start_date: "",
      withdrawal_end_date: "",
      page: 1,
      page_size: 20,
    });
  };

  return (
    <MainLayout resource_key="finance">
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Laporan Transfer Komisi ke Pulsa Pro</h1>
        <Box title="Laporan Transfer Komisi ke Pulsa Pro" loading={loading}>
          <div className="row">
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Pencarian</label>
                <input
                  type="text"
                  className="form-control"
                  value={formFilter.q}
                  onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Status</label>
                <select
                  value={formFilter.withdrawal_status}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, withdrawal_status: event.target.value })
                  }
                  className="form-control"
                >
                  <option value="">Semua</option>
                  {withdrawStatusArr.map((status, index) => (
                    <option key={index} value={index}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Tanggal BAST Awal</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.installation_start_date}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, installation_start_date: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Tanggal BAST Akhir</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.installation_end_date}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, installation_end_date: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Tanggal Transfer Awal</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.withdrawal_start_date}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, withdrawal_start_date: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Tanggal Transfer Akhir</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.withdrawal_end_date}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, withdrawal_end_date: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Role</label>
                <select
                  value={formFilter.role}
                  onChange={(event) => setFormFilter({ ...formFilter, role: event.target.value })}
                  className="form-control"
                >
                  <option value="">Semua</option>
                  <option value="technician">Technician</option>
                  <option value="helper">Helper</option>
                  <option value="sales">Sales</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
            {hasAccess("export-commission-disbursement-report") && (
              <div className="col-12 col-md-auto ml-auto" style={{ paddingTop: "32px" }}>
                <ButtonDownloadReport filter={filter} onSuccess={onSuccess} onError={onError} />
              </div>
            )}
          </div>

          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />

          <div className="mt-2" style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Nama</th>
                  <th>Role</th>
                  <th>Transfer Status</th>
                  <th>Tanggal BAST</th>
                  <th>Waktu Transfer</th>
                  <th>Jumlah BAST</th>
                  <th>Total Komisi</th>
                </tr>
              </thead>
              <tbody>
                {commissions.length === 0 && (
                  <tr>
                    <td colSpan={9} className="text-center">
                      Belum ada data
                    </td>
                  </tr>
                )}
                {commissions.map((commission, index) => (
                  <tr key={index}>
                    <td className="text-nowrap">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          setSelected(commission);
                          setModal("detail");
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                      {commission.withdrawal_status === 2 &&
                        hasAccess("withdraw-commission-disbursement") && (
                          <button
                            className="btn btn-custom-orange btn-sm ml-1"
                            onClick={() => {
                              setSelected(commission);
                              setModal("resend");
                            }}
                          >
                            <i className="fa fa-repeat"></i>
                          </button>
                        )}
                    </td>
                    <td>{commission.short_id || "-"}</td>
                    <td>{commission.user.name || "-"}</td>
                    <td>{commission.role || "-"}</td>
                    <td>
                      <span
                        className={`badge badge-${withdrawColorArr[commission.withdrawal_status]}`}
                      >
                        {withdrawStatusArr[commission.withdrawal_status] || "-"}
                      </span>
                    </td>
                    <td className="text-nowrap">
                      {moment(commission.recording_date, "YYYY-MM-DD").format("DD MMM YYYY")}
                    </td>
                    <td className="text-nowrap">
                      {commission.withdrawal_timestamp
                        ? moment.unix(commission.withdrawal_timestamp).format("DD MMM YYYY - HH:mm")
                        : "-"}
                    </td>
                    <td>{commission.commission_count || "-"}</td>
                    <td className="text-right">
                      Rp {convertToRupiah(commission.total_commission)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            {meta.count > meta.page_size && (
              <Pagination
                totalpage={Math.ceil(meta.count / meta.page_size)}
                selectedpage={meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(meta.page + 1)}
                prev={() => gotoPage(meta.page - 1)}
              />
            )}
          </div>
        </Box>
      </div>

      <ModalDetailWithdraw
        selected={selected}
        show={modal === "detail"}
        onClose={() => setModal(null)}
      />
      <ModalResendTransfer
        selected={selected}
        show={modal === "resend"}
        onSuccess={onSuccess}
        onClose={() => setModal(null)}
      />
    </MainLayout>
  );
}

function ModalDetailWithdraw({ selected, show, onClose }) {
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Detail Transfer Komisi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        {selected ? (
          <>
            <h4>Data</h4>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{selected?.short_id}</td>
                </tr>
                <tr>
                  <td style={{ width: "250px" }}>Nama</td>
                  <td>{selected.user?.name || "-"}</td>
                </tr>
                <tr>
                  <td>Role</td>
                  <td>{selected.role || "-"}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{withdrawStatusArr[selected.withdrawal_status] || "-"}</td>
                </tr>
                <tr>
                  <td>Tanggal BAST</td>
                  <td className="text-nowrap">
                    {selected.recording_date
                      ? moment(selected.recording_date, "YYYY-MM-DD").format("DD MMM YYYY")
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>Waktu Transfer</td>
                  <td className="text-nowrap">
                    {selected.withdrawal_timestamp
                      ? moment.unix(selected.withdrawal_timestamp).format("DD MMM YYYY - HH:mm")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Respon Transfer Pulsa Pro</td>
                  <td>{selected.withdrawal_response || "-"}</td>
                </tr>
                <tr>
                  <td>Jumlah BAST</td>
                  <td>{selected.commission_count || "-"}</td>
                </tr>
                <tr>
                  <td>Total Komisi</td>
                  <td>Rp {convertToRupiah(selected.total_commission || 0)}</td>
                </tr>
              </tbody>
            </table>

            <h4>Data Komisi</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>No. Reg</th>
                  <th>Tipe</th>
                  <th>Tgl. BAST</th>
                  <th>Komisi</th>
                </tr>
              </thead>
              <tbody>
                {selected.commission_disbursement_details?.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.registration_number}</td>
                    <td>{detail.type}</td>
                    <td>
                      {detail.bast_date
                        ? moment(detail.bast_date, "YYYY-MM-DD").format("DD MMM YYYY")
                        : "-"}
                    </td>
                    <td className="text-right">Rp {convertToRupiah(detail.commission_rate)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}

function ModalResendTransfer({ selected, show, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const transfer = async () => {
    setLoading(true);
    try {
      let response = await transferCommission(selected.id);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="">
      <Modal.Header closeButton>
        <Modal.Title>Transfer Ulang</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        {selected ? (
          <>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td style={{ width: "250px" }}>Nama</td>
                  <td>{selected.user?.name || "-"}</td>
                </tr>
                <tr>
                  <td>Tipe</td>
                  <td>{selected.role || "-"}</td>
                </tr>
                <tr>
                  <td>Status Terakhir</td>
                  <td>{withdrawStatusArr[selected.withdrawal_status] || "-"}</td>
                </tr>
                <tr>
                  <td>Jumlah Registrasi</td>
                  <td>{selected.registration_count || "-"}</td>
                </tr>
                <tr>
                  <td>Total Komisi</td>
                  <td>Rp {convertToRupiah(selected.total_commission || 0)}</td>
                </tr>
              </tbody>
            </table>
            <div className="card">
              <div className="card-body">
                <h5>Konfirmasi</h5>
                <p>Apakah anda yakin untuk transfer ulang?</p>
                <button className="btn btn-success" disabled={loading} onClick={() => transfer()}>
                  Ya, Saya Yakin
                </button>
                <button
                  className="btn btn-light ml-2"
                  disabled={loading}
                  onClick={() => handleClose()}
                >
                  Tidak
                </button>
              </div>
            </div>
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}

function ButtonDownloadReport({ filter, onSuccess, onError }) {
  const [loading, setLoading] = useState(false);

  const downloadReport = async () => {
    setLoading(true);
    try {
      let response = await downloadReportCommission(filter);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Laporan Pembayaran Komisi.xlsx`);
      onSuccess(`Berhasil mengunduh Laporan Pembayaran Komisi.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      onError(message);
    }

    setLoading(false);
  };

  return (
    <button className="btn btn-primary" onClick={() => downloadReport()} disabled={loading}>
      <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
    </button>
  );
}
