import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateHistoryById } from "../../../api/finance";
import AlertBox from "../../AlertBox";

export default function ModalManualConfirm({ show, id, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({status: 'manual-confirm', note: '', billing_number: ''});
  const [alert, setAlert] = useState({show: false, message: '', type: 'danger'});

  const confirmHistoryTransfer = async (form) => {
    setLoading(true);
    try {
      let response = await updateHistoryById(id, form)
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }

    setLoading(false);
  }

  const submitUpdate = (event) => {
    event.preventDefault();
    confirmHistoryTransfer(form);
  }

  const handleClose = () => {
    setForm({status: 'manual-confirm', note: '', billing_number: ''});
    setAlert({show: false, message: '', type: 'danger'});
    onClose();
  }

  return (
    <Modal show={show} onHide={() => handleClose()} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Manual Confirm</Modal.Title>
      </Modal.Header>
      <form onSubmit={event => submitUpdate(event)}>
        <Modal.Body>
          <AlertBox { ...alert } setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="noteconfirm">Note</label>
            <input id="noteconfirm" type="text" className="form-control" value={form.note} onChange={event => setForm({ ...form, note: event.target.value})} />
          </div>
          <div className="form-group">
            <label htmlFor="billingconfirm">Billing Number</label>
            <input id="billingconfirm" type="text" className="form-control" value={form.billing_number} onChange={event => setForm({ ...form, billing_number: event.target.value})} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>Batal</button>
          <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Menunggu ...' : 'Simpan Perubahan'}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}