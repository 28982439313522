import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateScheduleById } from "../../api/schedules";
import { useScheduleDismantleHooks } from "../../utils/SchedulesUtil";
import AlertBox from "../AlertBox";

export default function ModalNoteSchedule({ show, id, setShow, onSuccess }) {
  let [form, setForm] = useState({additional_note: ''});
  const { data, loading: dataLoading } = useScheduleDismantleHooks({id});
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  let [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      setAlert({show: false, message: '', type: ''});
      setForm({additional_note: ''})
      setShow();
    }
  }

  const addNoteToSchedule = async () => {
    setLoading(true);
    try {
      let response = await updateScheduleById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }

    setLoading(false);
  }

  return(
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Tambah Catatan Khusus untuk Penjadwalan "{data.registration?.registration_number} - {data.registration?.name}"</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <div className="form-group">
          <label>Catatan Khusus:</label>
          <textarea className="form-control" value={form.additional_note} onChange={event => setForm({ ...form, additional_note : event.target.value})}></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>Tidak</button>
        <button className="btn btn-warning" onClick={() => addNoteToSchedule()} disabled={loading || dataLoading}>{loading ? 'Menunggu ...' : 'Ya'}</button>
      </Modal.Footer>
    </Modal>
  )
}