import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createVendor } from "../../../api/projects";
import useRegionHooks from "../../../utils/RegionHooks";
import AlertBox from "../../AlertBox";
import { RequiredAsterisk } from "../../FormHelper";

export default function ModalVendorCreate({ show, onClose, onSuccess }) {
  let [alert, setAlert] = useState({show: false, message: '', type: ''});
  let [form, setForm] = useState({name: '', address: ''});
  let address = useRegionHooks();

  const handleClose = () => {
    resetForm();
    onClose();
  }

  const resetForm = () => {
    setAlert({show: false, message: '', type: ''});
    setForm({ ...form, name: '', address: ''});
    address.resetRegion();
  }

  const sendData = async (formData)  => {
    try {
      let response = await createVendor(formData);
      onSuccess(true, response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }
  }

  const onSubmitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
      address_id: address.addressId
    }

    sendData(formData)
  }

  return(
    <Modal show={show} onHide={() => handleClose()} size="md">
      <form onSubmit={event => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Data Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="vendorName">Nama Vendor <RequiredAsterisk /></label>
                <input type="text" required value={form.name} onChange={event => setForm({ ...form, name: event.target.value})} className="form-control"/>
              </div>
              <div className="form-group">
                <label htmlFor="vendorAddr">Alamat <RequiredAsterisk /></label>
                <textarea required value={form.address} onChange={event => setForm({ ...form, address: event.target.value})} className="form-control"/>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">Provinsi <RequiredAsterisk /></label>
                <select name="provinsi"  requiredid="provinsi" className="form-control" value={address.province} onChange={event => address.changeProvince(event.target.value)}>
                  <option value="">-- Pilih Provinsi --</option>
                  {address.provinces.map((province, index) => (
                    <option key={index} value={province.province}>{province.province}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">Kota/Kabupaten <RequiredAsterisk /></label>
                <select name="kabupaten"  requiredid="kabupaten" className="form-control" value={address.city} onChange={event => address.changeCity(event.target.value)}>
                  <option value="">-- Pilih Kabupaten/Kota --</option>
                  {address.cities.map((city, index) => (
                    <option key={index} value={city.kota_kab}>{city.kota_kab}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">Kecamatan <RequiredAsterisk /></label>
                <select name="kecamatan" required id="kecamatan" className="form-control" value={address.district} onChange={event => address.changeDistrict(event.target.value)}>
                  <option value="">-- Pilih Kecamatan --</option>
                  {address.districts.map((district, index) => (
                    <option key={index} value={district.kecamatan}>{district.kecamatan}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">Kelurahan <RequiredAsterisk /></label>
                <select name="kelurahan" required id="kelurahan" className="form-control" value={address.addressId} onChange={event => address.setAddressId(event.target.value)}>
                  <option value="">-- Pilih Kelurahan --</option>
                  {address.addresslist.map((kelurahan, index) => (
                    <option key={index} value={kelurahan.id}>{kelurahan.kelurahan}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">Close</button>
          <button className="btn btn-primary">Tambah Vendor</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}