import { useState } from "react";
import Pagination from "../../components/Pagination";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useTechnicianHistoryHooks } from "../../utils/TechniciansUtils";
import { useEffect } from "react";
import moment from "moment";
import ModalDownloadHistoryTechnician from "../../components/technician/ModalDownloadHistoryTechnician";
import AlertBox from "../../components/AlertBox";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";

export default function HistoryTechnician() {
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [filter, setFilter] = useState({
    name: "",
    date_from: "",
    date_until: "",
    referral_name: "",
    n: "",
    customer_name: "",
    project: "",
    page: 1,
    page_size: 10,
  });
  let [formFilter, setFormFilter] = useState({
    name: "",
    date_from: "",
    date_until: "",
    referral_name: "",
    n: "",
    customer_name: "",
    project: "",
  });
  const { projects } = useGroupListHooks();

  let [showModalDownload, setShowModalDownload] = useState(false);
  let historiesData = useTechnicianHistoryHooks();

  useEffect(() => {
    historiesData.getHistories(filter);
  }, [filter]);

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const toggleModalDownload = () => {
    setShowModalDownload(!showModalDownload);
  };

  const onSuccess = (message) => {
    historiesData.getHistories(filter);
    setAlert({ show: true, message: message, type: "success" });
  };

  const onSubmitFilter = () => {
    setFilter({ ...filter, ...formFilter, page: 1 });
  };
  const onResetFilter = () => {
    setFormFilter({
      name: "",
      date_from: "",
      date_until: "",
      referral_name: "",
      n: "",
      customer_name: "",
      project: "",
    });
    setFilter({
      name: "",
      date_from: "",
      date_until: "",
      referral_name: "",
      n: "",
      customer_name: "",
      project: "",
      page: 1,
      page_size: 10,
    });
  };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Riwayat Pemasangan Teknisi</h1>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary" onClick={() => toggleModalDownload()}>
                <i className="fa fa-download"></i> Unduh Data
              </button>
            </div>
          </div>
        </div>
        <Box
          title={`Riwayat Pemasangan (Total: ${historiesData.meta.count})`}
          loading={historiesData.loading}
        >
          <div className="mb-4">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="namefilter">Nama Teknisi</label>
                  <input
                    type="text"
                    className="form-control"
                    id="namefilter"
                    value={formFilter.name}
                    onChange={(event) => setFormFilter({ ...formFilter, name: event.target.value })}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="dateFromLabel">Tanggal dari</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.date_from}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, date_from: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="dateEndLabel">Tanggal sampai</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.date_until}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, date_until: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="namefilter">Nama Sales</label>
                  <input
                    type="text"
                    className="form-control"
                    id="namefilter"
                    value={formFilter.referral_name}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, referral_name: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="noreg">No. Registrasi/Pelanggan</label>
                  <input
                    type="text"
                    className="form-control"
                    id="noreg"
                    value={formFilter.n}
                    onChange={(event) => setFormFilter({ ...formFilter, n: event.target.value })}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="cust_name">Nama Pelanggan</label>
                  <input
                    type="text"
                    className="form-control"
                    id="cust_name"
                    value={formFilter.customer_name}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, customer_name: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="projectFilter">Grup/Project</label>
                  <select
                    className="form-control"
                    id="projectFilter"
                    value={formFilter.project}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, project: event.target.value })
                    }
                  >
                    <option value="">SEMUA</option>
                    {projects.map((project, index) => (
                      <option value={project.id} key={index}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-2" style={{ paddingTop: "32px" }}>
                <button className="btn btn-primary" onClick={() => onSubmitFilter()}>
                  Filter
                </button>
                <button className="btn btn-default" onClick={() => onResetFilter()}>
                  Reset
                </button>
              </div>
            </div>
          </div>
          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Tgl. BAST</th>
                  <th>No. Registrasi</th>
                  <th>Nama Pelanggan</th>
                  <th>Alamat Pemasangan</th>
                  <th>Teknisi</th>
                  <th>Helper</th>
                  <th>Sales</th>
                  <th>Project</th>
                </tr>
              </thead>
              <tbody>
                {historiesData.histories.length === 0 && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {historiesData.histories.map((history, index) => (
                  <tr key={index}>
                    <td className="text-nowrap">
                      {moment(history.bast.created_at).format("DD MMM YYYY - HH:mm")}
                    </td>
                    <td>{history.registration_number}</td>
                    <td className="text-nowrap">{history.name}</td>
                    <td>
                      {history.installation_address.address} RT{history.installation_address.rt}/RW
                      {history.installation_address.rw},{" "}
                      {history.installation_address.addresses.kelurahan},{" "}
                      {history.installation_address.addresses.kecamatan},{" "}
                      {history.installation_address.addresses.kota_kab},{" "}
                      {history.installation_address.addresses.province},{" "}
                      {history.installation_address.addresses.postal_code}
                    </td>
                    <td className="text-nowrap">{history.technicians[0]?.name || "-"}</td>
                    <td className="text-nowrap">{history.technicians[1]?.name || "-"}</td>
                    <td className="text-nowrap">{history.referral?.name || "-"}</td>
                    <td>{history?.project?.name || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {historiesData.meta.count > historiesData.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(historiesData.meta.count / historiesData.meta.page_size)}
              selectedpage={historiesData.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(historiesData.meta.page + 1)}
              prev={() => gotoPage(historiesData.meta.page - 1)}
            />
          )}
        </Box>
      </div>
      <ModalDownloadHistoryTechnician
        show={showModalDownload}
        onClose={toggleModalDownload}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
