import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { downloadComissionDetail } from "../../api/finance";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useCommissionDetailHooks } from "../../utils/finance/PointSalesUtils";

export default function DetailPointSales () {
  const { id } = useParams();
  const { data, internetPackages, fetched, loading, errorMsg, getDetail } = useCommissionDetailHooks({id});
  
  const [alert, setAlert] = useState({show: false, message: '', type: ''});

  useEffect(() => {
    if (errorMsg) {
      setAlert({show: true, message: errorMsg, type: 'danger'})
    }
  }, [errorMsg]);

  const onSuccess = (message) => {
    setAlert({show: true, message, type: "success"});
    getDetail(id);
  }

  const onError = (message) => {
    setAlert({show: true, message, type: "danger"});
  }

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Detail Poin Sales</h1>
            </div>
            <div className="col-auto">
              {data.referral && 
                <DownloadReportButton sales={data.referral} id={id} onSuccess={onSuccess} onError={onError} />
              }
            </div>
          </div>
        </div>
        <Box title="Detail Point" loading={loading}>
          <AlertBox {...alert} setAlert={setAlert} />
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td width={200}>Nama Sales</td>
                <td>{data.referral?.name || "-"}</td>
              </tr>
              <tr>
                <td width={200}>Email</td>
                <td>{data.referral?.email || "-"}</td>
              </tr>
              <tr>
                <td width={200}>No. Telp</td>
                <td>{data.referral?.phone_number || "-"}</td>
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nama Paket</th>
                <th>Jumlah</th>
              </tr>
            </thead>
            <tbody>
              {internetPackages.length === 0 &&
                <tr>
                  <td colSpan={2} className="text-center">TIdak Ada Data</td>
                </tr>
              }
              {internetPackages.map((pack, index) => (
                <tr key={index}>
                  <td>{pack.name}</td>
                  <td>{pack.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </div>
    </MainLayout>
  )
}

function DownloadReportButton({ id, sales, onSuccess, onError }) {
  const [ loading, setLoading ] = useState(false);

  const downloadFile = async () => {
    setLoading(true)
    try {
      let response = await downloadComissionDetail(id);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Detail Poin - ${sales.name}.xlsx`);
      onSuccess(`Berhasil mengunduh Detail Poin - ${sales.name}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      let message = "Something wrong ... "

      if (error.message) {
        message = error.message
      }
      onError(message);
    }
    setLoading(false)
  }

  return (
    <button className="btn btn-primary" disabled={loading} onClick={() => downloadFile()}><i className="fa fa-download"></i> {loading ? "Downloading ..." : "Download Detail Poin Sales"}</button>
  )
}