import { useState, useEffect } from "react";
import { getAllAccounts } from "../../api/manage-app";

export function useAllUserAccounts({ filter }) {
  let [data, setData] = useState([]);
  let [meta, setMeta] = useState({
    count: 0,
    page: 1,
    pageSize: 10,
  });
  // let [success, setSuccess] = useState(null);
  let [errorMsg, setErrorMsg] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserMobile(filter);
  }, [filter]);

  const fetchUserMobile = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllAccounts({ ...filter });
      setData(data.data.listAccounts);
      setMeta(data.data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  return {
    data,
    meta,
    loading,
    errorMsg,
    fetchUserMobile,
  };
}
