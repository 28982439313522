import { useEffect } from "react";
import { CartesianGrid, Legend, Tooltip, ResponsiveContainer, XAxis, YAxis, AreaChart, Area } from "recharts";
import { useTrafficStatistic } from "../../utils/DashboardHooks"
import Box from "../Box"

export default function BoxTotalTraffic() {
  const { data, fetched, fetchTraffic, loading, errorMsg } = useTrafficStatistic();

  useEffect(() => {
    fetchTraffic("upstream");
  }, [fetchTraffic])
  return (
    <Box title="Upstream 14 Hari Terakhir" loading={loading}>
      {errorMsg && 
        <div className="alert alert-danger">
          {errorMsg}
        </div>
      }

      {fetched && 
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
              <defs>
                <linearGradient id="colorInbound" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#28a745" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#28a745" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="colorOutbound" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#007bff" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#007bff" stopOpacity={0}/>
                </linearGradient>
              </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis unit={` G`} />
            <Tooltip />
            <Legend />
            <Area dataKey="total_inbound" name="Inbound" unit={` G`} fill="url(#colorInbound)" stroke="#28a745" dot={{ r: 2, strokeWidth: 4, fill: '#28a745' }}strokeWidth={3} />
            <Area dataKey="total_outbound" name="Outbound" unit={` G`} fill="url(#colorOutbound)" stroke="#007bff" strokeWidth={3} dot={{ r: 2, strokeWidth: 4, fill: '#007bff' }}/>
          </AreaChart>
        </ResponsiveContainer>
      }
    </Box>
  )
}