import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { updatePasswordTechnician } from "../../api/technician";

export default function ModalEditPasswordTechnician({ account, show, onSuccess, setShow }) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ password: "" });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const submitData = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let { data } = await updatePasswordTechnician(account.id, { ...form });
      handleClose();
      onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  const handleClose = () => {
    setForm({ password: "" });
    setAlert({ show: false, message: "", type: "" });
    setShow();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Ubah Password Akun</Modal.Title>
      </Modal.Header>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <p>
                <h5>Detail Akun:</h5>
                <b>Nama:</b> {account.name}
                <br />
                <b>Email:</b> {account.email}
              </p>
              {alert.show && (
                <div
                  className={`alert alert-${alert.type} alert-dismissible fade show`}
                  role="alert"
                >
                  {alert.message}
                  <button
                    type="button"
                    className="close"
                    onClick={() => setAlert({ show: false, message: "", type: "" })}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              <div className="form-group">
                <label htmlFor="password_new">Password Baru:</label>
                <input
                  type="password"
                  name="password_new"
                  id="password_new"
                  value={form.password}
                  onChange={(event) => setForm({ ...form, password: event.target.value })}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" type="submit" disabled={loading}>
            Ubah Password
          </button>
          <button className="btn btn-secondary" type="button" onClick={() => handleClose()}>
            Close
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
