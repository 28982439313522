import { useState } from "react";
import { useInventoryOutHooks } from "../../utils/InventoryHooks";
import moment from "moment";
import Pagination from "../Pagination";
import { LoadingInsideBox } from "../Loading";

export default function TableInventoryOut({ id, unit, warehouse_id }) {
  let [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    id: id,
    warehouse_id: warehouse_id,
  });

  let dataOut = useInventoryOutHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <div className="loading-data-div">
      <h5>Data Barang Keluar</h5>
      {dataOut.loading && <LoadingInsideBox />}
      <table className="table table-hover table-striped table-bordered">
        <thead>
          <tr>
            <th>Waktu Keluar</th>
            <th>Qty</th>
            <th>Proses</th>
            <th>Tujuan</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          {dataOut.histories.length === 0 && (
            <tr>
              <td colSpan={5} className="text-center">
                Tidak ada data ditampilkan
              </td>
            </tr>
          )}
          {dataOut.histories?.map((history, index) => (
            <tr key={index}>
              <td>{moment(history.datetime).format("DD MMMM YYYY - HH:mm")}</td>
              <td>
                {history.qty} {unit}
              </td>
              <td>{history.process}</td>
              <td>
                {history.destination_type === "REGISTRATION" &&
                  history.registration?.registration_number}
                {history.destination_type === "PROJECT" && history.project.name}
                {history.destination_warehouse !== null && history.destination_warehouse.name}
              </td>
              <td>{history.user?.name || "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {dataOut.meta.count > dataOut.meta.page_size && (
        <Pagination
          totalpage={Math.ceil(dataOut.meta.count / dataOut.meta.page_size)}
          selectedpage={dataOut.meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(dataOut.meta.page + 1)}
          prev={() => gotoPage(dataOut.meta.page - 1)}
        />
      )}
    </div>
  );
}
