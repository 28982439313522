import { useEffect } from "react";
import { useState } from "react";
import { getProjectVendorById, getTaskById, getVendorsByProjectId, getVendorTask } from "../../api/projects";

export function useVendorByProjectHooks({ id }) {
  const [vendors, setVendors] = useState([]);
  const [meta, setMeta] = useState({count: 0, page: 1, pageSize: 10});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchVendors = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('');
    try {
      const { data } = await getVendorsByProjectId(id, { ...filter });
      setVendors(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  return {
    vendors,
    meta,
    loading,
    errorMsg,
    fetchVendors
  }
}


export function useVendorTasksByProjectHooks({ id }) {
  const [tasks, setTasks] = useState([]);
  const [meta, setMeta] = useState({count: 0, page: 1, pageSize: 10});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchTask = async () => {
    setLoading(true);
    setErrorMsg('');
    try {
      const { data } = await getVendorTask(id);
      setTasks(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  return {
    tasks,
    meta,
    loading,
    errorMsg,
    fetchTask
  }
}

export function useProjectVendorHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getVendor(id)
    }
  }, [id]);

  const getVendor = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false)
    try {
      let response = await getProjectVendorById(id);
      setData(response.data.data);
      setFetched(true)
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setError(data.message)
      } else {
        setError("Something Error Happened")
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getVendor,
  }
}

export function useTaskHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getTask(id)
    }
  }, [id]);

  const getTask = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false)
    try {
      let response = await getTaskById(id);
      setData(response.data.data);
      setFetched(true)
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setError(data.message)
      } else {
        setError("Something Error Happened")
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getTask,
  }
}