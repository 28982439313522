import { useState, useCallback } from "react";
import { getBillingsByIdNumber, getHistoryTransferList } from "../../api/finance";

export function useHistoriesTransferHooks() {
  const [histories, setHistories] = useState([]);
  const [meta, setMeta] = useState({count: 0, page: 1, page_size: 10});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getHistories = useCallback(async (filter = {}) => {
    setLoading(true);
    setErrorMsg('')
    try {
      const { data } = await getHistoryTransferList({ ...filter });
      setHistories(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }, [])

  return {
    histories,
    meta,
    loading,
    errorMsg,
    getHistories
  }
}

export function useBillingByIdNumber() {
  const [billings, setBillings] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getBilling = async (id) => {
    setLoading(true);
    setErrorMsg('');
    try {
      const { data } = await getBillingsByIdNumber(id);
      setBillings(data.data);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  const resetList = () => {
    setBillings([])
  }

  return {
    billings,
    loading,
    errorMsg,
    getBilling,
    resetList
  }
}