import { useEffect, useState } from "react";
import { getAllFramedPools } from "../../api/radius";

export function useALlFramedPool({ filter }) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({count: 0, page: 1, pageSize: 10});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getFramedPools = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('')
    try {
      const { data } = await getAllFramedPools({ ...filter });
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    getFramedPools(filter)
  }, [filter])

  return {
    data,
    meta,
    loading,
    errorMsg,
    getFramedPools
  }
}