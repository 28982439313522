import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import ModalViewOlt from "../../components/infrastrucure/olt/ModalViewOlt";
import ModalCreateOltProfile from "../../components/infrastrucure/oltprofile/ModalCreateOltProfile";
import ModalDeleteOltProfile from "../../components/infrastrucure/oltprofile/ModalDeleteOltProfile";
import ModalUpdateOltProfile from "../../components/infrastrucure/oltprofile/ModalUpdateOltProfile";
import MainLayout from "../../components/layouts/MainLayout";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useOltProfileListHooks } from "../../utils/infrastructure/OltUtils";

export default function ManageOltProfilePage() {
  let { hasAccess } = useControlPermission();
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [formFilter, setFormFilter] = useState({ q: "" });
  let [filter, setFilter] = useState({ q: "", page: 1, page_size: 10 });

  let [selectedId, setSelectedId] = useState("");
  let [modal, setModal] = useState("");
  // create, update, delete

  const { data: oltData, meta, loading, errorMsg } = useOltProfileListHooks({ filter });

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ q: "" });
    setFilter({ q: "", page: 1, page_size: 10 });
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    setFilter({ ...filter });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="setup-modem">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Manajemen Profil OLT</h1>
          </div>
          <div className="col-auto">
            {hasAccess("create-olt-profile") && (
              <button className="btn btn-primary" onClick={() => setModal("create")}>
                <i className="fa fa-plus"></i> Tambah OLT Profil Baru
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title={`Daftar Profile OLT`} loading={loading}>
              <div className="row">
                <div className="col-lg-2 col-12">
                  <div className="form-group">
                    <label>Pencarian</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="nama / upstream / downstream ..."
                      value={formFilter.q}
                      onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-primary"
                    onClick={() => applyFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => resetFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
              <AlertBox {...alert} setAlert={setAlert} />
              <div className="auto-horizontal-scroll">
                <table className="table table-hover table-bordered table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Nama Profile</th>
                      <th>Upstream</th>
                      <th>Downstream</th>
                    </tr>
                  </thead>
                  <tbody>
                    {oltData.length === 0 && (
                      <tr>
                        <td colSpan={4} className="text-center">
                          Tidak ada data
                        </td>
                      </tr>
                    )}
                    {oltData.map((olt) => (
                      <tr key={olt.id}>
                        <td style={{ width: "100px" }}>
                          {hasAccess("update-olt-profile") && (
                            <button
                              className="btn btn-sm btn-info mr-2"
                              onClick={() => {
                                setModal("update");
                                setSelectedId(olt.id);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </button>
                          )}
                          {hasAccess("delete-olt-profile") && (
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                setModal("delete");
                                setSelectedId(olt.id);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          )}
                        </td>
                        <td>{olt.name}</td>
                        <td>{olt.upstream}</td>
                        <td>{olt.downstream}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {meta.count > meta.page_size && (
                <Pagination
                  totalpage={Math.ceil(meta.count / meta.page_size)}
                  selectedpage={meta.page}
                  clickpage={(page) => gotoPage(page)}
                  next={() => gotoPage(meta.page + 1)}
                  prev={() => gotoPage(meta.page - 1)}
                />
              )}
            </Box>
          </div>
        </div>
      </div>

      <ModalCreateOltProfile
        show={modal === "create"}
        onClose={() => setModal("")}
        onSuccess={onSuccess}
      />

      <ModalUpdateOltProfile
        show={modal === "update"}
        id={selectedId}
        onClose={() => setModal("")}
        onSuccess={onSuccess}
      />

      <ModalDeleteOltProfile
        show={modal === "delete"}
        id={selectedId}
        onClose={() => setModal("")}
        onSuccess={onSuccess}
      />

      <ModalViewOlt show={modal === "view"} id={selectedId} onClose={() => setModal("")} />
    </MainLayout>
  );
}
