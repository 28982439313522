import { useEffect, useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import MainLayout from "../../components/layouts/MainLayout";
import ModalVendorCreate from "../../components/project/vendor/ModalVendorCreate";
import ModalVendorDelete from "../../components/project/vendor/ModalVendorDelete";
import ModalVendorUpdate from "../../components/project/vendor/ModalVendorUpdate";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useVendorListHooks } from "../../utils/project/VendorUtils";

export default function ManageVendorPage() {
  let { hasAccess } = useControlPermission();

  const [filter, setFilter] = useState({ page: 1, page_size: 10 });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [selectedId, setSelectedId] = useState(null);

  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const vendorList = useVendorListHooks();

  useEffect(() => {
    vendorList.getVendors(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const toggleModalUpdate = (id = null) => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleModalDelete = (id = null) => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const onSuccess = (show, message) => {
    setAlert({ show, message, type: "success" });
    setFilter({ ...filter });
  };

  return (
    <MainLayout resource_key="manage-project">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Vendor</h1>
            </div>
            <div className="col-auto">
              {hasAccess("create-vendor") && (
                <button className="btn btn-primary" onClick={() => setShowModalCreate(true)}>
                  <i className="fa fa-plus"></i> Tambah Vendor
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Daftar Vendor" loading={vendorList.loading}>
          <AlertBox {...alert} setAlert={setAlert} />
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Nama Vendor</th>
                <th>Alamat Vendor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {vendorList.vendors.length === 0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
              {vendorList.vendors.map((vendor, index) => (
                <tr key={index}>
                  <td>{vendor.name}</td>
                  <td>
                    {vendor.address}, {vendor.addresses.kelurahan}, {vendor.addresses.kecamatan},{" "}
                    {vendor.addresses.kota_kab}, {vendor.addresses.province},{" "}
                    {vendor.addresses.postal_code}
                  </td>
                  <td>
                    {hasAccess("update-vendor") && (
                      <button
                        className="btn btn-sm btn-info btn-block"
                        onClick={() => toggleModalUpdate(vendor.id)}
                      >
                        <i className="fa fa-pencil-alt"></i> Edit
                      </button>
                    )}
                    {hasAccess("delete-vendor") && (
                      <button
                        className="btn btn-sm btn-danger btn-block"
                        onClick={() => toggleModalDelete(vendor.id)}
                      >
                        <i className="fa fa-trash"></i> Hapus
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {vendorList.meta.count > vendorList.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(vendorList.meta.count / vendorList.meta.page_size)}
              selectedpage={vendorList.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(vendorList.meta.page + 1)}
              prev={() => gotoPage(vendorList.meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalVendorCreate
        show={showModalCreate}
        onClose={() => setShowModalCreate(!showModalCreate)}
        onSuccess={onSuccess}
      />
      <ModalVendorUpdate
        show={showModalUpdate}
        onClose={toggleModalUpdate}
        id={showModalUpdate ? selectedId : null}
        onSuccess={onSuccess}
      />
      <ModalVendorDelete
        show={showModalDelete}
        onClose={toggleModalDelete}
        id={showModalDelete ? selectedId : null}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
