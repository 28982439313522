import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getRegistratioById, sendReportCoverage } from "../../api/user";
import AlertBox from "../AlertBox";

export function ModalCheckCoverage({ show, id, setShow, onSuccess }) {
  let [data, setData] = useState({});
  let [form, setForm] = useState({ coverage: "1", note: "", bypass: false });
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [error, setError] = useState("");
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await getRegistratioById({ id });
        setData(response.data.data);
      } catch (error) {
        if (error.response) {
          setError(error.response.data.message);
        } else {
          setError("Terjadi kesalahan");
        }
      }
    };

    if (id) {
      getData();
    }
  }, [id]);

  useEffect(() => {
    if (error) {
      setAlert({ show: true, message: error, type: "danger" });
    }
  }, [error]);

  const sendReport = async (form_data) => {
    setLoading(true);
    try {
      let { data } = await sendReportCoverage(id, form_data);
      onSuccess(data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  const onSubmitData = (event) => {
    event.preventDefault();
    sendReport(form);
  };

  const resetForm = () => {
    setData({});
    setForm({ coverage: "1", note: "" });
  };

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    resetForm();
    setShow("");
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <form onSubmit={(event) => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Pengecekan Coverage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="row">
            <div className="col-lg-6">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Nomor Registrasi</td>
                    <td>{data.registration_number}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{data.status ? data.status.display_name : "-"}</td>
                  </tr>
                  <tr>
                    <td>Nama Lengkap</td>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <td>Nomor Telepon</td>
                    <td>{data.phone_number}</td>
                  </tr>
                  <tr>
                    <td>Nomor Pelanggan</td>
                    <td>{data.customer_number ? data.customer_number : "-"}</td>
                  </tr>
                  {data.installation_address && (
                    <tr>
                      <td>Alamat Pemasangan</td>
                      <td>
                        {data.installation_address.address}, RT {data.installation_address.rt}/RW{" "}
                        {data.installation_address.rw},{" "}
                        {data.installation_address.addresses.kelurahan},{" "}
                        {data.installation_address.addresses.kecamatan},{" "}
                        {data.installation_address.addresses.kota_kab},{" "}
                        {data.installation_address.addresses.province},{" "}
                        {data.installation_address.addresses.postal_code}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Grup/Project</td>
                    <td>{data.project?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>Pilihan Paket</td>
                    <td>{data.internet_package ? data.internet_package.name : ""}</td>
                  </tr>
                  <tr>
                    <td>Jenis Pelanggan</td>
                    <td>
                      {data.registration_type === "INSTALLED" ? "Pelanggan Lama" : "Pelanggan Baru"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Form Report Coverage */}
            <div className="col-lg-6">
              <h5>Hasil Pengecekan</h5>
              <div className="form-group">
                <label>Termasuk dalam Jangkauan</label>
                <select
                  className="form-control"
                  value={form.coverage}
                  onChange={(event) => setForm({ ...form, coverage: event.target.value })}
                >
                  <option value="1">Iya</option>
                  <option value="0">Tidak</option>
                </select>
              </div>
              <div className="form-group">
                <label>Catatan</label>
                <textarea
                  className="form-control"
                  required={form.coverage === "0"}
                  value={form.note}
                  onChange={(event) => setForm({ ...form, note: event.target.value })}
                ></textarea>
              </div>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    className=""
                    checked={form.bypass}
                    onChange={() => setForm({ ...form, bypass: !form.bypass })}
                    name="coverage_booelan"
                  />{" "}
                  Bypass Survey{" "}
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-gray" onClick={() => handleClose()}>
            Cancel
          </button>
          <button className="btn btn-primary" disabled={loading}>
            {loading ? "Menunggu ..." : "Simpan"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
