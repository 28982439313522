import { apiAuth, apiDownloadAuth } from "./base";

export function getAllSchedules(params) {
  let api = apiAuth();
  return api.get('v1/schedules', { params });
}

export function getSchedulesById(id) {
  let api = apiAuth();
  return api.get(`v1/schedules/${id}`);
}

export function assignScheduleById(id, data) {
  let api = apiAuth();
  return api.put(`v1/schedules/${id}/set-schedule`, data);
}

export function updateScheduleDismantleById(id, data) {
  let api = apiAuth();
  return api.put(`v1/schedules/${id}`, data);
}

export function cancelScheduleDismantleById(id, data) {
  let api = apiAuth();
  return api.put(`v1/schedules/${id}/cancel`);
}

export function updateScheduleById(id, data) {
  let api = apiAuth();
  return api.put(`v1/schedules/${id}`, data);
}

export function downloadSchedule(params = {}) {
  let api =  apiDownloadAuth();
  return api.get("v1/schedules/export/xlsx", {params});
}

export function acceptDismantleModem(id, data) {
  let api = apiAuth();
  return api.put(`v1/schedules/${id}/reset/modem-connection`, data);
}

export function showAllNocDoneDismantle(params) {
  let api = apiAuth();
  return api.get(`v1/schedules/show-all`, { params });
}

export function showBastSchedule(params) {
  let api = apiAuth();
  return api.get(`v1/schedules/show-all`, { params });
}

export function checkCoverageSchedule(id, data) {
  let api = apiAuth();
  return api.post(`v1/schedules/${id}/set-coverage`, data);
} 

export function bastDismantleModem(id, data) {
  let api = apiAuth();
  return api.post(`v1/schedules/${id}/bast`, data);
} 

export function bastSchedule(id, data) {
  let api = apiAuth();
  return api.post(`v1/schedules/${id}/bast`, data);
} 
