import moment from "moment";
import { useState } from "react";
import Box from "../components/Box";
import Pagination from "../components/Pagination";
import MainLayout from "../components/layouts/MainLayout";
import ModalDetail from "../components/registration/ModalDetail";
import ModalNoteSchedule from "../components/schedule/ModalNoteSchedule";
import ModalAssignTechnician from "../components/technician/ModalAssignTechnician";
import ModalGenerateAssignment from "../components/technician/ModalGenerateAssignment";
import { BUILDING_STATUS } from "../tools/userdatatools";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import { useRegisteredSchedules } from "../utils/CostumerListHooks";
import { useGroupListHooks } from "../utils/ProjectsUtilHooks";

export default function SchedulerPage() {
  let { hasAccess } = useControlPermission();

  let [formFilter, setFormFilter] = useState({
    q: "",
    registration_number: "",
    installation_schedule_number: "",
    installation_date_until: "",
    installation_date_from: "",
    name: "",
    survey_status: "",
    project_id: "",
    technician_name: "",
    assigned: "",
  });
  let [filter, setFilter] = useState({
    q: "",
    registration_number: "",
    installation_schedule_number: "",
    installation_date_until: "",
    installation_date_from: "",
    name: "",
    survey_status: "",
    project_id: "",
    technician_name: "",
    assigned: "",
    page: 1,
    page_size: 10,
  });
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const scheduleData = useRegisteredSchedules({ filter });

  let [selectedId, setSelectedId] = useState("");
  let [showModalDetail, setShowModalDetail] = useState(false);
  let [showModalAssign, setShowModalAssign] = useState(false);
  let [showModalAssignment, setShowModalAssignment] = useState(false);
  let [showModalNote, setShowModalNote] = useState(false);

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({
      q: "",
      registration_number: "",
      installation_schedule_number: "",
      installation_date_until: "",
      installation_date_from: "",
      name: "",
      survey_status: "",
      project_id: "",
      technician_name: "",
      assigned: "",
    });
    setFilter({
      q: "",
      registration_number: "",
      installation_schedule_number: "",
      installation_date_until: "",
      installation_date_from: "",
      name: "",
      survey_status: "",
      project_id: "",
      technician_name: "",
      assigned: "",
      page: 1,
      page_size: 10,
    });
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(!showModalDetail);
  };

  const toggleModalAssign = (id) => {
    setSelectedId(id);
    setShowModalAssign(!showModalAssign);
  };

  const toggleModalDownloadAssignment = (id) => {
    setSelectedId(id);
    setShowModalAssignment(!showModalAssignment);
  };

  const toggleModalNote = (id) => {
    setSelectedId(id);
    setShowModalNote(!showModalNote);
  };

  const onSuccess = (message) => {
    scheduleData.getCostumers(filter);
    setAlert({ show: true, message: message, type: "success" });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const getInstallationSchedule = (schedule = []) => {
    let data = {
      id: "",
      number: "",
      date: "",
      technicians: [],
    };
    let search = schedule.find((schedule) => schedule.schedule.type === "INSTALLATION");

    if (search) {
      data.id = search.schedule.id;
      data.number = search.schedule.installation_schedule_number;
      data.date = moment(search.schedule.schedule).format("DD MMM YYYY HH:mm");
      data.technicians = search.technicians;
    }

    return data;
  };

  const printRow = (customer, index) => {
    let installation_schedule = getInstallationSchedule(customer.registration_schedules);

    return (
      <tr key={index}>
        <td>
          <button
            className="btn btn-sm btn-block mb-1 btn-info text-nowrap"
            onClick={() => toggleModalDetail(customer.id)}
          >
            <i className="fas fa-eye"></i> Lihat Detail
          </button>

          {hasAccess("assign-technician-schedule") && (
            <button
              className="btn btn-sm btn-block btn-primary mr-1"
              onClick={() => toggleModalAssign(customer.id)}
            >
              <i className="fa fa-user-cog"></i> Penugasan
            </button>
          )}

          {hasAccess("installation-assignment-letter") && installation_schedule.number && (
            <button
              className="btn btn-sm btn-block btn-success"
              onClick={() => toggleModalDownloadAssignment(customer.id)}
            >
              <i className="fa fa-print"></i> ST Instalasi
            </button>
          )}

          {hasAccess("update-schedule") && installation_schedule.id && (
            <button
              className="btn btn-sm btn-block btn-warning mr-1 text-nowrap"
              onClick={() => toggleModalNote(installation_schedule.id)}
            >
              <i className="fa fa-solid fa-note-sticky"></i> Catatan Teknisi
            </button>
          )}
        </td>
        <td>{customer.registration_number}</td>
        <td>{installation_schedule.number}</td>
        <td>{installation_schedule.date}</td>
        <td>
          {customer.billing_status ? (
            <>
              <span className="badge badge-info">Sudah Disurvey</span>
              <br />
              {customer.billing_status === "PAID" ? (
                <span className="badge badge-success">Siap Pasang</span>
              ) : (
                <span className="badge badge-danger">Belum Bayar</span>
              )}
            </>
          ) : (
            <span className="badge badge-warning">Belum Survey</span>
          )}
        </td>
        <td>{customer.name}</td>
        <td className="text-wrap">
          {customer.installation_address.address} RT {customer.installation_address.rt}/RW{" "}
          {customer.installation_address.rw}
        </td>
        <td>
          {customer.installation_address.addresses.kelurahan} -{" "}
          {customer.installation_address.addresses.postal_code}
        </td>
        <td>{customer.project?.name || "-"}</td>
        <td>{BUILDING_STATUS[customer.building_ownership_status]}</td>
        <td>
          <ol style={{ paddingLeft: "15px" }}>
            {installation_schedule.technicians.map((technician, index) => (
              <li key={index}>{technician.name}</li>
            ))}
          </ol>
        </td>
      </tr>
    );
  };

  return (
    <MainLayout resource_key="schedule">
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Penjadwalan Instalasi</h1>
        </div>

        <Box
          title={`Jadwal Instalasi (Total: ${scheduleData.meta.count})`}
          loading={scheduleData.loading}
        >
          <div className="mb-4">
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="q_field">No Reg./Nama Registrasi</label>
                  <input
                    type="text"
                    name="q_field"
                    placeholder="No Reg./Nama Registrasi"
                    value={formFilter.q}
                    onChange={(event) => changeFilter("q", event.target.value)}
                    className="form-control"
                    id="q_field"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="installation_schedule_number">No. Penugasan</label>
                  <input
                    type="text"
                    name="installation_schedule_number"
                    value={formFilter.installation_schedule_number}
                    onChange={(event) =>
                      changeFilter("installation_schedule_number", event.target.value)
                    }
                    className="form-control"
                    id="installation_schedule_number"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="installation_date_from">Tanggal dari</label>
                  <input
                    type="date"
                    max={formFilter.installation_date_until}
                    name="installation_date_from"
                    value={formFilter.installation_date_from}
                    onChange={(event) => changeFilter("installation_date_from", event.target.value)}
                    className="form-control"
                    id="installation_date_from"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="installation_date_until">Tanggal ke</label>
                  <input
                    type="date"
                    min={formFilter.installation_date_from}
                    name="installation_date_until"
                    value={formFilter.installation_date_until}
                    onChange={(event) =>
                      changeFilter("installation_date_until", event.target.value)
                    }
                    className="form-control"
                    id="installation_date_until"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="installation_date_until">Project/Site</label>
                  <ProjectFilterForm
                    value={formFilter.project_id}
                    onChangeForm={(value) => changeFilter("project_id", value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Status</label>
                  <select
                    className="form-control"
                    value={formFilter.survey_status}
                    onChange={(event) => changeFilter("survey_status", event.target.value)}
                  >
                    <option value="">Semua</option>
                    <option value="no-survey">Belum Survey</option>
                    <option value="survey-unpaid">Sudah Survey - Belum Bayar</option>
                    <option value="survey-paid">Sudah Survey - Siap Pasang</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Penugasan</label>
                  <select
                    className="form-control"
                    value={formFilter.assigned}
                    onChange={(event) => changeFilter("assigned", event.target.value)}
                  >
                    <option value="">Semua</option>
                    <option value="true">Sudah Ditugaskan</option>
                    <option value="false">Belum Ditugaskan</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="technician_name">Nama Teknisi</label>
                  <input
                    type="text"
                    name="filter_name"
                    value={formFilter.technician_name}
                    onChange={(event) => changeFilter("technician_name", event.target.value)}
                    className="form-control"
                    id="technician_name"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <button
                    className="btn btn-primary"
                    onClick={() => applyFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => resetFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          {scheduleData.errorMsg && (
            <div className="alert alert-danger">{scheduleData.errorMsg}</div>
          )}
          <div style={{ overflowX: "auto" }}>
            <table className="table table-striped table-bordered table-nowrap">
              <thead>
                <tr>
                  <th></th>
                  <th>No. Registrasi</th>
                  <th>No. Penugasan</th>
                  <th>Jadwal</th>
                  <th>Status</th>
                  <th>Nama Pelanggan</th>
                  <th>Alamat</th>
                  <th>Kelurahan - Kode Pos</th>
                  <th>Project</th>
                  <th>Kepemilikan Lokasi</th>
                  <th>Teknisi</th>
                </tr>
              </thead>
              <tbody>
                {scheduleData.costumers.length === 0 && (
                  <tr>
                    <td colSpan="11" className="text-center">
                      Tidak ada data pemasangan
                    </td>
                  </tr>
                )}
                {scheduleData.costumers.map((customer, index) => printRow(customer, index))}
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            {scheduleData.meta.count > scheduleData.meta.page_size && (
              <Pagination
                totalpage={Math.ceil(scheduleData.meta.count / scheduleData.meta.page_size)}
                selectedpage={scheduleData.meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(scheduleData.meta.page + 1)}
                prev={() => gotoPage(scheduleData.meta.page - 1)}
              />
            )}
          </div>
        </Box>
      </div>

      <ModalDetail show={showModalDetail} id={selectedId} setShow={toggleModalDetail}></ModalDetail>
      <ModalGenerateAssignment
        show={showModalAssignment}
        id={selectedId}
        setShow={toggleModalDownloadAssignment}
        onSuccess={onSuccess}
        type="INSTALLATION"
      ></ModalGenerateAssignment>
      <ModalAssignTechnician
        show={showModalAssign}
        id={selectedId}
        setShow={toggleModalAssign}
        onSuccess={onSuccess}
      ></ModalAssignTechnician>
      <ModalNoteSchedule
        show={showModalNote}
        id={showModalNote ? selectedId : null}
        setShow={toggleModalNote}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function ProjectFilterForm({ value, onChangeForm }) {
  const { projects } = useGroupListHooks();
  return (
    <select
      className="form-control"
      id="projectFilter"
      value={value}
      onChange={(event) => onChangeForm(event.target.value)}
    >
      <option value="">SEMUA</option>
      {projects.map((project, index) => (
        <option value={project.id} key={index}>
          {project.name}
        </option>
      ))}
    </select>
  );
}
