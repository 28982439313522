/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

class Pagination extends Component {
  render () {
    let pageNum = [];
    for (let page = 1; page <= this.props.totalpage; page++) {
      if (
        page === (this.props.selectedpage - 4) || 
        page === (this.props.selectedpage + 4) || 
        page === (this.props.selectedpage - 3) || 
        page === (this.props.selectedpage + 3) || 
        page === (this.props.selectedpage - 2) || 
        page === (this.props.selectedpage + 2) || 
        page === (this.props.selectedpage - 1) || 
        page === (this.props.selectedpage + 1) || 
        page === this.props.selectedpage
      ) {
        pageNum.push(page)
      }
    }

    return (
      <ul className="pagination justify-content-center" role="group">
        <li className="page-item">
          {this.props.selectedpage <= 1 
          ? <a 
              className={`page-link`}
              type="button"
            >
              Previous
            </a>
          : <a 
              className={`page-link`}
              type="button"
              onClick={() => this.props.prev()}
            >
              Previous
            </a>
          }
        </li>
        
        {pageNum.map((page, index) => (
          <li className={`page-item ${this.props.selectedpage === page ? "active" : ""}`} key={index}>
            <a
              className={`page-link`}
              type="button"
              onClick={() => this.props.clickpage(page)}
            >
              {page}
            </a>
          </li>
        ))}

        <li className="page-item">
          {this.props.selectedpage === this.props.totalpage 
            ? <a
                className={`page-link `}
                type="button"
              >
                Next
              </a>
            : <a
                className={
                  `page-link`
                }
                type="button"
                onClick={() => this.props.next()}
              >
                Next
              </a>
          }
          
        </li>
        
      </ul>
    )
  }
}


export default Pagination;
