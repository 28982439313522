import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateWarehouseById } from "../../../api/inventory";
import { useWarehouseHooks } from "../../../utils/InventoryHooks";
import useRegionHooks from "../../../utils/RegionHooks";
import AlertBox from "../../AlertBox";
import { RequiredAsterisk } from "../../FormHelper";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../../Loading";

export default function ModalUpdateWarehouse({ show, onClose, id, onSuccess }) {
  let { data, fetched, loading: loadingDetailWarehouse, error } = useWarehouseHooks({ id });
  let [form, setForm] = useState({ name: "", description: "" });
  let [loading, setLoading] = useState(false);
  let region = useRegionHooks();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    if (fetched) {
      setForm({
        name: data.name,
        description: data.description,
      });

      region.setAddress(data.address);
      region.setProvince(data.addresses.province);
      region.setCity(data.addresses.kota_kab);
      region.setDistrict(data.addresses.kecamatan);
      region.setAddressId(data.addresses.id);
    }
  }, [fetched]);

  const handleClose = () => {
    setForm({ name: "", description: "" });
    region.resetRegion();
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
      address: region.address,
      address_id: String(region.addressId),
    };

    console.log(formData);
    sendUpdateWarehouse(formData);
  };

  const sendUpdateWarehouse = async (form) => {
    setLoading(true);
    try {
      let response = await updateWarehouseById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Ubah data gudang</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingInsideBoxWrapper>
            {(loading || loadingDetailWarehouse) && <LoadingInsideBox />}
            {error && <div className="alert alert-danger">{error}</div>}
            <AlertBox {...alert} setAlert={setAlert} />
            <div className="form-group">
              <label htmlFor="">
                Nama Gudang <RequiredAsterisk />{" "}
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={form.name}
                onChange={(event) => setForm({ ...form, name: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">
                Deskripsi <RequiredAsterisk />
              </label>
              <textarea
                type="text"
                className="form-control"
                required
                value={form.description}
                onChange={(event) => setForm({ ...form, description: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">
                Alamat <RequiredAsterisk />
              </label>
              <textarea
                type="text"
                className="form-control"
                required
                value={region.address}
                onChange={(event) => region.setAddress(event.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="projectname">
                Provinsi <RequiredAsterisk />
              </label>
              <select
                name="provinsi"
                requiredid="provinsi"
                className="form-control"
                value={region.province}
                onChange={(event) => region.changeProvince(event.target.value)}
              >
                <option value="">-- Pilih Provinsi --</option>
                {region.provinces.map((province, index) => (
                  <option key={index} value={province.province}>
                    {province.province}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="projectname">
                Kota/Kabupaten <RequiredAsterisk />
              </label>
              <select
                name="kabupaten"
                requiredid="kabupaten"
                className="form-control"
                value={region.city}
                onChange={(event) => region.changeCity(event.target.value)}
              >
                <option value="">-- Pilih Kabupaten/Kota --</option>
                {region.cities.map((city, index) => (
                  <option key={index} value={city.kota_kab}>
                    {city.kota_kab}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="projectname">
                Kecamatan <RequiredAsterisk />
              </label>
              <select
                name="kecamatan"
                required
                id="kecamatan"
                className="form-control"
                value={region.district}
                onChange={(event) => region.changeDistrict(event.target.value)}
              >
                <option value="">-- Pilih Kecamatan --</option>
                {region.districts.map((district, index) => (
                  <option key={index} value={district.kecamatan}>
                    {district.kecamatan}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="projectname">
                Kelurahan <RequiredAsterisk />
              </label>
              <select
                name="kelurahan"
                required
                id="kelurahan"
                className="form-control"
                value={region.addressId}
                onChange={(event) => region.setAddressId(event.target.value)}
              >
                <option value="">-- Pilih Kelurahan --</option>
                {region.addresslist.map((kelurahan, index) => (
                  <option key={index} value={kelurahan.id}>
                    {kelurahan.kelurahan}
                  </option>
                ))}
              </select>
            </div>
          </LoadingInsideBoxWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary">
            <i className="fas fa-save"></i> Simpan Perubahan
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
