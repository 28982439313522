import { useEffect, useState } from "react";
import Select from "react-select";
import { useShowAllOltHooks } from "../../utils/infrastructure/OltUtils";

export default function OltForm({ value, onChange }) {
  const [oltList, setOltList] = useState([]);
  let { data } = useShowAllOltHooks({});

  useEffect(() => {
    let oltTemp = [];

    data.forEach((oltVal) => {
      let temp = {
        value: oltVal.id,
        label: oltVal.name,
      };
      oltTemp.push(temp);
    });

    setOltList(oltTemp);
  }, [data]);

  const onChangeValue = (selected) => {
    onChange(selected);
  };

  return (
    <>
      <Select
        value={value}
        isClearable
        placeholder="Pilih OLT"
        onChange={(selected) => onChangeValue(selected)}
        options={oltList}
      />
    </>
  );
}
