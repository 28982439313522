import { useState, useEffect } from "react";
import { getCommmission, getDetailCommissions } from "../../api/finance";

export function useCommissionListHooks({ filter }) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({count: 0, page: 1, page_size: 10});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getAllCommissions = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('')
    try {
      const { data } = await getCommmission({ ...filter });
      setData(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  return {
    data,
    meta,
    loading,
    errorMsg,
    getAllCommissions
  }
}

export function useCommissionDetailHooks({ id }) {
  const [data, setData] = useState({});
  const [internetPackages, setInternetPackages] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getDetail = async (id, filter = {}) => {
    setLoading(true);
    setData({});
    setErrorMsg('')
    try {
      const { data } = await getDetailCommissions(id, filter);
      setData(data.data);
      setInternetPackages(data.data.internet_packages);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }
  
  useEffect(() => {
    getDetail(id)
  }, [id])

  return {
    data,
    internetPackages,
    loading,
    errorMsg,
    getDetail
  }
}