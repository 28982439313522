import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import AlertBox from "../AlertBox";
import * as api from "../../api/roleaccess";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../Loading";
import { usePermissionHooks } from "../../utils/PermissionManageUtils";

export default function ModalEditDataPermission({ id, show, setShow, onSuccess }) {
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ display_name: "", description: "" });
  const [selected, setSelected] = useState([]);

  let { data, fetched, loading: permissionLoading } = usePermissionHooks({ id });

  useEffect(() => {
    if (fetched) {
      setForm({
        display_name: data.display_name || "",
        description: data.description || "",
      });
      setSelected([...data.types]);
    }
  }, [data, fetched]);

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "danger" });
    setSelected([]);
    setShow();
  };

  const handleCheckboxChange = (event) => {
    const newSelected = [...selected];
    if (event.target.checked) {
      newSelected.push(event.target.value);
    } else {
      newSelected.splice(newSelected.indexOf(event.target.value), 1);
    }
    setSelected(newSelected);
  };

  const savePermission = async () => {
    setLoading(true);

    let formData = {
      ...form,
      types: selected,
    };
    try {
      let response = await api.updatePermissionById(id, formData);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    savePermission();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Izinkan Role - {data.name}</Modal.Title>
      </Modal.Header>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <LoadingInsideBoxWrapper>
            {(loading || permissionLoading) && <LoadingInsideBox />}
            <div className="form-group">
              <label>Display Name</label>
              <input
                type="text"
                className="form-control"
                value={form.display_name}
                onChange={(event) => setForm({ ...form, display_name: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                value={form.description}
                onChange={(event) => setForm({ ...form, description: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Tipe Permission</label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="menu"
                  id="menuCheck"
                  checked={selected.indexOf("menu") !== -1}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" for="menuCheck">
                  Menu
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="api"
                  id="apiCheck"
                  checked={selected.indexOf("api") !== -1}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" for="apiCheck">
                  API
                </label>
              </div>
            </div>
          </LoadingInsideBoxWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">
            Close
          </button>
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
