import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updatePackageCustomerById } from "../../api/customer";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import { useInternetPackageListHooks } from "../../utils/InternetPackageUtils";
import AlertBox from "../AlertBox";
import { convertToRupiah } from "../../tools/numerictools";

export default function ModalChangePackage({ show, id, onSuccess, onClose }) {
  const { data } = useGetCustomerHooks({ id });

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [form, setForm] = useState({
    internet_package_id: "",
    notification: 1,
    amount: "",
    start_date: "",
    end_date: "",
  });

  // internet package loader
  let internetPackages = useInternetPackageListHooks({
    internet_package_type_id: "",
    name: "",
    page_size: 100,
    page: 1,
  });

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      internet_package_id: form.internet_package_id,
      amount: form.amount,
      notification: form.notification,
      start_date: form.start_date,
      end_date: form.end_date,
    };

    submitChange(formData);
  };

  const resetForm = () => {
    setForm({ internet_package_id: "", notification: 1, start_date: "", end_date: "" });
    setAlert({ show: false, message: "", type: "" });
  };

  const submitChange = async (form) => {
    setLoading(true);
    try {
      let response = await updatePackageCustomerById(id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const checkNotification = (e) => {
    if (e.target.checked) {
      setForm({ ...form, notification: 1 });
    } else {
      setForm({ ...form, notification: 0 });
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          Ubah Paket Pelanggan - {data.registration?.registration_number} - {data.name}
        </Modal.Title>
      </Modal.Header>
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Body>
          <div className="form-group">
            <label>Paket yang baru:</label>
            <select
              className="form-control"
              required
              value={form.internet_package_id}
              onChange={(event) => setForm({ ...form, internet_package_id: event.target.value })}
            >
              <option value="">
                -- Pilih paket baru (Nama Paket - Kecepatan - Harga - Biaya Pemasangan) --
              </option>
              {internetPackages.data.map((pack, index) => (
                <option value={pack.id} key={index}>
                  {pack.name} - ({pack.internet_package_varian.name}) - Rp{" "}
                  {convertToRupiah(pack.price)} - Rp {convertToRupiah(pack.installation_cost)}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Harga Paket</label>
            <input
              type="number"
              className="form-control"
              value={form.amount}
              onChange={(event) => setForm({ ...form, amount: event.target.value })}
            />
            <small>Isi jika ingin mengubah harga paket yang berlaku pada tagihan ini</small>
          </div>
          <div className="form-group">
            <div class="form-check">
              <input
                type="checkbox"
                checked={form.notification === 1}
                onChange={(event) => checkNotification(event)}
                class="form-check-input"
                id="notification-check"
              />
              <label class="form-check-label" htmlFor="notification-check">
                Beri notifikasi pelanggan
              </label>
            </div>
          </div>
          <div className="form-group">
            <label>Mulai Tanggal</label>
            <input
              type="date"
              className="form-control"
              value={form.start_date}
              onChange={(event) => setForm({ ...form, start_date: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Tanggal Jatuh Tempo</label>
            <input
              type="date"
              className="form-control"
              value={form.end_date}
              onChange={(event) => setForm({ ...form, end_date: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button type="button" onClick={() => handleClose()} className="btn btn-secondary mr-1">
              Batal
            </button>
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? "Menunggu ..." : "Simpan"}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
