import moment from "moment";
import { useEffect, useState } from "react";
import AlertBox from "../../components/AlertBox";
import ModalDownloadTransferList from "../../components/billing/autotransfer/ModalDownloadTransferList";
import ModalManualConfirm from "../../components/billing/autotransfer/ModalManualConfirm";
import ModalManualMatch from "../../components/billing/autotransfer/ModalManualMatch";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import Pagination from "../../components/Pagination";
import { convertToRupiah } from "../../tools/numerictools";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useHistoriesTransferHooks } from "../../utils/finance/TransferHistoriesUtils";

export function AutoTransferListPage() {
  const { histories, loading, meta, getHistories } = useHistoriesTransferHooks();
  let { hasAccess } = useControlPermission();

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [formFilter, setFormFilter] = useState({
    q: "",
    status: "",
    n: "",
    type: "CREDIT",
    date: "",
  });
  const [filter, setFilter] = useState({
    q: "",
    status: "",
    n: "",
    type: "CREDIT",
    date: "",
    page: 1,
    page_size: 10,
  });

  const [selectedId, setSelectedId] = useState(null);
  const [showManualConfirmModal, setShowManualConfirmModal] = useState(false);
  const [showManualMatchModal, setShowManualMatchModal] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);

  useEffect(() => {
    getHistories(filter);
  }, [filter, getHistories]);

  const toggleOpenModalConfirm = (id = null) => {
    setSelectedId(id);
    setShowManualConfirmModal(!showManualConfirmModal);
  };

  const toggleOpenModalMatch = (id = null) => {
    setSelectedId(id);
    setShowManualMatchModal(!showManualMatchModal);
  };

  const toggleModalDownload = () => {
    setShowModalDownload(!showModalDownload);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
    getHistories(filter);
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const applyFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
      date: formFilter.date ? moment(formFilter.date).format("DD-MM-YYYY") : "",
      page: 1,
    });
  };

  const resetFilter = () => {
    setFormFilter({ n: "", status: "", q: "", type: "CREDIT", date: "" });
    setFilter({ n: "", status: "", q: "", type: "CREDIT", date: "", page: 1, page_size: 10 });
  };

  return (
    <MainLayout resource_key="finance">
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Auto Transfer List</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-transferhistory") && (
              <button className="btn btn-primary" onClick={() => toggleModalDownload()}>
                <i className="fas fa-download"></i> Unduh Data
              </button>
            )}
          </div>
        </div>
        <Box title="Auto Transfer" loading={loading}>
          <div className="row mb-4">
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>No. Billing / Transfer Amount</label>
                <input
                  type="text"
                  className="form-control"
                  value={formFilter.n}
                  onChange={(event) => changeFilter("n", event.target.value)}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label htmlFor="remark">Remark</label>
                <input
                  type="text"
                  className="form-control"
                  value={formFilter.q}
                  onChange={(event) => changeFilter("q", event.target.value)}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Status</label>
                <select
                  name="status"
                  value={formFilter.status}
                  onChange={(event) => changeFilter("status", event.target.value)}
                  className="form-control"
                  id="status"
                >
                  <option value="">SEMUA</option>
                  <option value="unprocessed">Belum Diproses</option>
                  <option value="auto-confirm">Auto Confirm</option>
                  <option value="manual-confirm">Manual Confirm</option>
                  <option value="manual-match">Manual Match</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Tipe Transaksi</label>
                <select
                  name="type"
                  value={formFilter.type}
                  onChange={(event) => changeFilter("type", event.target.value)}
                  className="form-control"
                  id="type"
                >
                  <option value="CREDIT">CREDIT</option>
                  <option value="DEBIT">DEBIT</option>
                  <option value="">SEMUA</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Transaction Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.date}
                  onChange={(event) => changeFilter("date", event.target.value)}
                />
              </div>
            </div>
            <div className="col-12 col-sm">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
          </div>
          <div style={{ overflowX: "auto" }}>
            <AlertBox {...alert} setAlert={setAlert} />
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-nowrap">
                  <th></th>
                  <th>No Billing</th>
                  <th>No Reg/Cust</th>
                  <th>Retrieve Date</th>
                  <th>Transaction Date</th>
                  <th>Process Status</th>
                  <th>Transfer Amount</th>
                  <th>DB/CR</th>
                  <th>Transfer Note</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {histories.length === 0 && (
                  <tr>
                    <td colSpan={11} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {histories.map((transaction, index) => (
                  <tr key={index}>
                    <td>
                      {transaction.status === null ? (
                        <>
                          {hasAccess("manual-confirm-transferhistory") && (
                            <button
                              className="btn btn-sm btn-primary btn-block text-nowrap"
                              onClick={() => toggleOpenModalConfirm(transaction.id)}
                            >
                              Manual Confirm
                            </button>
                          )}
                          {hasAccess("manual-match-transferhistory") && (
                            <button
                              className="btn btn-sm btn-primary btn-block text-nowrap"
                              onClick={() => toggleOpenModalMatch(transaction.id)}
                            >
                              Manual Match
                            </button>
                          )}
                        </>
                      ) : (
                        "---"
                      )}
                    </td>
                    <td>{transaction.billing?.billing_number}</td>
                    <td>
                      {transaction.billing?.customer
                        ? transaction.billing?.customer?.customer_number
                        : transaction.billing?.registration?.registration_number}
                    </td>
                    <td>{moment.unix(transaction.retrieve_date).format("DD MMM YYYY - HH:mm")}</td>
                    <td>{transaction.transaction_date}</td>
                    <td>{transaction.status}</td>
                    <td className="text-nowrap">Rp {convertToRupiah(transaction.amount)}</td>
                    <td>{transaction.transaction_type}</td>
                    <td>{transaction.note}</td>
                    <td>{transaction.remark}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {meta.count > meta.page_size && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.page_size)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalManualConfirm
        show={showManualConfirmModal}
        onClose={toggleOpenModalConfirm}
        id={selectedId}
        onSuccess={onSuccess}
      />
      <ModalManualMatch
        show={showManualMatchModal}
        onClose={toggleOpenModalMatch}
        id={selectedId}
        onSuccess={onSuccess}
      />
      <ModalDownloadTransferList
        show={showModalDownload}
        onClose={() => setShowModalDownload(false)}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
