import moment from "moment";
import { useEffect, useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import ModalDownloadBillingSales from "../../components/billing/ModalDownloadBillingSales";
import MainLayout from "../../components/layouts/MainLayout";
import { convertToRupiah } from "../../tools/numerictools";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useBillingsHooks } from "../../utils/finance/BillingUtils";
import ModalDetailSalesBilling from "../../components/billing/ModalDetailSalesBilling";

export default function BillingSalesPage() {
  let { hasAccess } = useControlPermission();

  let [formFilter, setFormFilter] = useState({
    status: "PAID",
    type: "",
    name: "",
    n: "",
    payment_method: "",
    confirmed_start_date: "",
    confirmed_end_date: "",
    created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
    created_end_date: moment().format("YYYY-MM-DD"),
    amt_from: "",
    amt_until: "",
  });
  let [filter, setFilter] = useState({
    status: "PAID",
    type: "",
    name: "",
    n: "",
    payment_method: "",
    confirmed_start_date: "",
    confirmed_end_date: "",
    created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
    created_end_date: moment().format("YYYY-MM-DD"),
    amt_from: "",
    amt_until: "",
    page: 1,
    page_size: 25,
  });
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [selectedId, setSelectedId] = useState("");
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);

  let billings = useBillingsHooks({ filter });

  useEffect(() => {
    if (billings.errorMsg) {
      setAlert({ show: true, message: billings.errorMsg, type: "danger" });
    }
  }, [billings.errorMsg]);

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(true);
  };

  const toggleModalDownload = () => {
    setShowModalDownload(!showModalDownload);
  };

  const applyFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
      page: 1,
    });
  };

  const resetFilter = () => {
    setFormFilter({
      status: "PAID",
      type: "",
      name: "",
      n: "",
      payment_method: "",
      confirmed_start_date: "",
      confirmed_end_date: "",
      created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
      created_end_date: moment().format("YYYY-MM-DD"),
      amt_from: "",
      amt_until: "",
    });
    setFilter({
      status: "PAID",
      type: "",
      name: "",
      n: "",
      payment_method: "",
      confirmed_start_date: "",
      confirmed_end_date: "",
      created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
      created_end_date: moment().format("YYYY-MM-DD"),
      province: "",
      kota_kab: "",
      amt_from: "",
      amt_until: "",
      page: 1,
      page_size: 25,
    });
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const onSuccess = (message) => {
    billings.getBillings(filter);
    setAlert({ show: true, message: message, type: "success" });
  };

  return (
    <MainLayout resource_key="sales">
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Pembayaran Pelanggan</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-sales-billing") && (
              <button className="btn btn-primary" onClick={() => toggleModalDownload()}>
                <i className="fas fa-download"></i> Unduh Data
              </button>
            )}
          </div>
        </div>
        <Box title={`Pembayaran (Jumlah Data: ${billings.meta.count})`} loading={billings.loading}>
          <div className="mb-4">
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Nama Pelanggan</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formFilter.name}
                    onChange={(event) => changeFilter("name", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Nomor</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formFilter.n}
                    onChange={(event) => changeFilter("n", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Tanggal Konfirm Dari</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.confirmed_start_date}
                    onChange={(event) => changeFilter("confirmed_start_date", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Tanggal Konfirm Sampai</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.confirmed_end_date}
                    onChange={(event) => changeFilter("confirmed_end_date", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <label>Tanggal Billing Dari</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.created_start_date}
                  onChange={(event) => changeFilter("created_start_date", event.target.value)}
                />
              </div>
              <div className="col-12 col-md-2">
                <label>Tanggal Billing Sampai</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.created_end_date}
                  onChange={(event) => changeFilter("created_end_date", event.target.value)}
                />
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Total Pembayaran Minimal</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formFilter.amt_from}
                    onChange={(event) => changeFilter("amt_from", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Total Pembayaran Maksimal</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formFilter.amt_until}
                    onChange={(event) => changeFilter("amt_until", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm">
                <button
                  className="btn btn-primary"
                  onClick={() => applyFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => resetFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />

          <div style={{ overflowX: "auto" }}>
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>No. Billing</th>
                  <th>No. Reg/Pelanggan</th>
                  <th>Nama</th>
                  <th>Paket</th>
                  <th>Konfirmasi Pembayaran</th>
                  <th>Total Pembayaran</th>
                </tr>
              </thead>
              <tbody>
                {billings.data.length === 0 && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {billings.data.map((billing, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        className="btn text-nowrap btn-sm btn-block btn-primary"
                        onClick={() => toggleModalDetail(billing.id)}
                      >
                        <i className="fa fa-eye"></i> Lihat Detail
                      </button>
                    </td>
                    {/* Nomor Billing */}
                    <td className="text-nowrap">{billing.billing_number || "-"}</td>
                    {/* Nomor Pelanggan / Registrasi */}
                    <td>
                      {billing.type === "REGISTRATION"
                        ? billing.registration.registration_number
                        : billing.customer.customer_number}
                    </td>
                    {/* Nama Pelanggan */}
                    <td className="text-nowrap">
                      {billing.type === "REGISTRATION"
                        ? billing.registration.name
                        : billing.customer.name}
                    </td>
                    {/* Paket */}
                    <td className="text-nowrap">{billing.internet_package.name}</td>
                    {/* Konfirmasi Pembayaran */}
                    <td className="text-nowrap">
                      {billing.status === "UNPAID"
                        ? "-"
                        : billing.confirmed_date
                        ? moment.unix(billing.confirmed_date).format("DD MMM YYYY - HH:mm")
                        : "-"}
                    </td>
                    {/* Total Pembayaran */}
                    <td className="text-nowrap">Rp. {convertToRupiah(billing.final_amount)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            {billings.meta.count > billings.meta.page_size && (
              <Pagination
                totalpage={Math.ceil(billings.meta.count / billings.meta.page_size)}
                selectedpage={billings.meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(billings.meta.page + 1)}
                prev={() => gotoPage(billings.meta.page - 1)}
              />
            )}
          </div>
        </Box>
      </div>

      <ModalDetailSalesBilling
        show={showModalDetail}
        id={showModalDetail ? selectedId : ""}
        onClose={() => setShowModalDetail(false)}
      />

      <ModalDownloadBillingSales
        show={showModalDownload}
        onClose={toggleModalDownload}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
