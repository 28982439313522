import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useStatisticCustomer } from "../../utils/DashboardHooks";
import BoxGenericCustomerStats from "./BoxGenericCustomerStats";
import BoxOnlineUsers from "./BoxOnlineUsers";

export default function SectionCustomerGraph() {
  const { data, meta, fetched, loading, errorMsg } = useStatisticCustomer();
  let { hasAccess } = useControlPermission();

  return (
    <div>
      <div className="row">
        {hasAccess("active-customer-count") && (
          <div className="col-lg-4 col-12">
            <BoxOnlineUsers />
          </div>
        )}
        {hasAccess("traffic-customer-monitor") && (
          <div className="col-lg col-12">
            <BoxGenericCustomerStats
              type="aktif"
              title="Statistik Pelanggan 14 hari terakhir"
              data={data}
              fetched={fetched}
              loading={loading}
              errorMsg={errorMsg}
              min={meta.active?.min || 0}
              max={meta.active?.max || 0}
              color="#28a745"
            />
          </div>
        )}
      </div>
      {hasAccess("traffic-customer-monitor") && (
        <div className="row">
          <div className="col-lg-6 col-12">
            <BoxGenericCustomerStats
              type="dismantle"
              title="Statistik Dismantle 14 hari terakhir"
              data={data}
              fetched={fetched}
              loading={loading}
              errorMsg={errorMsg}
              min={meta.dismantle?.min || 0}
              max={meta.dismantle?.max || 0}
              color="#dc3545"
            />
          </div>
          <div className="col-lg-6 col-12">
            <BoxGenericCustomerStats
              type="isolir"
              title="Statistik Isolir 14 hari terakhir"
              data={data}
              fetched={fetched}
              loading={loading}
              errorMsg={errorMsg}
              min={meta.isolir?.min || 0}
              max={meta.isolir?.max || 0}
              color="#ffc107"
            />
          </div>
        </div>
      )}
    </div>
  );
}
