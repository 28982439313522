export default function Timepicker(props) {
  let hours = []
  for (let hour = 0; hour < 24; hour++) {
    if (hour < 10) {
      hours.push("0"+hour);
    } else {
      hours.push(hour);
    }
  }

  let minutes = []
  for (let minute = 0; minute < 60; minute++) {
    if (minute < 10) {
      minutes.push("0"+minute);
    } else {
      minutes.push(minute);
    }
  }


  let timeHour = props.time.split(':')[0]
  let timeMinute = props.time.split(':')[1]

  const onChangeHour = (value) => {
    props.onChangeTime(value+":"+timeMinute)
  }

  const onChangeMinute = (value) => {
    props.onChangeTime(timeHour+":"+value)
  }

  return(
    <div className="row">
      <div className="col">
        <select className="form-control" value={timeHour} onChange={event => onChangeHour(event.target.value)}>
          {hours.map(hour => (
            <option value={hour} key={hour}>{hour}</option>
          ))}
        </select>
      </div>
      <div>:</div>
      <div className="col">
        <select className="form-control" value={timeMinute} onChange={event => onChangeMinute(event.target.value)}>
          {minutes.map(minute => (
            <option value={minute} key={minute}>{minute}</option>
          ))}
        </select>
      </div>
    </div>
  )
}