import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useInventoriesByWarehouseHooks } from "../../../utils/InventoryHooks";
import AlertBox from "../../AlertBox";

export function ModalselectInventory({ show, onClose, addInventory, warehouse_id, onSuccess }) {
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [filterInv, setFilterInv] = useState({ page: 1, page_size: 100, id: warehouse_id });

  let [inventoryIndex, setInventoryIndex] = useState("");

  let [selectedInventory, setSelectedInventory] = useState({});
  let [qty, setQty] = useState(1);

  let inventories = useInventoriesByWarehouseHooks({ filter: filterInv });

  useEffect(() => {
    if (show) {
      setFilterInv((prev) => ({ ...prev, id: warehouse_id }));
    }
  }, [warehouse_id, show]);

  useEffect(() => {
    if (selectedInventory.inventory_category?.name.toUpperCase() === "MODEM") {
      setQty(1);
    }
  }, [selectedInventory]);

  const handleClose = () => {
    setInventoryIndex("");
    setSelectedInventory({});
    setQty(1);
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };
  const onChangeInventory = (index) => {
    setInventoryIndex(index);
    setSelectedInventory({ ...inventories.inventories[index] });
  };

  const onSubmitData = (event) => {
    event.preventDefault();

    addInventory(selectedInventory, qty);
    handleClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Pilih Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label>Pilih Barang</label>
            <select
              className="form-control"
              value={inventoryIndex}
              onChange={(event) => onChangeInventory(event.target.value)}
              required
            >
              <option value=""> -- Pilih Barang/Inventory -- </option>
              {inventories.inventories.map((inventory, index) => (
                <option value={index} key={index}>
                  {inventory.name} (Stok: {inventory.stock} {inventory.unit})
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Kuantitas</label>
            <input
              type="number"
              min={1}
              disabled={selectedInventory.inventory_category?.name.toUpperCase() === "MODEM"}
              className="form-control"
              value={qty}
              onChange={(event) => setQty(event.target.value)}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary">
            <i className="fas fa-plus"></i> Tambah Inventory
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
