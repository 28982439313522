import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateVariantById } from "../../api/internetpackage";
import { useVariantHooks } from "../../utils/InternetPackageUtils";
import { RequiredAsterisk } from "../FormHelper";
import { OLTProfileForm } from "./OLTProfileForm";

export default function ModalUpdateVariant({ show, id, onClose, onSuccess }) {
  let [form, setForm] = useState({
    name: "",
    description: "",
    amount: "",
    unit: "",
    validity_period: "",
    radius_profile: "",
    olt_profile_id: "",
  });
  let [loading, setLoading] = useState(false);
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let variantPkg = useVariantHooks({ id });

  useEffect(() => {
    if (variantPkg.fetched) {
      setForm({
        name: variantPkg.data.name,
        description: variantPkg.data.description,
        amount: variantPkg.data.amount,
        unit: variantPkg.data.unit,
        validity_period: variantPkg.data.validity_period,
        radius_profile: variantPkg.data.radius_profile,
        olt_profile_id: variantPkg.data.olt_profile_id || "",
      });
    }
  }, [variantPkg.fetched]);

  useEffect(() => {
    if (variantPkg.error) {
      setAlert({ show: true, message: variantPkg.error, type: "danger" });
    }
  }, [variantPkg.error]);

  const handleClose = () => {
    setForm({
      name: "",
      description: "",
      amount: "",
      unit: "",
      validity_period: "",
      radius_profile: "",
    });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    updateVariantPackage(form);
  };

  const updateVariantPackage = async (form) => {
    setLoading(true);
    try {
      let response = await updateVariantById(id, form);
      onSuccess(true, response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Ubah Varian Paket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="">
              Nama Varian <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi <span className="text-danger">*</span>
            </label>
            <textarea
              type="text"
              required
              className="form-control"
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Kuota/Kecepatan <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.amount}
              onChange={(event) => setForm({ ...form, amount: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Satuan <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.unit}
              onChange={(event) => setForm({ ...form, unit: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Profile Radius <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.radius_profile}
              onChange={(event) => setForm({ ...form, radius_profile: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>
              Profil OLT <RequiredAsterisk />
            </label>
            <OLTProfileForm
              value={form.olt_profile_id}
              onChangeValue={(value) => setForm({ ...form, olt_profile_id: value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Berlaku (dalam hari)<span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.validity_period}
              onChange={(event) => setForm({ ...form, validity_period: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-plus"></i> {loading ? "Menunggu ..." : "Simpan Perubahan"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
