import { useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../../AlertBox";
import { deleteOltProfileById } from "../../../api/infrastructure";
import { useOltProfileByIdHooks } from "../../../utils/infrastructure/OltUtils";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../../Loading";

export default function ModalDeleteOltProfile({ id, show, onClose, onSuccess }) {
  return (
    <Modal show={show} onHide={() => onClose()}>
      <FormDeleteOltProfile id={id} onSuccess={onSuccess} onClose={() => onClose()} />
    </Modal>
  );
}

function FormDeleteOltProfile({ id, onSuccess, onClose }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let { data, error, loading: loadingData } = useOltProfileByIdHooks({ id });

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      onClose();
    }
  };

  const deleteOlt = async () => {
    setLoading(true);
    try {
      let response = await deleteOltProfileById(id);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Hapus Profil OLT </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoadingInsideBoxWrapper>
          {(loading || loadingData) && <LoadingInsideBox />}
          {error && <div className="alert alert-danger">{error}</div>}
          <AlertBox {...alert} setAlert={setAlert} />
          <h5 className="text-gray-800">
            Apakah anda yakin akan menghapus data olt profile "{data.name}"?
          </h5>
        </LoadingInsideBoxWrapper>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
          Close
        </button>
        <button disabled={loading} className="btn btn-danger" onClick={() => deleteOlt()}>
          <i className="fas fa-trash"></i> {loading ? "Update Profil OLT ..." : "Hapus Profil OLT"}
        </button>
      </Modal.Footer>
    </>
  );
}
