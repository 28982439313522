import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import ModalCreateModem from "../../components/inventory/modem/ModalCreateModem";
import ModalUpdateModem from "../../components/inventory/modem/ModalUpdateModem";
import BackToWarehouseInventoryLink from "../../components/inventory/warehouse/BackToWarehouseLink";
import MainLayout from "../../components/layouts/MainLayout";
import { CONDITION_INVENTORY } from "../../tools/inventorytools";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import {
  useInventoryHooks,
  useModemByInventoryHooks,
  useWarehouseHooks,
} from "../../utils/InventoryHooks";

export default function ModemInventoryPage() {
  const { warehouseId, id } = useParams();
  let { hasAccess } = useControlPermission();

  let warehouse = useWarehouseHooks({ id: warehouseId });

  let [filter, setFilter] = useState({
    name: "",
    is_available: "",
    registration_number: "",
    serial_number: "",
    page: 1,
    page_size: 10,
    warehouse_status: "",
    warehouse_id: warehouseId,
  });
  let [formFilter, setFormFilter] = useState({
    name: "",
    is_available: "",
    registration_number: "",
    serial_number: "",
    warehouse_status: "",
  });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [selectedId, setSelectedId] = useState("");
  let [showModalCreate, setShowModalCreate] = useState(false);
  let [showModalUpdate, setShowModalUpdate] = useState(false);

  let inventory = useInventoryHooks({ id });
  let modems = useModemByInventoryHooks({ id, filter });

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({
      name: "",
      is_available: "",
      registration_number: "",
      serial_number: "",
      warehouse_status: "",
    });
    setFilter({
      name: "",
      is_available: "",
      registration_number: "",
      serial_number: "",
      page: 1,
      page_size: 10,
      warehouse_status: "",
      warehouse_id: warehouseId,
    });
  };

  const toggleOpenModalUpdate = (id = null) => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };
  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    setFilter({ ...filter });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <BackToWarehouseInventoryLink warehouse={warehouse.data} />
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Data Modem "{inventory.data.name}"</h1>
          </div>
        </div>

        <Box title={`Inventory Modem (Total: ${modems.meta.count})`} loading={modems.loading}>
          <div className="row mb-4">
            <div className="col-12 col-sm-2">
              <label>Serial Number</label>
              <input
                type="text"
                className="form-control"
                value={formFilter.serial_number}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, serial_number: event.target.value })
                }
              />
            </div>
            <div className="col-12 col-sm-2">
              <label>No. Reg</label>
              <input
                type="text"
                className="form-control"
                value={formFilter.registration_number}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, registration_number: event.target.value })
                }
              />
            </div>
            <div className="col-12 col-sm-2">
              <label>Nama</label>
              <input
                type="text"
                className="form-control"
                value={formFilter.name}
                onChange={(event) => setFormFilter({ ...formFilter, name: event.target.value })}
              />
            </div>
            <div className="col-12 col-sm-2">
              <label>Ketersediaan</label>
              <select
                name="availability"
                value={formFilter.is_available}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, is_available: event.target.value })
                }
                className="form-control"
                id="registration_type"
              >
                <option value="">SEMUA</option>
                <option value="true">Tersedia</option>
                <option value="false">Terpasang/Tidak Tersedia</option>
              </select>
            </div>
            <div className="col-12 col-sm-2">
              <label>Status Gudang</label>
              <select
                name="warehouse_status"
                value={formFilter.warehouse_status}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, warehouse_status: event.target.value })
                }
                className="form-control"
                id="registration_type"
              >
                <option value="">SEMUA</option>
                <option value="1">Stok Tersedia</option>
                <option value="0">Stok Tidak Tersedia</option>
              </select>
            </div>
            <div className="col-12 col-sm">
              <button
                className="btn btn-primary"
                style={{ marginTop: "32px" }}
                onClick={() => applyFilter()}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                style={{ marginTop: "32px" }}
                onClick={() => resetFilter()}
              >
                Reset
              </button>
            </div>
          </div>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="auto-horizontal-scroll mb-3">
            <table className="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Type</th>
                  <th>S/N Modem</th>
                  <th>S/N GPON</th>
                  <th>Ketersediaan</th>
                  <th>No. Registrasi</th>
                  <th>Nama</th>
                  <th>Kondisi</th>
                  <th>Tgl Input</th>
                  <th>Status Gudang</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {modems.inventories.length === 0 && (
                  <tr>
                    <td colSpan={12} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {modems.inventories.map((modem, index) => (
                  <tr key={index}>
                    <td>{modem.brand}</td>
                    <td>{modem.type}</td>
                    <td>{modem.serial_number_modem}</td>
                    <td>{modem.serial_number_gpon}</td>
                    <td>
                      {modem.is_used ? (
                        <span className="badge badge-danger">Terpasang</span>
                      ) : (
                        <span className="badge badge-success">Tersedia</span>
                      )}
                    </td>
                    <td>{modem.inventory_modem_pair?.registration.registration_number || "-"}</td>
                    <td>{modem.inventory_modem_pair?.registration.name || "-"}</td>
                    <td>
                      {!modem.status ? (
                        <span className="badge badge-danger">
                          {CONDITION_INVENTORY[modem.status]}
                        </span>
                      ) : (
                        <span className="badge badge-success">
                          {CONDITION_INVENTORY[modem.status]}
                        </span>
                      )}
                    </td>
                    <td className="text-nowrap">
                      {moment.unix(modem.input_date).format("DD MMM YYYY - HH:mm")}
                    </td>
                    <td>
                      {modem.warehouse_status ? (
                        <span className="badge badge-success">Stok Tersedia</span>
                      ) : (
                        <span className="badge badge-danger">Stok Tidak Tersedia</span>
                      )}
                    </td>
                    <td>
                      {hasAccess("update-inventory-modem") && (
                        <button
                          className="btn btn-block text-nowrap btn-sm btn-info"
                          onClick={() => toggleOpenModalUpdate(modem.id)}
                        >
                          <i className="fa fa-pencil-alt"></i> Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {modems.meta.count > modems.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(modems.meta.count / modems.meta.page_size)}
              selectedpage={modems.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(modems.meta.page + 1)}
              prev={() => gotoPage(modems.meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalCreateModem
        show={showModalCreate}
        onClose={() => setShowModalCreate(false)}
        inventoryId={id}
        onSuccess={onSuccess}
      />
      <ModalUpdateModem
        show={showModalUpdate}
        onClose={toggleOpenModalUpdate}
        inventoryId={id}
        id={showModalUpdate ? selectedId : ""}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
