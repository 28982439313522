import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createPackage } from "../../api/internetpackage";
import { convertToRupiah } from "../../tools/numerictools";
import {
  useInternetPackageListHooks,
  usePackTypeList,
  useVariantsList,
} from "../../utils/InternetPackageUtils";

let filter = { sort_type: "alphabet", show_all: true };

export default function ModalCreatePack({ show, onClose, onSuccess }) {
  let [form, setForm] = useState({
    name: "",
    description: "",
    internet_package_type_id: "",
    internet_package_varian_id: "",
    price: 0,
    normal_price: 0,
    installation_cost: 0,
    priority: "",
    recurring: 1,
    public_access: 0,
    trial: 0,
    deposit_amount: 0,
    next_applied_package_id: "",
  });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const typesPkg = usePackTypeList({ filter });
  const variantPkg = useVariantsList({ filter });
  const internetPackage = useInternetPackageListHooks();

  const handleClose = () => {
    setForm({
      name: "",
      description: "",
      installation_cost: 0,
      price: 0,
      normal_price: 0,
      internet_package_type_id: "",
      internet_package_varian_id: "",
      priority: "",
      recurring: 1,
      public_access: 0,
      trial: 0,
      deposit_amount: 0,
      next_applied_package_id: "",
    });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    createInternetPackage(form);
  };

  const createInternetPackage = async (form) => {
    setLoading(true);
    try {
      let response = await createPackage({ ...form });
      onSuccess(true, response.data.message, response.data.data);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const checkRecurring = (e) => {
    if (e.target.checked) {
      setForm({ ...form, recurring: 1 });
    } else {
      setForm({ ...form, recurring: 0 });
    }
  };

  const checkPublic = (e) => {
    if (e.target.checked) {
      setForm({ ...form, public_access: 1 });
    } else {
      setForm({ ...form, public_access: 0 });
    }
  };

  const checkTrial = (e) => {
    if (e.target.checked) {
      setForm({ ...form, trial: 1 });
    } else {
      setForm({ ...form, trial: 0 });
    }
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Paket Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="">
              Nama Paket <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              maxLength={50}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deskripsi <span className="text-danger">*</span>
            </label>
            <textarea
              type="text"
              className="form-control"
              required
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Prioritas <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.priority}
              onChange={(event) => setForm({ ...form, priority: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Tipe <span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              value={form.internet_package_type_id}
              onChange={(event) =>
                setForm({ ...form, internet_package_type_id: event.target.value })
              }
              required
            >
              <option value="">-- Pilih Tipe Paket --</option>
              {typesPkg.types.map((types, index) => (
                <option value={types.id} key={index}>
                  {types.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="">
              Varian <span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              value={form.internet_package_varian_id}
              onChange={(event) =>
                setForm({ ...form, internet_package_varian_id: event.target.value })
              }
              required
            >
              <option value="">-- Pilih Varian Paket --</option>
              {variantPkg.variants.map((variant, index) => (
                <option value={variant.id} key={index}>
                  {variant.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="">
              Harga Normal <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.normal_price}
              onChange={(event) => setForm({ ...form, normal_price: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Harga Jual <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.price}
              onChange={(event) => setForm({ ...form, price: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Biaya Pemasangan <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.installation_cost}
              onChange={(event) => setForm({ ...form, installation_cost: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Deposit <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              value={form.deposit_amount}
              onChange={(event) => setForm({ ...form, deposit_amount: event.target.value })}
            />
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                type="checkbox"
                checked={form.trial === 1}
                onChange={(event) => checkTrial(event)}
                className="form-check-input"
                id="trial_check"
              />
              <label className="form-check-label" htmlFor="trial_check">
                Paket Percobaan (Trial)
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                type="checkbox"
                checked={form.public_access === 1}
                onChange={(event) => checkPublic(event)}
                className="form-check-input"
                id="public_access_check"
              />
              <label className="form-check-label" htmlFor="public_access_check">
                Publik (tampil di web registrasi)
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                type="checkbox"
                checked={form.recurring === 1}
                onChange={(event) => checkRecurring(event)}
                className="form-check-input"
                id="recurring-check"
              />
              <label className="form-check-label" htmlFor="recurring-check">
                Paket Berulang
              </label>
            </div>
          </div>
          {form.recurring === 0 && (
            <div className="form-group">
              <label>Pilihan Paket Berikutnya</label>
              <select
                className="form-control input"
                required
                value={form.next_applied_package_id}
                onChange={(event) =>
                  setForm({ ...form, next_applied_package_id: event.target.value })
                }
              >
                <option value="" disabled>
                  {" "}
                  -- Pilih Paket -- (Nama Paket - Kecepatan - Harga - Biaya Pemasangan)
                </option>
                {internetPackage.data.map((pack, index) => (
                  <option key={index} value={pack.id}>
                    {pack.name} - ({pack.internet_package_varian.name}) - Rp{" "}
                    {convertToRupiah(pack.price)} - Rp {convertToRupiah(pack.installation_cost)}
                  </option>
                ))}
              </select>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-plus"></i> Buat Paket Baru
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
