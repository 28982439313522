import { useState, useEffect } from "react";
import { useALlFramedPool } from "../../utils/radius/FramedPoolUtil";
import Select from "react-select";

export default function FramedPoolForm({ value, onChange, new_value, onChangeNew }) {
  const [poolsOpt, setPoolsOpt] = useState([]);
  let { data } = useALlFramedPool({});

  useEffect(() => {
    let framedPoolTemp = [];

    data.forEach((helperVal) => {
      let temp = {
        value: helperVal.name,
        label: helperVal.name,
      };
      framedPoolTemp.push(temp);

      return true;
    });

    framedPoolTemp.push({
      value: "add-new",
      label: "Tambah Framed Pool baru",
    });
    setPoolsOpt(framedPoolTemp);
  }, [data]);

  const onChangeValue = (selected) => {
    onChange(selected);
  };

  return (
    <>
      <Select
        value={value}
        isClearable
        placeholder="Pilih Framed Pool"
        required
        onChange={(selected) => onChangeValue(selected)}
        options={poolsOpt}
      />
      {value?.value === "add-new" && (
        <div className="form-group mt-3">
          <label>Framed Pool Baru</label>
          <input
            type="text"
            className="form-control"
            value={new_value}
            onChange={(event) => onChangeNew(event.target.value)}
          />
        </div>
      )}
    </>
  );
}
