import moment from "moment";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { convertToRupiah } from "../../tools/numerictools";
import { BUILDING_STATUS } from "../../tools/userdatatools";
import { useBillingIdHooks } from "../../utils/finance/BillingUtils";
import AlertBox from "../AlertBox";
import { BILLING_TYPE } from "../../tools/billingdatatools";

export default function ModalDetailSalesBilling({ show, id, onClose }) {
  const [alert, setAlert] = useState({ show: false, message: "", type: "danger" });
  const { data } = useBillingIdHooks({ id });

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "danger" });
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Detail Billing {data.billing_number}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <div className="row">
          <div className="col-lg">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>No. Billing</td>
                  <td>{data.billing_number}</td>
                </tr>
                <tr>
                  <td>Dibuat oleh</td>
                  <td>{data.created_by || "SYSTEM"}</td>
                </tr>
                <tr>
                  <td>Nomor</td>
                  <td>
                    {(data.type === "CUSTOMER" ||
                      data.type === "CUSTOMER-RELOCATE" ||
                      data.type === "ADDITIONAL-ITEM") &&
                      data.customer?.customer_number}
                    {data.type === "REGISTRATION" && data.registration?.registration_number}
                  </td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td>
                    {(data.type === "CUSTOMER" ||
                      data.type === "CUSTOMER-RELOCATE" ||
                      data.type === "ADDITIONAL-ITEM") &&
                      data.customer?.name}
                    {data.type === "REGISTRATION" && data.registration?.name}
                  </td>
                </tr>
                <tr>
                  <td>Total Pembayaran</td>
                  <td>{data.final_amount && `Rp. ${convertToRupiah(data.final_amount)}`}</td>
                </tr>
                <tr>
                  <td>Jatuh Tempo Pembayaran</td>
                  <td>{data.due_date && moment.unix(data.due_date).format("DD MMM YYYY")}</td>
                </tr>
                <tr>
                  <td>Jatuh Tempo Layanan</td>
                  <td>
                    {data.type === "CUSTOMER" ||
                    data.type === "CUSTOMER-RELOCATE" ||
                    data.type === "ADDITIONAL-ITEM"
                      ? data.plan_end_date
                        ? moment.unix(data.plan_end_date).format("DD MMM YYYY")
                        : "-"
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Periode Berikutnya</td>
                  <td>
                    {data.type === "CUSTOMER" ||
                    data.type === "CUSTOMER-RELOCATE" ||
                    data.type === "ADDITIONAL-ITEM"
                      ? data.payment.start_period
                        ? moment(data.payment.start_period).format("DD MMM YYYY") +
                          " - " +
                          moment(data.payment.end_period).format("DD MMM YYYY")
                        : "-"
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Tanggal Konfirmasi Pembayaran</td>
                  <td>
                    {data.status === "PAID"
                      ? data.confirmed_date
                        ? moment.unix(data.confirmed_date).format("DD MMM YYYY - HH:mm")
                        : "-"
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Metode Pembayaran</td>
                  <td>{data.payment?.payment_method}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{data.status}</td>
                </tr>
                <tr>
                  <td>Paket</td>
                  <td>{data.internet_package?.name}</td>
                </tr>
                <tr>
                  <td>Harga Paket</td>
                  <td>Rp. {convertToRupiah(data.amount || 0)}</td>
                </tr>
                <tr>
                  <td>Biaya Instalasi</td>
                  <td>
                    {data.installation_cost
                      ? `Rp. ${convertToRupiah(data.installation_cost)}`
                      : `-`}
                  </td>
                </tr>
                {data.change_package_id && (
                  <tr>
                    <td>Pergantian Paket</td>
                    <td>YA</td>
                  </tr>
                )}
                <tr>
                  <td>Tipe Billing</td>
                  <td>{BILLING_TYPE[data.type]}</td>
                </tr>
                <tr>
                  <td>No. Telepon</td>
                  <td>
                    {data.type === "REGISTRATION" && data.registration.phone_number}
                    {(data.type === "CUSTOMER" ||
                      data.type === "CUSTOMER-RELOCATE" ||
                      data.type === "ADDITIONAL-ITEM") &&
                      data.customer.phone_number}
                  </td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>
                    {data.type === "REGISTRATION" &&
                      `${data.registration.installation_address.address} ${data.registration.installation_address.rt}/${data.registration.installation_address.rw}, ${data.registration.installation_address.addresses.kelurahan}, ${data.registration.installation_address.addresses.kecamatan}, ${data.registration.installation_address.addresses.kota_kab}, ${data.registration.installation_address.addresses.province}, ${data.registration.installation_address.addresses.postal_code}`}
                    {(data.type === "CUSTOMER" ||
                      data.type === "CUSTOMER-RELOCATE" ||
                      data.type === "ADDITIONAL-ITEM") &&
                      `${data.customer.installation_address.address} ${data.customer.installation_address.rt}/${data.customer.installation_address.rw}, ${data.customer.installation_address.addresses.kelurahan}, ${data.customer.installation_address.addresses.kecamatan}, ${data.customer.installation_address.addresses.kota_kab}, ${data.customer.installation_address.addresses.province}, ${data.customer.installation_address.addresses.postal_code}`}
                  </td>
                </tr>
                <tr>
                  <td>Status Kepemilikan Bangunan</td>
                  <td>
                    {data.type === "REGISTRATION" &&
                      BUILDING_STATUS[data.registration.building_ownership_status]}
                    {(data.type === "CUSTOMER" ||
                      data.type === "CUSTOMER-RELOCATE" ||
                      data.type === "ADDITIONAL-ITEM") &&
                      BUILDING_STATUS[data.customer.building_ownership_status]}
                  </td>
                </tr>
                <tr>
                  <td>Project</td>
                  <td>
                    {data.type === "REGISTRATION" && (data.registration.project?.name || "-")}
                    {(data.type === "CUSTOMER" ||
                      data.type === "CUSTOMER-RELOCATE" ||
                      data.type === "ADDITIONAL-ITEM") &&
                      (data.customer.project?.name || "-")}
                  </td>
                </tr>
                <tr>
                  <td>Sales/Affiliator</td>
                  <td>
                    {data.type === "REGISTRATION" && (data.registration.referral?.name || "-")}
                    {(data.type === "CUSTOMER" ||
                      data.type === "CUSTOMER-RELOCATE" ||
                      data.type === "ADDITIONAL-ITEM") &&
                      (data.customer.referral?.name || "-")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
