import moment from "moment/moment";
import { Modal } from "react-bootstrap";
import { CONDITION_INVENTORY } from "../../../tools/inventorytools";
import { useModemIdHooks } from "../../../utils/inventory/ModemUtils";

export default function ModalHistoryModem({ show, onClose, id }) {
  const { data, resetData } = useModemIdHooks({ id });

  const handleClose = () => {
    resetData();
    onClose(false);
  }

  return (
  <Modal show={show} onHide={() => handleClose()} size="xl">
    <Modal.Header closeButton>
      <Modal.Title>Riwayat Modem</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-md-4">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Brand</td>
                <td>{data.brand || '-'}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>{data.type || '-'}</td>
              </tr>
              <tr>
                <td>S/N Modem</td>
                <td>{data.serial_number_modem || '-'}</td>
              </tr>
              <tr>
                <td>S/N GPON</td>
                <td>{data.serial_number_gpon || '-'}</td>
              </tr>
              <tr>
                <td>Ketersediaan</td>
                <td>
                  {data.available
                    ? <span className="badge badge-success">Tersedia</span>
                    : <span className="badge badge-danger">Tidak Tersedia</span>
                  }
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  {!data.status
                    ? <span className="badge badge-danger">{CONDITION_INVENTORY[data.status]}</span>
                    : <span className="badge badge-success">{CONDITION_INVENTORY[data.status]}</span>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-8">
          <h4>Riwayat Modem</h4>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Tgl. Setup</th>
                <th>ODP</th>
                <th>ODC</th>
                <th>OLT</th>
                <th>Framed Pool</th>
                <th>No Reg</th>
                <th>Nama</th>
              </tr>
            </thead>
            <tbody>
              {data.installation_modem_histories?.length === 0 && 
                <tr>
                  <td colSpan={7}>Tidak ada data</td>
                </tr>
              }
              {data.installation_modem_histories?.map((history, index) => (
                <tr key={index}>
                  <td className="text-nowrap">{moment.unix(history.createdAt).format('DD MMM YYYY')}</td>
                  <td>{history.odp}</td>
                  <td>{history.odc}</td>
                  <td>{history.olt}</td>
                  <td>{history.framed_pool}</td>
                  <td>{history.registration?.registration_number}</td>
                  <td>{history.registration?.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal.Body>
  </Modal>
  )
}