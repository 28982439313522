import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { updateCustomerById } from "../../api/customer";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import useRegionHooks from "../../utils/RegionHooks";
import { useListSales } from "../../utils/sales/SalesUtilsHooks";
import AlertBox from "../AlertBox";

export default function ModalEditCustomer({ show, id, onSuccess, onClose }) {
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const { data, fetched } = useGetCustomerHooks({ id });

  const { projects } = useGroupListHooks();
  const { sales } = useListSales();

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    gender: "",
    phone_number: "",
    alternate_phone_number: "",
    id_number: "",
    npwp_number: "",
    address_id: "",
    birthdate: "",
    birthdate_place: "",
    building_ownership_status: "",
    project_id: "",
    referral_id: "",
  });

  const userRegion = useRegionHooks();
  const installRegion = useRegionHooks();

  const handleChange = (name, value) => {
    setUserData({ ...userData, [name]: value });
  };

  useEffect(() => {
    if (fetched) {
      setUserData({
        name: data.name,
        email: data.email,
        gender: data.gender,
        phone_number: data.phone_number,
        alternate_phone_number: data.alternate_phone_number || "",
        id_number: data.id_number,
        npwp_number: data.npwp_number,
        birthdate: data.birthdate,
        birthdate_place: data.birthdate_place,
        building_ownership_status: data.building_ownership_status,
        project_id: data.project_id || "",
        referral_id: data.referral_id || "",
      });

      userRegion.setAddress(data.address);
      userRegion.setRt(data.rt);
      userRegion.setRw(data.rw);
      userRegion.setProvince(data.addresses.province);
      userRegion.setCity(data.addresses.kota_kab);
      userRegion.setDistrict(data.addresses.kecamatan);
      userRegion.setAddressId(data.addresses.id);

      installRegion.setAddress(data.installation_address.address);
      installRegion.setRt(data.installation_address.rt);
      installRegion.setRw(data.installation_address.rw);
      installRegion.setProvince(data.installation_address.addresses.province);
      installRegion.setCity(data.installation_address.addresses.kota_kab);
      installRegion.setDistrict(data.installation_address.addresses.kecamatan);
      installRegion.setAddressId(data.installation_address.addresses.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fetched]);

  function resetForm() {
    // reset user data
    setUserData({
      name: "",
      email: "",
      gender: "",
      phone_number: "",
      alternate_phone_number: "",
      id_number: "",
      npwp_number: "",
      address_id: "",
      birthdate: "",
      birthdate_place: "",
      building_ownership_status: "",
      project_id: "",
      referral_id: "",
    });
    // reset user region
    userRegion.resetRegion();
    // reset install region
    installRegion.resetRegion();
  }

  function handleSubmit(event) {
    event.preventDefault();
    let formData = {
      ...userData,
      address: userRegion.address,
      address_id: String(userRegion.addressId),
      rt: userRegion.rt,
      rw: userRegion.rw,
      installation_address: {},
    };

    formData.installation_address = {
      address: installRegion.address,
      rt: installRegion.rt,
      rw: installRegion.rw,
      address_id: String(installRegion.addressId),
    };

    saveData(formData);
  }

  async function saveData(form) {
    setLoading(true);
    try {
      let { data } = await updateCustomerById(id, form);
      setAlert({ show: true, message: "Berhasil: " + data.message, type: "success" });
      onSuccess(data.message);
      handleClose();
      resetForm();
    } catch (error) {
      let message = "Sesuatu kesalahan sedang terjadi";

      if (error.response) {
        message = error.response.data.message;
      }
      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  }

  const handleClose = () => {
    resetForm();
    setAlert({ show: false, message: "", type: "" });
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Update Data - {data.registration?.registration_number} - {data.name}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={(event) => handleSubmit(event)}>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="row">
            <div className="col-lg-6">
              <h4>Data Pelanggan</h4>
              <div className="form-group">
                <label htmlFor="name">
                  Nama Lengkap <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  required
                  value={userData.name}
                  onChange={(event) => handleChange("name", event.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="your_email">
                  Nomor Identitas <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="id_number"
                  id="id_number"
                  className="form-control"
                  required
                  value={userData.id_number}
                  onChange={(event) => handleChange("id_number", event.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="npwp_number">Nomor NPWP</label>
                <input
                  type="text"
                  name="npwp_number"
                  id="npwp_number"
                  className="form-control"
                  value={userData.npwp_number}
                  onChange={(event) => handleChange("npwp_number", event.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  required
                  value={userData.email}
                  onChange={(event) => handleChange("email", event.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="birthdate_place">
                  Tempat Lahir <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="birthdate_place"
                  id="birthdate_place"
                  className="form-control"
                  required
                  value={userData.birthdate_place}
                  onChange={(event) => handleChange("birthdate_place", event.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="birthdate">
                  Tanggal Lahir <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  name="birthdate"
                  id="birthdate"
                  className="form-control"
                  value={userData.birthdate}
                  onChange={(event) => handleChange("birthdate", event.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="gender">
                  Jenis Kelamin <span className="text-danger">*</span>
                </label>
                <select
                  className="input form-control"
                  name="gender"
                  id="gender"
                  value={userData.gender}
                  onChange={(event) => handleChange("gender", event.target.value)}
                >
                  <option value="">-- Pilih Jenis Kelamin --</option>
                  <option value="L">Laki-laki</option>
                  <option value="P">Perempuan</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="phone_number">
                  Nomor Telepon <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  value={userData.phone_number}
                  onChange={(event) => handleChange("phone_number", event.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="alternate_phone_number">
                  Nomor Telepon Alternatif <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="alternate_phone_number"
                  id="alternate_phone_number"
                  value={userData.alternate_phone_number}
                  onChange={(event) => handleChange("alternate_phone_number", event.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="address">
                  Alamat Lengkap <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="form-control"
                  value={userRegion.address}
                  onChange={(event) => userRegion.setAddress(event.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="addressRT">
                  RT (3 Digit) <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="addressRT"
                  id="addressRT"
                  className="form-control"
                  value={userRegion.rt}
                  onChange={(event) => userRegion.setRt(event.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="addressRW">
                  RW (3 Digit) <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="addressRW"
                  id="addressRW"
                  className="form-control"
                  value={userRegion.rw}
                  onChange={(event) => userRegion.setRw(event.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="user_province">
                  Provinsi <span className="text-danger">*</span>
                </label>
                <select
                  className="input form-control"
                  id="user_province"
                  required
                  value={userRegion.province}
                  onChange={(event) => userRegion.changeProvince(event.target.value)}
                >
                  <option value="">-- Pilih Provinsi --</option>
                  {userRegion.provinces.map((province, index) => (
                    <option key={index} value={province.province}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="user_city">
                  Kota/Kabupaten <span className="text-danger">*</span>
                </label>
                <select
                  className="input form-control"
                  id="user_city"
                  required
                  value={userRegion.city}
                  onChange={(event) => userRegion.changeCity(event.target.value)}
                >
                  <option value="">-- Pilih Kota / Kab --</option>
                  {userRegion.cities.map((city, index) => (
                    <option key={index} value={city.kota_kab}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="your_email">
                  Kecamatan <span className="text-danger">*</span>
                </label>
                <select
                  className="input form-control"
                  id="user_district"
                  required
                  value={userRegion.district}
                  onChange={(event) => userRegion.changeDistrict(event.target.value)}
                >
                  <option value="">-- Pilih Kecamatan --</option>
                  {userRegion.districts.map((district, index) => (
                    <option key={index} value={district.kecamatan}>
                      {district.kecamatan}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="user_kelurahan">
                  Kelurahan <span className="text-danger">*</span>
                </label>
                <select
                  className="input form-control"
                  id="user_kelurahan"
                  required
                  value={userRegion.addressId}
                  onChange={(event) => userRegion.setAddressId(event.target.value)}
                >
                  <option value="">-- Pilih Kelurahan --</option>
                  {userRegion.addresslist.map((kelurahan, index) => (
                    <option key={index} value={kelurahan.id}>
                      {kelurahan.kelurahan} ({kelurahan.postal_code})
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <h4 className="mb-2">Data Pemasangan</h4>
              <div className="form-group">
                <label htmlFor="building_ownership_status">
                  Status Lokasi Pemasangan <span className="text-danger">*</span>
                </label>
                <select
                  className="input form-control"
                  id="building_ownership_status"
                  value={userData.building_ownership_status}
                  onChange={(event) =>
                    handleChange("building_ownership_status", event.target.value)
                  }
                >
                  <option value="">-- Status --</option>
                  <option value="OWN">Milik Sendiri</option>
                  <option value="RENT">Sewa</option>
                  <option value="BOARDING">Kost</option>
                  <option value="PUBLIC_FACILITIES">Fasilitas Umum</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="project_group">Kelompok/Project</label>
                <select
                  className="input form-control"
                  id="project_group"
                  value={userData.project_id}
                  onChange={(event) => handleChange("project_id", event.target.value)}
                >
                  <option value="">-- Kelompok/Project --</option>
                  {projects.map((project, index) => (
                    <option key={index} value={project.id}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="sales_name">Sales / Affiliator</label>
                <select
                  className="input form-control"
                  id="sales_name"
                  value={userData.referral_id}
                  onChange={(event) => handleChange("referral_id", event.target.value)}
                >
                  <option value="">-- Sales / Affiliator --</option>
                  {sales.map((sales, index) => (
                    <option key={index} value={sales.id}>
                      {sales.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="install_address">
                  Alamat Lengkap <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="install_address"
                  id="install_address"
                  className="form-control"
                  value={installRegion.address}
                  onChange={(event) => installRegion.setAddress(event.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="install_addressRT">
                  RT (3 Digit) <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="install_addressRT"
                  id="install_addressRT"
                  className="form-control"
                  value={installRegion.rt}
                  onChange={(event) => installRegion.setRt(event.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="install_addressRW">
                  RW (3 Digit) <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="install_addressRW"
                  id="install_addressRW"
                  className="form-control"
                  value={installRegion.rw}
                  onChange={(event) => installRegion.setRw(event.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="install_province">
                  Provinsi <span className="text-danger">*</span>
                </label>
                <select
                  className="input form-control"
                  id="install_province"
                  value={installRegion.province}
                  onChange={(event) => installRegion.changeProvince(event.target.value)}
                >
                  <option value="">-- Pilih Provinsi --</option>
                  {installRegion.provinces.map((province, index) => (
                    <option key={index} value={province.province}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="install_city">
                  Kota/Kabupaten <span className="text-danger">*</span>
                </label>
                <select
                  className="input form-control"
                  id="install_city"
                  value={installRegion.city}
                  onChange={(event) => installRegion.changeCity(event.target.value)}
                >
                  <option value="">-- Pilih Kota / Kab --</option>
                  {installRegion.cities.map((city, index) => (
                    <option key={index} value={city.kota_kab}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="install_district">
                  Kecamatan <span className="text-danger">*</span>
                </label>
                <select
                  className="input form-control"
                  id="install_district"
                  value={installRegion.district}
                  onChange={(event) => installRegion.changeDistrict(event.target.value)}
                >
                  <option value="">-- Pilih Kecamatan --</option>
                  {installRegion.districts.map((district, index) => (
                    <option key={index} value={district.kecamatan}>
                      {district.kecamatan}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="install_kelurahan">
                  Kelurahan <span className="text-danger">*</span>
                </label>
                <select
                  className="input form-control"
                  id="install_kelurahan"
                  value={installRegion.addressId}
                  onChange={(event) => installRegion.setAddressId(event.target.value)}
                >
                  <option value="">-- Pilih Kelurahan --</option>
                  {installRegion.addresslist.map((kelurahan, index) => (
                    <option key={index} value={kelurahan.id}>
                      {kelurahan.kelurahan} ({kelurahan.postal_code})
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button type="button" onClick={() => handleClose()} className="btn btn-secondary mr-1">
              Batal
            </button>
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? "Menunggu ..." : "Simpan"}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
