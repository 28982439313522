import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useInventoryHooks } from "../../utils/InventoryHooks";
import AlertBox from "../AlertBox";
import TableInventoryIn from "./TableInventoryIn";
import TableInventoryOut from "./TableInventoryOut";

export default function ModalHistoryInventory({ show, onClose, id, warehouse_id }) {
  const { data } = useInventoryHooks({ id });
  const [tab, setTab] = useState("in");
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Riwayat Keluar Barang: {data.sku} - {data.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item">
            <button className={`nav-link ${tab === "in" && "active"}`} onClick={() => setTab("in")}>
              Barang Masuk
            </button>
          </li>
          <li className="nav-item" onClick={() => setTab("out")}>
            <button
              className={`nav-link ${tab === "out" && "active"}`}
              onClick={() => setTab("out")}
            >
              Barang Keluar
            </button>
          </li>
        </ul>
        {tab === "in" && <TableInventoryIn id={id} unit={data.unit} warehouse_id={warehouse_id} />}
        {tab === "out" && (
          <TableInventoryOut id={id} unit={data.unit} warehouse_id={warehouse_id} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
