import { useEffect, useState } from "react";
import {
  getAllCustomers,
  getAllRequestChangePackage,
  getAllStatuses,
  getAllTypes,
  getCustomerById,
  getCustomerLogById,
  getStatusById,
  getTypeById,
  getWithdrawInfoByCustomerId,
} from "../api/customer";

export function useAllCustomersHooks({ filter }) {
  const [customers, setCustomers] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getCustomers = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllCustomers({ ...filter });
      setCustomers(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getCustomers(filter);
  }, [filter]);

  return {
    customers,
    meta,
    loading,
    errorMsg,
    getCustomers,
  };
}

export function useGetCustomerHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getCustomer(id);
    }
  }, [id]);

  const getCustomer = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getCustomerById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getCustomer,
  };
}

export function useTypeCustomerHooks() {
  let [data, setData] = useState([]);
  let [meta, setMeta] = useState([]);
  let [error, setError] = useState("");

  const getTypes = async () => {
    setError("");
    try {
      let { data } = await getAllTypes();
      setData(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }
  };

  useEffect(() => {
    getTypes();
  }, []);

  return {
    data,
    meta,
    error,
    getTypes,
  };
}

export function useGetType({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getTypeCustomer(id);
    }
  }, [id]);

  const getTypeCustomer = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getTypeById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getTypeCustomer,
  };
}

export function useStatusCostumerHooks() {
  let [data, setData] = useState([]);
  let [meta, setMeta] = useState([]);
  let [error, setError] = useState("");

  const getStatuses = async () => {
    setError("");
    try {
      let { data } = await getAllStatuses();
      setData(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }
  };

  useEffect(() => {
    getStatuses();
  }, []);

  return {
    data,
    meta,
    error,
    getStatuses,
  };
}

export function useGetStatus({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getStatusCustomer(id);
    }
  }, [id]);

  const getStatusCustomer = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getStatusById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getStatusCustomer,
  };
}

export function useAllRequestPackageChange() {
  const [customers, setCustomers] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getRequest = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllRequestChangePackage({ ...filter });
      setCustomers(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  return {
    customers,
    meta,
    loading,
    errorMsg,
    getRequest,
  };
}

export function useCustomerLogHooks({ filter }) {
  const [histories, setHistories] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchLog = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getCustomerLogById(filter.id, { ...filter });
      setHistories(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filter.id) {
      fetchLog(filter);
    }
  }, [filter]);

  return {
    histories,
    meta,
    loading,
    errorMsg,
    fetchLog,
  };
}

export function useGetWithdrawInfoById({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getWithdrawInfo(id);
    }
  }, [id]);

  const getWithdrawInfo = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getWithdrawInfoByCustomerId(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getWithdrawInfo,
  };
}
