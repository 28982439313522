import { useState } from "react";
import { Modal } from "react-bootstrap";
import { downloadSetupModem } from "../../../api/inventory";
import { useALlFramedPool } from "../../../utils/radius/FramedPoolUtil";
import AlertBox from "../../AlertBox";

export default function ModalDownloadModem({ show, onClose, onSuccess }) {
  let framedPools = useALlFramedPool({});
  let [form, setForm] = useState({framed_pool: ''});
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  
  const handleClose = () => {
    setForm({framed_pool: ''});
    setAlert({show: false, message: '', type: ''});
    onClose(false);
  }

  const submitData = (event) => {
    event.preventDefault();
    downloadDataModem(form);
  }

  const downloadDataModem = async (form) => {
    setLoading(true);
    try {
      let response = await downloadSetupModem({...form});
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Data Modem Setup.xlsx');
      onSuccess("Berhasil mengunduh Data Modem Setup.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }

    setLoading(false);
  }
  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={event => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Download Data Setup Modem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert}/>
          <div className="form-group">
            <label>Framed Pools</label>
            <select className="form-control" value={form.framed_pool} onChange={event => setForm({ ...form, framed_pool: event.target.value})} >
              <option value="">Semua</option>
              {framedPools.data.map((framed_pool, index) => (
              <option key={index} value={framed_pool.name}>{framed_pool.name}</option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" type="button" onClick={() => handleClose()}>Close</button>
          <button className="btn btn-primary" type="submit" disabled={loading}><i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}