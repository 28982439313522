import { useState, useEffect } from "react";
import { getPresetActivityCodes, getPresetActivityTypes } from "../../api/customer";

export function useActivityType() {
  const [types, setTypes] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getActivityTypePreset = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getPresetActivityTypes();
      setTypes(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getActivityTypePreset();
  }, []);

  return {
    types,
    loading,
    errorMsg,
  };
}

export function useActivityCode() {
  const [codes, setCodes] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getActivityTypePreset = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getPresetActivityCodes();
      setCodes(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getActivityTypePreset();
  }, []);

  return {
    codes,
    loading,
    errorMsg,
  };
}
