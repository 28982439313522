import { useState } from "react";
import moment from "moment";
import Box from "../components/Box";
import AlertBox from "../components/AlertBox";
import MainLayout from "../components/layouts/MainLayout";
import Pagination from "../components/Pagination";
import { convertToRupiah } from "../tools/numerictools";
import { BUILDING_STATUS } from "../tools/userdatatools";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import { useInternetPackageListHooks } from "../utils/InternetPackageUtils";
import { useCustomersPartnerInfraHooks } from "../utils/project/PartnerUtils";
import ModalDownloadCustomerPartner from "../components/customer/ModalDownloadCustomerPartner";

export default function CustomerPartnerPage() {
  const { hasAccess } = useControlPermission();

  const [formFilter, setFormFilter] = useState({
    customer_name: "",
    n: "",
    internet_package: "",
    installation_start_date: "",
    installation_end_date: "",
    is_active: "",
    building_ownership_status: "",
  });
  const [filter, setFilter] = useState({
    customer_name: "",
    n: "",
    internet_package: "",
    installation_start_date: "",
    installation_end_date: "",
    is_active: "",
    building_ownership_status: "",
    page: 1,
    page_size: 20,
  });

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [modal, setModal] = useState(null);
  const { customers, errorMsg, meta, loading, getAllCustomers } = useCustomersPartnerInfraHooks({
    filter,
  });
  const internetPackage = useInternetPackageListHooks();

  function applyFilter() {
    let form_filter = {
      ...filter,
      ...formFilter,
      page: 1,
    };
    setFilter(form_filter);
  }

  const resetFilter = () => {
    setFormFilter({
      customer_name: "",
      n: "",
      internet_package: "",
      is_active: "",
      installation_start_date: "",
      installation_end_date: "",
      building_ownership_status: "",
    });
    setFilter({
      customer_name: "",
      n: "",
      internet_package: "",
      is_active: "",
      installation_start_date: "",
      installation_end_date: "",
      building_ownership_status: "",
      page: 1,
      page_size: 10,
    });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const onSuccess = (message) => {
    getAllCustomers(filter);
    setAlert({ show: true, message: message, type: "success" });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="partner-infrastructure">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Pelanggan</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-partner-infrastructure-customer") && (
              <button className="btn btn-primary" onClick={() => setModal("download")}>
                <i className="fas fa-download"></i> Unduh Data Pelanggan
              </button>
            )}
          </div>
        </div>

        <Box title={`Daftar Pelanggan (Total Data: ${meta.count})`} loading={loading}>
          <div className="mb-4">
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="customer_number">Pencarian Nomor</label>
                  <input
                    type="text"
                    name="customer_number"
                    value={formFilter.n}
                    placeholder="No. HP/No. Reg/No. Pelanggan"
                    onChange={(event) => changeFilter("n", event.target.value)}
                    className="form-control"
                    id="customer_number"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="customer_name">Nama Pelanggan</label>
                  <input
                    type="text"
                    name="customer_name"
                    value={formFilter.customer_name}
                    onChange={(event) => changeFilter("customer_name", event.target.value)}
                    className="form-control"
                    id="customer_name"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="installation_start">Tanggal Instalasi Mulai</label>
                  <input
                    type="date"
                    name="installation_start"
                    value={formFilter.installation_start_date}
                    onChange={(event) =>
                      changeFilter("installation_start_date", event.target.value)
                    }
                    className="form-control"
                    id="installation_start"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="installation_end">Tanggal Instalasi Akhir</label>
                  <input
                    type="date"
                    name="installation_end"
                    value={formFilter.installation_end_date}
                    onChange={(event) => changeFilter("installation_end_date", event.target.value)}
                    className="form-control"
                    id="installation_end"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="package_name">Paket</label>
                  <select
                    className="form-control input"
                    value={formFilter.internet_package}
                    onChange={(event) => changeFilter("internet_package", event.target.value)}
                  >
                    <option value="" disabled>
                      {" "}
                      -- Pilih Paket --
                    </option>
                    {internetPackage.data.map((pack, index) => (
                      <option key={index} value={pack.id}>
                        {pack.name} - ({pack.internet_package_varian.name}) - Rp{" "}
                        {convertToRupiah(pack.price)} - Rp {convertToRupiah(pack.installation_cost)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="statusFilter">Status</label>
                  <select
                    className="form-control"
                    id="statusFilter"
                    value={formFilter.is_active}
                    onChange={(event) => changeFilter("is_active", event.target.value)}
                  >
                    <option value="">SEMUA</option>
                    <option value="0">Isolir</option>
                    <option value="1">Aktif</option>
                    <option value="2">Dismantled</option>
                    <option value="3">Aktif Trial</option>
                    <option value="4">Dismantle Trial</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="buildingStatusFilter">Status Bangunan</label>
                  <select
                    className="form-control"
                    id="buildingStatusFilter"
                    value={formFilter.building_ownership_status}
                    onChange={(event) =>
                      changeFilter("building_ownership_status", event.target.value)
                    }
                  >
                    <option value="">SEMUA</option>
                    {Object.keys(BUILDING_STATUS).map((objKey, index) => (
                      <option value={objKey} key={index}>
                        {BUILDING_STATUS[objKey]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-primary"
                  onClick={() => applyFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => resetFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />

          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  {/* <th>No. Pelanggan</th> */}
                  <th>No. Registrasi</th>
                  <th>Tanggal Terpasang</th>
                  <th>Jatuh Tempo</th>
                  <th>Nama</th>
                  <th>Paket</th>
                  <th>Durasi</th>
                  <th>Biaya</th>
                  <th>Deposit</th>
                  <th>Kepemilikan Lokasi</th>
                  <th>Grup/Project</th>
                </tr>
              </thead>
              <tbody>
                {customers.length === 0 && (
                  <tr>
                    <td colSpan={15} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {customers.map((customer, index) => (
                  <tr key={index}>
                    {/* <td>{customer.customer_number}</td> */}
                    <td className="text-nowrap">{customer.registration.registration_number}</td>
                    <td className="text-nowrap">
                      {moment.unix(customer.createdAt).format("DD MMMM YYYY - HH:mm")}
                    </td>
                    <td className="text-nowrap">
                      {moment(customer.plan.end_date).format("DD MMMM YYYY - HH:mm")}
                    </td>
                    <td className="text-nowrap">{customer.name}</td>
                    <td className="text-nowrap">{customer.internet_package.name}</td>
                    <td className="text-nowrap">
                      {customer.internet_package.internet_package_varian.validity_period} hari
                    </td>
                    <td className="text-nowrap">
                      Rp. {convertToRupiah(customer.internet_package.price)}
                    </td>
                    <td className="text-nowrap">
                      Rp. {convertToRupiah(customer.deposit_amount || 0)}
                    </td>
                    <td className="text-nowrap">
                      {BUILDING_STATUS[customer.building_ownership_status]}
                    </td>
                    <td className="text-nowrap">{customer.project?.name || "-"}</td>
                    <td>
                      <CustomerActiveBadge status={customer.is_active} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {meta.count > meta.page_size && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.page_size)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>
      <ModalDownloadCustomerPartner
        show={modal === "download"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function CustomerActiveBadge({ status }) {
  switch (status) {
    case 0:
      return <span className="badge badge-warning">Isolir</span>;
    case 1:
      return <span className="badge badge-success">Aktif</span>;
    case 2:
      return <span className="badge badge-danger">Dismantled</span>;
    case 3:
      return <span className="badge badge-success">Aktif Trial</span>;
    case 4:
      return <span className="badge badge-danger">Dismantle Trial</span>;
    default:
      return <></>;
  }
}
