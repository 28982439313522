import { useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../../AlertBox";
import { RequiredAsterisk } from "../../FormHelper";
import { createOltProfile } from "../../../api/infrastructure";

export default function ModalCreateOltProfile({ show, onClose, onSuccess }) {
  let [form, setForm] = useState({
    name: "",
    upstream: "",
    downstream: "",
  });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({ name: "", upstream: "", downstream: "" });
    setAlert({ show: false, message: "", type: "" });
    onClose();
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
    };
    sendCreateOltProfile(formData);
  };

  const sendCreateOltProfile = async (form) => {
    setLoading(true);
    try {
      let response = await createOltProfile(form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Profil OLT Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Nama OLT Profile <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Upstream <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.upstream}
              onChange={(event) => setForm({ ...form, upstream: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Downstream <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.downstream}
              onChange={(event) => setForm({ ...form, downstream: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" disabled={loading} className="btn btn-primary">
            <i className="fas fa-plus"></i>{" "}
            {loading ? "Membuat Profil OLT ..." : "Buat Profil OLT Baru"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
