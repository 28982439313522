import { apiAuth } from "./base";

export function getAllPackages(params) {
  let api = apiAuth();
  return api.get(`v1/packages`, { params });
}

export function createPackage(data) {
  let api = apiAuth();
  return api.post(`v1/packages`, data);
}

export function getPakcageById(id) {
  let api = apiAuth();
  return api.get(`v1/packages/${id}`)
}

export function deletePakcageById(id) {
  let api = apiAuth();
  return api.delete(`v1/packages/${id}`)
}

export function updatePakcageById(id, data) {
  let api = apiAuth();
  return api.put(`v1/packages/${id}`, data)
}

export function getAllTypes(params) {
  let api = apiAuth();
  return api.get(`v1/packages/types`, { params });
}

export function createType(data) {
  let api = apiAuth();
  return api.post(`v1/packages/types`, data);
}

export function getTypeById(id) {
  let api = apiAuth();
  return api.get(`v1/packages/types/${id}`)
}

export function deleteTypeById(id) {
  let api = apiAuth();
  return api.delete(`v1/packages/types/${id}`)
}

export function updateTypeById(id, data) {
  let api = apiAuth();
  return api.put(`v1/packages/types/${id}`, data)
}

export function getAllVariants(params) {
  let api = apiAuth();
  return api.get(`v1/packages/varians`, { params });
}

export function createVariant(data) {
  let api = apiAuth();
  return api.post(`v1/packages/varians`, data);
}

export function getVariantById(id) {
  let api = apiAuth();
  return api.get(`v1/packages/varians/${id}`)
}

export function deleteVariantById(id) {
  let api = apiAuth();
  return api.delete(`v1/packages/varians/${id}`)
}

export function updateVariantById(id, data) {
  let api = apiAuth();
  return api.put(`v1/packages/varians/${id}`, data)
}

export function getListPackage(params) {
  let api = apiAuth();
  return api.get("v1/presets/internet-packages", {params})
}

export function getListFilteredPackage() {
  let api = apiAuth();
  return api.get("v1/packages/show-all")
}

export function updateGroupPackage(data) {
  let api = apiAuth();
  return api.post(`v1/packages/${data.package_id}/projects/${data.project_id}`)
}

export function deleteGroupPackage(id) {
  let api = apiAuth();
  return api.delete(`v1/packages/projects/${id}`)
}