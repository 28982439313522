import { apiAuth } from "./base";

export function getUnreadNotif() {
  let api = apiAuth();
  return api.get(`v1/notifications/unreadcount`);
}


export function getAllNotif() {
  let api = apiAuth();
  return api.get(`v1/notifications`);
}

export function readAllNotif() {
  let api = apiAuth();
  return api.put(`v1/notifications/read`);
}