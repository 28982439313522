import { useState, useEffect } from "react";
import {
  getAllPermissions,
  getAllResources,
  getAllRoles,
  getPermissionById,
  getPermissionByRoleId,
} from "../api/roleaccess";

export function usePermissionsListHooks({ filter }) {
  const [permissions, setPermissions] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 100 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getPermissions = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllPermissions({ ...filter });
      setPermissions(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getPermissions(filter);
  }, [filter]);

  return {
    permissions,
    meta,
    loading,
    errorMsg,
    getPermissions,
  };
}

export function usePermissionsByRoleHooks({ id }) {
  const [permissions, setPermissions] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 100 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(true);

  const getPermissionsByRole = async (id) => {
    setFetched(false);
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getPermissionByRoleId(id);
      setPermissions(data.data);
      setMeta(data.pagination);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getPermissionsByRole(id);
    }
  }, [id]);

  return {
    permissions,
    meta,
    loading,
    fetched,
    errorMsg,
    getPermissionsByRole,
  };
}

export function usePermissionHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getPermission(id);
    }
  }, [id]);

  const getPermission = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getPermissionById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    getPermission,
  };
}

export function useRolesListHooks() {
  const [roles, setRoles] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getRoles = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllRoles({ ...filter });
      setRoles(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  return {
    roles,
    loading,
    errorMsg,
    getRoles,
  };
}

export function useTechnicianRolesListHooks() {
  const [roles, setRoles] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getRoles = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllRoles({ scope: "technician" });
      setRoles(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  return {
    roles,
    loading,
    errorMsg,
    getRoles,
  };
}

export function useAllRolesListHooks() {
  const [roles, setRoles] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getRoles = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllRoles({ scope: "all" });
      setRoles(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  return {
    roles,
    loading,
    errorMsg,
    getRoles,
  };
}

export function useResourcesListHooks() {
  const [resources, setResources] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getResources = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllResources({ ...filter });
      setResources(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getResources();
  }, []);

  return {
    resources,
    loading,
    errorMsg,
    getResources,
  };
}

export function useRbacRolesListHooks() {
  const [roles, setRoles] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getRoles = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllRoles({ scope: "rbac" });
      setRoles(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  return {
    roles,
    loading,
    errorMsg,
    getRoles,
  };
}
