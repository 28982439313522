import { useState } from "react";
import Toggle from "react-toggle";
import { addRoleOnPermission, deleteRoleOnPermission } from "../../api/roleaccess";

export function TogglePermissionRole({ value, permission_id, role_id, onSuccess, disabled }) {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const submitPermission = async () => {
    setErrorMsg("");
    setLoading(true);
    try {
      let { data } = await addRoleOnPermission(permission_id, role_id);
      onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setErrorMsg(message);
    }
    setLoading(false);
  };

  const removePermission = async () => {
    setErrorMsg("");
    setLoading(true);
    try {
      let { data } = await deleteRoleOnPermission(permission_id, role_id);
      onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setErrorMsg(message);
    }
    setLoading(false);
  };

  const togglePermission = (event) => {
    if (event.target.checked) {
      submitPermission();
    } else {
      removePermission();
    }
  };

  if (!role_id) {
    return "---";
  }

  return (
    <>
      <Toggle
        checked={value}
        onChange={(event) => togglePermission(event)}
        disabled={loading || disabled}
      />
      {errorMsg && (
        <span className="text-sm text-danger d-block">
          <i className="fa fa-triangle-exclamation"></i> Error goes here
        </span>
      )}
    </>
  );
}
