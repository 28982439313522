import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { getRegistratioById, updateScheduleTechnician } from "../../api/user";
import Timepicker from "../Timepicker";
import ListTechnician from "./ListTechnician";

export default function ModalAssignTechnician(props) {
  let [data, setData] = useState({});
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState("");

  let [form, setForm] = useState({ date: moment().format("YYYY-MM-DD"), time: "00:00" });
  let [assigned, setAssigned] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await getRegistratioById({ id: props.id });
        setData(response.data.data);
        let date = moment().format("YYYY-MM-DD");
        let time = "00:00";

        if (response.data.data.registration_schedules.length > 0) {
          let installation_schedule = response.data.data.registration_schedules;

          let schedule = getInstallationSchedule(installation_schedule);
          date = schedule.date.format("YYYY-MM-DD");
          time = schedule.date.format("HH:mm");

          if (schedule.technicians.length > 0) {
            let schedulerTechnician = [];

            schedule.technicians.forEach((techie) => {
              schedulerTechnician.push({ id: techie.id, ...techie });
            });

            setAssigned(schedulerTechnician);
          }
        }

        setForm({ date: date, time: time });
      } catch (error) {
        console.log(error);
        if (error.response) {
          setError(error.response.data.message);
        } else {
          setError("Terjadi kesalahan");
        }
      }
    };

    if (props.id && props.show) {
      setError("");
      getData();
    }
  }, [props.id, props.show]);

  const handleClose = () => {
    setData({});
    setAssigned([]);
    setError("");
    setForm({ date: moment().format("YYYY-MM-DD"), time: "00:00" });
    props.setShow("");
  };

  const submitData = () => {
    let tempDate = form.date || moment().format("YYYY-MM-DD");
    let form_data = {
      schedule: tempDate + " " + form.time,
      type: "INSTALLATION",
    };

    let technicians = [];
    if (assigned.length > 0) {
      assigned.map((technician) => technicians.push({ id: technician.id }));
    }

    form_data.technicians = technicians;

    sendData(form_data);
  };

  const sendData = async (form_data) => {
    setLoading(true);
    try {
      let { data } = await updateScheduleTechnician(props.id, form_data);
      props.onSuccess(data.message);
      handleClose();
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }
    setLoading(false);
  };

  const getInstallationSchedule = (schedule = []) => {
    let data = {
      date: "",
      technicians: [],
    };
    let search = schedule.find((schedule) => schedule.schedule.type === "INSTALLATION");

    if (search) {
      data.date = search.schedule.schedule
        ? moment(search.schedule.schedule)
        : moment().startOf("day");
      data.technicians = search.technicians;
    }

    return data;
  };

  const changeTime = (time) => {
    setForm({ ...form, time: time });
  };

  const removeAssigned = (index) => {
    let arrAssigned = [...assigned];
    arrAssigned.splice(index, 1);
    setAssigned(arrAssigned);
  };

  return (
    <Modal show={props.show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Penugasan Teknisi</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && (
          <div className={`alert alert-danger alert-dismissible fade show`} role="alert">
            {error}
            <button type="button" className="close" onClick={() => setError("")} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}

        <div className="row">
          <div className="col-sm-6">
            <ListTechnician assigned={assigned} add={setAssigned} max={1} />
          </div>
          <div className="col-sm-6">
            <h5>Data Pelanggan:</h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Nama</td>
                  <td>{data.name}</td>
                </tr>
                <tr>
                  <td>Nomor Registrasi</td>
                  <td>{data.registration_number}</td>
                </tr>
                <tr>
                  <td>Paket</td>
                  <td>{data.internet_package ? data.internet_package.name : ""}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{data.status ? data.status.display_name : "-"}</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h5>Jadwal Pemasangan:</h5>
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="status">Tanggal</label>
                  <input
                    type="date"
                    className="form-control"
                    value={form.date}
                    onChange={(event) => setForm({ ...form, date: event.target.value })}
                  />
                </div>
                <div className="col-5">
                  <label htmlFor="status">Jam</label>
                  <Timepicker time={form.time} onChangeTime={changeTime} />
                </div>
              </div>
            </div>

            <hr />

            <h5>
              Teknisi yang bertugas{" "}
              <span className="text-sm" style={{ fontSize: "10pt" }}>
                (Teknisi wajib 1 orang)
              </span>
            </h5>
            <table className="table table-striped tabel-hover table-bordered">
              <thead>
                <tr>
                  <th>Nama</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {assigned.length === 0 ? (
                  <tr>
                    <td colSpan="2" className="text-center">
                      {" "}
                      Belum ada teknisi yang ditugaskan
                    </td>
                  </tr>
                ) : (
                  assigned.map((technician, index) => (
                    <tr key={index}>
                      <td>{technician.name}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => removeAssigned(index)}
                        >
                          <i className="fa fa-user-times"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          className="btn btn-primary"
          disabled={assigned.length < 1 || loading}
          onClick={() => submitData()}
        >
          {loading ? "Menunggu ..." : "Simpan"}
        </button>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
