import { useState } from "react";
import { Modal } from "react-bootstrap";
import { activateCustomerModem } from "../../api/customer";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import AlertBox from "../AlertBox";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../Loading";

export default function ModalActivationInternet({ show, id, onClose, onSuccess }) {
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const { data, loading: dataLoading } = useGetCustomerHooks({ id });

  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  const activateModem = async () => {
    setLoading(true);
    try {
      let response = await activateCustomerModem(id);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>
          Aktivasi Internet - {data.registration?.registration_number} - {data.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoadingInsideBoxWrapper>
          {dataLoading && <LoadingInsideBox />}
          <div className="alert alert-warning">
            <b>Perhatian</b>
            <ul className="pl-3">
              <li>Pastikan data pelanggan sudah benar</li>
              <li>
                Pastikan pelanggan sudah menginstal aplikasi "My Viberlink" pada perangkat
                smartphone-nya!
              </li>
            </ul>
          </div>
          <AlertBox {...alert} setAlert={setAlert} />
          <p>
            Apakah anda yakin untuk mengaktifkan internet pelanggan{" "}
            {data.registration?.registration_number} - {data.name}?
          </p>
          <div className="d-flex justify-content-start mb-4">
            <button type="button" onClick={() => handleClose()} className="btn btn-secondary mr-1">
              Tidak
            </button>
            <button
              type="button"
              onClick={() => activateModem()}
              disabled={loading}
              className="btn btn-success mr-1"
            >
              {loading ? "Mengaktifkan Internet Pelanggan ..." : "Ya, Saya Yakin"}
            </button>
          </div>
        </LoadingInsideBoxWrapper>
      </Modal.Body>
    </Modal>
  );
}
