import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import ModalProjectVendorCreate from "../../components/project/ModalProjectVendorCreate";
import ModalProjectVendorDelete from "../../components/project/ModalProjectVendorDelete";
import ModalProjectVendorUpdate from "../../components/project/ModalProjectVendorUpdate";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useProjectHooks } from "../../utils/ProjectsUtilHooks";
import { useVendorByProjectHooks } from "../../utils/project/VendorProjectUtils";

export default function ProjectVendorListPage() {
  let { hasAccess } = useControlPermission();
  const { id } = useParams();
  // const [formFilter, setFormFilter] = useState({pic_name: '', address_id: '', name: ''});
  const [filter, setFilter] = useState({
    pic_name: "",
    address_id: "",
    name: "",
    page: 1,
    page_size: 10,
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [selectedId, setSelectedId] = useState(null);

  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  let project = useProjectHooks({ id });
  const vendorList = useVendorByProjectHooks({ id });

  const toggleModalUpdate = (id = null) => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleModalDelete = (id = null) => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  useEffect(() => {
    vendorList.fetchVendors(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
    setFilter({ ...filter });
  };

  return (
    <MainLayout resource_key="manage-project">
      <div className="container-fluid">
        <BackToProjectLink />
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Pekerjaan Proyek - "{project.data.name}"</h1>
            </div>
            <div className="col-auto">
              {hasAccess("create-project-vendor") && (
                <button className="btn btn-primary" onClick={() => setShowModalCreate(true)}>
                  <i className="fa fa-plus"></i> Tambah Vendor
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Daftar Vendor" loading={vendorList.loading}>
          <AlertBox {...alert} setAlert={setAlert} />
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Nama Vendor</th>
                <th>Alamat Vendor</th>
                <th>Deskripsi</th>
                <th>PIC</th>
                <th>Tanggal PO </th>
                <th>Progress</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {vendorList.vendors.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    Tidak ada Data
                  </td>
                </tr>
              )}
              {vendorList.vendors.map((vendor, index) => (
                <tr key={index}>
                  <td>{vendor.vendor.name}</td>
                  <td>{vendor.vendor.address}</td>
                  <td>{vendor.description}</td>
                  <td>{vendor.pic}</td>
                  <td>{moment.unix(vendor.createdAt).format("DD MMM YYYY")}</td>
                  <td>{vendor.percentage}</td>
                  <td>
                    {hasAccess("view-project-vendor-task") && (
                      <Link
                        to={`/project-vendor/${vendor.id}/report`}
                        className="btn btn-sm btn-primary btn-block"
                      >
                        <i className="fa fa-file-lines"></i> Report
                      </Link>
                    )}
                    {hasAccess("update-project-vendor") && (
                      <button
                        className="btn btn-sm btn-info btn-block"
                        onClick={() => toggleModalUpdate(vendor.id)}
                      >
                        <i className="fa fa-pencil-alt"></i> Edit
                      </button>
                    )}
                    {hasAccess("delete-project-vendor") && (
                      <button
                        className="btn btn-sm btn-danger btn-block"
                        onClick={() => toggleModalDelete(vendor.id)}
                      >
                        <i className="fa fa-trash"></i> Hapus
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </div>

      <ModalProjectVendorCreate
        show={showModalCreate}
        onClose={() => setShowModalCreate(!showModalCreate)}
        onSuccess={onSuccess}
      />
      <ModalProjectVendorUpdate
        show={showModalUpdate}
        id={showModalUpdate ? selectedId : null}
        onClose={() => toggleModalUpdate()}
        onSuccess={onSuccess}
      />
      <ModalProjectVendorDelete
        show={showModalDelete}
        id={showModalDelete ? selectedId : null}
        onClose={() => toggleModalDelete()}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function BackToProjectLink() {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={`/project`}>
            <i className="fa-regular fa-circle-left"></i> Kembali ke Halaman Project
          </Link>
        </li>
      </ol>
    </nav>
  );
}
