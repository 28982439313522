export default function CompleteTimepicker(props) {
  let hours = [];
  for (let hour = 0; hour < 24; hour++) {
    if (hour < 10) {
      hours.push("0" + hour);
    } else {
      hours.push(hour);
    }
  }

  let minutes = [];
  for (let minute = 0; minute < 60; minute++) {
    if (minute < 10) {
      minutes.push("0" + minute);
    } else {
      minutes.push(minute);
    }
  }

  let seconds = [];
  for (let second = 0; second < 60; second++) {
    if (second < 10) {
      seconds.push("0" + second);
    } else {
      seconds.push(second);
    }
  }

  let timeHour = props.time.split(":")[0];
  let timeMinute = props.time.split(":")[1];
  let timeSecond = props.time.split(":")[2];

  const onChangeHour = (value) => {
    props.onChangeTime(value + ":" + timeMinute + ":" + timeSecond);
  };

  const onChangeMinute = (value) => {
    props.onChangeTime(timeHour + ":" + value + ":" + timeSecond);
  };

  const onChangeSecond = (value) => {
    props.onChangeTime(timeHour + ":" + timeMinute + ":" + value);
  };

  return (
    <div className="row">
      <div className="col">
        <select
          className="form-control"
          value={timeHour}
          onChange={(event) => onChangeHour(event.target.value)}
        >
          {hours.map((hour) => (
            <option value={hour} key={hour}>
              {hour}
            </option>
          ))}
        </select>
      </div>
      <div>:</div>
      <div className="col">
        <select
          className="form-control"
          value={timeMinute}
          onChange={(event) => onChangeMinute(event.target.value)}
        >
          {minutes.map((minute) => (
            <option value={minute} key={minute}>
              {minute}
            </option>
          ))}
        </select>
      </div>
      <div>:</div>
      <div className="col">
        <select
          className="form-control"
          value={timeSecond}
          onChange={(event) => onChangeSecond(event.target.value)}
        >
          {seconds.map((second) => (
            <option value={second} key={second}>
              {second}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
