import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as api from '../../api/account';

export default function ModalEditPassword(props) {
  const [form, setForm] = useState({password: ''});
  const [alert, setAlert] = useState({show: false, message: '', type: ''});


  const submitData = async (event) => {
    event.preventDefault();
    try {
      let { data } = await api.updatePassword(props.account.id, {...form})
      handleClose()
      props.onSuccess(data.message)
    } catch (error) {
      let message = '';
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message  = "Something Error Happened"
      }
      setAlert({show: true, message: message, type: 'danger'});
    }

  }

  const handleClose = () => {
    setForm({password: ''});
    setAlert({show: false, message: '', type: ''});
    props.setShow()
  }

  return(
    <Modal show={props.show} onHide={() => handleClose()} >
    <Modal.Header closeButton>
      <Modal.Title>Ubah Password Akun</Modal.Title>
    </Modal.Header>
    <form onSubmit={event => submitData(event)}>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <p>
              <h5>Detail Akun:</h5>
              <b>Nama:</b> {props.account.name}<br />
              <b>Email:</b> {props.account.email}
            </p>
            {alert.show &&
              <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                {alert.message}
                <button type="button" className="close"  onClick={() => setAlert({show: false, message: '', type: ''})} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            }
            <div className="form-group">
              <label htmlFor="password_new">Password Baru:</label>
              <input type="password" name="password_new" id="password_new" value={form.password} onChange={event => setForm({ ...form, password: event.target.value})} className="form-control" />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" type="submit">Ubah Password</button>
        <button className="btn btn-secondary" type="button" onClick={() => handleClose()}>Close</button>
      </Modal.Footer>
    </form>
  </Modal>
  )
}