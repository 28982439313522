import BoxTypePack from "../components/internetpacks/BoxTypePacks";
import BoxVariantPack from "../components/internetpacks/BoxVariantPack";
import MainLayout from "../components/layouts/MainLayout";

export default function InternetPackVariantPage() {
  return(
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Tipe dan Varian Paket</h1>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <BoxTypePack />
          </div>
          <div className="col-sm-6">
            <BoxVariantPack />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}