import React from 'react';
import { Link } from 'react-router-dom';
import image from '../assets/images/icon.png'
import MainLayout from '../components/layouts/MainLayout';
import { useAuth } from '../utils/AuthHooks';
import underconstruction from '../assets/images/under-construction.png';

function UnderConstrutionPage() {
  let auth = useAuth();

  if (auth.isAuthenticated) {
    return(
      <MainLayout>
        <div className="container-fluid">
          <div className="text-center">
            <img src={underconstruction} className='img-fluid' alt="under construction" style={{width: '100%', maxWidth: "350px"}}/>
            <p className="lead text-gray-800 mb-5">Halaman belum tersedia</p>
            <Link to="/" className="btn btn-primary">
              Kembali ke Dashboard
            </Link>
          </div>
        </div>
      </MainLayout>
    )
  }

  return (
    <div className="login-page-wrapper bg-gradient-primary" style={{minHeight: "100vh", padding: "100px 0"}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <div className="card o-hidden border-0 shadow-lg">
              <div className="card-body p-5">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center mb-5">
                      <img src={image} alt="Blue Viberlink Logo" className="mb-5" style={{maxWidth: "200px"}}/>
                      <h1 className="h1 text-gray-900 mb-2">Halaman Belum Tersedia</h1>
                      <h3 className="h5 text-gray-900 mb-4">Not Found</h3>
                      <Link to="/" className="btn btn-primary">
                        Kembali ke Dashboard
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnderConstrutionPage
