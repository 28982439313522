import { apiAuth, apiDownloadAuth } from "./base";

export function getAllTechnicians(params) {
  let api = apiAuth();
  return api.get(`v1/technicians`, { params });
}

export function registerTechnician(data) {
  let api = apiAuth();
  return api.post(`v1/technicians`, data);
}

export function getTechnicianById(id) {
  let api = apiAuth();
  return api.get(`v1/technicians/${id}`);
}

export function updateTechnicianById(id, data) {
  let api = apiAuth();
  return api.put(`v1/technicians/${id}`, data);
}

export function deleteTechnicianById(id) {
  let api = apiAuth();
  return api.delete(`v1/technicians/${id}`);
}

export function resetPasswordTechnician(id) {
  let api = apiAuth();
  return api.put(`v1/technicians/${id}/reset-password`);
}

export function getAllHistories(params) {
  let api = apiAuth();
  return api.get(`v1/technicians/installations`, { params });
}

export function downloadHistoryTechnician(params = {}) {
  let api =  apiDownloadAuth();
  return api.get("v1/technicians/installations/export/xlsx", {params});
}

export function showAllTechnician(params = {}) {
  let api = apiAuth();
  return api.get(`/v1/technicians/show-all`, { params });
}

export function updatePasswordTechnician(id, data) {
  let api = apiAuth();
  return api.put(`v1/technicians/${id}/reset-password`, data);
}

export function createProjectTechnician(data) {
  let api = apiAuth();
  return api.post(`v1/technicians/${data.technician_id}/projects/${data.project_id}`);
}


export function removeProjectTechnicianById(data) {
  let api = apiAuth();
  return api.delete(`v1/technicians/${data.technician_id}/projects/${data.project_id}`);
}
