import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateProjectInventoryById } from "../../api/projects";
import { useProjectInventoryHooks } from "../../utils/ProjectsUtilHooks";
import AlertBox from "../AlertBox";
import { RequiredAsterisk } from "../FormHelper";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../Loading";

export default function ModalProjectInventory({ show, id, onClose, onSuccess }) {
  let project = useProjectInventoryHooks({id});

  let [alert, setAlert] = useState({show: false, message: '', type: ''});
  let [ inventories, setInventories ] = useState([{name: '', type: '', amount: "0", unit: ''}]);


  useEffect(() => {
    if (project.fetched) {
      let inventoriesArr = [];
      project.data.forEach((inv) => {
        inventoriesArr.push({ 
          name: inv.name,
          type: inv.type,
          amount: inv.amount,
          unit: inv.unit
        });
      })

      setInventories([ ...inventoriesArr ]);
    }
  }, [project.data, project.fetched])

  const handleClose = () => {
    resetForm();
    onClose();
  }

  const resetForm = () => {
    setAlert({show: false, message: '', type: ''});
    setInventories([{name: '', type: '', amount: "0", unit: ''}]);
  }

  const addInventory = () => {
    let data = [ ...inventories ];
    data.push({name: '', type: '', amount: "0", unit: ''});
    setInventories(data)
  }

  const removeInventory = (index) => {
    let data = [ ...inventories ];

    data.splice(index, 1);
    setInventories(data);
  }

  const changeFormInventory = (index, name, value) => {
    let data = [ ...inventories ];
    data[index][name] = value;
    setInventories(data);
  }

  const sendDataProject = async (formData)  => {
    try {
      let response = await updateProjectInventoryById(id, formData);
      onSuccess(true, response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }
  }

  const onSubmitData = (event) => {
    event.preventDefault();
    let formData = { inventories }

    sendDataProject(formData)
  }

  return(
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <form onSubmit={event => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Ubah Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <LoadingInsideBoxWrapper>
            {project.loading && <LoadingInsideBox />}
            <div className="row">
              <div className="col-lg-12">
                <h5 className="mb-3">Inventory yang digunakan</h5>
                {inventories.length === 0 && 
                  <div className="card">
                    <div className="card-body">
                      Tidak ada inventory
                    </div>
                  </div>
                }
                {inventories.map((inventory, index) => (
                  <div className="row mb-2" key={index}>
                    <div className="col-4">
                      <label>Nama Barang <RequiredAsterisk /></label>
                      <input type="text" required className="form-control" value={inventory.name} onChange={event => changeFormInventory(index, "name", event.target.value)}/>
                    </div>
                    <div className="col">
                      <label>Tipe <RequiredAsterisk /></label>
                      <input type="text" required className="form-control" value={inventory.type} onChange={event => changeFormInventory(index, "type", event.target.value)}/>
                    </div>
                    <div className="col">
                      <label>Jumlah <RequiredAsterisk /></label>
                      <input type="number" required className="form-control" min="0" value={inventory.amount} onChange={event => changeFormInventory(index, "amount", event.target.value)}/>
                    </div>
                    <div className="col">
                      <label>Unit <RequiredAsterisk /></label>
                      <input type="text" required className="form-control" value={inventory.unit} onChange={event => changeFormInventory(index, "unit", event.target.value)}/>
                    </div>
                    <div className="col-auto d-flex align-items-end">
                      <button className="btn btn-sm btn-secondary btn-circle" type="button" onClick={() => removeInventory(index)}><i className="fa fa-minus"></i></button>
                    </div>
                  </div>
                ))}
                <button className="btn btn-sm btn-light mt-2" onClick={() => addInventory()} type="button"><i className="fa fa-plus"></i> Tambah barang</button>
              </div>
            </div>
          </LoadingInsideBoxWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">Close</button>
          <button className="btn btn-primary">Simpan Data</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}