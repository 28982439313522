import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { checkCoverageSchedule, getSchedulesById } from "../../api/schedules";
import AlertBox from "../AlertBox";

export function ModalCheckCoverageRelocate({show, id, setShow, onSuccess}) {
  let [form, setForm] = useState({coverage: "1", note: ''});
  let [alert, setAlert] = useState({show: false, message: '', type: ''});
  let [loading, setLoading] = useState(false);

  let [data, setData] = useState({});
  let [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setAlert({show: true, message: error, type: "danger"});
    }
  }, [error])

  
  useEffect(() => {
    const getData = async () => {
      try {
        let { data } = await getSchedulesById(id);
        setData(data.data);
      } catch (error) {
        console.error(error)
        if (error.response) {
          setError(error.response.data.message)
        } else {
          setError('Terjadi kesalahan')
        }
      }
    }

    if (id && show) {
      setError('')
      getData()
    }
  }, [id, show])

  const handleClose = () => {
    setData({});
    setError('');
    setShow('')
  }


  const sendReport = async (form_data) => {
    setLoading(true);
    try {
      let { data } = await checkCoverageSchedule(id, form_data);
      onSuccess(data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened"
      }

      setAlert({show: true, message: message, type: 'danger'})
    }
    setLoading(false);
  }

  const onSubmitData = (event) => {
    event.preventDefault();
    sendReport(form)
  }

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <form onSubmit={event => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Pengecekan Coverage Relokasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox { ...alert } setAlert={setAlert}/>
          <div className="row">
            <div className="col-lg-6">
              <table className='table table-bordered'>
                <tr>
                  <td>No. Reg</td>
                  <td>{data.registration?.registration_number || "-"}</td>
                </tr>
                <tr>
                  <td>Nama Pelanggan</td>
                  <td>{data.registration?.name || "-"}</td>
                </tr>
                <tr>
                  <td>No. Telp</td>
                  <td>{data.registration?.phone_number || "-"}</td>
                </tr>
                <tr>
                  <td>Alamat Instalasi Baru</td>
                  <td>
                    {data.address 
                      ? data.address + " RT " + data.rt + "/RW " + data.rw   + ", " + data.addresses.kelurahan + ", " + data.addresses.kecamatan + ", " + data.addresses.kota_kab + ", " + data.addresses.province + ", " + data.addresses.postal_code
                      : "-"
                    }
                  </td>
                </tr>
                <tr>
                  <td>Catatan Relokasi</td>
                  <td>{data.description || "-"}</td>
                </tr>
                <tr>
                  <td>Status Relokasi</td>
                  <td>{data.status || "-"}</td>
                </tr>
                <tr>
                  <td>Alamat Instalasi Lama</td>
                  <td>
                    {data.relocate_history 
                      ? data.relocate_history.address + " RT " + data.relocate_history.rt + "/RW " + data.relocate_history.rw   + ", " + data.relocate_history.addresses.kelurahan + ", " + data.relocate_history.addresses.kecamatan + ", " + data.relocate_history.addresses.kota_kab + ", " + data.relocate_history.addresses.province + ", " + data.relocate_history.addresses.postal_code
                      : "-"
                    }
                  </td>
                </tr>
              </table>
            </div>

            {/* Form Report Coverage */}
            <div className="col-lg-6">
              <h5>Hasil Pengecekan</h5>
              <div className="form-group">
                <label>Termasuk dalam Jangkauan</label>
                <select className="form-control" value={form.coverage} onChange={event => setForm({ ...form, coverage: event.target.value })}>
                  <option value="1">Iya</option>
                  <option value="0">Tidak</option>
                </select>
              </div>
              <div className="form-group">
                <label>Catatan</label>
                <textarea className="form-control" required={form.coverage === "0"} value={form.note} onChange={event => setForm({ ...form, note: event.target.value })}></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-gray" onClick={() => handleClose()}>Cancel</button>
          <button className="btn btn-primary" disabled={loading}>{loading ? 'Menunggu ...' : 'Simpan'}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )

}