import Box from "../../components/Box";
import Toggle from 'react-toggle'
import MainLayout from "../../components/layouts/MainLayout";
import { useProvincesCoverageHooks } from "../../utils/SettingUtils";
import { useState } from "react";
import { updateProvinceCoverage } from "../../api/setting";
import AlertBox from "../../components/AlertBox";

export default function CoverageSettingPage() {
  let [alert, setAlert] = useState({show: false, message: '', type: ''});

  let provinces = useProvincesCoverageHooks();

  const toggleCoverage = (event, id) => {
    let form = {};
    
    if (event.target.checked) {
      form.is_coverage = 1
    } else {
      form.is_coverage = 0
    }

    sendData(id, form)
  }

  const sendData = async (id, form) => {
    try {
      let response = await updateProvinceCoverage(id, form);
      setAlert({show: true, message: response.data.message, type: 'success'});
      provinces.getProvince();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({show: true, message, type: 'danger'})
    }
  }

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Pengaturan Coverage Provinsi</h1>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title="List Provinsi" loading={provinces.loading}>
              <AlertBox { ...alert } setAlert={setAlert} />
              <table className="table table-hover table-stripped table-bordered">
                <thead>
                  <tr>
                    <th>Provinsi</th>
                    <th>Coverage</th>
                  </tr>
                </thead>
                <tbody>
                  {provinces.data.map((province, index) => (
                    <tr key={index}>
                      <td>{province.province}</td>
                      <td>
                        <Toggle
                          defaultChecked={province.is_coverage === 1}
                          onChange={(event) => toggleCoverage(event, province.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}