import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { transferBulkInventories, transferBulkInventoriesModem } from "../../api/inventory";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import ModalChooseTransferInventories from "../../components/inventory/transfer/ModalChooseTransferInventory";
import MainLayout from "../../components/layouts/MainLayout";
import { useWarehouseHooks, useWarehousesHooks } from "../../utils/InventoryHooks";
import ModalChooseTransferModem from "../../components/inventory/transfer/ModalChooseTransferModem";
import BackToWarehouseInventoryLink from "../../components/inventory/warehouse/BackToWarehouseLink";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

let filterWarehouses = { page: 1, page_size: 100 };

export default function WarehouseManualTransferPage() {
  let { id } = useParams();
  let { hasAccess } = useControlPermission();

  let warehouse = useWarehouseHooks({ id });
  let { warehouses } = useWarehousesHooks({ filter: filterWarehouses });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({ type: "inventory", destination_warehouse_id: "" });
  let [previewInventories, setPreviewInventories] = useState([]);

  let [modal, setModal] = useState(null);

  const onSelectInventory = (item) => {
    let items = [...previewInventories];
    items.push(item);
    setPreviewInventories([...items]);
    setModal(null);
  };

  const onChangeType = (value) => {
    setForm({ ...form, type: value });
    setPreviewInventories([]);
  };

  const onChangeQty = (value, index) => {
    let items = [...previewInventories];
    items[index].qty = value;
    setPreviewInventories(items);
  };

  const onDeleteItem = (index) => {
    let items = [...previewInventories];
    items.splice(index, 1);
    setPreviewInventories(items);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  const resetData = () => {
    setForm({ type: "inventory", destination_warehouse_id: "" });
    setPreviewInventories([]);
  };

  const submitBulk = () => {
    let formData = {
      inventories: [],
      warehouse_id: warehouse.data.id,
      destination_warehouse_id: form.destination_warehouse_id,
    };

    formData.inventories = previewInventories.map((inventory) => {
      let temp = {};
      if (form.type !== "modem") {
        temp = {
          sku: inventory.sku,
          stock: inventory.qty,
        };
      } else {
        temp = {
          sku: inventory.sku,
          brand: inventory.brand,
          type: inventory.type,
          serial_number_modem: inventory.serial_number_modem,
          serial_number_gpon: inventory.serial_number_gpon,
        };
      }
      return temp;
    });

    bulkAddInventories(formData);
  };

  const bulkAddInventories = async (formData) => {
    setModal("loading");

    try {
      let message = "";
      if (form.type === "inventory") {
        let { data } = await transferBulkInventories(formData);
        message = data.message;
      } else if (form.type === "modem") {
        let { data } = await transferBulkInventoriesModem(formData);
        message = data.message;
      }

      onSuccess(message);
      resetData();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setModal("null");
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <BackToWarehouseInventoryLink warehouse={warehouse.data} />
        <h1 className="h3 mb-4 text-gray-800">Manual Transfer Barang - {warehouse.data.name}</h1>
        <AlertBox {...alert} setAlert={setAlert} />

        <Box title="Form Transfer Barang">
          <div className="form-group">
            <label>Pilih Gudang Tujuan</label>
            <select
              className="form-control"
              value={form.destination_warehouse_id}
              onChange={(event) =>
                setForm({ ...form, destination_warehouse_id: event.target.value })
              }
            >
              <option value="">-- Pilih Gudang --</option>
              {warehouses.map((warehouse) => (
                <option value={warehouse.id} key={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Pilih Tipe Inventory</label>
            <select
              className="form-control"
              value={form.type}
              onChange={(event) => onChangeType(event.target.value)}
            >
              <option value="inventory">Inventory</option>
              <option value="modem">Modem</option>
            </select>
          </div>

          <div className="row mt-4">
            <div className="col">
              <h4>List Preview Transfer Inventory</h4>
            </div>
            <div className="col-auto">
              {form.type === "modem" ? (
                <button className="btn btn-primary btn-sm" onClick={() => setModal("choose-modem")}>
                  Daftar Modem
                </button>
              ) : (
                <button className="btn btn-primary btn-sm" onClick={() => setModal("inventories")}>
                  Daftar Inventory
                </button>
              )}
            </div>
          </div>

          {previewInventories.length === 0 && (
            <div className="alert alert-warning">
              Silahkan lakukan input inventory melalui <b>Daftar Inventory</b> terlebih dahulu
            </div>
          )}

          {form.type === "modem" ? (
            <ModemTable inventories={previewInventories} setInventories={setPreviewInventories} />
          ) : (
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Nama</th>
                  <th>Qty</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {previewInventories.length === 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      Belum ada data
                    </td>
                  </tr>
                )}
                {previewInventories.map((inventory, index) => (
                  <tr key={index}>
                    <td>{inventory.sku}</td>
                    <td>{inventory.name}</td>
                    <td>
                      <div className="row">
                        <div className="col">
                          <input
                            type="number"
                            className="form-control"
                            value={inventory.qty}
                            min={1}
                            onChange={(event) => onChangeQty(event.target.value, index)}
                          />
                        </div>
                        <div className="col-auto">{inventory.unit}</div>
                      </div>
                    </td>
                    <td>
                      <div className="btn btn-danger" onClick={() => onDeleteItem(index)}>
                        <i className="fa fa-times"></i> Hapus
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <div className="row justify-content-end">
            <div className="col-12 col-md-6 d-flex justify-content-end">
              {hasAccess("transfer-inventory") && (
                <button
                  className="btn btn-primary btn-lg"
                  onClick={() => setModal("confirm")}
                  disabled={previewInventories.length === 0}
                >
                  <i className="fa fa-paper-plane" /> Transfer Inventory
                </button>
              )}
            </div>
          </div>
        </Box>
      </div>

      <ModalChooseTransferInventories
        show={modal === "inventories"}
        onClose={() => setModal(null)}
        warehouse_id={id}
        onSelectInventory={onSelectInventory}
        modem={form.type === "modem"}
      />
      <ModalChooseTransferModem
        show={modal === "choose-modem"}
        onClose={() => setModal(null)}
        warehouse_id={id}
        onSelectInventory={onSelectInventory}
        modem={form.type === "modem"}
      />
      <ModalConfirm
        show={modal === "confirm"}
        onClose={() => setModal(null)}
        onBulkAdd={() => submitBulk()}
      />
      <LoadingModalUpload show={modal === "loading"} />
    </MainLayout>
  );
}

function ModalConfirm({ show, onClose, onBulkAdd }) {
  const handleClose = () => {
    onClose();
  };

  const upload = async () => {
    onBulkAdd();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => event.preventDefault()}>
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi Transfer Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Apakah anda yakin untuk melakukan transfer inventory ini? Anda tidak dapat melakukan
          tindakan kembali jika terjadi kesalahan. Pastikan data anda sudah benar!
          <div className="d-flex justify-content-center py-4">
            <button className="btn btn-lg btn-success mr-2" onClick={() => upload()}>
              Ya, Saya yakin
            </button>
            <button className="btn btn-lg btn-secondary" onClick={() => handleClose()}>
              Tidak
            </button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
}

function LoadingModalUpload({ show }) {
  return (
    <Modal show={show} onHide={() => {}}>
      <div className="alert alert-warning mb-0">
        <h4>
          <i className="fa fa-hourglass"></i> Harap menunggu.
        </h4>
        <span>Inventori sedang diproses oleh sistem ...</span>
      </div>
    </Modal>
  );
}

function ModemTable({ inventories, setInventories }) {
  const onDeleteItem = (index) => {
    let items = [...inventories];
    items.splice(index, 1);
    setInventories(items);
  };

  return (
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>SKU</th>
          <th>Merk</th>
          <th>Tipe</th>
          <th>SN Modem</th>
          <th>SN GPON</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {inventories.length === 0 && (
          <tr>
            <td colSpan={6} className="text-center">
              Belum ada data
            </td>
          </tr>
        )}
        {inventories.map((inventory, index) => (
          <tr key={index}>
            <td>{inventory.sku}</td>
            <td>{inventory.brand}</td>
            <td>{inventory.type}</td>
            <td>{inventory.serial_number_modem}</td>
            <td>{inventory.serial_number_gpon}</td>
            <td>
              <button
                className="btn btn-danger btn-sm text-nowrap"
                onClick={() => onDeleteItem(index)}
              >
                <i className="fa fa-times"></i> Hapus
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
