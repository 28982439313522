import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useInventoriesByWarehouseHooks } from "../../../utils/InventoryHooks";
import { LoadingInsideBox } from "../../Loading";
import Pagination from "../../Pagination";

export default function ModalChooseTransferInventories({
  show,
  onSelectInventory,
  warehouse_id,
  modem,
  onClose,
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Cari Inventory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableInventories
          modem={modem}
          warehouse_id={warehouse_id}
          onSelectInventory={onSelectInventory}
        />
      </Modal.Body>
    </Modal>
  );
}

function TableInventories({ modem, onSelectInventory, warehouse_id }) {
  let [formFilter, setFormFilter] = useState({ q: "" });
  let [filter, setFilter] = useState({
    q: "",
    page: 1,
    page_size: 5,
    id: warehouse_id,
    modem: modem,
  });

  let { inventories: data, loading, meta, error } = useInventoriesByWarehouseHooks({ filter });

  const onSubmitSearch = (event) => {
    event.preventDefault();
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <div className="loading-data-div">
      {error && <div className="alert alert-danger">{error}</div>}
      {loading && <LoadingInsideBox />}
      <form onSubmit={(event) => onSubmitSearch(event)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Pencarian</label>
              <input
                type="text"
                placeholder="SKU / nama ..."
                className="form-control"
                value={formFilter.q}
                onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="form-group" style={{ paddingTop: "30px" }}>
              <button className="btn btn-primary">Cari</button>
            </div>
          </div>
        </div>
      </form>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Nama</th>
            <th>Unit</th>
            <th>Kategori</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr>
              <td colSpan={5} className="text-center">
                Tidak ada data
              </td>
            </tr>
          )}
          {data.map((inventory) => (
            <tr key={inventory.id}>
              <td>{inventory.sku}</td>
              <td>{inventory.name}</td>
              <td>{inventory.unit}</td>
              <td>{inventory.inventory_category?.name}</td>
              <td>
                {inventory.inventory_category.name !== "Modem" && (
                  <button
                    className="btn btn-primary"
                    onClick={() => onSelectInventory({ ...inventory, qty: 0 })}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {meta.count > meta.page_size && (
        <Pagination
          totalpage={Math.ceil(meta.count / meta.page_size)}
          selectedpage={meta.page}
          clickpage={(page) => gotoPage(page)}
          next={() => gotoPage(meta.page + 1)}
          prev={() => gotoPage(meta.page - 1)}
        />
      )}
    </div>
  );
}
