import { useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { RequiredAsterisk } from "../FormHelper";
import AlertBox from "../AlertBox";
import CompleteTimepicker from "../CompleteTimePicker";
import { updateIncidentById } from "../../api/infrastructure";
import { useIncidentHook } from "../../utils/infrastructure/ProjectPerformanceUtil";
import { useEffect } from "react";

export default function ModalUpdateIncident({ show, report_id, id, onClose, onSuccess }) {
  const { data, loading: dataLoading, fetched, error } = useIncidentHook({ id, report_id });
  let [form, setForm] = useState({
    description: "",
    up_date: moment().format("YYYY-MM-DD"),
    up_time: "00:00:00",
    down_date: moment().format("YYYY-MM-DD"),
    down_time: "00:00:00",
    is_done: false,
  });

  useEffect(() => {
    if (fetched) {
      let tempData = {
        description: data.description,
        down_date: moment.unix(data.down).format("YYYY-MM-DD"),
        down_time: moment.unix(data.down).format("HH:mm:ss"),
        up_date: moment().format("YYYY-MM-DD"),
        up_time: moment().format("HH:mm:00"),
        is_done: false,
      };

      if (data.up) {
        tempData.up_date = moment.unix(data.up).format("YYYY-MM-DD");
        tempData.up_time = moment.unix(data.up).format("HH:mm:ss");
        tempData.is_done = true;
      }
      setForm({ ...tempData });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetched]);

  useEffect(() => {
    if (error) {
      setAlert({ show: true, message: error, type: "danger" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({
      description: "",
      up_date: moment().format("YYYY-MM-DD"),
      up_time: "00:00:00",
      down_date: moment().format("YYYY-MM-DD"),
      down_time: "00:00:00",
    });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      down: form.down_date + " " + form.down_time,
      description: form.description,
    };

    if (form.is_done) {
      formData.up = form.up_date + " " + form.up_time;
    }

    updateProject(formData);
  };

  const updateProject = async (form) => {
    setLoading(true);
    try {
      let response = await updateIncidentById(id, report_id, { ...form });
      onSuccess(true, response.data.message, response.data.data);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const changeTime = (type, time) => {
    setForm({ ...form, [type]: time });
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Laporan Uptime Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />

          <div className="form-group">
            <label>
              Start Down
              <RequiredAsterisk />
            </label>
            <div className="row">
              <div className="col">
                <label>Tanggal</label>
                <input
                  type="date"
                  className="form-control"
                  value={form.down_date}
                  onChange={(event) => setForm({ ...form, down_date: event.target.value })}
                  required
                />
              </div>
              <div className="col-auto">
                <label>Waktu</label>
                <CompleteTimepicker
                  time={form.down_time}
                  onChangeTime={(time) => changeTime("down_time", time)}
                />
              </div>
            </div>
          </div>
          <label>
            <input
              type="checkbox"
              checked={form.is_done}
              onChange={(event) => setForm({ ...form, is_done: event.target.checked })}
            />{" "}
            Insiden sudah selesai
          </label>
          {form.is_done && (
            <div className="form-group">
              <label>
                Start Up
                <RequiredAsterisk />{" "}
              </label>
              <div className="row">
                <div className="col">
                  <label>Tanggal</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    value={form.up_date}
                    onChange={(event) => setForm({ ...form, up_date: event.target.value })}
                  />
                </div>
                <div className="col-auto">
                  <label>Waktu</label>
                  <CompleteTimepicker
                    time={form.up_time}
                    onChangeTime={(time) => changeTime("up_time", time)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="explainTextArea">
              Keterangan <RequiredAsterisk />
            </label>
            <textarea
              id="explainTextArea"
              type="text"
              className="form-control"
              required
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading || dataLoading}>
            <i className="fas fa-save"></i> Simpan Perubahan
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
