import { Modal } from "react-bootstrap";
import { useOltByIdHooks } from "../../../utils/infrastructure/OltUtils";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../../Loading";

export default function ModalViewOlt({ id, show, onClose }) {
  return (
    <Modal show={show} onHide={() => onClose()}>
      <ViewOlt id={id} onClose={() => onClose()} />
    </Modal>
  );
}

function ViewOlt({ id, onClose }) {
  let { data, error, loading } = useOltByIdHooks({ id });

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Informasi OLT </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoadingInsideBoxWrapper>
          {loading && <LoadingInsideBox />}
          {error && <div className="alert alert-danger">{error}</div>}
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Nama</td>
                <td>{data.name}</td>
              </tr>
              <tr>
                <td>IP</td>
                <td>{data.ip}</td>
              </tr>
              <tr>
                <td>Port</td>
                <td>{data.port}</td>
              </tr>
              <tr>
                <td>Username</td>
                <td>{data.username}</td>
              </tr>
              <tr>
                <td>Password</td>
                <td>{data.password}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{data.description}</td>
              </tr>
            </tbody>
          </table>
        </LoadingInsideBoxWrapper>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </>
  );
}
