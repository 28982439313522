import { useState } from "react";
import { Modal } from "react-bootstrap";
import { downloadWarehouseInventories } from "../../api/inventory";
import { useInventoryCategories } from "../../utils/InventoryHooks";
import AlertBox from "../AlertBox";

export default function ModalDownloadWarehouseInventory({
  show,
  onClose,
  onSuccess,
  warehouse_id,
  warehouse_name,
}) {
  let [form, setForm] = useState({ q: "", inventory_category_id: "" });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let categories = useInventoryCategories({ page: 1, page_size: 99 });

  const handleClose = () => {
    setForm({ q: "", inventory_category_id: "" });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    downloadDataInventory(form);
  };

  const downloadDataInventory = async (form) => {
    setLoading(true);
    try {
      let response = await downloadWarehouseInventories(warehouse_id, { ...form });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Data Inventory - ${warehouse_name}.xlsx`);
      onSuccess(`Berhasil mengunduh Data Inventory - ${warehouse_name}.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };
  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Download Data Inventory Gudang</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="inventory_name_dowload">Pencarian</label>
            <input
              type="text"
              name="inventory_name_dowload"
              placeholder="SKU / nama inventory .."
              value={form.q}
              onChange={(event) => setForm({ ...form, q: event.target.value })}
              className="form-control"
              id="inventory_name_dowload"
            />
          </div>
          <div className="form-group">
            <label htmlFor="category_download">Kategori</label>
            <select
              type="text"
              name="category_download"
              value={form.inventory_category_id}
              onChange={(event) => setForm({ ...form, inventory_category_id: event.target.value })}
              className="form-control"
              id="category_download"
            >
              <option value="">-- Pilih Kategori --</option>
              {categories.categories.map((category, index) => (
                <option value={category.id} key={index}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" type="button" onClick={() => handleClose()}>
            Close
          </button>
          <button className="btn btn-primary" type="submit" disabled={loading}>
            <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
