import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { createVendorsByProjectId } from "../../api/projects";
import { useAllVendorsHooks } from "../../utils/project/VendorUtils";
import useRegionHooks from "../../utils/RegionHooks";
import AlertBox from "../AlertBox";
import { RequiredAsterisk } from "../FormHelper";

export default function ModalProjectVendorCreate({ show, onClose, onSuccess }) {
  const {id} = useParams();
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  const [form, setForm] = useState({vendor_id: '', pic: '', description: ''});
  const [tasks, setTasks] = useState([{name: '', qty: 0, done: 0}]);
  
  const address = useRegionHooks();
  const vendorList = useAllVendorsHooks();

  const handleClose = () => {
    resetForm();
    onClose();
  }

  const resetForm = () => {
    setAlert({show: false, message: '', type: ''});
    setForm({vendor_id: '', pic: '', description: ''});
    setTasks([{name: '', qty: 0, done: 0}]);
    address.resetRegion();
  }

  const addTask = () => {
    let data = [ ...tasks ];
    data.push({name: '', qty: 0, done: 0});
    setTasks(data)
  }

  const removeTask = (index) => {
    let data = [ ...tasks ];

    if (!(data.length === 1)) {
      data.splice(index, 1);
      setTasks(data);
    }
  }

  const changeFormTask = (index, name, value) => {
    let data = [ ...tasks ];
    data[index][name] = value;
    setTasks(data);
  }

  const sendDataVendor = async (formData)  => {
    try {
      let response = await createVendorsByProjectId(id, formData);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }
  }

  const onSubmitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
      tasks
    }

    sendDataVendor(formData)
  }

  return(
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <form onSubmit={event => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Vendor Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="vendorname">Vendor <RequiredAsterisk /></label>
                <select className="form-control" value={form.vendor_id} onChange={event => setForm({ ...form, vendor_id: event.target.value})} required>
                  <option value="" disabled>-- Pilih Vendor --</option>
                  {vendorList.vendors.map((vendor, index) => (
                    <option value={vendor.id} key={index}>{vendor.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="vendorpic">Nama PIC <RequiredAsterisk /></label>
                <input type="text" required value={form.pic} onChange={event => setForm({ ...form, pic: event.target.value})} className="form-control"/>
              </div>
              <div className="form-group">
                <label htmlFor="vendordesc">Deskripsi</label>
                <textarea value={form.description} onChange={event => setForm({ ...form, description: event.target.value})} className="form-control"/>
              </div>
            </div>
            <div className="col-lg-12">
              {/*  value={inventory.name} onChange={event => changeFormInventory(index, "name", event.target.value)}/ */}
              <h5 className="mb-3">Pekerjaan</h5>
              {tasks.map((task, index) => (
                <div className="row mb-2" key={index}>
                  <div className="col-8">
                    <label>Nama Pekerjaan #{index + 1} <RequiredAsterisk /></label>
                    <input type="text" required className="form-control" value={task.name} onChange={event => changeFormTask(index, "name", event.target.value)} />
                  </div>
                  <div className="col">
                    <label>Quantity <RequiredAsterisk /></label>
                    <input type="number" required className="form-control" min={0} value={task.qty} onChange={event => changeFormTask(index, "qty", event.target.value)}  />
                  </div>
                  <div className="col">
                    <label>Done <RequiredAsterisk /></label>
                    <input type="number" required className="form-control" min={0} value={task.done} onChange={event => changeFormTask(index, "done", event.target.value)}  />
                  </div>
                  <div className="col-auto">
                    <button className="btn btn-secondary btn-circle" style={{marginTop: '32px'}} type="button" onClick={() => removeTask(index)}><i className="fa fa-minus"></i></button>
                  </div>
                </div>
              ))}
              <button className="btn btn-sm btn-light mt-2" onClick={() => addTask()} type="button"><i className="fa fa-plus"></i> Tambah Pekerjaan</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">Close</button>
          <button className="btn btn-primary">Tambah Vendor Project</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}