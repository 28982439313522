import { useEffect, useState } from "react";
import moment from "moment";
import { convertToRupiah } from "../tools/numerictools";
import MainLayout from "../components/layouts/MainLayout";
import Box from "../components/Box";
import { useRegisteredCostumer } from "../utils/CostumerListHooks";
import { getRegistrationStatuses } from "../api/user";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import { useCityRegionHooks } from "../utils/RegionHooks";
import { useGroupListHooks } from "../utils/ProjectsUtilHooks";
import ModalDetail from "../components/registration/ModalDetail";
import ModalDownload from "../components/registration/ModalDownload";
import ModalEditRegistration from "../components/registration/ModalEditRegistration";
import Pagination from "../components/Pagination";
import { ModalCheckCoverage } from "../components/registration/ModalCheckCoverage";
import ModalRegistrationLog from "../components/registration/ModalRegistrationLog";

function UserRegistrationPage() {
  let { hasAccess } = useControlPermission();

  const [formFilter, setFormFilter] = useState({
    name: "",
    n: "",
    registration_type: "",
    status: "",
    date_from: "",
    date_until: "",
    project_id: "",
    survey_status: "",
    internet_package: "",
  });
  const [filter, setFilter] = useState({
    name: "",
    n: "",
    registration_type: "",
    status: "",
    province: "",
    kota_kab: "",
    date_from: "",
    date_until: "",
    project_id: "",
    survey_status: "",
    internet_package: "",
    page: 1,
    page_size: 10,
  });
  const regionFilter = useCityRegionHooks();
  const { projects } = useGroupListHooks();

  const [selectedId, setSelectedId] = useState("");
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [showModalAssign, setShowModalAssign] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalLog, setShowModalLog] = useState(false);

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [registrationStatuses, setRegistrationStatuses] = useState([]);
  useEffect(() => {
    const getTypes = async () => {
      try {
        const { data } = await getRegistrationStatuses();
        setRegistrationStatuses(data.data);
      } catch (error) {}
    };

    getTypes();
  }, []);

  const customers = useRegisteredCostumer({ filter });

  useEffect(() => {
    if (customers.errorMsg) {
      setAlert({ show: true, message: customers.errorMsg, type: "danger" });
    }
  }, [customers.errorMsg]);

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(!showModalDetail);
  };

  const toggleModalAssign = (id) => {
    setSelectedId(id);
    setShowModalAssign(!showModalAssign);
  };

  const toggleModalDownload = () => {
    setShowModalDownload(!showModalDownload);
  };
  const toggleModalEdit = (id) => {
    setSelectedId(id);
    setShowModalEdit(!showModalEdit);
  };

  const toggleModalLog = (id) => {
    setSelectedId(id);
    setShowModalLog(!showModalLog);
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const applyFilter = () => {
    let form_filter = {
      ...filter,
      ...formFilter,
      province: regionFilter.province,
      kota_kab: regionFilter.city,
      page: 1,
    };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    regionFilter.resetRegion();
    let formFilterReset = {
      name: "",
      n: "",
      registration_type: "",
      status: "",
      date_from: "",
      date_until: "",
      project_id: "",
      survey_status: "",
      internet_package: "",
    };
    let filterReset = {
      name: "",
      n: "",
      registration_type: "",
      status: "",
      province: "",
      kota_kab: "",
      date_from: "",
      project_id: "",
      date_until: "",
      survey_status: "",
      internet_package: "",
      page: 1,
      page_size: 10,
    };
    setFormFilter({ ...formFilterReset });
    setFilter({ ...filterReset });
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const onSuccess = (message) => {
    customers.getCostumers(filter);
    setAlert({ show: true, message: message, type: "success" });
  };

  const printRow = (customer, index) => {
    return (
      <tr key={index}>
        <td>
          {hasAccess("view-registration") && (
            <button
              className="btn btn-sm btn-block mb-1 btn-primary text-nowrap"
              onClick={() => toggleModalDetail(customer.id)}
            >
              <i className="fas fa-eye"></i> Lihat Detail
            </button>
          )}
          {hasAccess("check-coverage-registration") && customer.status_id <= 2 && (
            <button
              className="btn btn-sm btn-block mb-1 btn-warning text-nowrap"
              onClick={() => toggleModalAssign(customer.id)}
            >
              <i className="fas fa-search-location"></i> Pengecekan Coverage
            </button>
          )}
          {hasAccess("update-registration") && ![3, 7, 8, 9].includes(customer.status_id) && (
            <button
              className="btn btn-sm btn-block mb-1 btn-info text-nowrap"
              onClick={() => toggleModalEdit(customer.id)}
            >
              <i className="fas fa-pencil-alt"></i> Ubah Data
            </button>
          )}
          {hasAccess("view-registration-log") && (
            <button
              className="btn btn-sm btn-custom-purple btn-block text-nowrap mt-2"
              onClick={() => toggleModalLog(customer.id)}
            >
              <i className="fa fa-clock-rotate-left"></i> Riwayat Perubahan
            </button>
          )}
        </td>
        <td>{customer.status.display_name}</td>
        <td>
          <BadgeStatusSurvey
            bast_survey={customer.bast_survey}
            status={customer.status_id}
            billing={customer.billings[0]}
          />
        </td>
        <td>{customer.registration_type === "INSTALLED" ? "Pelanggan Lama" : "Pelanggan Baru"}</td>
        <td>{moment.unix(customer.createdAt).format("DD MMM YYYY HH:mm")}</td>
        <td>{customer.registration_number}</td>
        <td>{customer.internet_package.name}</td>
        <td>
          {customer.billings[0] ? `Rp. ${convertToRupiah(customer.billings[0].final_amount)}` : "-"}
        </td>
        <td>{customer.promo ? customer.promo.code : "-"}</td>
        <td>{customer.name}</td>
        <td>
          {customer.installation_address.address} RT {customer.installation_address.rt}/RW{" "}
          {customer.installation_address.rw}
        </td>
        <td>
          {customer.installation_address.addresses.kelurahan} -{" "}
          {customer.installation_address.addresses.postal_code}
        </td>
        <td>{customer.referral?.name || "-"}</td>
        <td>{customer.project?.name || "-"}</td>
      </tr>
    );
  };

  return (
    <MainLayout resource_key="registration">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Daftar Pendaftaran</h1>
            </div>
            <div className="col-auto">
              {hasAccess("export-registration") && (
                <button className="btn btn-primary" onClick={() => toggleModalDownload()}>
                  <i className="fas fa-download"></i> Unduh Data Registrasi
                </button>
              )}
            </div>
          </div>
        </div>
        <Box
          title={`Daftar Pendaftaran (Total Data: ${customers.meta.count})`}
          loading={customers.loading}
        >
          <div className="mb-4">
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select
                    name="status"
                    id="status"
                    className="form-control"
                    value={formFilter.status}
                    onChange={(event) => changeFilter("status", event.target.value)}
                  >
                    <option value="">SEMUA</option>
                    {registrationStatuses.map((type_status) => (
                      <option value={type_status.id} key={type_status.id}>
                        {type_status.display_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Status Survey</label>
                  <select
                    className="form-control"
                    value={formFilter.survey_status}
                    onChange={(event) => changeFilter("survey_status", event.target.value)}
                  >
                    <option value="">Semua</option>
                    <option value="no-survey">Belum Survey</option>
                    <option value="survey-unpaid">Sudah Survey - Belum Bayar</option>
                    <option value="survey-paid">Sudah Survey - Siap Pasang</option>
                    <option value="survey-uninstallable">Sudah Survey - Ditolak</option>
                    <option value="survey-canceled">Sudah Survey - Dibatalkan</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="costumer_name">Nama Pelanggan</label>
                  <input
                    type="text"
                    name="costumer_name"
                    value={formFilter.name}
                    onChange={(event) => changeFilter("name", event.target.value)}
                    className="form-control"
                    id="costumer_name"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="customer_number">No. Reg/Pelanggan</label>
                  <input
                    type="text"
                    name="customer_number"
                    value={formFilter.n}
                    onChange={(event) => changeFilter("n", event.target.value)}
                    className="form-control"
                    id="customer_number"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="date_from">Tanggal dari</label>
                  <input
                    type="date"
                    max={formFilter.date_until}
                    name="date_from"
                    value={formFilter.date_from}
                    onChange={(event) => changeFilter("date_from", event.target.value)}
                    className="form-control"
                    id="date_from"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="date_until">Tanggal ke</label>
                  <input
                    type="date"
                    min={formFilter.date_from}
                    name="date_to"
                    value={formFilter.date_until}
                    onChange={(event) => changeFilter("date_until", event.target.value)}
                    className="form-control"
                    id="date_until"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <label htmlFor="projectFilter">Grup/Project</label>
                <select
                  className="form-control"
                  id="projectFilter"
                  value={formFilter.project_id}
                  onChange={(event) => changeFilter("project_id", event.target.value)}
                >
                  <option value="">SEMUA</option>
                  {projects.map((project, index) => (
                    <option value={project.id} key={index}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="package_name">Nama Paket</label>
                  <input
                    type="text"
                    name="package_name"
                    value={formFilter.internet_package}
                    onChange={(event) => changeFilter("internet_package", event.target.value)}
                    className="form-control"
                    id="package_name"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <label>Provinsi</label>
                <select
                  className="form-control"
                  value={regionFilter.province}
                  onChange={(event) => regionFilter.changeProvince(event.target.value)}
                >
                  <option value="">-- Semua Provinsi --</option>
                  {regionFilter.provinces.map((province, index) => (
                    <option value={province.province} key={index}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-2">
                <label>Kabupaten</label>
                <select
                  className="form-control"
                  value={regionFilter.city}
                  onChange={(event) => regionFilter.changeCity(event.target.value)}
                >
                  <option value="">-- Semua Kabupaten --</option>
                  {regionFilter.cities.map((city, index) => (
                    <option value={city.kota_kab} key={index}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-2">
                <button
                  className="btn btn-primary"
                  onClick={() => applyFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => resetFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div style={{ overflowX: "scroll" }}>
            <table className="table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>Status</th>
                  <th>Status Survey</th>
                  <th>Jenis Pendaftar</th>
                  <th>Tanggal Pendaftaran</th>
                  <th>Nomor Registrasi</th>
                  <th>Pilihan Paket</th>
                  <th>Total Pembayaran</th>
                  <th>Promo</th>
                  <th>Nama Lengkap</th>
                  <th>Alamat Pemasangan</th>
                  <th>Kelurahan Pemasangan</th>
                  <th>Nama Sales/Affiliator</th>
                  <th>Grup/Project</th>
                </tr>
              </thead>
              <tbody>
                {customers.costumers.length === 0 && (
                  <tr>
                    <td colSpan={15} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {customers.costumers.map((costumer, index) => printRow(costumer, index))}
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            {customers.meta.count > customers.meta.page_size && (
              <Pagination
                totalpage={Math.ceil(customers.meta.count / customers.meta.page_size)}
                selectedpage={customers.meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(customers.meta.page + 1)}
                prev={() => gotoPage(customers.meta.page - 1)}
              />
            )}
          </div>
        </Box>

        <ModalDetail
          show={showModalDetail}
          id={selectedId}
          setShow={toggleModalDetail}
        ></ModalDetail>
        <ModalDownload
          show={showModalDownload}
          setShow={toggleModalDownload}
          statuses={registrationStatuses}
        ></ModalDownload>
        <ModalCheckCoverage
          show={showModalAssign}
          id={showModalAssign ? selectedId : ""}
          setShow={toggleModalAssign}
          onSuccess={onSuccess}
        ></ModalCheckCoverage>
        <ModalEditRegistration
          show={showModalEdit}
          id={showModalEdit ? selectedId : ""}
          setShow={toggleModalEdit}
          onSuccess={onSuccess}
        ></ModalEditRegistration>
        <ModalRegistrationLog
          show={showModalLog}
          id={showModalLog ? selectedId : ""}
          onClose={() => toggleModalLog(null)}
        />
      </div>
    </MainLayout>
  );
}

function BadgeStatusSurvey({ billing, bast_survey, status }) {
  if (status <= 2) {
    return <span className="badge badge-dark">Belum Cek Coverage</span>;
  }

  if (status === 8) {
    // Jika punya billing
    if (billing) {
      return (
        <>
          <span className="badge badge-info">Sudah Survey</span>
          <br />
          <span className="badge badge-danger">Dibatalkan</span>
        </>
      );
    } else {
      // Jika tidak punya billing
      // cek apakah survey dapat dipasang atau disetujui
      if (bast_survey?.installable === false || bast_survey?.installation_agreement === false) {
        return (
          <>
            <span className="badge badge-info">Sudah Survey</span>
            <br />
            <span className="badge badge-danger">Ditolak</span>
          </>
        );
      }
    }
  }

  if (billing?.status === "PAID") {
    return (
      <>
        <span className="badge badge-info">Sudah Survey</span>
        <br />
        {status === 7 || status === 9 ? (
          <span className="badge badge-primary">Sudah Dipasang</span>
        ) : (
          <span className="badge badge-success">Siap Pasang</span>
        )}
      </>
    );
  }

  if (billing?.status === "UNPAID") {
    return (
      <>
        <span className="badge badge-info">Sudah Survey</span>
        <br />
        <span className="badge badge-danger">Belum Bayar</span>
      </>
    );
  }

  if (!bast_survey) {
    return <span className="badge badge-warning">Belum Survey</span>;
  }

  return <span className="badge badge-secondary">Unknown Status</span>;
}

export default UserRegistrationPage;
