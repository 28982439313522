import { useAllNasHooks } from "../../utils/radius/NasUtils"
import Box from "../Box"

export default function BoxNasList() {
  const DataNas = useAllNasHooks({});

  return (
    <Box title="List NAS" loading={DataNas.loading}>
      <div style={{overflowX: "auto"}}>
        <table className="table table-striped table-bordered table-hover">
          <thead>
              <tr>
                <th>ID</th>
                <th>NAS name</th>
                <th>Short Name</th>
                <th>Type</th>
                <th>Ports</th>
                <th>Secret</th>
                <th>Server</th>
                <th>Community</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {DataNas.data.length === 0 && 
                <tr>
                  <td colSpan={9} className="text-center">Tidak ada data</td>
                </tr>
              }
              {DataNas.data.map((nas, index) => (
                <tr key={index}>
                  <td>{nas.id}</td>
                  <td>{nas.nasname}</td>
                  <td>{nas.shortname}</td>
                  <td>{nas.type}</td>
                  <td>{nas.ports}</td>
                  <td>{nas.secret}</td>
                  <td>{nas.server}</td>
                  <td>{nas.community}</td>
                  <td>{nas.description}</td>
                </tr>
              ))}
            </tbody>
        </table>
      </div>
    </Box>
  )
}