import { useEffect } from "react";
import { useState } from "react";
import Select from 'react-select';
import { useHelperListHooks } from "../../utils/TechniciansUtils";

export default function DropdownSelectHelper({ value, onChangeValue }) {
  const [helperOpt, setHelperOpt] = useState([{value: 'testValue', label: 'Test Helper From Code'}, {value: 'testValue2', label: 'Test Helper 2 From Code'}]);

  const { helpers } = useHelperListHooks();

  useEffect(() => {
    let helpersTemp = [];

    helpers.forEach((helperVal) => {
      let temp = {
        value: helperVal.id,
        label: helperVal.name
      }
      helpersTemp.push(temp);

      return true;
    })

    setHelperOpt(helpersTemp);
  }, [helpers]);

  return (
    <Select value={value} isClearable placeholder="Pilih Helper" required onChange={selected => onChangeValue(selected)} options={helperOpt} />
  )
}