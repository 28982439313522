import { useState } from "react";
import { Modal } from "react-bootstrap";
import { deletePartnerById } from "../../../api/projects";
import { usePartnerHooks } from "../../../utils/project/PartnerUtils";
import AlertBox from "../../AlertBox";

export default function ModalPartnerDelete({ show, id, onClose, onSuccess }) {
  let {data} = usePartnerHooks({id});
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  let [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      setAlert({show: false, message: '', type: ''});
      onClose();
    }
  }

  const onDelete = async () => {
    setLoading(true);
    try {
      let response = await deletePartnerById(id);
      onSuccess(true, response.data.message);
      handleClose();
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({show: true, message, type: 'danger'})
    }

    setLoading(false);
  }

  return(
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Hapus Data Mitra</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox { ...alert } setAlert={setAlert} />
        <h5 className="text-gray-800">Apakah anda yakin akan menghapus Mitra "{data.name}"?</h5>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>Tidak</button>
        <button className="btn btn-danger" onClick={() => onDelete()} disabled={loading}>{loading ? 'Menunggu ...' : 'Ya'}</button>
      </Modal.Footer>
    </Modal>
  )
}