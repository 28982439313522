export function ActivityStatusBadge({ status }) {
  switch (status) {
    case "open":
      return <span className="badge badge-success">Open</span>;
    case "in-progress":
      return <span className="badge badge-warning">In Progress</span>;
    case "closed":
      return <span className="badge badge-secondary">Closed</span>;
    default:
      return <span className="badge badge-dark">{status}</span>;
  }
}

export function TicketStatusBadge({ status }) {
  switch (status) {
    case "open":
      return <span className="badge badge-success">Open</span>;
    case "working":
      return <span className="badge badge-warning">Working</span>;
    case "closed":
      return <span className="badge badge-secondary">Closed</span>;
    default:
      return <span className="badge badge-dark">{status}</span>;
  }
}
