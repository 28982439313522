import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import Timepicker from '../Timepicker';
import ListTechnician from './ListTechnician';
import { assignScheduleById, getSchedulesById } from '../../api/schedules';

export default function ModalAssignDismantle({id, show, setShow, onSuccess}) {
  let [data, setData] = useState({});
  let [error, setError] = useState('');
  let [loading, setLoading] = useState(false);
  
  let [form, setForm] = useState({date: moment().format('YYYY-MM-DD'), time: '00:00'});
  let [assigned, setAssigned]= useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        let { data } = await getSchedulesById(id);
        setData(data.data);

        // TODO
        let date = moment().format('YYYY-MM-DD');
        let time = "00:00";

        if (data.data.schedule) {
          let schedule = moment(data.data.schedule);
          date = schedule.format('YYYY-MM-DD')
          time = schedule.format('HH:mm');
          setForm({date: date, time: time})
        }

        if (data.data.technicians) {
          let technicians = data.data.technicians;

          if (technicians.length > 0) {
            let schedulerTechnician = [];

            technicians.forEach(techie => {
              schedulerTechnician.push({ ...techie })
            });

            setAssigned(schedulerTechnician);
          }
        }

      } catch (error) {
        console.error(error)
        if (error.response) {
          setError(error.response.data.message)
        } else {
          setError('Terjadi kesalahan')
        }
      }
    }

    if (id && show) {
      setError('')
      getData()
    }
  }, [id, show])

  const handleClose = () => {
    setData({});
    setAssigned([]);
    setError('');
    setForm({date: moment().format('YYYY-MM-DD'), time: '00:00'})
    setShow('')
  }

  const sendData = async () => {
    setLoading(true);
    try {
      let form_data = {
        schedule: form.date + ' ' + form.time,
      }

      let technicians = [];
      if (assigned.length > 0) {
        assigned.map(technician => technicians.push({id: technician.id}))
      } 

      form_data.technicians = technicians

      let { data } = await assignScheduleById(id, form_data);
      onSuccess(data.message);
      handleClose()
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setError(data.message)
      } else {
        setError("Something Error Happened")
      }
    }
    setLoading(false);
  }

  const changeTime = (time) => {
    setForm({ ...form, time: time})
  }

  const removeAssigned = (index) => {
    let arrAssigned = [ ...assigned ];
    arrAssigned.splice(index, 1);
    setAssigned(arrAssigned);
  }

  return (
    <Modal show={show} onHide={() => handleClose()} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Penugasan Teknisi</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && 
          <div className={`alert alert-danger alert-dismissible fade show`} role="alert">
            {error}
            <button type="button" className="close"  onClick={() => setError('')} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }

        <div className="row">
          <div className="col-sm-6">
            <ListTechnician assigned={assigned} add={setAssigned} max={1} />
          </div>
          <div className="col-sm-6">
            <h5>Data Pelanggan:</h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Nama</td>
                  <td>{data.registration?.name}</td>
                </tr>
                <tr>
                  <td>Nomor Registrasi</td>
                  <td>{data.registration?.registration_number}</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h5>Jadwal Dismantle:</h5>
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="status">Tanggal</label> 
                  <input type="date" className="form-control"value={form.date} onChange={event => setForm({ ...form, date: event.target.value})} />
                </div>
                <div className="col-5">
                  <label htmlFor="status">Jam</label> 
                  <Timepicker time={form.time} onChangeTime={changeTime} />
                </div>
              </div>
            </div>

            <hr />

            <h5>Teknisi yang bertugas <span className="text-sm" style={{fontSize: '10pt'}}>(Teknisi wajib 1 orang)</span></h5>
            <table className="table table-striped tabel-hover table-bordered">
              <thead>
                <tr>
                  <th>Nama</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {assigned.length === 0 ? 
                  <tr>
                    <td colSpan="2" className="text-center"> Belum ada teknisi yang ditugaskan</td>
                  </tr> :
                  assigned.map((technician, index) => (
                    <tr key={index}>
                      <td>{technician.name}</td>
                      <td>
                        <button className="btn btn-sm btn-danger" onClick={() => removeAssigned(index)}><i className="fa fa-user-times"></i></button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-primary" disabled={assigned.length < 1 || loading} onClick={() => sendData()}>{loading ? "Menunggu ..." : "Simpan"}</button>
        <button className="btn btn-secondary" onClick={() => handleClose()}>Close</button>    
      </Modal.Footer>
    </Modal>
  )
} 