import { useEffect } from "react";
import { useState } from "react";
import { getAllHistoriesModems, getAllModems, getModemById } from "../../api/inventory";

export function useModemListHooks({ filter }) {
  const [modems, setModems] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getListModem = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllModems({ ...filter });
      setModems(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getListModem(filter);
  }, [filter]);

  return {
    modems,
    meta,
    loading,
    errorMsg,
    getListModem,
  };
}

export function useModemHistoryListHooks({ filter }) {
  const [modems, setModems] = useState([]);
  const [meta, setMeta] = useState({ count: 0, page: 1, page_size: 10 });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const getListModem = async (filter = {}) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const { data } = await getAllHistoriesModems({ ...filter });
      setModems(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getListModem(filter);
  }, [filter]);

  return {
    modems,
    meta,
    loading,
    errorMsg,
    getListModem,
  };
}

export function useModemIdHooks({ id }) {
  let [data, setData] = useState({});
  let [success, setSuccess] = useState(null);
  let [fetched, setFetched] = useState(false);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getModem(id);
    }
  }, [id]);

  const getModem = async (id) => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
    try {
      let response = await getModemById(id);
      setData(response.data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setError(data.message);
      } else {
        setError("Something Error Happened");
      }
    }

    setLoading(false);
  };

  const resetData = () => {
    setData({});
    setError("");
    setLoading(true);
    setSuccess(null);
    setFetched(false);
  };

  return {
    data,
    fetched,
    success,
    error,
    loading,
    resetData,
    getModem,
  };
}
