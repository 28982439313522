import { useEffect } from "react";

const InputMask = ({ mask, placeholder, value, onChange }) => {
  const formatValue = (input) => {
    if (!input) return input;
    const chars = input.split("");
    let output = "";
    let maskIndex = 0;

    for (let i = 0; i < chars.length && maskIndex < mask.length; i++) {
      const maskChar = mask[maskIndex];
      const inputChar = chars[i];

      if (maskChar === "#" && /\d/.test(inputChar)) {
        output += inputChar;
        maskIndex++;
      } else if (maskChar === "@" && /[a-zA-Z]/.test(inputChar)) {
        output += inputChar;
        maskIndex++;
      } else if (maskChar === "*" && /[\w]/.test(inputChar)) {
        output += inputChar;
        maskIndex++;
      } else if (maskChar === inputChar) {
        output += inputChar;
        maskIndex++;
      } else if (!/[\d@#*]/.test(maskChar)) {
        output += maskChar;
        maskIndex++;
        i--;
      }
    }

    return output;
  };

  useEffect(() => {
    // Apply mask to initial value
    const formattedInitialValue = formatValue(value);
    if (formattedInitialValue !== value) {
      onChange(formattedInitialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleChange = (event) => {
    const input = event.target.value;
    const formattedValue = formatValue(input);
    onChange(formattedValue);
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      className="form-control"
    />
  );
};

export default InputMask;
