import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import { convertToRupiah } from "../../tools/numerictools";
import { BUILDING_STATUS } from "../../tools/userdatatools";
import CustomerActivitesList from "../../components/customer/activities/CustomerActivitiesList";
import CustomerTicketList from "../../components/customer/activities/CustomerTicketList";
import CustomerBillingsList from "../../components/customer/activities/CustomerBillingList";
import { CustomerContext } from "../../utils/customer/CustomerRelationUtils";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function CustomerRelationPage() {
  const { id } = useParams();
  const { data, loading, error, fetched } = useGetCustomerHooks({ id: id });

  return (
    <MainLayout resource_key="manage-customer">
      <CustomerContext.Provider value={{ customer: data, loading, error, fetched }}>
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/pelanggan`}>
                  <i className="fa-regular fa-circle-left"></i> Kembali ke Manajemen Pelanggan
                </Link>
              </li>
            </ol>
          </nav>
          <div className="row mb-4">
            <div className="col">
              <h1 className="h4 mb-0 text-gray-800">
                Detail {data.registration?.registration_number} - {data.name}
              </h1>
            </div>
          </div>

          {loading && <div className="alert alert-warning">Loading ...</div>}
          {error && <div className="alert alert-danger">{error}</div>}
          <Box title={`Data Pelanggan`} loading={loading}>
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-4">
                <h5>Data Pelanggan</h5>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Nama Lengkap</td>
                      <td>{data.name}</td>
                    </tr>
                    <tr>
                      <td>Nomor Identitas</td>
                      <td>{data.id_number}</td>
                    </tr>
                    <tr>
                      <td>Nomor NPWP</td>
                      <td>{data.npwp_number}</td>
                    </tr>
                    <tr>
                      <td>Nomor Registrasi</td>
                      <td>{data.registration?.registration_number}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{data.email}</td>
                    </tr>
                    <tr>
                      <td>Tempat Lahir</td>
                      <td>{data.birthdate_place}</td>
                    </tr>
                    <tr>
                      <td>Status Pelanggan</td>
                      <td>
                        <CustomerActiveBadge status={data.is_active} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <h5>Data Pemasangan</h5>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Alamat</td>
                      <td>
                        {data.installation_address &&
                          data.installation_address.address +
                            " RT " +
                            data.installation_address.rt +
                            "/RW " +
                            data.installation_address.rw +
                            ", " +
                            data.installation_address.addresses.kelurahan +
                            ", " +
                            data.installation_address.addresses.kecamatan +
                            ", " +
                            data.installation_address.addresses.kota_kab +
                            ", " +
                            data.installation_address.addresses.province +
                            ", " +
                            data.installation_address.addresses.postal_code}
                      </td>
                    </tr>
                    <tr>
                      <td>Status Bangunan</td>
                      <td>{BUILDING_STATUS[data.building_ownership_status]}</td>
                    </tr>
                    <tr>
                      <td>Grup/Project</td>
                      <td>{data.project?.name || "-"}</td>
                    </tr>
                    <tr>
                      <td>Sales Affiliator</td>
                      <td>{data.referral?.name || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <h5>Data Paket</h5>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Nama Paket</td>
                      <td>{data.internet_package?.name}</td>
                    </tr>
                    <tr>
                      <td>Tipe Paket</td>
                      <td>{data.internet_package?.internet_package_type.name}</td>
                    </tr>
                    <tr>
                      <td>Varian Paket</td>
                      <td>{data.internet_package?.internet_package_varian.name}</td>
                    </tr>
                    <tr>
                      <td>Biaya Paket</td>
                      <td>Rp. {convertToRupiah(data.internet_package?.price || 0)}</td>
                    </tr>
                    <tr>
                      <td>Deposit</td>
                      <td>
                        {data.deposit_amount ? `Rp. ${convertToRupiah(data.deposit_amount)}` : `-`}
                      </td>
                    </tr>
                    <tr>
                      <td>Durasi Paket</td>
                      <td>{data.internet_package?.internet_package_varian.validity_period} hari</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Box>
          {fetched ? (
            <div className="card">
              <div className="card-body">
                <NavigationTabs />
              </div>
            </div>
          ) : null}
        </div>
      </CustomerContext.Provider>
    </MainLayout>
  );
}

const NavigationTabs = () => {
  let { id } = useParams();
  let { hasAccess } = useControlPermission();

  const [activeTab, setActiveTab] = useState(null);

  const tabs = [
    { label: "Aktivitas", href: "#", accessKey: "view-customer-crm-activity" },
    { label: "Tiket", href: "#", accessKey: "view-customer-crm-ticket" },
    { label: "Billing", href: "#", accessKey: "view-customer-crm-billing" },
  ];

  useEffect(() => {
    for (let index = 0; index < tabs.length; index++) {
      if (hasAccess(tabs[index].accessKey)) {
        setActiveTab(index);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ul className="nav nav-tabs">
        {tabs.map((tab, index) => (
          <li key={index} className="nav-item">
            {hasAccess(tab.accessKey) ? (
              <button
                onClick={(e) => {
                  if (!tab.disabled) {
                    setActiveTab(index);
                  }
                }}
                className={`nav-link ${
                  activeTab === index ? "active text-primary font-weight-bold" : ""
                } ${tab.disabled ? "disabled" : ""}`}
              >
                {tab.label}
              </button>
            ) : null}
          </li>
        ))}
      </ul>
      {activeTab === 0 ? <CustomerActivitesList id={id} /> : null}
      {activeTab === 1 ? <CustomerTicketList id={id} /> : null}
      {activeTab === 2 ? <CustomerBillingsList id={id} /> : null}
    </>
  );
};

function CustomerActiveBadge({ status }) {
  switch (status) {
    case 0:
      return <span className="badge text-md badge-warning">Isolir</span>;
    case 1:
      return <span className="badge text-md badge-success">Aktif</span>;
    case 2:
      return <span className="badge text-md badge-danger">Dismantled</span>;
    case 3:
      return <span className="badge text-md badge-success">Aktif Trial</span>;
    case 4:
      return <span className="badge text-md badge-danger">Dismantle Trial</span>;
    default:
      return <></>;
  }
}
