import { useState } from "react";
import { getAuditLog } from "../api/setting";

export function useAuditLogs() {
  const [logs, setLogs] = useState([]);
  const [meta, setMeta] = useState({count: 0, page: 1, page_size: 10});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const getLogs = async (filter = {}) => {
    setLoading(true);
    setErrorMsg('')
    try {
      const { data } = await getAuditLog({ ...filter });
      setLogs(data.data);
      setMeta(data.pagination);
    } catch (error) {
      if (error.response) {
        let { data } =  error.response;
        setErrorMsg(data.message)
      } else {
        setErrorMsg("Something Error Happened")
      }
    }
    setLoading(false);
  }

  return {
    logs,
    meta,
    loading,
    errorMsg,
    getLogs
  }
}
