import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { reminderBillingById } from "../../api/finance";
import { convertToRupiah } from "../../tools/numerictools";
import { useBillingIdHooks } from "../../utils/finance/BillingUtils";
import AlertBox from "../AlertBox";
import { BILLING_TYPE } from "../../tools/billingdatatools";

export default function ModalResendBilling({ show, id, onSuccess, onClose }) {
  const { data } = useBillingIdHooks({ id });

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);

  const [items, setItems] = useState([
    { id: "email", name: "Email", checked: false },
    { id: "whatsapp", name: "WhatsApp", checked: false },
    { id: "user-application", name: "Aplikasi MyViberlink", checked: false },
  ]);

  const handleCheckboxChange = (id) => {
    setItems(items.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item)));
  };

  const handleClose = () => {
    if (!loading) {
      setAlert({ show: false, message: "", type: "" });
      setItems(items.map((item) => ({ ...item, checked: false })));
      onClose();
    }
  };

  const confirmResend = async (formData) => {
    setLoading(true);
    try {
      let { data } = await reminderBillingById(id, formData);
      onSuccess(data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  const actionBtn = () => {
    const selectedItems = items.filter((item) => item.checked).map((item) => item.id);

    let formData = {
      notification_types: selectedItems,
    };

    console.log(formData);
    confirmResend(formData);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Kirim Ulang Billing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />

        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Nomor Billing</td>
              <td>{data.billing_number}</td>
            </tr>
            <tr>
              <td>Tipe Billing</td>
              <td>{BILLING_TYPE[data.type]}</td>
            </tr>
            <tr>
              <td>Total Pembayaran</td>
              <td>{data.final_amount && `Rp. ${convertToRupiah(data.final_amount)}`}</td>
            </tr>
            <tr>
              <td>Nomor</td>
              <td>
                {(data.type === "CUSTOMER" ||
                  data.type === "CUSTOMER-RELOCATE" ||
                  data.type === "ADDITIONAL-ITEM") &&
                  data.customer?.customer_number}
                {data.type === "REGISTRATION" && data.registration?.registration_number}
              </td>
            </tr>
            <tr>
              <td>Nama</td>
              <td>
                {(data.type === "CUSTOMER" ||
                  data.type === "CUSTOMER-RELOCATE" ||
                  data.type === "ADDITIONAL-ITEM") &&
                  data.customer?.name}
                {data.type === "REGISTRATION" && data.registration?.name}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="form-group">
          <label className="font-weight-bold">
            Pilih platform untuk kirim ulang pemberitahuan tagihan
          </label>

          {items.map((item) => (
            <div className="form-check" key={item.id}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={item.checked}
                disabled={data.type === "REGISTRATION" && item.id === "user-application"}
                id={item.id}
                onChange={() => handleCheckboxChange(item.id)}
              />
              <label className="form-check-label" for={item.id}>
                {item.name}
              </label>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>
          Tidak
        </button>
        <button className="btn btn-success" onClick={() => actionBtn()} disabled={loading}>
          {loading ? "Menunggu ..." : "Kirim Ulang"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
