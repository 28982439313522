import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import AlertBox from "../AlertBox";
import { deleteInfrastructureById } from "../../api/infrastructure";
import { useProjectPerformanceHooks } from "../../utils/infrastructure/ProjectPerformanceUtil";

export default function ModalDeleteProjectSite({ show, id, onClose, onSuccess }) {
  const { data, loading: dataLoading, error } = useProjectPerformanceHooks({ id });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    if (error) {
      setAlert({ show: true, message: error, type: "danger" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleClose = () => {
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const deleteProjectSite = async (form) => {
    setLoading(true);
    try {
      let response = await deleteInfrastructureById(id, { ...form });
      onSuccess(true, response.data.message, response.data.data);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Hapus Data Project Site</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} />
        <h5 className="text-gray-800">
          Apakah anda yakin akan menghapus data Project Site "{data.project?.name}"?
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={() => handleClose()}>
          Tidak
        </button>
        <button
          className="btn btn-danger"
          onClick={() => deleteProjectSite()}
          disabled={loading || dataLoading}
        >
          {loading ? "Menunggu ..." : "Ya"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
