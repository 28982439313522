import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useWarehouseHooks } from "../../utils/InventoryHooks";
import ModalChooseInventories from "../../components/inventory/transfer/ModalChooseInventory";
import { createBulkInventories, createBulkInventoriesModem } from "../../api/inventory";
import BackToWarehouseInventoryLink from "../../components/inventory/warehouse/BackToWarehouseLink";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function WarehouseManualAddInventoryPage() {
  let { id } = useParams();
  let { hasAccess } = useControlPermission();

  let warehouse = useWarehouseHooks({ id });

  let [form, setForm] = useState({ type: "inventory" });
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [previewInventories, setPreviewInventories] = useState([]);

  let [modal, setModal] = useState(null);

  const onChangeType = (value) => {
    setForm({ ...form, type: value });
    setPreviewInventories([]);
  };

  const onSelectInventory = (item) => {
    let items = [...previewInventories];
    items.push(item);
    setPreviewInventories([...items]);
    setModal(null);
  };

  const onChangeQty = (value, index) => {
    let items = [...previewInventories];
    items[index].qty = value;
    setPreviewInventories(items);
  };

  const onDeleteItem = (index) => {
    let items = [...previewInventories];
    items.splice(index, 1);
    setPreviewInventories(items);
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  const resetData = () => {
    setPreviewInventories([]);
  };

  const submitBulk = () => {
    let formData = {
      inventories: [],
      warehouse_id: warehouse.data.id,
    };

    formData.inventories = previewInventories.map((inventory) => {
      let temp = {};
      if (form.type !== "modem") {
        temp = {
          sku: inventory.sku,
          stock: inventory.qty,
        };
      } else {
        temp = {
          sku: inventory.sku,
          brand: inventory.brand,
          type: inventory.type,
          serial_number_modem: inventory.serial_number_modem,
          serial_number_gpon: inventory.serial_number_gpon,
        };
      }
      return temp;
    });

    bulkAddInventories(formData);
  };

  const bulkAddInventories = async (formData) => {
    setModal("loading");

    try {
      if (form.type === "inventory") {
        let { data } = await createBulkInventories(formData);
        onSuccess(data.message);
      } else if (form.type === "modem") {
        let { data } = await createBulkInventoriesModem(formData);
        onSuccess(data.message);
      }

      resetData();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setModal("null");
  };

  return (
    <MainLayout resource_key="manage-inventory">
      <div className="container-fluid">
        <BackToWarehouseInventoryLink warehouse={warehouse.data} />
        <h1 className="h3 mb-4 text-gray-800">Manual Tambah Barang - {warehouse.data.name}</h1>
        <AlertBox {...alert} setAlert={setAlert} />
        <div className="row">
          <div className="col-12">
            <Box title="Form Manual Add">
              <div className="form-group">
                <select
                  className="form-control"
                  value={form.type}
                  onChange={(event) => onChangeType(event.target.value)}
                >
                  <option value="inventory">Inventory</option>
                  <option value="modem">Modem</option>
                </select>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4>List Preview Inventory</h4>
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => setModal("inventories")}
                  >
                    Daftar Inventory
                  </button>
                </div>
              </div>
              {previewInventories.length === 0 && (
                <div className="alert alert-warning">
                  Silahkan lakukan input inventory melalui <b>Daftar Inventory</b> terlebih dahulu
                </div>
              )}
              {form.type === "modem" ? (
                <ModemTable
                  inventories={previewInventories}
                  setInventories={setPreviewInventories}
                />
              ) : (
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>Nama</th>
                      <th>Qty</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {previewInventories.length === 0 && (
                      <tr>
                        <td colSpan={4} className="text-center">
                          Belum ada data
                        </td>
                      </tr>
                    )}
                    {previewInventories.map((inventory, index) => (
                      <tr key={index}>
                        <td>{inventory.sku}</td>
                        <td>{inventory.name}</td>
                        <td>
                          <div className="row">
                            <div className="col">
                              <input
                                type="number"
                                className="form-control"
                                value={inventory.qty}
                                min={1}
                                onChange={(event) => onChangeQty(event.target.value, index)}
                              />
                            </div>
                            <div className="col-auto">{inventory.unit}</div>
                          </div>
                        </td>
                        <td>
                          <div className="btn btn-danger" onClick={() => onDeleteItem(index)}>
                            <i className="fa fa-times"></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className="row justify-content-end">
                <div className="col-12 col-md-6 d-flex justify-content-end">
                  {hasAccess("create-inventory") && (
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={() => setModal("confirm")}
                      disabled={previewInventories.length === 0}
                    >
                      <div className="fa fa-plus"></div> Tambah Inventory
                    </button>
                  )}
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <ModalChooseInventories
        show={modal === "inventories"}
        onClose={() => setModal(null)}
        onSelectInventory={onSelectInventory}
        modem={form.type === "modem"}
      />
      <ModalConfirm
        show={modal === "confirm"}
        onClose={() => setModal(null)}
        onBulkAdd={() => submitBulk()}
      />
      <LoadingModalUpload show={modal === "loading"} />
    </MainLayout>
  );
}

function ModalConfirm({ show, onClose, onBulkAdd }) {
  const handleClose = () => {
    onClose();
  };

  const upload = async () => {
    onBulkAdd();
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => event.preventDefault()}>
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi Penambahan Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Apakah anda yakin untuk melakukan penambahan inventory? Anda tidak dapat melakukan
          tindakan kembali jika terjadi kesalahan. Pastikan data anda sudah benar!
          <div className="d-flex justify-content-center py-4">
            <button className="btn btn-lg btn-success mr-2" onClick={() => upload()}>
              Ya, Saya yakin
            </button>
            <button className="btn btn-lg btn-secondary" onClick={() => handleClose()}>
              Tidak
            </button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
}

function LoadingModalUpload({ show }) {
  return (
    <Modal show={show} onHide={() => {}}>
      <div className="alert alert-warning mb-0">
        <h4>
          <i className="fa fa-hourglass"></i> Harap menunggu.
        </h4>
        <span>Inventori sedang diproses oleh sistem ...</span>
      </div>
    </Modal>
  );
}

function ModemTable({ inventories, setInventories }) {
  const duplicateSKU = (item) => {
    let items = [...inventories];
    items.push({
      ...item,
      serial_number_modem: "",
      serial_number_gpon: "",
    });
    setInventories(items);
  };
  const onChangeForm = (value, index, name) => {
    let items = [...inventories];
    items[index][name] = value;
    setInventories(items);
  };

  const onDeleteItem = (index) => {
    let items = [...inventories];
    items.splice(index, 1);
    setInventories(items);
  };

  return (
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>SKU</th>
          <th>Merk</th>
          <th>Tipe</th>
          <th>SN Modem</th>
          <th>SN GPON</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {inventories.length === 0 && (
          <tr>
            <td colSpan={6} className="text-center">
              Belum ada data
            </td>
          </tr>
        )}
        {inventories.map((inventory, index) => (
          <tr key={index}>
            <td>{inventory.sku}</td>
            <td>
              <input
                type="text"
                className="form-control"
                value={inventory.brand}
                onChange={(event) => onChangeForm(event.target.value, index, "brand")}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                value={inventory.type}
                onChange={(event) => onChangeForm(event.target.value, index, "type")}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                value={inventory.serial_number_modem}
                onChange={(event) => onChangeForm(event.target.value, index, "serial_number_modem")}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                value={inventory.serial_number_gpon}
                onChange={(event) => onChangeForm(event.target.value, index, "serial_number_gpon")}
              />
            </td>
            <td>
              <button
                className="btn btn-primary btn-sm btn-block text-nowrap"
                onClick={() => duplicateSKU(inventory)}
              >
                <i className="fa fa-copy"></i> Duplikasi SKU
              </button>
              <button
                className="btn btn-danger btn-sm btn-block text-nowrap"
                onClick={() => onDeleteItem(index)}
              >
                <i className="fa fa-times"></i> Hapus
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
