import { useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../AlertBox";
import { RequiredAsterisk } from "../FormHelper";
import { createBouncedEmail } from "../../api/setting";

export default function ModalCreateNewBounced({ show, onClose, onSuccess }) {
  let [form, setForm] = useState({
    address: "",
    description: "",
    type: "",
  });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    setForm({ address: "", description: "", type: "" });
    setAlert({ show: false, message: "", type: "" });
    onClose();
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
    };
    sendCreateOlt(formData);
  };

  const sendCreateOlt = async (form) => {
    setLoading(true);
    try {
      let response = await createBouncedEmail(form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Alamat Email Bounced Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Alamat <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.address}
              onChange={(event) => setForm({ ...form, address: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Deskripsi</label>
            <input
              type="text"
              className="form-control"
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Tipe <RequiredAsterisk />{" "}
            </label>
            <select
              className="form-control"
              value={form.type}
              onChange={(event) => setForm({ ...form, type: event.target.value })}
            >
              <option value="">-- Pilih tipe alamat --</option>
              <option value="email">EMAIL</option>
              <option value="domain">DOMAIN</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" disabled={loading} className="btn btn-primary">
            <i className="fas fa-plus"></i>{" "}
            {loading ? "Membuat Email Bounce ..." : "Buat Email Bounce Baru"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
