import { useState } from "react";
import { Modal } from "react-bootstrap";
import { requestDismantleByCustomerId } from "../../api/customer";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import AlertBox from "../AlertBox";

export default function ModalRequestDismantle({ show, id, onClose, onSuccess }) {
  const { data, loading: dataLoading } = useGetCustomerHooks({ id });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);
  let [form, setForm] = useState({ reason: "", description: "" });

  const handleClose = () => {
    if (!loading) {
      setForm({ description: "" });
      setAlert({ show: false, message: "", type: "" });
      onClose();
    }
  };

  const onSubmitDismantle = (event) => {
    event.preventDefault();

    let formData = {
      description: form.reason,
    };

    if (form.description) {
      formData.description = formData.description + " || " + form.description;
    }

    requestDismantle(formData);
  };

  const requestDismantle = async (formData) => {
    setLoading(true);
    try {
      let response = await requestDismantleByCustomerId(id, { ...formData });
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Permintaan Dismantle</Modal.Title>
      </Modal.Header>
      <form onSubmit={(event) => onSubmitDismantle(event)}>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <h5 className="text-gray-800">
            Apakah anda yakin untuk melakukan proses dismantle customer "{data.name}"?
          </h5>
          <div className="form-group">
            <label>Alasan</label>
            <select
              className="form-control"
              value={form.reason}
              onChange={(event) =>
                setForm({ ...form, reason: event.target.value, description: "" })
              }
              required
            >
              <option value="">-- Pilih alasan --</option>
              <option value="pindah">Pindah</option>
              <option value="ganti provider">Ganti Provider</option>
              <option value="kualitas jaringan">Kualitas Jaringan</option>
              <option value="pasca layanan">Pasca Layanan (CS, Sales, atau Teknisi)</option>
              <option value="keuangan pelanggan">Keuangan Pelanggan</option>
              <option value="lainnya">Lainnya</option>
            </select>
          </div>
          <div className="form-group">
            <label>Catatan</label>
            <textarea
              className="form-control"
              required={form.reason === "lainnya"}
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-default" type="button" onClick={() => handleClose()}>
            Tidak
          </button>
          <button className="btn btn-danger" type="submit" disabled={loading || dataLoading}>
            {loading ? "Menunggu ..." : "Ya"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
