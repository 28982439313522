export function LoadingInsideBoxWrapper({ children }) {
  return (
    <div className="loading-data-div">
      {children} 
    </div>
  )
}

export function LoadingInsideBox() {
  return (
    <div className="loading">
      <div className="loading-text">
        <div className="spinner-border text-secondary mr-3" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        Loading ...
      </div>
    </div>
  )
}