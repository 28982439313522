import { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { useCityRegionHooks } from "../../utils/RegionHooks";
import * as api from "../../api/account";
import ImageUploader from "../ImageUploader";
import { useRolesListHooks } from "../../utils/PermissionManageUtils";

export default function ModalUpdate(props) {
  const [form, setForm] = useState({ name: "", email: "", phone_number: "", image_url: null });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const region = useCityRegionHooks();

  const [roleOpt, setRoleOpt] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);

  const { roles } = useRolesListHooks();

  useEffect(() => {
    let rolesTemp = [];

    roles.forEach((project) => {
      let temp = {
        value: project.id,
        label: project.name,
      };
      rolesTemp.push(temp);

      return true;
    });

    setRoleOpt(rolesTemp);
  }, [roles]);

  // resetting data and populate props to form state
  useEffect(() => {
    if (props.account.id) {
      let account = { ...props.account };
      setForm({
        name: account.name,
        email: account.email,
        phone_number: account.phone_number,
        image_url: account.image_url,
      });

      if (account.addresses) {
        region.setProvince(account.addresses.province);
        region.setCity(account.address_id);
      }

      if (account.roles) {
        let roleArr = [];
        account.roles.forEach((role) => {
          roleArr.push({
            value: role.id,
            label: role.name,
          });
        });

        setSelectedRole([...roleArr]);
      }
    }
  }, [props.account.id]);

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url });
  };

  const handleChangeRole = (selected) => {
    setSelectedRole(selected);
  };

  const submitData = async (event) => {
    event.preventDefault();
    let formData = {
      ...form,
      address_id: String(region.city),
      roles: [],
    };

    if (selectedRole.length > 0) {
      formData.roles = [];
      selectedRole.forEach((role) => {
        formData.roles.push({
          id: role.value,
        });
      });
    }

    try {
      let { data } = await api.updateAccount(props.account.id, { ...formData });
      handleClose();
      props.onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message: message, type: "danger" });
    }
  };

  const handleClose = () => {
    setForm({ name: "", email: "", role: "", phone_number: "", image_url: null });
    setAlert({ show: false, message: "", type: "danger" });
    region.resetRegion();
    setSelectedRole([]);
    props.setShow();
  };

  return (
    <Modal show={props.show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Akun</Modal.Title>
      </Modal.Header>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Body>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="nameCreate">
                  {" "}
                  Nama <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form.name}
                  onChange={(event) => setForm({ ...form, name: event.target.value })}
                  id="nameCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="emailCreate">
                  {" "}
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  value={form.email}
                  onChange={(event) => setForm({ ...form, email: event.target.value })}
                  id="emailCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumbCreate">
                  Nomor Telp <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form.phone_number}
                  onChange={(event) => setForm({ ...form, phone_number: event.target.value })}
                  id="phoneNumbCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label>
                  Foto Akun <span className="text-danger">*</span>
                </label>
                <ImageUploader image={form.image_url} type="image_url" onChangeImg={onChangeImg} />
              </div>
              <div className="form-group">
                <label htmlFor="provinsi">
                  Provinsi <span className="text-danger">*</span>
                </label>
                <select
                  name="provinsi"
                  id="provinsi"
                  className="form-control"
                  value={region.province}
                  onChange={(event) => region.setProvince(event.target.value)}
                >
                  <option value="">-- Pilih Provinsi --</option>
                  {region.provinces.map((province, index) => (
                    <option key={index} value={province.province}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="kabupaten">
                  Area Kabupaten/Kota <span className="text-danger">*</span>
                </label>
                <select
                  name="kabupaten"
                  id="kabupaten"
                  className="form-control"
                  value={region.city}
                  onChange={(event) => region.setCity(event.target.value)}
                >
                  <option value="">-- Pilih Kabupaten/Kota --</option>
                  {region.cities.map((city, index) => (
                    <option key={index} value={city.id}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="project">
                  Multi Role <span className="text-danger">*</span>
                </label>
                <Select
                  value={selectedRole}
                  isMulti
                  isClearable
                  placeholder="Role"
                  required
                  onChange={(selected) => handleChangeRole(selected)}
                  options={roleOpt}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" type>
            Simpan Perubahan
          </button>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">
            Close
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
